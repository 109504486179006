<div class="loading" *ngIf="loading$ | async">
    <app-progress-spinner />
</div>

<ng-container *ngIf="vins$ | async as vins">
    <div class="vin" *ngFor="let vin of vins">
        <app-input [form]="vin" name="vin" type="vin" />
        <app-button (action)="onAccept(vin.value.vin)">
            <app-mat-icon icon="done" />
        </app-button>
    </div>
</ng-container>

<ng-container *ngIf="error$ | async as error">
    <p class="form-error">
        {{ error | translate }}
    </p>
</ng-container>
