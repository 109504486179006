import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-produkt-detail',
    templateUrl: './produkt-detail.component.html',
    styleUrls: ['./produkt-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailComponent implements OnInit {
    viewport$: Observable<Viewport>;
    viewport = Viewport;

    constructor(private readonly viewportService: ViewportService) {}

    ngOnInit(): void {
        this.viewport$ = this.viewportService.observe();
    }
}
