import { Injectable } from '@angular/core';
import { Bauteil } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailCgFeststellungBauteilFormViewFactory extends FormViewModelBaseFactory<Bauteil> {
    constructor() {
        super();
    }

    protected createField(model: Bauteil, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields.PositionenBauteileversichert.fields;
        switch (name) {
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung, {
                    updateOn: 'change',
                });
            case fields.Baugruppe.name:
                return new ViewFormControl(model.baugruppe);
            case fields.Prueftext.name:
                return new ViewFormControl(model.prueftext, {
                    updateOn: 'change',
                });
            case fields.Bilder.name:
                return new ViewFormControl(model.bilder || []);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
