import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { AcAbschluss } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailAcAbschlussFormViewFactory extends FormViewModelBaseFactory<AcAbschluss> {
    protected createField(model: AcAbschluss, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.AcAbschluss.fields;
        switch (name) {
            case fields.Adresse.name:
                return new ViewFormControl('', {
                    validators: Validators.required,
                });
            case fields.AcVersendet.name:
                return new ViewFormControl(model.acVersendet);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
