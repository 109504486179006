<ng-container [ngSwitch]="loaded$ | async">
    <ng-container *ngSwitchCase="false">
        <button (click)="onReloadClick()" mat-button>
            {{ 'audio.reload' | translate }}
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="true">
        <ng-container *ngIf="audioSrc$ | async as audioSrc">
            <audio [src]="audioSrc" (load)="onAudioLoad(audioSrc)" controls>
                {{ 'audio.unsupported' | translate }}
            </audio>
            <ng-container *ngIf="name$ | async as name">
                <em>{{ name }}</em>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
