import { Routes } from '@angular/router';
import { createOldtimerResolver } from '@modules/oldtimer/services/create-oldtimer.resolver';
import { oldtimerResolver } from '@modules/oldtimer/services/oldtimer.resolver';

export enum OldtimerRoutes {
    Oldtimer = '/oldtimer',
    Create = '/oldtimer/erstellen',
}

export const OLDTIMER_ROUTES: Routes = [
    {
        path: 'erstellen',
        resolve: {
            create: createOldtimerResolver,
        },
        loadComponent: () => import('./oldtimer.component').then((m) => m.OldtimerComponent),
    },
    {
        path: ':id/:tab',
        resolve: {
            oldtimer: oldtimerResolver,
        },
        loadComponent: () => import('./oldtimer.component').then((m) => m.OldtimerComponent),
    },
    {
        path: ':id',
        resolve: {
            oldtimer: oldtimerResolver,
        },
        loadComponent: () => import('./oldtimer.component').then((m) => m.OldtimerComponent),
    },
    {
        path: '**',
        redirectTo: '/dashboard',
    },
];
