import { Injectable } from '@angular/core';
import { Textbaustein } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../../../produkt/factory/form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class TextbausteinFormViewFactory extends FormViewModelBaseFactory<Textbaustein> {
    constructor() {
        super();
    }

    protected createField(model: Textbaustein, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Textbausteine.fields;
        switch (name) {
            case fields.Id.name:
                return new ViewFormControl(model.id);
            case fields.Kurztext.name:
                return new ViewFormControl(model.kurztext);
            case fields.Langtext.name:
                return new ViewFormControl(model.langtext);
            case fields.Produkte.name:
                return new ViewFormControl(model.produkte || []);
            case fields.Feature.name:
                return new ViewFormControl(model.feature);
            case fields.Feld.name:
                return new ViewFormControl(model.feld);
            case fields.Standard.name:
                return new ViewFormControl(model.standard);
            case fields.Verfügbarkeit.name:
                return new ViewFormControl(model.verfuegbarkeit || []);
            case fields.ErstelltVon.name:
                return new ViewFormControl(model.erstelltVon);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
