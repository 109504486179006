import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BueroEinstellungen } from '@data/api-gateway/service/einstellungen.service';
import { EinstellungenBueroFormFactory } from '@modules/einstellungen/factory';
import { EinstellungenViewModelResolveService } from '@modules/einstellungen/service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';

@Component({
    selector: 'app-einstellungen-buero-form',
    templateUrl: './einstellungen-buero-form.component.html',
    styleUrls: ['./einstellungen-buero-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinstellungenBueroFormComponent implements OnInit {
    readonly name = 'einstellungen';
    form: UntypedFormGroup;
    maxFileUploadSizeInKb = 500;

    protected readonly ButtonType = ButtonType;

    @Input()
    saving: boolean;

    @Output()
    save = new EventEmitter<BueroEinstellungen>();

    constructor(
        private readonly resolve: EinstellungenViewModelResolveService,
        private readonly factory: EinstellungenBueroFormFactory,
    ) {
        Assert.notNullOrUndefined(resolve, 'resolve');
        Assert.notNullOrUndefined(factory, 'factory');
    }

    ngOnInit(): void {
        const { buero } = this.resolve.get();

        this.form = this.factory.create(buero);
    }

    onSave(): void {
        this.save.next(this.form.value);
    }
}
