<ng-container [formGroup]="form">
    <div class="label-container">
        <label>{{ displayName | translate }}</label>
        <ng-container *ngIf="form.get(name).value as value">
            <span *ngIf="values">
                {{ valuePrefix ? (valuePrefix + values.values[value] | lowercase | translate) : values.values[value] }}
            </span>
        </ng-container>
    </div>
    <mat-button-toggle-group
        [formControlName]="name"
        [name]="name"
        (change)="change.emit($event)"
        tabindex="1"
        value="{{ defaultValue$ | async }}"
    >
        <mat-button-toggle class="button" *ngFor="let selection of selections" [value]="selection" disableRipple="true">
            {{ selection }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</ng-container>
