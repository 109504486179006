<mat-form-field class="textbausteine-field-wrapper" (click)="onFieldClick()">
    <mat-label>{{ displayName | translate }}</mat-label>
    <mat-icon
        class="open-dialog-button material-icons-outlined"
        [class.disabled]="!multiple && control.value.length >= 1"
        (click)="!multiple && control.value.length >= 1 ? {} : onAddTextbausteinClicked()"
        matSuffix
    >
        font_download
    </mat-icon>
    <mat-chip-grid #list>
        <mat-chip-row
            *ngFor="let value of control.value; let index = index; trackBy: trackByKey"
            (removed)="onChipRemoveClicked(index)"
            (click)="onChipClicked(viewControlArray.controls[index], index)"
            matTooltip="{{ value.langtext }}"
        >
            {{ value.kurztext }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
            #input
            #trigger="matAutocompleteTrigger"
            [placeholder]="displayName | translate"
            [readonly]="true"
            [id]="name"
            [formControl]="viewControl"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="list"
            [matChipInputSeparatorKeyCodes]="seperators"
            (matChipInputTokenEnd)="onOptionAdd($event, input)"
            matInput
        />
    </mat-chip-grid>
    <mat-autocomplete #autocomplete (optionSelected)="onOptionSelected($event, input)">
        <mat-option *ngFor="let option of optionsFiltered$ | async" [value]="option">
            {{ option }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<!-- Delete dialog -->
<ng-template #delete>
    <app-delete-dialog />
</ng-template>

<!-- Edit dialog -->
<ng-template #dialogEdit let-data="data">
    <app-textbausteine-dialog-edit
        [form]="data.form"
        [feature]="data.feature"
        [feld]="data.feld"
        [data]="data"
        [feldDisplayName]="displayName"
    />
</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
    <app-textbausteine-dialog
        [form]="form"
        [feature]="data.feature"
        [feld]="data.feld"
        [feldDisplayName]="displayName"
        [feature]="feature"
        [feld]="name"
        [produktArt]="produktArt"
        (textbausteinEventEmitter)="onTextbausteinAdd($event)"
    />
</ng-template>
