<div class="video-container" [hidden]="(stateChange | async) === 3">
    <div class="loading" *ngIf="(stateChange | async) === 1">
        <mat-progress-spinner mode="indeterminate" />
    </div>
    <video
        class="video"
        #video
        (playing)="onVideoPlaying()"
        width="1080"
        height="1920"
        muted
        autoplay
        playsinline
    ></video>
</div>
<div class="image-container" #container [hidden]="(stateChange | async) !== 3">
    <canvas></canvas>
    <button class="delete" (click)="onDelete()" type="button" mat-mini-fab>
        <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
    </button>
</div>
