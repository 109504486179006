<ng-container [ngSwitch]="state">
    <button class="camera-save-button" *ngSwitchCase="3" (click)="onSave()" type="button" mat-fab>
        <mat-icon>save</mat-icon>
    </button>
    <ng-container *ngSwitchDefault>
        <button class="flip" *ngIf="state === 2" (click)="onFlipClick()" type="button" mat-mini-fab color="">
            <mat-icon>flip_camera_android</mat-icon>
        </button>
        <button class="camera-trigger-button" [disabled]="state !== 2" (click)="onTrigger()" type="button" mat-fab>
            <mat-icon>photo_camera</mat-icon>
        </button>
        <button
            class="orientation"
            #orientation
            *ngIf="state === 2"
            (click)="onOrientationChange()"
            type="button"
            mat-mini-fab
            color=""
        >
            <mat-icon>
                {{ (orientation$ | async) === orientations.values['Portrait'] ? 'portrait' : 'landscape' }}
            </mat-icon>
        </button>
    </ng-container>
</ng-container>
