import { Injectable } from '@angular/core';
import { Textbaustein } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from './form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailTextbausteinFormViewFactory extends FormViewModelBaseFactory<Textbaustein> {
    protected createField(model: Textbaustein, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Auftrag.fields.Auftragsbeschreibung.fields;
        switch (name) {
            case fields.Id.name:
                return new ViewFormControl(model.id, { updateOn: 'change' });
            case fields.Kurztext.name:
                return new ViewFormControl(model.kurztext, { updateOn: 'change' });
            case fields.Langtext.name:
                return new ViewFormControl(model.langtext, { updateOn: 'change' });
            case fields.Verfuegbarkeit.name:
                return new ViewFormControl(model.verfuegbarkeit || [], { updateOn: 'change' });
            case fields.Produkte.name:
                return new ViewFormControl(model.produkte || [], { updateOn: 'change' });
            case fields.Feature.name:
                return new ViewFormControl(model.feature, { updateOn: 'change' });
            case fields.Feld.name:
                return new ViewFormControl(model.feld, { updateOn: 'change' });
            case fields.Tags.name:
                return new ViewFormControl(model.tags, { updateOn: 'change' });
            case fields.Standard.name:
                return new ViewFormControl(model.standard, { updateOn: 'change' });
            case fields.ErstelltAm.name:
                return new ViewFormControl(model.erstelltAm, { updateOn: 'change' });
            case fields.ErstelltVon.name:
                return new ViewFormControl(model.erstelltVon, { updateOn: 'change' });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
