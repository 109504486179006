<h3 *ngIf="title">{{ title | translate }}</h3>
<div class="file" *ngFor="let file of files; trackBy: trackByKey">
    <app-mini-fab class="delete" *ngIf="delete.observers.length > 0" (click)="delete.emit(file)">
        <app-mat-icon icon="close" />
    </app-mini-fab>
    <app-audio [fileId]="file" />
</div>
<ng-container *ngIf="add.observers.length > 0">
    <div class="add">
        <mat-form-field>
            <input #name [placeholder]="'audioGallery.name' | translate" matInput />
        </mat-form-field>
        <button
            [color]="timerSubscription ? 'accent' : ''"
            [matBadgeHidden]="(timer$ | async) === -1"
            [matBadge]="(timer$ | async) + 's'"
            (click)="onAction(name)"
            mat-mini-fab
        >
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-microphone" />
        </button>
    </div>
</ng-container>
