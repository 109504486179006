import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FileService } from '@app/service/file.service';
import {
    AbschlussDownloadResponse,
    AbschlussDownloadService,
    AbschlussGetResponse,
    AbschlussRechnungsArt,
    AbschlussRechnungsService,
    AbschlussService,
    AbschlussVersandArt,
    AbschlussVersandService,
    AbschlussWorkflowStatus,
    AbschlussWorkflowStep,
} from '@data/api-gateway';
import {
    ProduktArtNachbewertung,
    ProduktRechnungsArt,
    ProduktStatus,
    ProduktVersandArt,
} from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailAbschlussHelperService } from '@modules/produkt/service/produkt-detail-abschluss-helper.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject, Observable, PartialObserver, Subscription, combineLatest, iif, of, throwError } from 'rxjs';
import { concatMap, delay, finalize, first, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { ProduktDetailNachbewertungDialogComponent } from '../produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';

const STATUS_INTERVAL = 1000;

const STATUS_INTERVAL_FACTOR = [3, 2, 1, 2, 3, 5, 5, 10, 10, 15, 30];
const MESSAGE_ERROR_BILDER = 'abschluss.error.check.bilder';
const MESSAGE_ERROR_PDF = 'abschluss.error.check.pdf';
const ABSCHLUSS_TIMEOUT = 1000 * 60;

interface ProduktNachbewertungDialogData {
    produkt: Produkt;
}

@Component({
    selector: 'app-produkt-detail-abschluss-workflow',
    templateUrl: './produkt-detail-abschluss-workflow.component.html',
    styleUrls: ['./produkt-detail-abschluss-workflow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAbschlussWorkflowComponent implements OnInit, OnDestroy {
    trackByField = TrackBy.trackByField;

    @Input()
    name: string = PRODUKT_CONFIG_FEATURES.Abschluss.name;

    @Input()
    produkt: Produkt;

    @ViewChild(StepperComponent)
    stepper: StepperComponent;

    @ViewChild('abschlussResetDialog', { static: true })
    abschlussResetTemplate: TemplateRef<any>;

    @Output()
    statusChangedEvent = new EventEmitter<ProduktStatus>();

    statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

    rechnungsArt = new EnumValues(ProduktRechnungsArt);
    rechnungsArtDisabled = {
        [ProduktRechnungsArt.Manuell]: true,
    };
    rechnungsArtChanged = new BehaviorSubject<ProduktRechnungsArt>(undefined);

    versandArt = new EnumValues(ProduktVersandArt);
    versandArtDisabled = {
        [ProduktVersandArt.MitRechnung]: true,
        [ProduktVersandArt.OhneRechnung]: true,
    };
    versandArtChanged = new BehaviorSubject<ProduktVersandArt>(undefined);

    loading$ = new BehaviorSubject(false);
    downloadUrl$ = new BehaviorSubject<string>(undefined);
    showCancelAbschluss$ = new BehaviorSubject<boolean>(false);
    produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);
    currentSyncCount: number;

    protected readonly ButtonType = ButtonType;

    private subscriptions: Subscription[] = [];
    private timerShowCancelAbschluss: NodeJS.Timeout;
    @ViewChild('dialogConfirmDuplicate', { static: true })
    dialogConfirmDuplicateTemplate: TemplateRef<any>;

    @ViewChild('nachbewertungArtSelection')
    selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

    @ViewChild('dialogDataSynchronization', { static: true })
    dialogDataSynchronizationTemplate: TemplateRef<any>;

    constructor(
        private readonly router: Router,
        private readonly abschlussService: AbschlussService,
        private readonly abschlussRechnungsService: AbschlussRechnungsService,
        private readonly abschlussVersandService: AbschlussVersandService,
        private readonly abschlussDownloadService: AbschlussDownloadService,
        private readonly snackBarService: SnackBarService,
        private readonly produktService: ProduktService,
        private readonly produktDetailAbschlussHelperService: ProduktDetailAbschlussHelperService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly fileService: FileService,
    ) {
        Assert.notNullOrUndefined(abschlussService, 'abschlussService');
        Assert.notNullOrUndefined(abschlussRechnungsService, 'abschlussRechnungsService');
        Assert.notNullOrUndefined(abschlussVersandService, 'abschlussVersandService');
        Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
        Assert.notNullOrUndefined(snackBarService, 'snackbarService');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(produktDetailAbschlussHelperService, 'produktDetailAbschlussHelperService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(fileService, 'fileService');
    }

    ngOnInit(): void {
        if (this.produkt?.status) {
            this.statusChanged$.next(this.produkt.status);
        }
        this.subscriptions.push(this.checkStatus().subscribe());
        this.subscriptions.push(this.createLoadingSubscription());
        this.subscriptions.push(this.createSyncCountSubscription());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    onProduktCloseClick(): void {
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.close.failed`);
        this.subscriptions.push(this.checkStatus(this.abschlussService.post(this.produkt.id)).subscribe(observer));
    }

    onProduktOpenClick(): void {
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.reopen.failed`);
        this.subscriptions.push(
            this.checkStatus(
                this.abschlussRechnungsService.post(this.produkt.id, AbschlussRechnungsArt.Aborted),
            ).subscribe(observer),
        );
    }

    onRechungsArtSelect(rechnungsArt: ProduktRechnungsArt): void {
        Assert.notNullOrUndefined(rechnungsArt, 'rechnungsArt');

        let art = AbschlussRechnungsArt.Without;
        switch (rechnungsArt) {
            case ProduktRechnungsArt.None:
                return;
            case ProduktRechnungsArt.MitBVWS:
                art = AbschlussRechnungsArt.Bvws;
                break;
            case ProduktRechnungsArt.Manuell:
                art = AbschlussRechnungsArt.Manual;
                break;
            case ProduktRechnungsArt.Ohne:
                break;
        }

        const observer = this.getCheckStatusObserver(`${this.name}.workflow.rechnung.failed`);
        this.subscriptions.push(
            this.checkStatus(this.abschlussRechnungsService.post(this.produkt.id, art)).subscribe(observer),
        );
    }

    onRechnungUploadClick(): void {
        this.nextStep();
    }

    onVersandArtSelect(versandArt: ProduktVersandArt): void {
        Assert.notNullOrUndefined(versandArt, 'versandArt');

        let art = AbschlussVersandArt.NoDelivery;
        switch (versandArt) {
            case ProduktVersandArt.None:
                return;
            case ProduktVersandArt.OhneRechnung:
                art = AbschlussVersandArt.DeliveryWithoutInvoice;
                break;
            case ProduktVersandArt.MitRechnung:
                art = AbschlussVersandArt.DeliveryWithInvoice;
                break;
            case ProduktVersandArt.Kein:
                art = AbschlussVersandArt.NoDelivery;
                break;
        }

        const observer = this.getCheckStatusObserver(`${this.name}.workflow.versand.failed`);
        this.subscriptions.push(
            this.checkStatus(this.abschlussVersandService.post(this.produkt.id, art)).subscribe(observer),
        );
    }

    onDownloadClick(): void {
        const observer = this.getActionObserver<AbschlussDownloadResponse>(
            (response) => this.onDownloadResponse(response),
            `${this.name}.workflow.download.failed`,
        );
        this.loading$.next(true);
        this.subscriptions.push(
            this.abschlussDownloadService
                .get(this.produkt.id)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(observer),
        );
    }

    onOpenDownloadClick(): void {
        const { value } = this.downloadUrl$;
        window.open(value, '_blank');
    }

    onAbschlussResetClick(): void {
        this.openAbschlussResetConfirmDialog();
    }

    onAbschlussCancelClick(): void {
        this.resetAbschluss();
    }

    onClickDuplicate($event: MouseEvent, element: Produkt): void {
        const title = 'nachbewertung.title';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];
        const data: ProduktNachbewertungDialogData = { produkt: element };
        $event.stopPropagation();
        this.templateDialogService.closeAll();

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogConfirmDuplicateTemplate, data, true)
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                    this.loading$.next(true);
                    const selectedProduktArt = this.selectedNachbewertungArt.getSelectedValue();
                    this.produktService
                        .create(selectedProduktArt)
                        .pipe(first())
                        .subscribe((produkt) => {
                            this.produktService
                                .getDuplikat(element.id, produkt.id, selectedProduktArt)
                                .pipe(first())
                                .subscribe(
                                    (_next) => {
                                        this.loading$.next(false);
                                        this.router.navigateByUrl(`/produkt/detail/${produkt.id}/auftrag`);
                                    },
                                    (_err) => {
                                        this.loading$.next(false);
                                        console.error(_err);
                                        this.snackBarService.error('Fehler beim duplizieren des Produktes!');
                                    },
                                );
                        });
                }
            });
    }

    canAbschlussReset(): boolean {
        if (!this.produkt || !this.produkt.erstelltAm) {
            console.error('Either produkt or produkt.erstelltAm is undefined.');
            return false;
        }

        if (this.produkt.status === ProduktStatus.Beendet) {
            const currentMonth = new Date().getMonth();
            const produktCreatedMonth = new Date(this.produkt.erstelltAm).getMonth();

            return currentMonth === produktCreatedMonth;
        } else {
            return true;
        }
    }

    private openAbschlussResetConfirmDialog() {
        const title = `${this.name}.workflow.reset.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        if (!this.canAbschlussReset()) {
            buttons.pop();
        }

        this.subscriptions.push(
            this.templateDialogService
                .openTemplate(title, buttons, this.abschlussResetTemplate, null, true)
                .pipe(take(1))
                .subscribe((result) => {
                    if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                        this.resetAbschluss();
                    }
                }),
        );
    }

    private resetAbschluss() {
        const observer = this.getActionObserver(() => window.location.reload(), `${this.name}.workflow.reset.failed`);
        this.subscriptions.push(this.abschlussService.delete(this.produkt.id).subscribe(observer));
    }

    private nextStep(count: number = 1): void {
        setTimeout(() => this.onNextStep(count), 1);
    }

    private updateProdukt(): Observable<Produkt> {
        return this.produktService.getInfoById(this.produkt.id).pipe(tap((produkt) => this.onUpdateProdukt(produkt)));
    }

    private getActionObserver<T>(action: (value: T) => void, error: string): PartialObserver<T> {
        return {
            next: (value) => action(value),
            error: (ex) => {
                console.warn('An unexpected error occured while checking the status', ex);
                this.snackBarService.error(error);
            },
        };
    }

    private getCheckStatusObserver(error: string): PartialObserver<boolean> {
        return {
            next: (success: boolean) => {
                if (!success) {
                    this.snackBarService.error(error);
                }
            },
            error: (ex) => {
                console.warn('An unexpected error occured while checking the status', ex);
                this.handleCheckErrors(ex);
                this.snackBarService.error(error);
            },
        };
    }

    private checkStatus(start?: Observable<any>): Observable<boolean | Produkt> {
        if (this.produkt?.status === ProduktStatus.Beendet) {
            if (this.produkt?.rechnungsArt) {
                this.rechnungsArtChanged.next(this.produkt.rechnungsArt);
            }
            if (this.produkt?.versandArt) {
                this.versandArtChanged.next(this.produkt.versandArt);
            }
            this.nextStep(6);
            return of(true);
        }

        this.loading$.next(true);
        return (start ? start : of(null)).pipe(
            mergeMap(() =>
                this.waitUntilStep([
                    AbschlussWorkflowStep.Abgebrochen,
                    AbschlussWorkflowStep.InvoiceTypeDecision,
                    AbschlussWorkflowStep.ProductDeliveryTypeDecision,
                    AbschlussWorkflowStep.WaitForInvoice,
                    AbschlussWorkflowStep.StartBilling,
                    AbschlussWorkflowStep.FinishProduct,
                ]),
            ),
            mergeMap((reached) => iif(() => reached, this.updateProdukt(), of(null))),
            tap(() => this.nextStep(6)),
            mergeMap(() =>
                this.waitUntilStep([
                    AbschlussWorkflowStep.Abgebrochen,
                    AbschlussWorkflowStep.InvoiceTypeDecision,
                    AbschlussWorkflowStep.ProductDeliveryTypeDecision,
                    AbschlussWorkflowStep.StartBilling,
                    AbschlussWorkflowStep.FinishProduct,
                ]),
            ),
            mergeMap((reached) => iif(() => reached, this.updateProdukt(), of(null))),
            tap(() => this.nextStep(6)),
            finalize(() => this.loading$.next(false)),
        );
    }

    private waitUntilStep(requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
        return this.abschlussService.get(this.produkt.id).pipe(
            mergeMap((response) => this.onStatusResponse(response, requiredSteps)),
            retryWhen((errors) => errors.pipe(concatMap((error, retry) => this.onStatusError(error, retry)))),
        );
    }

    private onNextStep(count: number): void {
        if (this.stepper) {
            for (let i = 0; i < count; ++i) {
                this.stepper.next();
            }
        }
    }

    private onUpdateProdukt(produkt: Produkt): void {
        this.statusChanged$.next(produkt.status);
        this.statusChangedEvent.emit(produkt.status);
        this.rechnungsArtChanged.next(produkt.rechnungsArt);
        this.versandArtChanged.next(produkt.versandArt);
    }

    private onDownloadResponse(response: AbschlussDownloadResponse): void {
        if (response?.url?.length) {
            window.open(response.url, '_blank');
            this.downloadUrl$.next(response.url);
        }
    }

    private onStatusError(error: any, retry: number): Observable<void> {
        const retryDelay = (STATUS_INTERVAL_FACTOR[retry] || 30) * STATUS_INTERVAL;
        return of(error).pipe(delay(retryDelay));
    }

    private onStatusResponse(
        response: AbschlussGetResponse,
        requiredSteps: AbschlussWorkflowStep[],
    ): Observable<boolean> {
        if (!response) {
            return throwError(() => new Error('could not retrieve status.'));
        }
        const { status } = response;
        if (status === AbschlussWorkflowStatus.None || status === AbschlussWorkflowStatus.Aborted) {
            return of(true);
        }
        if (!response.details || !response.details.step) {
            return throwError(() => new Error('could not retrieve details.'));
        }

        const { details } = response;
        if (status !== AbschlussWorkflowStatus.Running && status !== AbschlussWorkflowStatus.Succeeded) {
            return of(false);
        }

        const { step } = details;
        if (!requiredSteps.includes(step)) {
            return throwError(() => new Error('step not reached yet.'));
        }
        return of(true);
    }

    private restartTimerShowCancelAbschluss(): void {
        clearTimeout(this.timerShowCancelAbschluss);
        const that = this;

        this.timerShowCancelAbschluss = setTimeout(function () {
            that.showCancelAbschluss$.next(true);
        }, ABSCHLUSS_TIMEOUT);
    }

    private clearTimerShowCancelAbschluss(): void {
        clearTimeout(this.timerShowCancelAbschluss);
        this.showCancelAbschluss$.next(false);
    }

    private createLoadingSubscription(): Subscription {
        return combineLatest([this.statusChanged$, this.loading$])
            .pipe(
                map(([status, loading]) => {
                    if (!status || loading) {
                        this.restartTimerShowCancelAbschluss();
                    } else {
                        this.clearTimerShowCancelAbschluss();
                    }
                }),
            )
            .subscribe();
    }

    private createSyncCountSubscription(): Subscription {
        return this.fileService
            .syncCount()
            .pipe(tap((syncCount) => (this.currentSyncCount = syncCount)))
            .subscribe();
    }

    private handleCheckErrors(response: HttpErrorResponse) {
        if (response?.error) {
            switch (response?.error?.message) {
                case MESSAGE_ERROR_BILDER:
                case MESSAGE_ERROR_PDF: {
                    this.openSynchronizationDialog();
                }
            }
        }
    }

    private openSynchronizationDialog() {
        const title = `${this.name}.workflow.synchronization.dialog.title`;
        const buttons = [this.templateDialogService.getCancelButtonSetting()];
        if (this.currentSyncCount > 0) {
            buttons.push(this.templateDialogService.getSynchronizeButtonSetting());
        }

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogDataSynchronizationTemplate, null, true)
            .pipe(first())
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSynchronizeButtonSetting().title) {
                    this.produktDetailAbschlussHelperService.onSyncClicked(this.loading$);
                }
            });
    }
}
