import { Injectable } from '@angular/core';
import { ProduktArt, RadLagerort } from '@data/domain/schema/enum';
import { RadSatz } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailRaederRadFormViewFactory } from './produkt-detail-raeder-rad-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailRaederRadSatzFormViewFactory extends FormViewModelBaseFactory<RadSatz> {
    constructor(private readonly radFormViewFactory: ProduktDetailRaederRadFormViewFactory) {
        super();
        Assert.notNullOrUndefined(radFormViewFactory, 'radFormViewFactory');
    }

    protected createField(
        model: RadSatz,
        name: string,
        produktArt: ProduktArt = ProduktArt.None,
    ): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.fields;
        if (name === fields.Lagerort.name) {
            return new ViewFormControl(model.lagerort || RadLagerort.Montiert);
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(
        model: RadSatz,
        arrayName: string,
        arrayFields: FeatureFields,
        produktArt: ProduktArt,
    ): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.fields;
        if (arrayName === fields.Rads.name) {
            const rads = model.rads || [];
            return new ViewFormArray(rads.map((rad) => this.radFormViewFactory.create(rad, arrayFields, produktArt)));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
