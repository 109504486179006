import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { VersionEvent } from '@angular/service-worker';
import { SwService } from '@app/service/sw.service';
import { ProduktIcon } from '@data/domain/schema/enum';
import { TranslateService } from '@ngx-translate/core';
import { Assert } from '@shared/helper/assert';
import { LoadingSpinnerGlobalService } from '@shared/service/loading-spinner-global.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly translate: TranslateService,
        private readonly snackbar: SnackBarService,
        private readonly loadingSpinnerGlobalService: LoadingSpinnerGlobalService,
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer,
        private readonly sw: SwService,
    ) {
        Assert.notNullOrUndefined(translate, 'translate');
        Assert.notNullOrUndefined(snackbar, 'snackbar');
        Assert.notNullOrUndefined(loadingSpinnerGlobalService, 'loadingSpinnerGlobalService');
        Assert.notNullOrUndefined(matIconRegistry, 'matIconRegistry');
        Assert.notNullOrUndefined(domSanitizer, 'domSanitizer');
        Assert.notNullOrUndefined(sw, 'sw');

        this.registerDashboardIcons();
        this.loading$ = this.loadingSpinnerGlobalService.get();
    }

    private swUpdateSubscription: Subscription;

    ngOnInit(): void {
        this.translate.setDefaultLang('de');
        this.translate.use('de');

        this.swUpdateSubscription = this.sw
            .versionUpdates()
            .pipe(
                mergeMap((ev: VersionEvent) => {
                    switch (ev.type) {
                        case 'VERSION_DETECTED':
                            this.snackbar.info('sw.download');
                            return EMPTY;
                        case 'VERSION_READY':
                            return this.snackbar.info('sw.update', null, 'sw.install', null);
                        case 'VERSION_INSTALLATION_FAILED':
                            return this.snackbar.error('sw.install.error', null, 'sw.retry', null);
                        default:
                            return EMPTY;
                    }
                }),
                mergeMap(() => this.sw.activateUpdate()),
                tap(() => document.location.reload()),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.swUpdateSubscription.unsubscribe();
    }

    // eslint-disable-next-line max-lines-per-function,max-statements
    private registerDashboardIcons(): void {
        this.matIconRegistry.addSvgIcon(
            'bewertung',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/bewertung.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'dynarex-on-demand',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/dynarex-on-demand.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'gtue-classic',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/gtue-classic.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'gtue-modul',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/gtue-modul.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'kfz-haftpflichtschaden',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/kfz-haftpflichtschaden.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'kfz-kaskoschaden',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/kfz-kaskoschaden.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'kostenvoranschlag',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/kostenvoranschlag.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'ruecknahmebewertung',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/ruecknahmebewertung.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'zustandsbericht',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/zustandsbericht.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'car-garantie',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/car_garantie.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'product_add',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/product_add.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'product_my',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/product_my.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'alphacontroller',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/alphacontroller.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'product_gtue',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/product_gtue.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'settings',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/settings.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'settings_my',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/settings_my.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'text-module-settings',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/text-module-settings.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'gtue_logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/GTUE_Logo_1c.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            ProduktIcon.Oldtimer,
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/oldtimer.svg'),
        );
    }
}
