import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-layered-icon',
    templateUrl: './layered-icon.component.html',
    styleUrls: ['./layered-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayeredIconComponent {
    @Input()
    icon: string;
}
