import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { ViewFormControlFormatter } from '@shared/helper/form-controls/view-form-control';

@Injectable({
    providedIn: 'root',
})
export class CurrencyFormatterService implements ViewFormControlFormatter<number> {
    constructor(private readonly decimalPipe: DecimalPipe) {
        Assert.notNullOrUndefined(decimalPipe, 'decimalPipe');
    }

    format(rawValue: number): string {
        if (rawValue === undefined || rawValue === null) {
            return undefined;
        }
        return this.decimalPipe.transform(rawValue, '0.2-2');
    }

    parse(viewValue: string): number {
        if (viewValue === undefined || viewValue === null || viewValue === '') {
            return undefined;
        }
        return +viewValue.split('.').join('').replace(',', '.');
    }
}
