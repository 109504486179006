<nav
    [class.disabled]="disabled"
    [tabPanel]="tabPanel"
    (swipeLeft)="onLeft()"
    (swipeRight)="onRight()"
    mat-tab-nav-bar
    color="accent"
>
    <a
        class="link"
        #rla="routerLinkActive"
        *ngFor="let link of links; trackBy: trackByPath"
        [id]="link.id"
        [routerLink]="link.path"
        [active]="rla.isActive"
        mat-tab-link
        routerLinkActive
    >
        <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: link }" />
        &nbsp;{{ link.title }}
    </a>
    <ng-content />
</nav>
<mat-divider />
