import { OLDTIMER_NOTIZ_FIELDS } from '@data/domain/fragments/notiz.fragment';
import { TEXTBAUSTEIN_FIELDS } from '@data/domain/fragments/textbaustein.fragment';
import gql from 'graphql-tag';

export const getOldtimerProduktQuery = gql`
    query OldtimerProdukt($id: ID!) {
        oldtimerProdukt(id: $id) {
            id
            art
            idParent
            status
            erstelltAm
            abgeschlossenAm
            abgeschlossenVon
            anzahlBilder
            auftrag {
                auftragsbeschreibung {
                    ...TextbausteinFields
                }
                auftragsnummer
                vorgangsnummer
                auftragsdatum
                fertigstellungsdatum
                besichtigungen {
                    besichtigungsdatum
                    besichtigtDurch
                    strasseHausnummer
                    postleitzahl
                    ort
                    besichtigungsbemerkungen {
                        ...TextbausteinFields
                    }
                    besichtigungsbedingungen {
                        ...TextbausteinFields
                    }
                }
            }
            notizen {
                ...OldtimerNotizFields
            }
        }
    }

    ${OLDTIMER_NOTIZ_FIELDS}
    ${TEXTBAUSTEIN_FIELDS}
`;
