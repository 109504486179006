import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-chip',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
    @Input()
    displayName: string;
}
