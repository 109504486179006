import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProduktArt, RadLagerort, RadPosition } from '@data/domain/schema/enum';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';

enum RadMapper {
    // @ts-ignore
    0 = 0, // @ts-ignore
    1 = 1, // @ts-ignore
    2 = 3, // @ts-ignore
    3 = 2,
}

@Component({
    selector: 'app-produkt-detail-raeder-radsatz',
    templateUrl: './produkt-detail-raeder-radsatz.component.html',
    styleUrls: ['./produkt-detail-raeder-radsatz.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailRaederRadsatzComponent {
    private selectedRadIndex = 0;

    trackByField = TrackBy.trackByField;
    trackByIndex = TrackBy.trackByIndex;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    produktArt: ProduktArt;

    @Input()
    fields: FeatureFields;

    position = new EnumValues(RadPosition);
    lagerort = new EnumValues(RadLagerort);

    onSelectedRadIndexChange(selectedRadIndex: number): void {
        Assert.notNullOrUndefined(selectedRadIndex, 'selectedRadIndex');
        const mappedRadIndex = RadMapper[selectedRadIndex];
        this.selectedRadIndex = Number(mappedRadIndex);
    }

    onCopyAllClick(): void {
        this.copyAll();
    }

    onCopyAxisClick(): void {
        this.copyAxis();
    }

    private copyAll(): void {
        const rads = this.getRads();
        const { selectedRadValue } = this.getSelectedRad(rads);
        for (let i = 0; i < rads.length; ++i) {
            const rad = rads.get([i]) as ViewFormGroup;
            selectedRadValue.radPosition = this.getRadPosition(rad);
            rad.setRawValue(selectedRadValue);
        }
    }

    private copyAxis(): void {
        const achsenMap = {
            [RadPosition.VL]: RadPosition.VR,
            [RadPosition.VR]: RadPosition.VL,
            [RadPosition.HR]: RadPosition.HL,
            [RadPosition.HL]: RadPosition.HR,
        };

        const rads = this.getRads();
        const { selectedRad, selectedRadValue } = this.getSelectedRad(rads);
        const selectedRadPosition = this.getRadPosition(selectedRad);

        for (let i = 0; i < rads.length; ++i) {
            const rad = rads.get([i]) as ViewFormGroup;
            const radPosition = this.getRadPosition(rad);
            if (radPosition === achsenMap[selectedRadPosition]) {
                selectedRadValue.radPosition = radPosition;
                rad.setRawValue(selectedRadValue);
            }
        }
    }

    private getRads(): ViewFormArray {
        return this.form.get(PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.fields.Rads.name) as ViewFormArray;
    }

    private getSelectedRad(rads: ViewFormArray): {
        selectedRad: ViewFormGroup;
        selectedRadValue: any;
    } {
        const selectedRad = rads.get([this.selectedRadIndex]) as ViewFormGroup;
        const selectedRadValue = selectedRad.getRawValue();
        return {
            selectedRad,
            selectedRadValue,
        };
    }

    private getRadPosition(selectedRad: ViewFormGroup): number {
        return selectedRad.get(PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.fields.Rads.fields.RadPosition.name)
            .value;
    }
}
