import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { VinComponent } from '@shared/module/material/component/vin/vin.component';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-input-search',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearchComponent extends FormControlBase {
    @Input()
    autoTabLength: number;

    @Input()
    autoTabIndexSkip = 1;

    @Input()
    type: string;

    @Input()
    inputmode: 'numeric' | 'decimal';

    @Input()
    minlength: number;

    @Input()
    maxlength: number;

    @Input()
    readonly: boolean;

    @Input()
    disabled: boolean;

    @Input()
    placeholder: string;

    @Output()
    searchClick = new EventEmitter<string>();

    @Output()
    resetClick = new EventEmitter();

    @ViewChild(MatInput, { read: ElementRef })
    input: ElementRef<HTMLElement>;

    @ViewChild(VinComponent)
    vin: VinComponent;

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        const currentValue = this.form.get(this.name).value?.length;
        if (this.maxlength && currentValue && currentValue >= this.maxlength) {
            if (event.code !== 'Backspace') {
                event.preventDefault();
            }
            return;
        }
        // tslint:disable-next-line
        if (!(event.key === 'Enter' || event.keyCode === 13)) {
            return;
        }

        if (!event.shiftKey) {
            this.next();
        } else {
            this.prev();
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        // tslint:disable-next-line
        if (!this.autoTabLength) {
            return;
        }

        switch (event.code) {
            case 'Delete':
            case 'Backspace':
            case 'Space':
            case 'Tab':
            case 'Enter':
            case 'ShiftLeft':
                return;
        }

        const target = event.target as HTMLInputElement | null;

        if (target.value.length === Number(this.autoTabLength)) {
            this.next(this.autoTabIndexSkip);
        }
    }

    constructor(formControlFocusService: FormControlFocusService) {
        super(formControlFocusService);
    }

    blur(): void {
        if (this.input) {
            this.input.nativeElement.blur();
        } else if (this.vin) {
            this.vin.blur();
        }
    }

    focus(): void {
        if (this.input) {
            this.input.nativeElement.focus();
        } else if (this.vin) {
            this.vin.focus();
        }
    }

    onSearch(): void {
        const value = this.form.get(this.name).value;
        this.searchClick.emit(value);
    }

    onReset(): void {
        this.form.get(this.name).setValue(null);
        this.resetClick.emit();
    }

    isSearchDisabled(): boolean {
        const value = this.form.get(this.name).value;
        if (!value || value.length < this.minlength || value.length > this.maxlength) {
            return true;
        } else {
            return false;
        }
    }

    isResetDisabled(): boolean {
        const value = this.form.get(this.name).value;
        if (value && value.length > 0) {
            return false;
        } else {
            return true;
        }
    }
}
