import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-table-column',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnComponent {
    @Input()
    name: string;

    @Input()
    displayName: string;

    @Input()
    sort: boolean;

    @Input()
    start: 'asc' | 'desc';

    @ContentChild(TemplateRef, { static: true })
    template: TemplateRef<any>;
}
