import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { ProduktFeatureService } from '@data/domain/service/feature/produkt-feature.service';
import { ProduktService } from '@data/domain/service/produkt.service';
import { GraphQLResponse, saveAbschluss, SaveAbschlussData } from '../../graphql/mutations';
import { Abschluss, AbschlussInput, Produkt } from '../../schema/type';

@Injectable({
    providedIn: 'root',
})
export class ProduktAbschlussService extends ProduktFeatureService<Abschluss, AbschlussInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Abschluss): AbschlussInput {
        const input: AbschlussInput = {
            id: produktId,
            fazit: (feature.fazit || []).map((fazit, fazitIndex) => ({
                ...fazit,
            })),
            abschlussBemerkung: (feature.abschlussBemerkung || []).map(
                (abschlussBemerkung, abschlussBemerkungIndex) => ({
                    ...abschlussBemerkung,
                }),
            ),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveAbschluss;
    }

    protected getSaveVariables(input: AbschlussInput): any {
        return {
            abschluss: input,
        };
    }

    protected getSaveOptimisticResponse(input: AbschlussInput): any {
        return {
            saveAbschluss: {
                ...input,
                __typename: 'Abschluss',
                fazit: (input.fazit || []).map((fazit) => ({
                    __typename: 'Textbaustein',
                    ...fazit,
                })),
                abschlussBemerkung: (input.abschlussBemerkung || []).map((abschlussBemerkung) => ({
                    __typename: 'Textbaustein',
                    ...abschlussBemerkung,
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveAbschlussData>): Abschluss {
        return response.data.saveAbschluss;
    }

    protected update(produkt: Produkt, feature: Abschluss): void {
        produkt.abschluss.id = feature.id;
        produkt.abschluss.fazit = feature.fazit;
        produkt.abschluss.abschlussBemerkung = feature.abschlussBemerkung;
    }
}
