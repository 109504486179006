import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { deleteAdressenNotiz, saveAdressenNotiz } from '@data/domain/graphql/mutations';
import { Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '../../produkt.service';
import { ProduktFeatureNotizenService } from './produkt-feature-notizen.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktAdressenNotizenService extends ProduktFeatureNotizenService {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    getNotizen(produkt: Produkt): Notiz[] {
        if (!produkt.adressen.notizen) {
            produkt.adressen.notizen = [];
        }
        return produkt.adressen.notizen;
    }

    protected getSaveNotizMutation(): any {
        return saveAdressenNotiz;
    }

    protected getSaveNotizMutationName(): string {
        return 'saveAdressenNotiz';
    }

    protected getDeleteNotizMutation(): any {
        return deleteAdressenNotiz;
    }

    protected getDeleteNotizMutationName(): any {
        return 'deleteAdressenNotiz';
    }
}
