import { Injectable } from '@angular/core';
import { AuftragArt } from '@data/domain/schema/enum';
import { VtiAuftrag } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailVtiAuftragFormViewFactory extends FormViewModelBaseFactory<VtiAuftrag> {
    constructor() {
        super();
    }

    protected createField(model: VtiAuftrag, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.VtiAuftrag.fields;
        switch (name) {
            case fields.Art.name:
                return new ViewFormControl(model.art || AuftragArt.Partner);
            case fields.Nummer.name:
                return new ViewFormControl(model.nummer);
            case fields.Vorgangsnummer.name:
                return new ViewFormControl(model.vorgangsnummer);
            case fields.Auftragstyp.name:
                return new ViewFormControl(model.auftragstyp);
            case fields.BezugsAuftragsnummer.name:
                return new ViewFormControl(model.bezugsAuftragsnummer);
            case fields.Bemerkung.name:
                return new ViewFormControl(model.bemerkung, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
