<app-card [class.mobile]="(viewport$ | async) === viewport.Mobile">
    <h4>Textbausteine verwalten</h4>

    <ng-container *ngFor="let config of textbausteineConfigList">
        <app-accordion [multi]="true">
            <app-expansion-panel>
                <app-expansion-header>
                    <app-panel-title class="title">
                        <ng-container>
                            <app-layered-icon [icon]="config.icon" />
                            <div class="name">
                                <span>{{ config.featureDisplayName }}</span>
                                <span>-</span>
                                <strong>{{ config.feldDisplayName }}</strong>
                            </div>
                        </ng-container>
                    </app-panel-title>
                </app-expansion-header>
                <app-textbausteine-verwalten-list [feature]="config.feature" [feld]="config.feld" />
            </app-expansion-panel>
        </app-accordion>
    </ng-container>
</app-card>
