import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveWerte, SaveWerteData } from '../../graphql/mutations';
import { Produkt, Werte, WerteInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktWerteService extends ProduktFeatureService<Werte, WerteInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Werte): WerteInput {
        const input: WerteInput = {
            ...feature,
            id: produktId,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveWerte;
    }

    protected getSaveVariables(input: WerteInput): any {
        return {
            werte: input,
        };
    }

    protected getSaveOptimisticResponse(input: WerteInput): any {
        return {
            saveWerte: {
                ...input,
                __typename: 'Werte',
                manuelleWerte: (input.manuelleWerte || []).map((manuellerWert) => ({
                    ...manuellerWert,
                    __typename: 'ManuellerWert',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveWerteData>): Werte {
        return response.data.saveWerte;
    }

    protected update(produkt: Produkt, feature: Werte): void {
        produkt.werte = {
            ...produkt.werte,
            ...feature,
        };
    }
}
