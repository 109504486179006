import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProduktStatus } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktDetailAbschlussWorkflowSimpleComponent } from '@modules/produkt/component/produkt-detail-abschluss-workflow-simple/produkt-detail-abschluss-workflow-simple.component';

@Component({
    selector: 'app-produkt-detail-vti-abschluss-workflow',
    templateUrl: './produkt-detail-vti-abschluss-workflow.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailVtiAbschlussWorkflowComponent extends ProduktDetailAbschlussWorkflowSimpleComponent {
    @Input()
    name: string;

    @Input()
    produkt: Produkt;

    @Output()
    statusChangedEvent = new EventEmitter<ProduktStatus>();
}
