import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileData } from '@app/class/file-data';
import { readFile } from '@app/function/file';
import { CameraViewComponent, CameraViewState } from '@shared/component/camera/camera-view/camera-view.component';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';

export interface CaptureDialogSettings {
    title: string;
    skipSave?: boolean;
}

@Component({
    selector: 'app-capture-dialog',
    templateUrl: './capture-dialog.component.html',
    styleUrls: ['./capture-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptureDialogComponent implements OnInit, AfterViewInit {
    captureSupported: boolean;
    getUserMediaSupported: boolean;

    state$ = new BehaviorSubject<CameraViewState>(CameraViewState.Loading);

    @ViewChild('files', { static: true })
    files: ElementRef<HTMLInputElement>;

    @ViewChild('camera')
    camera: CameraViewComponent;

    constructor(
        private readonly dialogRef: MatDialogRef<CaptureDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        readonly settings: CaptureDialogSettings,
    ) {
        Assert.notNullOrUndefined(dialogRef, 'dialogRef');
        Assert.notNullOrUndefined(settings, 'settings');
    }

    ngOnInit(): void {
        const input = document.createElement('input');
        input.setAttribute('capture', 'true');
        this.captureSupported = !!input.capture;
        this.getUserMediaSupported = !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
    }

    ngAfterViewInit(): void {
        if (this.captureSupported) {
            this.files.nativeElement.click();
        }
    }

    onChange(): void {
        const files = this.files.nativeElement.files;
        if (files && files.length > 0) {
            const fileReads$: Observable<FileData<ArrayBuffer>>[] = [];
            for (let i = 0; i < files.length; ++i) {
                const file = files.item(i);
                fileReads$.push(readFile(file));
            }
            this.dialogRef.close(forkJoin(fileReads$));
        }
    }

    onImageSave(file: File): void {
        Assert.notNullOrUndefined(file, 'file');
        const fileReads$: Observable<FileData<ArrayBuffer>>[] = [];
        fileReads$.push(readFile(file));
        this.dialogRef.close(forkJoin(fileReads$));
    }

    onStateChange(state: CameraViewState): void {
        Assert.notNullOrUndefined(state, 'state');
        this.state$.next(state);
    }
}
