import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { VinComponent } from '@shared/module/material/component/vin/vin.component';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends FormControlBase implements OnInit {
    @Input()
    autoTabLength: number;

    @Input()
    autoTabIndexSkip = 1;

    @Input()
    type: string;

    @Input()
    inputmode: 'numeric' | 'decimal';

    @Input()
    readonly: boolean;

    @Input()
    disabled: boolean;

    @Input()
    vinDisabled = false;

    @Input()
    placeholder: string;

    @Input()
    onlyNumber = false;

    @Input()
    maxLength = 250;

    @ViewChild(MatInput, { read: ElementRef })
    input: ElementRef<HTMLElement>;

    @ViewChild(VinComponent)
    vin: VinComponent;

    required = false;

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (!(event.key === 'Enter' || event.keyCode === 13)) {
            return;
        }
        if (!event.shiftKey) {
            this.next();
        } else {
            this.prev();
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        if (!this.autoTabLength) {
            return;
        }

        switch (event.code) {
            case 'Delete':
            case 'Backspace':
            case 'Space':
            case 'Tab':
            case 'Enter':
            case 'ShiftLeft':
                return;
        }

        const target = event.target as HTMLInputElement | null;

        if (target?.value?.length === Number(this.autoTabLength)) {
            this.next(this.autoTabIndexSkip);
        }
    }

    constructor(formControlFocusService: FormControlFocusService) {
        super(formControlFocusService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.setRequired();
    }

    blur(): void {
        if (this.input?.nativeElement) {
            this.input.nativeElement.blur();
        } else if (this.vin) {
            this.vin.blur();
        }
    }

    focus(): void {
        if (this.input?.nativeElement) {
            this.input.nativeElement.focus();
        } else if (this.vin) {
            this.vin.focus();
        }
    }

    private setRequired(): void {
        if (!this.form || !this.control) {
            return;
        }

        const validator = this.control?.validator?.({} as AbstractControl);
        if (validator?.required) {
            this.required = true;
        }
    }
}
