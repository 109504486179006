import { Injectable } from '@angular/core';
import { UnterlagenPosition } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailUnterlagenPositionFormViewFactory extends FormViewModelBaseFactory<UnterlagenPosition> {
    protected createField(model: UnterlagenPosition, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Unterlagen.fields.Positionen.fields;
        switch (name) {
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Bilder.name:
                return new ViewFormControl(model.bilder || []);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
