import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormControl } from '@angular/forms';
import { FileValidationService, FileValidationTypes } from '@app/service/file-validation.service';
import { FileService } from '@app/service/file.service';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { ProduktArt, ProduktStatus } from '@data/domain/schema/enum';
import { Notiz, Textbaustein, Uebersicht, UebersichtInput } from '@data/domain/schema/type';
import { ProduktUebersichtService } from '@data/domain/service/feature';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { ProduktDetailUebersichtFormViewFactory } from '@modules/produkt/factory/produkt-detail-uebersicht-form-view.factory';
import { ProduktDetailUebersichtSonstigesFormViewFactory } from '@modules/produkt/factory/produkt-detail-uebersicht-sonstiges-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import {
    FileGalleryAddType,
    FileGalleryUpdateEvent,
} from '@shared/component/layout/file-gallery/file-gallery.component';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { CaptureDialogService } from '@shared/service/capture-dialog.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { UploadDialogService } from '@shared/service/upload-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { Scene } from 'three';

interface ProduktDetailUebersichtSonstigesDialogData {
    form: ViewFormGroup;
    fields: FeatureFields;
    produktArt: ProduktArt;
    textbaustein?: Textbaustein;
}

@Component({
    selector: 'app-produkt-detail-uebersicht',
    templateUrl: './produkt-detail-uebersicht.component.html',
    styleUrls: ['./produkt-detail-uebersicht.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailUebersichtComponent
    extends ProduktDetailFeatureInputComponent<Uebersicht, UebersichtInput>
    implements OnInit, OnDestroy {
    trackByField = TrackBy.trackByField;
    trackById = TrackBy.trackById;

    notizen$: Observable<Notiz[]>;
    viewport$: Observable<Viewport>;
    viewport = Viewport;
    statusEnum = ProduktStatus;

    scene: Scene;

    icon$ = new BehaviorSubject('photo_camera');
    iconSonstiges = 'add';
    count$: Observable<number>;

    uploadArt$ = new BehaviorSubject<FileGalleryAddType>(FileGalleryAddType.Capture);
    fileGalleryAddType = FileGalleryAddType;

    isModelLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    modelFileConfigs: ModelFileConfig[];
    hasImages$ = new BehaviorSubject<boolean>(false);

    modelDisabled$ = new BehaviorSubject<boolean>(false);

    subscriptions: Subscription[] = [];

    sonstigesFields: FeatureFields;
    sonstigeFields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Sonstiges.fields;
    sonstiges: ViewFormArray;
    vehicleFileSrc: string;

    fahrzeugVorhandenName = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.FahrzeugVorhanden.name;
    fahrzeugVorhanden$ = new BehaviorSubject<boolean>(true);

    protected readonly ProduktArt = ProduktArt;

    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    @ViewChild('dialogDeleteAll', { static: true })
    dialogDeleteAllTemplate: TemplateRef<any>;

    private sonstigesName = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Sonstiges.name;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktUebersichtService: ProduktUebersichtService,
        private readonly uebersichtFormViewFactory: ProduktDetailUebersichtFormViewFactory,
        private readonly uebersichtSonstigesFormViewFactory: ProduktDetailUebersichtSonstigesFormViewFactory,
        private readonly uploadService: UploadDialogService,
        private readonly captureService: CaptureDialogService,
        private readonly fileFieldService: ProduktDetailFileFieldService,
        private readonly modelFileService: ModelFileService,
        private readonly snackBarService: SnackBarService,
        private readonly viewportService: ViewportService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
        private readonly fileService: FileService,
        private readonly einstellungenService: EinstellungenService,
        private readonly fileValidationService: FileValidationService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktUebersichtService);
        Assert.notNullOrUndefined(uebersichtFormViewFactory, 'uebersichtFormViewFactory');
        Assert.notNullOrUndefined(uploadService, 'uploadService');
        Assert.notNullOrUndefined(captureService, 'captureService');
        Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
        Assert.notNullOrUndefined(modelFileService, 'modelFileService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(notizenService, 'notizenService');
        Assert.notNullOrUndefined(fileService, 'fileService');
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
        Assert.notNullOrUndefined(fileValidationService, 'fileValidationService');
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Uebersicht.name;
        this.notizen$ = this.notizenService.init(this.produkt, name);
        this.viewport$ = this.viewportService.observe();
        this.modelFileConfigs = this.modelFileService.get(
            this.produkt.fahrzeug.fahrzeugart,
            this.produkt.fahrzeug.bauform,
        );
        this.setModelFileName(this.modelFileConfigs);
        this.init(name);
        this.hasImages$.next(this.getImages().length > 0);
        this.setModel();
        this.initSubscriptions();
        this.fahrzeugVorhanden$.next(this.form.get(this.fahrzeugVorhandenName).value);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        super.ngOnDestroy();
    }

    getSonstigesFormGroup(id: number): ViewFormGroup {
        return this.sonstiges.controls[id] as ViewFormGroup;
    }

    onSceneLoad(scene: Scene): void {
        Assert.notNullOrUndefined(scene, 'scene');
        this.scene = scene;
    }

    onModelLoad(modelLoadResult: ModelLoadResult): void {
        Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
        if (modelLoadResult === ModelLoadResult.None) {
            this.isModelLoaded$.next(false);
            this.snackBarService.warning('modell.couldNotLoad');
        } else if (modelLoadResult === ModelLoadResult.Fallback) {
            this.snackBarService.info('modell.fallback');
        }
    }

    onUploadArtChange(uploadArt: FileGalleryAddType): void {
        Assert.notNullOrUndefined(uploadArt, 'uploadArt');
        this.uploadArt$.next(uploadArt);
        this.icon$.next(uploadArt === FileGalleryAddType.Capture ? 'photo_camera' : 'folder');
    }

    onIndicatorClick(field: UntypedFormControl, name: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(name, 'fieldName');
        const title = `uebersicht.${name}`;
        const files$ =
            this.uploadArt$.value === FileGalleryAddType.Capture
                ? this.captureService.captureImage(title)
                : this.uploadService.uploadFiles(title, '.png, .jpg, .jpeg, .bmp');
        this.subscriptions.push(
            files$.pipe().subscribe((files) => {
                const validFiles = this.fileValidationService.validateFileTypeAndExtension(
                    files,
                    FileValidationTypes.Image,
                ).validFiles;
                if (validFiles) {
                    this.fileFieldService.add(field, validFiles);
                }
            }),
        );
    }

    onSonstigesClick(name: string): void {
        const title = `uebersicht.${name}`;
        this.sonstigesFields = (
            this.fields.find((x: FeatureFieldArray) => x.arrayName === 'sonstiges') as FeatureFieldArray
        ).fields;
        const fields = this.sonstigesFields;
        const form = this.uebersichtSonstigesFormViewFactory.create(
            {
                id: '',
                bilder: [],
                bezeichnung: '',
            },
            fields,
        );

        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktDetailUebersichtSonstigesDialogData = { form, fields, produktArt: this.produkt.art };

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result.data.form.get('bilder').value.length === 0) {
                    this.snackBarService.info('uebersicht.noImageSelected');
                    return;
                }

                if (!result.data.form.get('bezeichnung').value) {
                    result.data.form.patchValue({ bezeichnung: 'Sonstige Bilder' });
                }

                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    this.sonstiges.push(result.data.form);
                }
            });
    }

    onFileDelete(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.remove(field, fileId);
        if (this.form.get('deckblatt').value === fileId) {
            this.form.patchValue({ deckblatt: null });
        }
    }

    onFileDeleteSonstiges(field: ViewFormControl<any>, fileId: string, id: number): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');

        const controlSonstiges = this.form.get(this.sonstigesName) as ViewFormArray;

        if (this.sonstiges.value[id].bilder.length === 1) {
            const newValue = controlSonstiges.controls.splice(id, 1);
            this.form.patchValue({ [this.sonstigesName]: newValue });
        } else {
            const index: number = this.sonstiges.value[id].bilder.indexOf(fileId);
            if (index !== -1) {
                const newValue = controlSonstiges.value[id].bilder.splice(index, 1);
                this.form.patchValue({ [this.sonstigesName]: newValue });
            }
        }
    }

    onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        this.fileFieldService.update(field, event);
        const bilder: string[] = field.value;
        this.updateDeckblattBild(bilder);
    }

    onSonstigeBilderUpdate(field: AbstractControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        this.fileFieldService.update(field.get('bilder') as UntypedFormControl, event);
        this.updateDeckblattBild(field.get('bilder').value);
    }

    onFileReset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.reset(field, fileId);
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    isFileResetable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.fileFieldService.isUpdateable(fileId);
    }

    onDeleteAll(): void {
        const title = 'Alle Übersichtsbilder löschen';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogDeleteAllTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                    this.deleteAllImages();
                }
            });
    }

    onDeckblattSelect(deckblattInput: string): void {
        if (deckblattInput) {
            this.form.patchValue({
                deckblatt: deckblattInput,
            });
        }
    }

    drop($event: CdkDragDrop<any>): void {
        const item = this.sonstiges.at($event.previousIndex);
        this.sonstiges.controls.splice($event.previousIndex, 1);
        this.sonstiges.controls.splice($event.currentIndex, 0, item);
        this.sonstiges.updateValueAndValidity();
    }

    protected createForm(): ViewFormGroup {
        const form = this.uebersichtFormViewFactory.create(this.produkt.uebersicht, this.fields);
        this.sonstiges = form.get(this.sonstigesName) as ViewFormArray;
        // this.count$ = form.valueChanges.pipe(
        //   startWith({}),
        //   map(() => this.fields
        //     .reduce((pv, field) => pv + this.form.get((<FeatureField>field).name).value.length, 0)));
        return form;
    }

    private initSubscriptions(): void {
        this.subscriptions.push(
            this.form.valueChanges.subscribe((_value) => this.hasImages$.next(this.getImages().length > 0)),
        );
        this.subscriptions.push(this.viewportService.mobileLandscapeOptimization());

        const controls = [
            this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.VorneLinks.name),
            this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.VorneRechts.name),
            this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.HintenLinks.name),
            this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.HintenRechts.name),
            this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Innenraum.name),
        ];

        controls.forEach((control) => {
            this.subscriptions.push(control.valueChanges.pipe().subscribe((result) => this.setDeckblattBild(result)));
        });
    }

    private setDeckblattBild(result: any): void {
        const deckblattField = this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Deckblatt.name);
        if (deckblattField.value) {
            return;
        }
        if (result) {
            this.form.patchValue({
                deckblatt: result[0],
            });
        } else {
            console.error('Cannot to set Deckblatt Bild, result is undefined.');
        }
    }

    private getImages(): string[] {
        let images: string[] = [];
        const uebersichtRawValue: Uebersicht = this.form.getRawValue();
        uebersichtRawValue.hintenLinks.forEach((value) => (images = images.concat(value)));
        uebersichtRawValue.hintenRechts.forEach((value) => (images = images.concat(value)));
        uebersichtRawValue.innenraum.forEach((value) => (images = images.concat(value)));
        uebersichtRawValue.sonstiges.forEach((value) => (images = images.concat(value.bilder)));
        uebersichtRawValue.vorneLinks.forEach((value) => (images = images.concat(value)));
        uebersichtRawValue.vorneRechts.forEach((value) => (images = images.concat(value)));
        return images;
    }

    private deleteAllImages(): void {
        const imagesToDelete = this.getImages();

        imagesToDelete.forEach((image) =>
            this.fileService
                .delete(image)
                .pipe(first())
                .subscribe((next) => this.fileService.syncCount()),
        );

        this.form.patchValue({
            deckblatt: '',
            ['hintenLinks']: [],
            ['hintenRechts']: [],
            ['innenraum']: [],
            ['vorneLinks']: [],
            ['vorneRechts']: [],
        });
        (this.form.get('sonstiges') as FormArray).clear();
    }

    private updateDeckblattBild(bilder: string[]) {
        if (!bilder) {
            return;
        }
        bilder.forEach((bild) => {
            if (bild.includes(this.form.get('deckblatt').value)) {
                this.form.patchValue({
                    deckblatt: '',
                });
            }
        });
    }

    private setModel(): void {
        this.einstellungenService
            .getBenutzer()
            .pipe(take(1))
            .subscribe((benutzerEinstellungen) =>
                this.modelDisabled$.next(benutzerEinstellungen?.deactivate3dModel || false),
            );
    }

    private setModelFileName(modelFileConfigs: ModelFileConfig[]) {
        let vehicle = modelFileConfigs?.[0]?.file;
        if (!vehicle) {
            vehicle = 'kombi';
        }
        this.vehicleFileSrc = `/assets/three/images/${vehicle}.png`;
    }
}
