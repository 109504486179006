<ng-container [formGroup]="form">
    <div
        class="checkbox-component-wrapper"
        [ngClass]="
            (control.touchedChanges | async) && !(control.validChanges | async) && control.hasError('required')
                ? 'error'
                : ''
        "
    >
        <mat-checkbox
            [id]="name"
            [formControlName]="name"
            [class.disabled]="disabled"
            (change)="valueChanged.emit($event)"
        >
            {{ displayName | translate }}
        </mat-checkbox>
    </div>
</ng-container>
