import gql from 'graphql-tag';

export const OLDTIMER_NOTIZ_FIELDS = gql`
    fragment OldtimerNotizFields on OldtimerNotiz {
        createdAt
        datei
        sprachnachricht
        textnachricht
    }
`;
