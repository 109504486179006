import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { ProduktArt } from '@data/domain/schema/enum';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import {
    FileGalleryAddType,
    FileGalleryComponent,
    FileGalleryUpdateEvent,
} from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-uebersicht-sonstiges',
    templateUrl: './produkt-detail-uebersicht-sonstiges.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailUebersichtSonstigesComponent implements AfterViewInit {
    trackByField = TrackBy.trackByField;
    fileGalleryAddType = FileGalleryAddType;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    @Input()
    produktArt: ProduktArt;

    @Input()
    uploadArt$ = new BehaviorSubject<FileGalleryAddType>(FileGalleryAddType.Capture);

    @ViewChild('bezeichnungTextbausteine')
    bezeichnungComponent: TextbausteineComponent;

    @ViewChild('fileGallery')
    fileGallery: FileGalleryComponent;

    constructor(private readonly fileFieldService: ProduktDetailFileFieldService) {
        Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
    }

    ngAfterViewInit() {
        this.bezeichnungComponent?.onAddTextbausteinClicked();
    }

    onTextbausteinDialogClose(viewFormArray: ViewFormArray): void {
        if (viewFormArray && viewFormArray.length > 0) {
            this.fileGallery.onAddClick(this.uploadArt$.value);
        }
    }

    onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(files, 'files');
        this.fileFieldService.add(field, files);
    }

    onFileDelete(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.remove(field, fileId);
    }

    onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        this.fileFieldService.update(field, event);
    }

    onFileReset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.reset(field, fileId);
    }

    isFileResetable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.fileFieldService.isUpdateable(fileId);
    }
}
