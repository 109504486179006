<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name || field.arrayName">
        <!-- Rad -->
        <ng-container *ngSwitchCase="'rads'">
            <app-tab-group (selectedIndexChange)="onSelectedRadIndexChange($event)">
                <ng-container *ngFor="let rad of form.get(field.arrayName).controls; let index = index">
                    <ng-container [ngSwitch]="index">
                        <ng-container *ngSwitchCase="0">
                            <app-tab
                                [label]="
                                    name + '.' + (position.values[rad.get('radPosition').value] | lowercase) | translate
                                "
                            >
                                <app-produkt-detail-raeder-rad
                                    [form]="rad"
                                    [name]="name"
                                    [fields]="field.fields"
                                    [produktArt]="produktArt"
                                />
                            </app-tab>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <app-tab
                                [label]="
                                    name + '.' + (position.values[rad.get('radPosition').value] | lowercase) | translate
                                "
                            >
                                <app-produkt-detail-raeder-rad
                                    [form]="rad"
                                    [name]="name"
                                    [fields]="field.fields"
                                    [produktArt]="produktArt"
                                />
                            </app-tab>
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                            <app-tab
                                [label]="
                                    name + '.' + (position.values[rad.get('radPosition').value] | lowercase) | translate
                                "
                            >
                                <app-produkt-detail-raeder-rad
                                    [form]="rad"
                                    [name]="name"
                                    [fields]="field.fields"
                                    [produktArt]="produktArt"
                                />
                            </app-tab>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let rad of form.get(field.arrayName).controls; let index = index">
                    <ng-container [ngSwitch]="index">
                        <ng-container *ngSwitchCase="2">
                            <app-tab
                                [label]="
                                    name + '.' + (position.values[rad.get('radPosition').value] | lowercase) | translate
                                "
                            >
                                <app-produkt-detail-raeder-rad
                                    [form]="rad"
                                    [name]="name"
                                    [fields]="field.fields"
                                    [produktArt]="produktArt"
                                />
                            </app-tab>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </app-tab-group>
            <div align="center">
                <app-button (action)="onCopyAllClick()">
                    {{ 'raeder.copyall' | translate }}
                </app-button>
                <app-button (action)="onCopyAxisClick()">
                    {{ 'raeder.copyaxis' | translate }}
                </app-button>
            </div>
        </ng-container>
        <!-- Lagerort -->
        <ng-container *ngSwitchCase="'lagerort'">
            <ng-container *ngTemplateOutlet="select; context: { field: field, values: lagerort }" />
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #select let-field="field" let-values="values">
    <app-select
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [values]="values"
        [valuePrefix]="name + '.'"
    />
</ng-template>

<ng-template #tab let-field="field" let-position="position" let-name="name" let-rad="rad">
    <app-tab [label]="name + '.' + (position.values[rad.get('radPosition').value] | lowercase) | translate">
        <app-produkt-detail-raeder-rad [form]="rad" [name]="name" [fields]="field.fields" [produktArt]="produktArt" />
    </app-tab>
</ng-template>
