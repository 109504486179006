import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FahrzeugLaufleistungBeschreibung, FahrzeugLaufleistungEinheit, ProduktArt } from '@data/domain/schema/enum';
import { Fahrzeug } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailFahrzeugLaufleistungenFormViewFactory } from '@modules/produkt/factory/fahrzeug/produkt-detail-fahrzeug-laufleistungen-form-view.factory';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ThousandsSeperatorFormatterService } from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFahrzeugFormViewFactory extends FormViewModelBaseFactory<Fahrzeug> {
    constructor(
        private readonly produktDetailFahrzeugLaufleistungenFormViewFactory: ProduktDetailFahrzeugLaufleistungenFormViewFactory,
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly thousandsSeperatorFormatterService: ThousandsSeperatorFormatterService,
    ) {
        super();
        Assert.notNullOrUndefined(
            produktDetailFahrzeugLaufleistungenFormViewFactory,
            'produktDetailFahrzeugLaufleistungenFormViewFactory',
        );
        Assert.notNullOrUndefined(produktDetailTextbausteinFormViewFactory, 'produktDetailTextbausteinFormViewFactory');
        Assert.notNullOrUndefined(thousandsSeperatorFormatterService, 'thousandsSeperatorFormatterService');
    }

    protected createField(model: Fahrzeug, name: string, produktArt: ProduktArt): AbstractViewFormControl {
        if (!model) {
            console.error(`Cannot create field with name '${name}': model is null or undefined`);
            return null;
        }
        const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
        switch (name) {
            case fields.Kennzeichen.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.kennzeichen, {
                        formatter: ViewFormControlFormatters.toUppercase,
                        validators: Validators.required,
                    });
                } else {
                    return new ViewFormControl(model.kennzeichen, {
                        formatter: ViewFormControlFormatters.toUppercase,
                    });
                }
            case fields.Identnummer.name:
                return new ViewFormControl(model.identnummer, {
                    formatter: ViewFormControlFormatters.toUppercase,
                    updateOn: 'change',
                    validators: [Validators.required, Validators.pattern('[A-Za-z0-9]{17}')],
                });
            case fields.TypCode.name:
                return new ViewFormControl(model.typCode, {
                    formatter: ViewFormControlFormatters.toUppercase,
                    validators: [Validators.required, Validators.pattern('[A-Za-z0-9]{3,8}')],
                });
            case fields.HerstellerCode.name:
                return new ViewFormControl(model.herstellerCode, {
                    formatter: ViewFormControlFormatters.toUppercase,
                    validators: [Validators.required, Validators.pattern('[A-Za-z0-9]{4,}')],
                });
            case fields.Erstzulassung.name:
                return new ViewFormControl(model.erstzulassung, {
                    validators: [Validators.required],
                });
            case fields.LetzteZulassung.name:
                return new ViewFormControl(model.letzteZulassung);
            case fields.Laufleistung.name:
                return new ViewFormControl(model.laufleistung, {
                    formatter: this.thousandsSeperatorFormatterService,
                    validators: [Validators.required],
                });
            case fields.LaufleistungEinheit.name:
                return new ViewFormControl(model.laufleistungEinheit || FahrzeugLaufleistungEinheit.Km, {
                    validators: [Validators.required],
                });
            case fields.LaufleistungBeschreibung.name:
                return new ViewFormControl(
                    model.laufleistungBeschreibung || FahrzeugLaufleistungBeschreibung.abgelesen,
                );
            case fields.Fahrzeugart.name:
                return new ViewFormControl(model.fahrzeugart, {
                    validators: [Validators.required],
                });
            case fields.FahrzeugartManuell.name:
                return new ViewFormControl(model.fahrzeugartManuell, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Bauform.name:
                return new ViewFormControl(model.bauform, {
                    validators: [Validators.required],
                });
            case fields.BauformManuell.name:
                return new ViewFormControl(model.bauformManuell, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Hersteller.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.hersteller, {
                        formatter: ViewFormControlFormatters.firstLetterToUppercase,
                        validators: [Validators.required],
                    });
                } else {
                    return new ViewFormControl(model.hersteller, {
                        formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    });
                }
            case fields.Modell.name:
                return new ViewFormControl(model.modell, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Untertyp.name:
                return new ViewFormControl(model.untertyp, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Lackcode.name:
                return new ViewFormControl(model.lackcode, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Innenausstattung.name:
                return new ViewFormControl(model.innenausstattung, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Polsterfarbe.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.polsterfarbe, {
                        formatter: ViewFormControlFormatters.firstLetterToUppercase,
                        validators: Validators.required,
                    });
                } else {
                    return new ViewFormControl(model.polsterfarbe, {
                        formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    });
                }
            case fields.Polstermaterial.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.polstermaterial, {
                        formatter: ViewFormControlFormatters.firstLetterToUppercase,
                        validators: Validators.required,
                    });
                } else {
                    return new ViewFormControl(model.polstermaterial, {
                        formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    });
                }
            case fields.Hubraum.name:
                return new ViewFormControl(model.hubraum, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.Leistung.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.leistung, {
                        formatter: this.thousandsSeperatorFormatterService,
                        validators: Validators.required,
                    });
                } else {
                    return new ViewFormControl(model.leistung, {
                        formatter: this.thousandsSeperatorFormatterService,
                    });
                }
            case fields.Zylinder.name:
                return new ViewFormControl(model.zylinder, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.Schadstoffklasse.name:
                return new ViewFormControl(model.schadstoffklasse);

            case fields.Leergewicht.name:
                return new ViewFormControl(model.leergewicht, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.ZulaessigesGesamtgewicht.name:
                return new ViewFormControl(model.zulaessigesGesamtgewicht, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.MasseLaenge.name:
                return new ViewFormControl(model.masseLaenge, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.MasseBreite.name:
                return new ViewFormControl(model.masseBreite, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.MasseHoehe.name:
                return new ViewFormControl(model.masseHoehe, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.AnzahlTueren.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.anzahlTueren, {
                        formatter: this.thousandsSeperatorFormatterService,
                        validators: Validators.required,
                    });
                } else {
                    return new ViewFormControl(model.anzahlTueren, {
                        formatter: this.thousandsSeperatorFormatterService,
                    });
                }
            case fields.AnzahlSitze.name:
                return new ViewFormControl(model.anzahlSitze, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.Antriebstechnologie.name:
                return new ViewFormControl(model.antriebstechnologie);
            case fields.Antriebsart.name:
                return new ViewFormControl(model.antriebsart);
            case fields.Getriebeart.name:
                return new ViewFormControl(model.getriebeart);
            case fields.AnzahlGaenge.name:
                return new ViewFormControl(model.anzahlGaenge, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.AnzahlVorbesitzer.name:
                return new ViewFormControl(model.anzahlVorbesitzer);
            case fields.ScheckheftGepflegt.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.scheckheftGepflegt, {
                        validators: [Validators.required],
                    });
                } else {
                    return new ViewFormControl(model.scheckheftGepflegt);
                }
            case fields.DatECode.name:
                return new ViewFormControl(model.datECode, {
                    formatter: ViewFormControlFormatters.toUppercase,
                });
            case fields.HvBatterie.name:
                return new ViewFormControl(model.hvBatterie);
            case fields.AcFahrzeugart.name:
                return new ViewFormControl(model.acFahrzeugart, {
                    validators: [Validators.required],
                });
            case fields.AcBauform.name:
                return new ViewFormControl(model.acBauform, {
                    validators: [Validators.required],
                });
            case fields.AcLackfarbe.name:
                return new ViewFormControl(model.acLackfarbe, {
                    validators: [Validators.required],
                });
            case fields.AcLackierung.name:
                return new ViewFormControl(model.acLackierung, {
                    validators: [Validators.required],
                });
            case fields.AcAntriebstechnologie.name:
                return new ViewFormControl(model.acAntriebstechnologie, {
                    validators: [Validators.required],
                });
            case fields.AcGetriebeart.name:
                return new ViewFormControl(model.acGetriebeart, {
                    validators: [Validators.required],
                });
            case fields.AcSchadstoffklasse.name:
                return new ViewFormControl(model.acSchadstoffklasse, {
                    validators: [Validators.required],
                });
            case fields.AcEhemaligeNutzung.name:
                return new ViewFormControl(model.acEhemaligeNutzung, {
                    validators: [Validators.required],
                });
            case fields.FahrzeugExternalServiceReference.name:
                return new ViewFormControl(model.fahrzeugExternalServiceReference);
            case fields.VinAbfrageErfolgt.name:
                return new ViewFormControl(model.vinAbfrageErfolgt || false);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Fahrzeug, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
        switch (arrayName) {
            case fields.Bemerkungen.name:
                const bemerkungen = model.bemerkungen || [];
                return new ViewFormArray(
                    bemerkungen.map((bemerkung) =>
                        this.produktDetailTextbausteinFormViewFactory.create(bemerkung, arrayFields),
                    ),
                );
            case fields.Laufleistungen.name:
                const laufleistungen = model.laufleistungen || [];
                return new ViewFormArray(
                    laufleistungen.map((laufleistung) =>
                        this.produktDetailFahrzeugLaufleistungenFormViewFactory.create(laufleistung, arrayFields),
                    ),
                );
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
