import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    SchadenBeschaedigungart,
    SchadenIntensitaet,
    SchadenObergruppe,
    SchadenReparaturweg,
    SchadenUntergruppe,
} from '@data/domain/schema/enum';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { SchadenMinderwertService } from '@modules/produkt/service/schaden-minderwert.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ObjectValues } from '@shared/helper/values';
import { ViewportService } from '@shared/service/viewport.service';
import { merge } from 'rxjs';
import { ProduktDetailSchadenPositionComponentBase } from '../base/produkt-detail-schaden-position-component-base';

@Component({
    selector: 'app-produkt-detail-schaden-position',
    templateUrl: './produkt-detail-schaden-position.component.html',
    styleUrls: ['./produkt-detail-schaden-position.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailSchadenPositionComponent
    extends ProduktDetailSchadenPositionComponentBase
    implements OnInit, AfterViewInit {
    @Input()
    relativerWert: number;

    fieldZustand = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Positionen.fields.Zustand.name;

    art = new ObjectValues(SchadenBeschaedigungart);
    arten = [
        'Abschuerfung',
        'Beklebung',
        'Beule',
        'Brandloch',
        'Delle',
        'Gebrochen',
        'Gerissen',
        'Kratzer',
        'Steinschlag',
        'Verformt',
        'Verschlissen',
    ];

    reparatur = new ObjectValues(SchadenReparaturweg);
    reparaturen = [
        'Aufbereiten',
        'Erneuern',
        'Instandsetzen',
        'InstandsetzenUndLackieren',
        'KalkulationNoetig',
        'Oberflaechenlack',
        'Pruefen',
        'Reinigen',
        'SmartRepair',
        'Tupfen',
        'Vermessung',
    ];

    obergruppen = ['Dach', 'Innenraum', 'Interieur', 'Karosserie', 'Unterboden'];
    untergruppen = [];

    intensitaet = new ObjectValues(SchadenIntensitaet);
    hasRelativerWert = false;

    @ViewChild('zustand')
    zustandElement: TextbausteineComponent;

    protected obergruppe = new ObjectValues(SchadenObergruppe);
    protected untergruppe = new ObjectValues(SchadenUntergruppe);

    private intensitaetControl: AbstractViewFormControl;
    private minderwertControl: AbstractViewFormControl;
    private preisControl: AbstractViewFormControl;

    constructor(
        fileFieldService: ProduktDetailFileFieldService,
        viewportService: ViewportService,
        private readonly textbausteineService: TextbausteineService,
        private readonly schadenMinderwertService: SchadenMinderwertService,
    ) {
        Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
        Assert.notNullOrUndefined(schadenMinderwertService, 'schadenMinderwertService');

        super(fileFieldService, viewportService);
    }

    ngOnInit(): void {
        this.feature = PRODUKT_CONFIG_FEATURES.Schaden.name;
        this.fieldConfig = PRODUKT_CONFIG_FEATURES.Schaden.fields.Positionen.fields;

        this.minderwertControl = this.form.get(this.fieldConfig?.Minderwert?.name) as AbstractViewFormControl;
        this.preisControl = this.form.get(this.fieldConfig?.Preis?.name) as AbstractViewFormControl;
        this.intensitaetControl = this.form.get(this.fieldConfig?.Intensitaet?.name) as AbstractViewFormControl;

        if (this.minderwertControl) {
            this.addMinderwertToSubscription();
        }

        this.hasRelativerWert = this.relativerWert > 0 ? true : false;

        this.requiredFields = [
            this.fieldConfig.Obergruppe.name,
            this.fieldConfig.Untergruppe.name,
            this.fieldConfig.Reparaturweg.name,
        ];

        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        const zustandControl = this.form?.get(this.fieldZustand);
        if (zustandControl?.value.length === 0) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldZustand,
                this.produktArt,
                this.form as ViewFormGroup,
                this.zustandElement,
            );
        }
    }

    private addMinderwertToSubscription(): void {
        this.subscriptions.push(
            merge(this.preisControl?.valueChanges, this.intensitaetControl?.valueChanges)
                .pipe()
                .subscribe(() => {
                    const preis = this.preisControl?.getRawValue() as number;
                    if (!preis || preis <= 0) {
                        return;
                    }

                    let berechneterMinderwert = 0;

                    if (this.hasRelativerWert) {
                        berechneterMinderwert = this.schadenMinderwertService.getMinderwertRelativerWert(
                            preis,
                            this.relativerWert,
                        );
                    }

                    const intensitaetValue = this.intensitaetControl?.getRawValue() as SchadenIntensitaet;

                    if (intensitaetValue) {
                        berechneterMinderwert = this.schadenMinderwertService.getMinderwertIntensitaet(
                            preis,
                            intensitaetValue,
                        );
                    }

                    this.minderwertControl?.setRawValue(berechneterMinderwert);
                }),
        );
    }

    onRelativerWertClicked(): void {
        const preis = this.preisControl.getRawValue();
        if (!preis) {
            return;
        }
        this.intensitaetControl.setRawValue(0);
        this.minderwertControl.setRawValue(
            this.schadenMinderwertService.getMinderwertRelativerWert(preis, this.relativerWert),
        );
    }
}
