import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ProduktStatusService } from '@app/service/produkt-status.service';
import { ProduktStatus } from '@data/domain/schema/enum';
import { ProduktDetailFeatureComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { Feature, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailVtiAbschlussFormViewFactory } from '@modules/produkt/factory/vti-abschluss/produkt-detail-vti-abschluss-form-view.factory';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureValidService } from '@modules/produkt/service/produkt-detail-feature-valid.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { BehaviorSubject } from 'rxjs';

interface FeatureValidation {
    name: string;
    incorrectFields: string[];
}

@Component({
    selector: 'app-produkt-detail-vti-abschluss',
    templateUrl: './produkt-detail-vti-abschluss.component.html',
    styleUrls: ['./produkt-detail-vti-abschluss.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailVtiAbschlussComponent extends ProduktDetailFeatureComponent implements OnInit {
    feature = PRODUKT_CONFIG_FEATURES.VtiAbschluss.name;

    form: ViewFormGroup;

    adressen: Feature;

    validations: FeatureValidation[] = [];

    statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        private readonly featureValidService: ProduktDetailFeatureValidService,
        private readonly produktDetailVtiAbschlussFormViewFactory: ProduktDetailVtiAbschlussFormViewFactory,
        private readonly produktStatusService: ProduktStatusService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService);
        Assert.notNullOrUndefined(featureValidService, 'featureValidService');
        Assert.notNullOrUndefined(produktStatusService, 'produktStatusService');
    }

    ngOnInit(): void {
        this.init(PRODUKT_CONFIG_FEATURES.VtiAbschluss.name);
        this.adressen = this.getFeatureByName(PRODUKT_CONFIG_FEATURES.Adressen.name);
        this.form = this.produktDetailVtiAbschlussFormViewFactory.create(this.produkt.vtiAbschluss, this.fields);
        this.getFeatures().forEach(({ name, fields }) => {
            const incorrectFields = this.featureValidService.getFeatureIncorrectFields(this.produkt, name, fields);
            if (incorrectFields.length > 0) {
                this.validations.push({ name, incorrectFields });
            }
        });
    }

    onStatusChanged($event: ProduktStatus): void {
        this.statusChanged$.next($event);
        this.produktStatusService.update($event);
    }
}
