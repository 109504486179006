import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

declare let SphinxClassCP: any;

interface DatKalkulationAuth {
    action: string;
    customerNumber?: string;
    user?: string;
    password?: string;
}

@Injectable({
    providedIn: 'root',
})
export class DatCalculateProService {
    private sphinx;
    private tokenUrl = 'https://www.dat.de/AuthorizationManager/service--/endpoint/tokenService';
    private auth: DatKalkulationAuth;
    private readonly restApiUrl: string;

    constructor(private httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/dat/kalkulation/auth`;
        this.sphinx = SphinxClassCP.getInstance();
        this.getDatAuth()
            .pipe(first())
            .subscribe((auth) => (this.auth = auth));
    }

    getDatAuth(): Observable<DatKalkulationAuth> {
        return this.httpClient.get<DatKalkulationAuth>(this.restApiUrl);
    }
    getToken(): Observable<Object> {
        return this.httpClient.post(this.tokenUrl, this.auth);
    }
}
