import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-fab',
    templateUrl: './fab.component.html',
    styleUrls: ['./fab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabComponent {
    @Input()
    count = 0;

    @Input()
    color = 'accent';

    @Output()
    action = new EventEmitter<MouseEvent>();
}
