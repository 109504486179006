import { CgBaugruppeBauteileversichert, CgBaugruppeFahrzeugversichert, CgBauteil } from '@data/domain/schema/enum';

const bgbv = CgBaugruppeBauteileversichert;
const bgfv = CgBaugruppeFahrzeugversichert;
const bt = CgBauteil;

export const BAUGRUPPE_TO_BAUTEIL_MAP = new Map<
    CgBaugruppeBauteileversichert | CgBaugruppeFahrzeugversichert,
    CgBauteil[]
>([
    [
        bgbv.Motor,
        [
            bt.MotorDichtringgehaeuse,
            bt.MotorOelkreislaufteile,
            bt.MotorMotorOelkuehler,
            bt.MotorMotorblock,
            bt.MotorNockenwellengehaeuse,
            bt.MotorOeldruckschalter,
            bt.MotorOelfiltergehaeuse,
            bt.MotorOelstandsensor,
            bt.MotorOelwanne,
            bt.MotorSchwungscheibeMitZahnkranz,
            bt.MotorSpannrolleFuerSteuerriemen,
            bt.MotorSteuergehaeusedeckel,
            bt.MotorSteuerriemen,
            bt.MotorUmlenkrolleFuerSteuerriemen,
            bt.MotorVentilschaftDichtung,
            bt.MotorZylinderkopf,
            bt.MotorZylinderkopfdichtung,
        ],
    ],
    [
        bgbv.SchaltAutomatikgetriebe,
        [
            bt.SchaltAutomatikgetriebeAntriebsscheibe,
            bt.SchaltAutomatikgetriebeDoppelkupplung,
            bt.SchaltAutomatikgetriebeDrehmomentwandler,
            bt.SchaltAutomatikgetriebeGetriebeOehlkuehler,
            bt.SchaltAutomatikgetriebeGetriebegehaeuse,
            bt.SchaltAutomatikgetriebeInnenteileDesSchaltUndAutomatikgetriebes,
            bt.SchaltAutomatikgetriebeKupplungsGeberzylinder,
            bt.SchaltAutomatikgetriebeKupplungsNehmerzylinder,
            bt.SchaltAutomatikgetriebeKupplungsaktuator,
            bt.SchaltAutomatikgetriebeNasseAnfahrkupplung,
            bt.SchaltAutomatikgetriebeSchaltaktuator,
            bt.SchaltAutomatikgetriebeSteuergeraetDesAutomatikgetriebes,
            bt.SchaltAutomatikgetriebeSteuergeraetDesAutomatisiertenSchaltgetriebes,
        ],
    ],
    [
        bgbv.AchsVerteilergetriebe,
        [
            bt.AchsVerteilergetriebeFlansch,
            bt.AchsVerteilergetriebeGetriebegehaeuse,
            bt.AchsVerteilergetriebeInnenteileAchsVerteilergetriebe,
        ],
    ],
    [
        bgbv.Kraftuebertragung,
        [
            bt.KraftuebertragungAntriebswelle,
            bt.KraftuebertragungAntriebswellenGelenk,
            bt.KraftuebertragungDrehzahlsensorASR,
            bt.KraftuebertragungDruckspeicherASR,
            bt.KraftuebertragungHaldexKupplung,
            bt.KraftuebertragungHaldexpumpe,
            bt.KraftuebertragungHydraulikeinheitASR,
            bt.KraftuebertragungKardanwelle,
            bt.KraftuebertragungLadepumpeASR,
            bt.KraftuebertragungMittellagerAntriebswelle,
            bt.KraftuebertragungMittellagerKardanwelle,
            bt.KraftuebertragungRadlager,
            bt.KraftuebertragungRadnabe,
            bt.KraftuebertragungSteuergeraetASR,
            bt.KraftuebertragungTraktionssteuergeraet,
            bt.KraftuebertragungViscokupplung,
        ],
    ],
    [
        bgbv.Lenkung,
        [
            bt.LenkungElektrischeLenkungsverriegelung,
            bt.LenkungElektrischerLenkhilfemotor,
            bt.LenkungElektronischeBauteileDerLenkung,
            bt.LenkungHydraulikpumpeLenkung,
            bt.LenkungLenkgetriebeMitAllenInnenteilen,
        ],
    ],
    [
        bgbv.Bremsanlage,
        [
            bt.BremsanlageABSDrehzahlsensor,
            bt.BremsanlageABSHydraulikeinheit,
            bt.BremsanlageABSSteuergeraet,
            bt.BremsanlageBremskraftbegrenzer,
            bt.BremsanlageBremskraftregler,
            bt.BremsanlageBremskraftverstaerker,
            bt.BremsanlageBremslichtschalter,
            bt.BremsanlageBremssattel,
            bt.BremsanlageBremssatteltraeger,
            bt.BremsanlageHauptbremszylinder,
            bt.BremsanlageHydropneumatikDruckregler,
            bt.BremsanlageHydropneumatikDruckspeicher,
            bt.BremsanlageHydropneumatikSteuergeraet,
            bt.BremsanlageRadbremszylinderDerTrommelbremse,
            bt.BremsanlageSteuergeraetFuerHandbremse,
            bt.BremsanlageVakuumPumpe,
        ],
    ],
    [
        bgbv.Kraftstoffanlage,
        [
            bt.KraftstoffanlageEinspritzpumpe,
            bt.KraftstoffanlageElektronischeBauteileDesMotormanagements,
            bt.KraftstoffanlageHochdruckpumpe,
            bt.KraftstoffanlageKompressor,
            bt.KraftstoffanlageKraftstoffpumpe,
            bt.KraftstoffanlageTurbolader,
            bt.KraftstoffanlageVorfoerderpumpe,
        ],
    ],
    [
        bgbv.ElektrischeAnlage,
        [
            bt.ElektrischeAnlageBordcomputer,
            bt.ElektrischeAnlageElektrischeLeitungenderelektronischenEinspritzanlage,
            bt.ElektrischeAnlageElektronischeBauteileDerZuendanlage,
            bt.ElektrischeAnlageGenerator,
            bt.ElektrischeAnlageGeneratorFreilauf,
            bt.ElektrischeAnlageGeneratorRegler,
            bt.ElektrischeAnlageHeizUndFrischluftgeblaesemotor,
            bt.ElektrischeAnlageKombiinstrument,
            bt.ElektrischeAnlageLesespule,
            bt.ElektrischeAnlageNavigationsgeraet,
            bt.ElektrischeAnlageSicherungskasten,
            bt.ElektrischeAnlageSignalhorn,
            bt.ElektrischeAnlageStarter,
            bt.ElektrischeAnlageStartgenerator,
            bt.ElektrischeAnlageSteuergeraete,
            bt.ElektrischeAnlageVorgluehrelaisSteuergeraet,
            bt.ElektrischeAnlageWischermotor,
            bt.ElektrischeAnlageXenonsteuergeraet,
            bt.ElektrischeAnlageZuendkabel,
        ],
    ],
    [
        bgbv.Kuehlsystem,
        [
            bt.KuehlsystemHeizungswaermetauscher,
            bt.KuehlsystemKuehlgeblaesemotor,
            bt.KuehlsystemLuefterSteuergeraet,
            bt.KuehlsystemLuefterkupplung,
            bt.KuehlsystemThermoschalter,
            bt.KuehlsystemThermostat,
            bt.KuehlsystemThermostatgehaeuse,
            bt.KuehlsystemWasserkuehlerMotor,
            bt.KuehlsystemWasserpumpe,
            bt.KuehlsystemZusatzwasserpumpe,
        ],
    ],
    [bgbv.Abgasanlage, [bt.AbgasanlageAGRKuehler, bt.AbgasanlageElektronischeBauteileDerAbgasreinigungsanlage]],
    [
        bgbv.Sicherheitssysteme,
        [
            bt.SicherheitssystemeAbstandsradar,
            bt.SicherheitssystemeAirbagSteuergeraet,
            bt.SicherheitssystemeCrashSensor,
            bt.SicherheitssystemeElektrischeSteckverbindungen,
            bt.SicherheitssystemeGurtstrafferSteuergeraet,
            bt.SicherheitssystemeKabelsaetze,
            bt.SicherheitssystemeLenkradkontaktteil,
            bt.SicherheitssystemeRadarsensor,
            bt.SicherheitssystemeReifendruckkontrollsystemSensor,
            bt.SicherheitssystemeReifendruckkontrollsystemSteuergeraet,
            bt.SicherheitssystemeSitzbelegungssensor,
            bt.SicherheitssystemeSteuergeraetAdaptiveGeschwindigkeitsregelung,
            bt.SicherheitssystemeSteuergeraetKollisionswarnsystem,
            bt.SicherheitssystemeSteuergeraetSpurhalteassistent,
            bt.SicherheitssystemeSteuergeraetVerkehrszeichenerkennung,
            bt.SicherheitssystemeTotwinkelsensor,
            bt.SicherheitssystemeVibrationsmotorSpurhalteassistent,
        ],
    ],
    [
        bgbv.Klimaanlage,
        [
            bt.KlimaanlageKlimaKompressor,
            bt.KlimaanlageKlimaKondensator,
            bt.KlimaanlageKlimaLuefter,
            bt.KlimaanlageKlimaSteuergeraet,
            bt.KlimaanlageKlimaVerdampfer,
            bt.KlimaanlageKlimaautomatikBedienteil,
            bt.KlimaanlageKompressorkupplung,
        ],
    ],
    [
        bgbv.Komfortelektrik,
        [
            bt.KomfortelektrikFensterheberMotor,
            bt.KomfortelektrikFensterheberSchalter,
            bt.KomfortelektrikFensterheberSteuergeraet,
            bt.KomfortelektrikFrontRueckfahrkamera,
            bt.KomfortelektrikFrontscheibenheizungselemente,
            bt.KomfortelektrikHeckscheibenheizungselemente,
            bt.KomfortelektrikMultifunktionskamera,
            bt.KomfortelektrikSchiebedachMotor,
            bt.KomfortelektrikSchiebedachSchalter,
            bt.KomfortelektrikSchiebedachSteuergeraet,
            bt.KomfortelektrikSeitenkamera,
            bt.KomfortelektrikSitzheizungssteuergeraet,
            bt.KomfortelektrikSpiegelsteuergeraet,
            bt.KomfortelektrikSteuergeraetSitzverstellung,
            bt.KomfortelektrikTuerHeckklappenschloss,
            bt.KomfortelektrikTuersteuergeraet,
            bt.KomfortelektrikVerdeckSteuergeraet,
            bt.KomfortelektrikZentralverriegelungsMotor,
            bt.KomfortelektrikZentralverriegelungsSchalter,
            bt.KomfortelektrikZentralverriegelungsSteuergeraet,
        ],
    ],
    [
        bgbv.Fahrdynamiksystem,
        [
            bt.FahrdynamiksystemBremsdrucksensor,
            bt.FahrdynamiksystemESPSteuergeraet,
            bt.FahrdynamiksystemGiermomentsensor,
            bt.FahrdynamiksystemLenkwinkelsensor,
            bt.FahrdynamiksystemQuerbeschleunigungssensor,
            bt.FahrdynamiksystemRaddrehzahlsensor,
            bt.FahrdynamiksystemSteuergeraetTraktionskontrolle,
        ],
    ],
    [
        bgbv.Elektroantrieb,
        [
            bt.ElektroantriebElektrischeHeizquelleFuerFahrgastraumbeheizung,
            bt.ElektroantriebElektrischerBremskraftverstaerker,
            bt.ElektroantriebElektrischerKlimakompressor,
            bt.ElektroantriebElektromotorenDesAntriebs,
            bt.ElektroantriebFahrzeugintegriertesNetzladegeraet,
            bt.ElektroantriebHochvoltverkabelung,
            bt.ElektroantriebKuehlungsluefterFuerDieAntriebsbatterie,
            bt.ElektroantriebLeistungselektronikDesAntriebs,
            bt.ElektroantriebSpannungswandlerFuerDasBordsystem,
            bt.ElektroantriebSteuergeraetDerAntriebsbatterie,
            bt.ElektroantriebWechselrichterFuerDasBordsystem,
        ],
    ],
    [
        bgbv.Hybridantrieb,
        [
            bt.HybridantriebElektrischeWasserpumpeDesHybridantriebs,
            bt.HybridantriebElektromotorenDesHybridantriebs,
            bt.HybridantriebElektronischesSteuergeraetDerHybridfahrzeugbatterie,
            bt.HybridantriebFahrzeugseitigesPlugInNetzladegeraet,
            bt.HybridantriebGeneratorenDesHybridantriebs,
            bt.HybridantriebGetriebeFuerDenHybridantrieb,
            bt.HybridantriebHochvoltACDCWandler,
            bt.HybridantriebHochvoltverkabelung,
            bt.HybridantriebKuehlerFuerDieHybridbatterie,
            bt.HybridantriebLeistungselektronikDesHybridantriebs,
            bt.HybridantriebLuefterFuerDieHybridbatterie,
            bt.HybridantriebSpannungswandler,
            bt.HybridantriebSteuergeraetFuerdenHybridantrieb,
            bt.HybridantriebWechselrichterFuerDasHybridsystem,
            bt.HybridantriebZwoelfVACDCWandler,
        ],
    ],
    [
        bgbv.KarosserieInnenraum,
        [
            bt.KarosserieInnenraumVerdeckHydraulikeinheit,
            bt.KarosserieInnenraumVerdeckSteuergeraet,
            bt.KarosserieInnenraumVerdeckpumpe,
        ],
    ],
]);
