export enum GltfGruppe {
    EXT_Felge_hinten_links = 1,
    EXT_Felge_hinten_rechts = 2,
    EXT_Felge_vorne_links = 3,
    EXT_Felge_vorne_rechts = 4,
    EXT_Reifen_hinten_links = 5,
    EXT_Reifen_hinten_rechts = 6,
    EXT_Reifen_vorne_links = 7,
    EXT_Reifen_vorne_rechts = 8,
    EXT_Heckklappe_Glas = 9, // EXT_Heckklappe
    EXT_Tuer_hinten_links_Glas = 10, // EXT_Tuer_hinten_links
    EXT_Tuer_hinten_rechts_Glas = 11, // EXT_Tuer_hinten_rechts
    EXT_Tuer_vorne_links_Glas = 12, // EXT_Tuer_vorne_links
    EXT_Tuer_vorne_rechts_Glas = 13, // EXT_Tuer_vorne_rechts
    EXT_Windschutzscheibe_Glas = 14,
    EXT_Aussenspiegel_links = 15,
    EXT_Aussenspiegel_rechts = 16,
    EXT_Fahrzeugdach = 17,
    EXT_Heckklappe = 18,
    EXT_Heckleuchte_glas_links = 19,
    EXT_Heckleuchte_glas_rechts = 20,
    EXT_Kotfluegel_links = 21,
    EXT_Kotfluegel_rechts = 22,
    EXT_Motorhaube = 23,
    EXT_Scheinwerfereinheit_glas_links = 24,
    EXT_Scheinwerfereinheit_glas_rechts = 25,
    EXT_Schweller_links = 26,
    EXT_Schweller_rechts = 27,
    EXT_Seitenwand_links = 28,
    EXT_Seitenwand_rechts = 29,
    EXT_Stossfaenger_hinten = 30,
    EXT_Stossfaenger_vorne = 31,
    EXT_Tuer_hinten_links = 32,
    EXT_Tuer_hinten_rechts = 33,
    EXT_Tuer_vorne_links = 34,
    EXT_Tuer_vorne_rechts = 35,
    EXT_Unterboden = 36,
    INT_Airbagsystem = 37, // ElektrischesSystem
    INT_Armaturenbrett = 38,
    INT_Dachhimmel = 39,
    INT_Fussraum_hinten_links = 40,
    INT_Fussraum_hinten_rechts = 41,
    INT_Fussraum_vorne_links = 42,
    INT_Fussraum_vorne_rechts = 43,
    INT_Handschuhfach = 44,
    INT_Instrumententafel = 45, // ElektrischesSystem
    INT_Klimasystem_mit_Heizung = 46, // ElektrischesSystem
    INT_Lenkrad = 47, // Lenkanlage
    INT_Mittelkonsole = 48,
    INT_Multimedia = 49, // ElektrischesSystem
    INT_Pedalanlage = 50,
    INT_Rueckspiegel = 51,
    INT_Sitz_hinten_links = 52,
    INT_Sitz_hinten_mittig = 53,
    INT_Sitz_hinten_rechts = 54,
    INT_Sitz_vorne_links = 55,
    INT_Sitz_vorne_rechts = 56,
    INT_Tuerverkleidung_hinten_links = 57,
    INT_Tuerverkleidung_hinten_rechts = 58,
    INT_Tuerverkleidung_vorne_links = 59,
    INT_Tuerverkleidung_vorne_rechts = 60,
    TEC_Bremssattel_hinten_links = 61, // BremsanlageHinten
    TEC_Bremsscheibe_hinten_links = 62, // BremsanlageHinten
    TEC_Bremssattel_hinten_rechts = 63, // BremsanlageHinten
    TEC_Bremsscheibe_hinten_rechts = 64, // BremsanlageHinten
    TEC_Bremssattel_vorne_links = 65, // BremsanlageVorne
    TEC_Bremsscheibe_vorne_links = 66, // BremsanlageVorne
    TEC_Bremssattel_vorne_rechts = 67, // BremsanlageVorne
    TEC_Bremsscheibe_vorne_rechts = 68, // BremsanlageVorne
    TEC_Lenkgetriebe = 69, // Lenkanlage
    TEC_Lenkrad = 70, // Lenkanlage
    TEC_Lenksaeule = 71, // Lenkanlage
    TEC_Abgasanlage = 72,
    TEC_Antriebsstrang = 73,
    TEC_Differenzial = 74,
    TEC_Getriebe = 75,
    TEC_Hinterachse = 76,
    TEC_Motor = 77, // Motorraum
    TEC_Motorkuehlung_Fluessigkeitsbehaelter = 78, // Motorraum
    TEC_Vorderachse = 79,

    // multivan only
    INT_Sitz_Ruecksitzreihe_eins_links = 80,
    INT_Sitz_Ruecksitzreihe_eins_rechts = 81,
    INT_Sitz_Ruecksitzreihe_zwei_links = 82,
    INT_Sitz_Ruecksitzreihe_zwei_mitte = 83,
    INT_Sitz_Ruecksitzreihe_zwei_rechts = 84,
}

export function parse(name: string): GltfGruppe {
    const prefixs = ['EXT', 'INT', 'TEC'];

    let gruppe = '';
    for (let i = 0; i < prefixs.length; ++i) {
        const prefixIndex = name.indexOf(prefixs[i]);
        if (prefixIndex !== -1) {
            gruppe = name.substr(prefixIndex);
            break;
        }
    }
    return GltfGruppe[gruppe];
}
