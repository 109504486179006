<mat-expansion-panel #matExpansionPanel [expanded]="expanded$ | async" [disabled]="true" hideToggle>
    <mat-expansion-panel-header (click)="onClick($event)">
        <ng-content select="app-expansion-header" />
        <app-icon-button (click)="togglePanel($event)">
            <app-mat-icon icon="{{ (expanded$ | async) ? 'expand_less' : 'expand_more' }}" />
        </app-icon-button>
    </mat-expansion-panel-header>
    <ng-content />
    <app-icon-button class="center" (click)="togglePanel($event)">
        <app-mat-icon icon="{{ (expanded$ | async) ? 'expand_less' : 'expand_more' }}" />
    </app-icon-button>
</mat-expansion-panel>
