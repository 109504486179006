import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { FileService } from '@app/service/file.service';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { Produkt } from '@data/domain/schema/type';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, filter, first, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

interface NavigationHeader {
    backUrl: string;
}

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy {
    produkt$: Observable<Produkt>;
    auftragsnummer$ = new BehaviorSubject<string>('');
    vorgangsnummerSubscription: Subscription;
    produkt: Produkt;
    navigating$: Observable<boolean>;
    backUrl$: Observable<string>;
    syncCount$: Observable<number>;
    syncInProgress$: Observable<boolean>;
    viewport$: Observable<Viewport>;
    viewport = Viewport;
    isActive = true;
    routerSubscription: Subscription;
    syncCountSubscription: Subscription;
    environmentName = '';
    userClaims$: Observable<any>;

    constructor(
        private readonly produktDetailResolveService: ProduktDetailResolveService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly fileService: FileService,
        private readonly snackBarService: SnackBarService,
        private readonly viewportService: ViewportService,
        private readonly authService: AuthService,
        private readonly einstellungenService: EinstellungenService,
    ) {
        Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(activatedRoute, 'activatedRoute');
        Assert.notNullOrUndefined(fileService, 'fileService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        Assert.notNullOrUndefined(authService, 'authService');
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
    }

    ngOnInit(): void {
        this.produkt$ = this.produktDetailResolveService.change();
        this.environmentName = environment.name;
        this.vorgangsnummerSubscription = this.produkt$.subscribe((produkt) => {
            produkt && produkt.auftrag
                ? this.auftragsnummer$.next(produkt.auftrag.nummer)
                : this.auftragsnummer$.next('');
        });
        this.routerSubscription = this.router.events.subscribe((val: NavigationEnd) => {
            if (val instanceof NavigationEnd) {
                this.isActive = val.url && val.url.includes('produkt/detail');
            }
        });
        this.produkt = this.produktDetailResolveService.get();
        this.registerRouterEvents();
        this.registerFileServiceEvents();
        this.viewport$ = this.viewportService.observe();
    }

    ngOnDestroy(): void {
        this.vorgangsnummerSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
        this.syncCountSubscription.unsubscribe();
    }

    onNavigateBefore(): void {
        this.router.navigate([this.getBackUrl()]);
    }

    onSyncClick(): void {
        this.fileService
            .sync()
            .pipe(take(1))
            .subscribe((result) => {
                if (result) {
                    this.snackBarService.success('fileService.sync.success');
                } else {
                    this.snackBarService.error('fileService.sync.error');
                }
            });
    }

    isProdEnvironment(): boolean {
        return this.environmentName === 'PROD';
    }

    onUserMenuClicked(): void {
        this.loadUserClaims();
    }

    private registerFileServiceEvents(): void {
        this.syncCount$ = this.fileService.syncCount();
        this.syncInProgress$ = this.fileService.syncInProgress();
        this.syncCountSubscription = this.syncCount$
            .pipe(
                switchMap((syncCount) => (syncCount > 0 ? this.checkSyncInProgress() : of(null))),
                switchMap((syncInProgress) => (syncInProgress === false ? this.checkDirectSync() : of(null))),
                tap((directSync) => {
                    if (directSync) {
                        this.onSyncClick();
                    }
                }),
            )
            .subscribe();
    }

    private checkSyncInProgress(): Observable<boolean> {
        return this.syncInProgress$.pipe(first());
    }

    private checkDirectSync(): Observable<boolean> {
        return this.einstellungenService.getBenutzer().pipe(
            map((benutzer) => {
                if (benutzer) {
                    return benutzer.directFileSync || false;
                }
                console.error('Error getting the directSync user configuration: benutzer is null or undefined');
                return null;
            }),
            catchError((err) => {
                console.error(`Error getting the directSync user configuration: ${err}`);
                return of(null);
            }),
        );
    }

    private registerRouterEvents(): void {
        this.navigating$ = this.router.events.pipe(
            filter(
                (event) =>
                    event instanceof NavigationStart ||
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError,
            ),
            map((event) => event instanceof NavigationStart),
        );
        this.backUrl$ = this.router.events.pipe(
            startWith(new NavigationEnd(null, null, null)),
            filter((event) => event instanceof NavigationEnd),
            map(() => this.getBackUrl()),
        );
    }

    private getBackUrl(): string {
        let route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        let header = route.snapshot.data as NavigationHeader;
        while (!header.backUrl && route.parent) {
            route = route.parent;
            header = route.snapshot.data as NavigationHeader;
        }
        return header.backUrl;
    }

    private loadUserClaims() {
        if (!this.userClaims$) {
            this.userClaims$ = this.authService.getClaims().pipe(
                map((claims) => {
                    if (claims) {
                        return {
                            ...claims,
                            bueroId: claims['custom:buero_id'],
                            userId: claims['custom:personal_nummer'],
                            userAgent: `${location}, ${navigator.userAgent}`,
                        };
                    }
                    return null;
                }),
            );
        }
    }
}
