import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { ProduktArt, RadBauart, RadZulaessig } from '@data/domain/schema/enum';
import { Rad } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { DecimalSeperatorFormatterService } from '@shared/service/form-controls/decimal-separator-formatter.service';
import { ThousandsSeperatorFormatterService } from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailRaederRadFormViewFactory extends FormViewModelBaseFactory<Rad> {
    constructor(
        private readonly thousandSeperatorFormatter: ThousandsSeperatorFormatterService,
        private readonly decimalSeperatorFormatter: DecimalSeperatorFormatterService,
    ) {
        super();
    }

    protected createField(model: Rad, name: string, produktArt: ProduktArt): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.fields.Rads.fields;
        switch (name) {
            case fields.RadPosition.name:
                return new ViewFormControl(model.radPosition);
            case fields.Hersteller.name:
                return new ViewFormControl(model.hersteller, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.ReifenTyp.name:
                return new ViewFormControl(model.reifenTyp);
            case fields.RadFelge.name:
                return new ViewFormControl(model.radFelge);
            case fields.Reifenbreite.name:
                return new ViewFormControl(model.reifenbreite, {
                    formatter: this.thousandSeperatorFormatter,
                });
            case fields.Querschnitt.name:
                return new ViewFormControl(model.querschnitt, {
                    formatter: this.thousandSeperatorFormatter,
                });
            case fields.Bauart.name:
                return new ViewFormControl(
                    model.bauart === undefined || model.bauart === null ? RadBauart.R : model.bauart,
                );
            case fields.Felgengroesse.name:
                return new ViewFormControl(model.felgengroesse, {
                    formatter: this.thousandSeperatorFormatter,
                });
            case fields.Tragfaehigkeit1.name:
                return new ViewFormControl(model.tragfaehigkeit1, {
                    formatter: this.thousandSeperatorFormatter,
                });
            case fields.Geschwindigkeitsindex.name:
                return new ViewFormControl(model.geschwindigkeitsindex);
            case fields.Profil.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.profil);
                } else {
                    return new ViewFormControl(model.profil, {
                        formatter: this.decimalSeperatorFormatter,
                    });
                }
            case fields.Dot.name:
                return new ViewFormControl(model.dot, {
                    validators: [Validators.pattern('[0-9]*'), Validators.minLength(4)],
                });
            case fields.Runflat.name:
                return new ViewFormControl(model.runflat);
            case fields.Zulaessig.name:
                return new ViewFormControl(model.zulaessig || RadZulaessig.None);
            case fields.Felgenhersteller.name:
                return new ViewFormControl(model.felgenhersteller, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
