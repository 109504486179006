import { Injectable } from '@angular/core';
import { Produkt } from '@data/domain/schema/type';
import { ProduktDetailCgFeststellungFormViewFactory } from '@modules/produkt/factory/cg-feststellung/produkt-detail-cg-feststellung-form-view.factory';
import { ProduktDetailFeststellungenFormViewFactory } from '@modules/produkt/factory/feststellungen/produkt-detail-feststellungen-form-view.factory';
import { ProduktDetailKalkulationFormViewFactory } from '@modules/produkt/factory/kalkulation/produkt-detail-kalkulation-form-view.factory';
import { ProduktDetailRestwertprognoseFormViewFactory } from '@modules/produkt/factory/restwertprognose/produkt-detail-restwertprognose-form-view.factory';
import { ProduktDetailVtiWerteFormViewFactory } from '@modules/produkt/factory/vti-werte/produkt-detail-vti-werte-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '../config/produkt-config';
import { ProduktDetailAdressenFormViewFactory } from '../factory/adressen/produkt-detail-adressen-form-view.factory';
import { ProduktDetailAuftragFormViewFactory } from '../factory/auftrag/produkt-detail-auftrag-form-view.factory';
import { ProduktDetailAusstattungFormViewFactory } from '../factory/ausstattung/produkt-detail-ausstattung-form-view.factory';
import { ProduktDetailFahrzeugFormViewFactory } from '../factory/fahrzeug/produkt-detail-fahrzeug-form-view.factory';
import { ProduktDetailFehlteileFormViewFactory } from '../factory/fehlteile/produkt-detail-fehlteile-form-view.factory';
import { ProduktDetailLackmessungFormViewFactory } from '../factory/lackmessung/produkt-detail-lackmessung-form-view.factory';
import { ProduktDetailUebersichtFormViewFactory } from '../factory/produkt-detail-uebersicht-form-view.factory';
import { ProduktDetailRaederFormViewFactory } from '../factory/raeder/produkt-detail-raeder-form-view.factory';
import { ProduktDetailSchadenFormViewFactory } from '../factory/schaden/produkt-detail-schaden-form-view.factory';
import { ProduktDetailUnterlagenFormViewFactory } from '../factory/unterlagen/produkt-detail-unterlagen-form-view.factory';
import { ProduktDetailVorschadenFormViewFactory } from '../factory/vorschaden/produkt-detail-vorschaden-form-view.factory';
import { ProduktDetailVtiAuftragFormViewFactory } from '../factory/vti-auftrag/produkt-detail-vti-auftrag-form-view.factory';
import { ProduktDetailWartungFormViewFactory } from '../factory/wartung/produkt-detail-wartung-form-view.factory';
import { ProduktDetailWerteFormViewFactory } from '../factory/werte/produkt-detail-werte-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFeatureFormViewProvider {
    constructor(
        private readonly produktDetailAdressenFormViewFactory: ProduktDetailAdressenFormViewFactory,
        private readonly produktDetailAuftragFormViewFactory: ProduktDetailAuftragFormViewFactory,
        private readonly produktDetailVtiAuftragFormViewFactory: ProduktDetailVtiAuftragFormViewFactory,
        private readonly produktDetailFahrzeugFormViewFactory: ProduktDetailFahrzeugFormViewFactory,
        private readonly produktDetailFehlteileFormViewFactory: ProduktDetailFehlteileFormViewFactory,
        private readonly produktDetailRaederFormViewFactory: ProduktDetailRaederFormViewFactory,
        private readonly produktDetailUebersichtFormViewFactory: ProduktDetailUebersichtFormViewFactory,
        private readonly produktDetailUnterlagenFormViewFactory: ProduktDetailUnterlagenFormViewFactory,
        private readonly produktDetailVorschadenFormViewFactory: ProduktDetailVorschadenFormViewFactory,
        private readonly produktDetailWartungFormViewFactory: ProduktDetailWartungFormViewFactory,
        private readonly produktDetailWerteFormViewFactory: ProduktDetailWerteFormViewFactory,
        private readonly produktDetailVtiWerteFormViewFactory: ProduktDetailVtiWerteFormViewFactory,
        private readonly produktDetailRestwertprognoseFormViewFactory: ProduktDetailRestwertprognoseFormViewFactory,
        private readonly produktDetailSchadenFormViewFactory: ProduktDetailSchadenFormViewFactory,
        private readonly produktDetailKalkulationFormViewFactory: ProduktDetailKalkulationFormViewFactory,
        private readonly produktDetailFeststellungenFormViewFactory: ProduktDetailFeststellungenFormViewFactory,
        private readonly produktDetailCgFeststellungFormViewFactory: ProduktDetailCgFeststellungFormViewFactory,
        private readonly produktDetailAusstattungFormViewFactory: ProduktDetailAusstattungFormViewFactory,
        private readonly produktDetailLackmessungFormViewFactory: ProduktDetailLackmessungFormViewFactory,
    ) {
        Assert.notNullOrUndefined(produktDetailAdressenFormViewFactory, 'produktDetailAdressenFormViewFactory');
        Assert.notNullOrUndefined(produktDetailAuftragFormViewFactory, 'produktDetailAuftragFormViewFactory');
        Assert.notNullOrUndefined(produktDetailVtiAuftragFormViewFactory, 'produktDetailVtiAuftragFormViewFactory');
        Assert.notNullOrUndefined(produktDetailFahrzeugFormViewFactory, 'produktDetailFahrzeugFormViewFactory');
        Assert.notNullOrUndefined(produktDetailFehlteileFormViewFactory, 'produktDetailFehlteileFormViewFactory');
        Assert.notNullOrUndefined(produktDetailRaederFormViewFactory, 'produktDetailRaederFormViewFactory');
        Assert.notNullOrUndefined(produktDetailUebersichtFormViewFactory, 'produktDetailUebersichtFormViewFactory');
        Assert.notNullOrUndefined(produktDetailUnterlagenFormViewFactory, 'produktDetailUnterlagenFormViewFactory');
        Assert.notNullOrUndefined(produktDetailVorschadenFormViewFactory, 'produktDetailVorschadenFormViewFactory');
        Assert.notNullOrUndefined(produktDetailWartungFormViewFactory, 'produktDetailWartungFormViewFactory');
        Assert.notNullOrUndefined(produktDetailWerteFormViewFactory, 'produktDetailWerteFormViewFactory');
        Assert.notNullOrUndefined(produktDetailVtiWerteFormViewFactory, 'produktDetailVtiWerteFormViewFactory');
        Assert.notNullOrUndefined(produktDetailSchadenFormViewFactory, 'produktDetailSchadenFormViewFactory');
        Assert.notNullOrUndefined(produktDetailKalkulationFormViewFactory, 'produktDetailKalkulationFormViewFactory');
        Assert.notNullOrUndefined(
            produktDetailFeststellungenFormViewFactory,
            'produktDetailFeststellungenFormViewFactory',
        );
        Assert.notNullOrUndefined(
            produktDetailCgFeststellungFormViewFactory,
            'produktDetailCgFeststellungFormViewFactory',
        );
        Assert.notNullOrUndefined(produktDetailAusstattungFormViewFactory, 'produktDetailAusstattungFormViewFactory');
        Assert.notNullOrUndefined(produktDetailLackmessungFormViewFactory, 'produktDetailLackmessungFormViewFactory');
    }

    provide(produkt: Produkt, featureName: string, featureFields: FeatureFields): ViewFormGroup {
        switch (featureName) {
            case PRODUKT_CONFIG_FEATURES.Auftrag.name:
                return this.produktDetailAuftragFormViewFactory.create(produkt.auftrag, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.VtiAuftrag.name:
                return this.produktDetailVtiAuftragFormViewFactory.create(
                    produkt.vtiAuftrag,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Fahrzeug.name:
                return this.produktDetailFahrzeugFormViewFactory.create(produkt.fahrzeug, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Adressen.name:
                return this.produktDetailAdressenFormViewFactory.create(produkt.adressen, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Uebersicht.name:
                return this.produktDetailUebersichtFormViewFactory.create(
                    produkt.uebersicht,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Raeder.name:
                return this.produktDetailRaederFormViewFactory.create(produkt.raeder, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Vorschaden.name:
                return this.produktDetailVorschadenFormViewFactory.create(
                    produkt.vorschaden,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Schaden.name:
                return this.produktDetailSchadenFormViewFactory.create(produkt.schaden, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Feststellungen.name:
                return this.produktDetailFeststellungenFormViewFactory.create(
                    produkt.feststellungen,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.CgFeststellung.name:
                return this.produktDetailCgFeststellungFormViewFactory.create(
                    produkt.cgFeststellung,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Ausstattung.name:
                return this.produktDetailAusstattungFormViewFactory.create(
                    produkt.ausstattung,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Unterlagen.name:
                return this.produktDetailUnterlagenFormViewFactory.create(
                    produkt.unterlagen,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Werte.name:
                return this.produktDetailWerteFormViewFactory.create(produkt.werte, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.VtiWerte.name:
                return this.produktDetailVtiWerteFormViewFactory.create(produkt.vtiWerte, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Kalkulation.name:
                return this.produktDetailKalkulationFormViewFactory.create(
                    produkt.kalkulation,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Restwertprognose.name:
                return this.produktDetailRestwertprognoseFormViewFactory.create(
                    produkt.restwertprognose,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Fehlteile.name:
                return this.produktDetailFehlteileFormViewFactory.create(produkt.fehlteile, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Wartung.name:
                return this.produktDetailWartungFormViewFactory.create(produkt.wartung, featureFields, produkt.art);
            case PRODUKT_CONFIG_FEATURES.Lackmessung.name:
                return this.produktDetailLackmessungFormViewFactory.create(
                    produkt.lackmessung,
                    featureFields,
                    produkt.art,
                );
            case PRODUKT_CONFIG_FEATURES.Abschluss.name:
                return { valid: true } as ViewFormGroup;
            case PRODUKT_CONFIG_FEATURES.VtiAbschluss.name:
                return { valid: true } as ViewFormGroup;
            case PRODUKT_CONFIG_FEATURES.CgAbschluss.name:
                return { valid: true } as ViewFormGroup;
            case PRODUKT_CONFIG_FEATURES.AcAbschluss.name:
                return { valid: true } as ViewFormGroup;
            default:
                throw new Error(`Could not create form for featureName: '${featureName}'.`);
        }
    }
}
