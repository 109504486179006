<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- Gruppen -->
        <ng-container *ngSwitchCase="'gruppen'">
            <app-accordion
                #accordion
                *ngFor="
                    let gruppe of form.get(field.arrayName).controls;
                    trackBy: trackByInstance;
                    first as first;
                    last as last;
                    index as index
                "
            >
                <app-expansion-panel
                    #panel
                    [expanded]="(active$ | async) === index"
                    (opened)="onPanelOpened(panel, index)"
                >
                    <!-- Header -->
                    <app-expansion-header>
                        <app-panel-title *ngIf="(gruppe.valueChanges | async) || gruppe.value as value">
                            <app-icon *ngIf="icons[value.name]" [icon]="icons[value.name]" />
                            &nbsp;
                            <strong>{{ value.name }}</strong>
                        </app-panel-title>
                    </app-expansion-header>
                    <!-- Input -->
                    <mat-form-field *ngIf="isManualAdded(gruppe)">
                        <input #input [name]="gruppe.value" matInput />
                        <ng-container matSuffix>
                            <button
                                [disabled]="input.value.length === 0"
                                (click)="onAddClick(gruppe, input, teileList)"
                                mat-icon-button
                            >
                                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
                            </button>
                        </ng-container>
                    </mat-form-field>
                    <!-- List -->
                    <app-selection-list #teileList [multiline]="true">
                        <app-list-option
                            *ngFor="let teil of gruppe.get('teile').controls; trackBy: trackByInstance; index as id"
                            [form]="teil"
                            [name]="'vorhanden'"
                            [displayName]="teil.value.name"
                            [id]="id + 1"
                        />
                    </app-selection-list>

                    <app-action-row>
                        <app-button [disabled]="first" (action)="prev(accordion)">
                            {{ name + '.zurueck' | translate }}
                        </app-button>
                        <app-divider [vertical]="true" />
                        <app-button [disabled]="last" (action)="next(accordion)">
                            {{ name + '.weiter' | translate }}
                        </app-button>
                    </app-action-row>
                </app-expansion-panel>
            </app-accordion>
        </ng-container>
        <!-- Bemerkungen -->
        <ng-container *ngSwitchCase="'individualaufbauten'">
            <app-accordion>
                <app-expansion-panel>
                    <app-expansion-header>
                        <app-panel-title>
                            <app-icon class="material-icons-outlined" matSuffix>font_download</app-icon>
                            &nbsp;
                            <strong>Individualaufbauten</strong>
                        </app-panel-title>
                    </app-expansion-header>
                    <app-textbausteine
                        #individualaufbauten
                        [form]="form"
                        [name]="field.arrayName"
                        [displayName]="'Individualaufbauten'"
                        [feature]="'Ausstattung'"
                        [produktArt]="this.produkt.art"
                    />
                </app-expansion-panel>
            </app-accordion>
        </ng-container>
    </ng-container>
</ng-container>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />
