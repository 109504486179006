<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- RadSaetze -->
        <ng-container *ngSwitchCase="'radSaetze'">
            <app-card
                class="satz"
                *ngFor="let radsatz of form.get(field.arrayName).controls; trackBy: trackByIndex; let i = index"
                spacing="8px"
            >
                <h3>{{ 'raeder.radsatz.' + i | translate }}</h3>
                <app-select
                    [form]="radsatz"
                    [name]="'lagerort'"
                    [displayName]="name + '.' + 'lagerort'"
                    [values]="valuesLagerort"
                    [valuePrefix]="name + '.'"
                />

                <app-tab-group>
                    <app-tab [label]="name + '.linkeSeite' | translate">
                        <ng-container
                            *ngTemplateOutlet="achse; context: { radsatzIndex: i, seite: 0, radsatz: radsatz }"
                        />
                    </app-tab>

                    <app-tab [label]="name + '.rechteSeite' | translate">
                        <ng-container
                            *ngTemplateOutlet="achse; context: { radsatzIndex: i, seite: 1, radsatz: radsatz }"
                        />
                    </app-tab>
                </app-tab-group>

                <button class="button delete-button" (click)="onRemoveSatzClick(i)" type="button" mat-icon-button>
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </button>
            </app-card>
            <app-card *ngIf="form.get(field.arrayName).controls.length !== 2" spacing="8px">
                <app-button (action)="onAddSatzClick()">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
                    {{ 'nfzRaeder.addsatz' | translate }}
                </app-button>
            </app-card>
        </ng-container>
    </ng-container>
</ng-container>

<app-card>
    <h3>{{ 'raeder.sonstiges' | translate }}</h3>
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name || field.arrayName">
            <!-- Notlaufeinrichtung -->
            <ng-container *ngSwitchCase="'notlaufeinrichtung'">
                <app-select
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [values]="notlaufeinrichtung"
                    [valuePrefix]="name + '.'"
                />
            </ng-container>
            <!-- Notlaufeinrichtung Profiltiefe -->
            <ng-container *ngSwitchCase="'notlaufeinrichtungProfiltiefe'">
                <app-input
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    type="text"
                    inputmode="numeric"
                >
                    <ng-container appSuffix>&nbsp;mm</ng-container>
                </app-input>
            </ng-container>
            <!-- Bemerkungen -->
            <ng-container *ngSwitchCase="'bemerkungen'">
                <app-textbausteine
                    #bemerkungen
                    [form]="form"
                    [name]="'bemerkungen'"
                    [displayName]="'Bemerkungen'"
                    [feature]="'raeder'"
                    [produktArt]="this.produkt.art"
                />
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<ng-template #achse let-radsatzIndex="radsatzIndex" let-seite="seite" let-radsatz="radsatz">
    <app-accordion [multi]="false">
        <app-expansion-panel
            *ngFor="let achse of radsatz.get('achsen').controls; trackBy: trackByIndex; let indexAchse = index"
        >
            <app-expansion-header>
                <app-panel-title class="title">
                    <strong>{{ indexAchse + 1 + '. Achse' }}</strong>
                </app-panel-title>
            </app-expansion-header>
            <app-input
                *ngIf="seite === 0"
                [form]="achse"
                [name]="'bewertung'"
                [displayName]="name + '.' + 'bewertung'"
                type="text"
            />

            <app-accordion [multi]="false">
                <app-expansion-panel
                    *ngFor="
                        let rad of achse.get('seiten').controls[seite].get('achsenRaeder').controls;
                        trackBy: trackByIndex;
                        let indexRad = index
                    "
                >
                    <app-expansion-header>
                        <app-panel-title class="title">
                            <span>{{ indexRad + 1 + '. Rad' }}</span>
                        </app-panel-title>
                    </app-expansion-header>
                    <app-produkt-detail-nfz-raeder-rad
                        [form]="rad"
                        [name]="'achsenRaeder'"
                        [fields]="this.fieldsAchsenRaeder"
                    />
                    <app-button (action)="onDuplicateRadClick(achse, seite, rad)">
                        <app-mat-icon class="new-style" [newStyle]="true" icon="icon-duplicate-report" />
                        {{ indexRad + 1 + '. ' + ('achsenRaeder.duplicateRad' | translate) }}
                    </app-button>
                    <app-button (action)="onDeleteRadClick(achse, seite, indexRad)" mat-icon-button>
                        <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                        {{ 'achsenRaeder.deleteRad' | translate }}
                    </app-button>
                </app-expansion-panel>
            </app-accordion>

            <div>
                <app-button (action)="onAddRadClick(achse, seite)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
                    {{ 'achsenRaeder.addRad' | translate }}
                </app-button>
                <app-button (action)="onDuplicateAchseClick(radsatz, achse)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-duplicate-report" />
                    {{ indexAchse + 1 + '. ' + ('achsenRaeder.duplicateAchse' | translate) }}
                </app-button>
                <app-button (action)="onDeleteAchseClick(radsatz, indexAchse)" mat-icon-button>
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                    {{ 'achsenRaeder.deleteAchse' | translate }}
                </app-button>
            </div>
        </app-expansion-panel>
    </app-accordion>

    <div>
        <app-button (action)="onAddAchseClick(radsatz)">
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
            {{ 'achsenRaeder.addAchse' | translate }}
        </app-button>
        <app-button *ngIf="seite === 0" (action)="onDuplicateSeiteClick(radsatz, radsatzIndex, seite)">
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-duplicate-report" />
            {{ (seite === 0 ? 'Linke ' : 'Rechte ') + ('achsenRaeder.duplicateSeite' | translate) }}
        </app-button>
        <app-button *ngIf="form.get('radSaetze').controls.length <= 1" (action)="onDuplicateSatzClick(radsatzIndex)">
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-duplicate-report" />
            {{ radsatzIndex + 1 + '. ' + ('nfzRaeder.duplicatesatz' | translate) }}
        </app-button>
    </div>
</ng-template>
