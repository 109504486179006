import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ProduktArt } from '@data/domain/schema/enum';
import { Verfuegbarkeit } from '@modules/textbausteine-verwalten/config/textbausteine-verwalten.config';
import { ObjectValues, Values } from '@shared/helper/values';

export enum EditMode {
    Textbaustein = 0,
    Verwaltung = 1,
}

@Component({
    selector: 'app-textbausteine-dialog-edit',
    templateUrl: './textbausteine-dialog-edit.component.html',
    styleUrls: ['./textbausteine-dialog-edit.component.scss'],
})
export class TextbausteineDialogEditComponent {
    @Input()
    feature = '';

    @Input()
    feld = '';

    @Input()
    feldDisplayName = '';

    @Input()
    form: UntypedFormGroup;

    @Input()
    mode = EditMode.Textbaustein;

    @Input()
    data: any;

    @Input()
    readonly = false;

    editMode = EditMode;

    produktArt = ProduktArt;

    verfuegbarkeit: Values = new ObjectValues(Verfuegbarkeit);

    verfuegbarkeitEnum = Verfuegbarkeit;

    isVerfuegbarkeitChecked(input: string): boolean {
        return this.form.get('verfuegbarkeit').value.find((value) => value === input);
    }

    isProduktChecked(art: ProduktArt): boolean {
        return this.form.get('produkte').value.find((value) => value === art);
    }

    setProdukt(art: ProduktArt, $event: MatSlideToggleChange): void {
        const currentlyChecked = this.form.get('produkte').value.find((value) => value === art);
        if (currentlyChecked !== $event.checked) {
            if ($event.checked) {
                this.form.get('produkte').value.push(art);
            } else {
                this.form.patchValue({
                    ['produkte']: this.form.get('produkte').value.filter((value) => value !== art),
                });
            }
        }
    }

    onVerfuegbarkeitChange($event: MatRadioChange): void {
        this.form.patchValue({ ['verfuegbarkeit']: [$event.value] });
    }
}
