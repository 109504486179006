import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CgFeststellung } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailCgFeststellungBauteilFormViewFactory } from '@modules/produkt/factory/cg-feststellung/produkt-detail-cg-feststellung-bauteil-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailCgFeststellungFormViewFactory extends FormViewModelBaseFactory<CgFeststellung> {
    constructor(
        private readonly produktDetailCgFeststellungBauteilFormViewFactory: ProduktDetailCgFeststellungBauteilFormViewFactory,
    ) {
        super();
        Assert.notNullOrUndefined(
            produktDetailCgFeststellungBauteilFormViewFactory,
            'produktDetailTextbausteinFormViewFactory',
        );
    }

    protected createField(model: CgFeststellung, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields;
        switch (name) {
            case fields.Versicherungsart.name:
                return new ViewFormControl(model?.versicherungsart);
            case fields.Verschleissbedingt.name:
                return new ViewFormControl(model?.verschleissbedingt);
            case fields.Diagnose.name:
                if (model.verschleissbedingt) {
                    return new ViewFormControl(model?.diagnose);
                } else {
                    return new ViewFormControl(model?.diagnose, { validators: Validators.required });
                }
            case fields.InternerKommentar.name:
                return new ViewFormControl(model?.internerKommentar);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: CgFeststellung, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields;
        switch (arrayName) {
            case fields.PositionenBauteileversichert.name: {
                const positionenBauteileversichert = model.positionenBauteileversichert || [];
                return new ViewFormArray(
                    positionenBauteileversichert.map((position) =>
                        this.produktDetailCgFeststellungBauteilFormViewFactory.create(position, arrayFields),
                    ),
                );
            }
            case fields.PositionenFahrzeugversichert.name: {
                const positionenFahrzeugversichert = model.positionenFahrzeugversichert || [];
                return new ViewFormArray(
                    positionenFahrzeugversichert.map((position) =>
                        this.produktDetailCgFeststellungBauteilFormViewFactory.create(position, arrayFields),
                    ),
                );
            }
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
