import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Values } from '@shared/helper/values';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent implements OnInit {
    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    displayName: string;

    @Input()
    default: any = null;

    @Input()
    defaultValue$ = new BehaviorSubject<any>(undefined);

    @Input()
    selections: any[];

    @Input()
    values: Values;

    @Input()
    valuePrefix = '';

    @Output()
    change = new EventEmitter<MatButtonToggleChange>();

    ngOnInit(): void {
        this.setDefaultValue();
    }

    private setDefaultValue(): void {
        if (this.default !== null && this.form.get(this.name).value === null) {
            this.form.patchValue({ [this.name]: this.default });
        }
    }
}
