<ng-container [formGroup]="form">
    <div
        class="select-component-wrapper"
        [ngClass]="
            (control.touchedChanges | async) && !(control.validChanges | async) && control.hasError('required')
                ? 'error'
                : ''
        "
    >
        <h4 class="mat-caption text-field-label">{{ (displayName | translate) + (required ? ' *' : '') }}</h4>
        <mat-form-field [class.disabled]="disabled" floatLabel="never">
            <mat-select
                #matSelect
                [id]="name"
                [formControlName]="name"
                [placeholder]="displayName | translate"
                (selectionChange)="selectionChange.emit($event)"
                disableRipple
                disableOptionCentering
                panelClass="mat-dropdown"
            >
                <mat-option *ngIf="hasEmptyField" />
                <mat-option
                    *ngFor="let key of values.keys; trackBy: trackByKey"
                    [value]="useKeyAsSelectValue ? key : values.values[key]"
                >
                    <ng-container *ngIf="useKeyAsDisplayValue">
                        {{
                            valuePrefix
                                ? (valuePrefix + key | lowercase | translate)
                                : valuePostfix
                                  ? key + valuePostfix
                                  : key
                        }}
                    </ng-container>
                    <ng-container *ngIf="!useKeyAsDisplayValue">
                        {{
                            valuePrefix
                                ? (valuePrefix + values.values[key] | lowercase | translate)
                                : valuePostfix
                                  ? values.values[key] + valuePostfix
                                  : values.values[key]
                        }}
                    </ng-container>
                </mat-option>
            </mat-select>
            <mat-icon class="dropdown-icon" [ngClass]="matSelect.panelOpen ? 'icon-chevron-up' : 'icon-chevron-down'" />
        </mat-form-field>
        <mat-error>
            {{ 'control.required' | translate: { name: (displayName | translate) } }}
        </mat-error>
    </div>
</ng-container>
