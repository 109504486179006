import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    FahrzeugIdentification,
    FahrzeugIdentificationOption,
    FahrzeugService,
    FahrzeugServiceRequest,
    FahrzeugServiceResponse,
} from '@data/api-gateway';
import { AbstractTemplateDialogComponent } from '@shared/component/popup-modal/template-dialog/template-dialog.component';
import { Assert } from '@shared/helper/assert';
import { ObjectValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

export interface ProduktDetailFahrzeugDialogData {
    response: FahrzeugServiceResponse;
}

@Component({
    selector: 'app-produkt-detail-fahrzeug-dialog',
    templateUrl: './produkt-detail-fahrzeug-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailFahrzeugDialogComponent implements AbstractTemplateDialogComponent, OnInit {
    private currentIdentifiers: FahrzeugIdentificationOption[] = [];
    private selectedIdentifiers: FahrzeugIdentificationOption[] = [];

    responseChange = new BehaviorSubject<FahrzeugServiceResponse>(null);
    identifiersChange = new BehaviorSubject<ObjectValues>(null);
    nextIdentifierChange = new BehaviorSubject<string>('');
    selectedIdentifiersChange = new BehaviorSubject<FahrzeugIdentificationOption[]>([]);

    requestActiveChange = new BehaviorSubject<boolean>(false);

    // input
    data: ProduktDetailFahrzeugDialogData;

    constructor(
        private readonly fahrzeugService: FahrzeugService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(fahrzeugService, 'fahrzeugService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }

    ngOnInit(): void {
        this.updateResponse(this.data.response);
    }

    onSearchWithNewIdentity(identifierIndex: string): void {
        Assert.notNullOrUndefined(identifierIndex, 'identifierIndex');
        this.selectIdentifier(+identifierIndex);
    }

    close(_action: string): void {
        // stub method
    }

    private selectIdentifier(index: number): void {
        const selectedIdentifier = this.currentIdentifiers[index];
        this.selectedIdentifiers.push(selectedIdentifier);
        if (this.updateIdentifiers()) {
            // no request therefor update selectedIdentifiers to view
            this.selectedIdentifiersChange.next([...this.selectedIdentifiers]);
            return;
        }

        const identification = this.getIdentification();
        const request = this.createRequest(identification);

        this.requestActiveChange.next(true);
        this.fahrzeugService
            .getVehicleIdentification(request)
            .pipe(
                finalize(() => this.requestActiveChange.next(false)),
                take(1),
            )
            .subscribe({
                next: (response) => this.handleResponse(response),
                error: () => this.snackBarService.error('fahrzeug.externalDataServiceResponseCode.error', request),
            });
    }

    private handleResponse(response: FahrzeugServiceResponse): void {
        if (response && response.fahrzeug && response.ausstattung) {
            this.data.response = response;
            this.close('fahrzeug.close');
        } else {
            if (
                !response.identifications ||
                response.identifications.length !== 1 ||
                response.identifications[0].nextIdentifiers.length === 0
            ) {
                this.close('fahrzeug.error');
            } else {
                this.updateResponse(response);
            }
        }
    }

    private updateResponse(response: FahrzeugServiceResponse): void {
        if (response.identifications.length === 1) {
            this.selectedIdentifiers = [...response.identifications[0].identifiers];
            this.selectedIdentifiersChange.next([...this.selectedIdentifiers]);
        }
        this.responseChange.next(response);
        this.updateIdentifiers();
    }

    private updateIdentifiers(): boolean {
        const response = this.responseChange.getValue();
        if (response.identifications.length === 1) {
            if (this.selectedIdentifiers.length !== response.identifications[0].identifiers.length) {
                this.currentIdentifiers = [];
            } else {
                this.currentIdentifiers = response.identifications[0].nextIdentifiers;
            }
        } else {
            const availableIdentifiers = response.identifications.map((x) => x.identifiers);
            this.currentIdentifiers = [];
            availableIdentifiers.forEach((availableIdentifier) => {
                for (let i = 0; i <= this.selectedIdentifiers.length; ++i) {
                    const identifier = availableIdentifier[i];
                    if (!identifier) {
                        break;
                    }

                    const selectedIdentifier = this.selectedIdentifiers[i];
                    if (!selectedIdentifier) {
                        if (this.currentIdentifiers.findIndex((x) => x.key === identifier.key) === -1) {
                            this.currentIdentifiers.push(identifier);
                        }
                        break;
                    }

                    if (
                        identifier.identifier !== selectedIdentifier.identifier ||
                        identifier.key !== selectedIdentifier.key
                    ) {
                        break;
                    }
                }
            });
        }

        if (this.currentIdentifiers.length === 0) {
            return false;
        }

        if (this.currentIdentifiers.length === 1) {
            this.selectIdentifier(0);
        } else {
            const identifiers = new ObjectValues(this.currentIdentifiers.map((x) => x.value));
            this.identifiersChange.next(identifiers);
            this.nextIdentifierChange.next(this.currentIdentifiers[0].identifier);
        }
        return true;
    }

    private getIdentification(): FahrzeugIdentification {
        let identification: FahrzeugIdentification = null;

        const response = this.responseChange.getValue();
        if (response.identifications.length === 1) {
            identification = JSON.parse(JSON.stringify(response.identifications[0])) as FahrzeugIdentification;
        } else {
            const availableIdentifiers = response.identifications.map((x) => x.identifiers);
            availableIdentifiers.forEach((availableIdentifier, index) => {
                for (let i = 0; i <= this.selectedIdentifiers.length; ++i) {
                    const identifier = availableIdentifier[i];

                    const selectedIdentifier = this.selectedIdentifiers[i];
                    if (!selectedIdentifier) {
                        identification = response.identifications[index];
                        break;
                    }

                    if (
                        !identifier ||
                        identifier.identifier !== selectedIdentifier.identifier ||
                        identifier.key !== selectedIdentifier.key
                    ) {
                        break;
                    }
                }
            });
        }
        identification.nextIdentifiers = [];
        identification.identifiers = this.selectedIdentifiers;
        return identification;
    }

    private createRequest(identification: FahrzeugIdentification): FahrzeugServiceRequest {
        const request: FahrzeugServiceRequest = {
            suchbaum: identification,
        };
        return request;
    }
}
