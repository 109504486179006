<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name">
        <!-- Anrede -->
        <ng-container *ngSwitchCase="'anrede'">
            <ng-container *ngTemplateOutlet="select; context: { field: field, values: anrede }" />
        </ng-container>
        <!-- Anrede Freitext -->
        <ng-container *ngSwitchCase="'anredeFreitext'">
            <ng-container *ngIf="isCustomAnrede()">
                <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
            </ng-container>
        </ng-container>
        <!-- Firma -->
        <ng-container *ngSwitchCase="'firma'">
            <ng-container *ngTemplateOutlet="textrequired; context: { field: field }" />
        </ng-container>
        <!-- Vorname -->
        <ng-container *ngSwitchCase="'vorname'">
            <ng-container *ngTemplateOutlet="textrequired; context: { field: field }" />
        </ng-container>
        <!-- Name -->
        <ng-container *ngSwitchCase="'name'">
            <ng-container *ngTemplateOutlet="textrequired; context: { field: field }" />
        </ng-container>
        <!-- StrasseNr -->
        <ng-container *ngSwitchCase="'strasseNr'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Postleitzahl -->
        <ng-container *ngSwitchCase="'postleitzahl'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Ort -->
        <ng-container *ngSwitchCase="'ort'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Telefon -->
        <ng-container *ngSwitchCase="'telefon'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Mobil -->
        <ng-container *ngSwitchCase="'mobil'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Email -->
        <ng-container *ngSwitchCase="'email'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- ExternalId -->
        <ng-container *ngSwitchCase="'externalId'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Bestellnummer -->
        <ng-container *ngSwitchCase="'bestellnummer'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- Arten -->
        <ng-container *ngSwitchCase="'arten'">
            <ng-container *ngTemplateOutlet="autocomplete; context: { field: field, options: arten }" />
        </ng-container>
    </ng-container>
</ng-container>

<!-- Templates -->
<ng-template #text let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
</ng-template>

<ng-template #textrequired let-field="field">
    <ng-container *ngIf="form.get(field.name) as control">
        <ng-container *ngIf="!control.valid || (control.value || '').length">
            <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #autocomplete let-field="field" let-options="options">
    <app-autocomplete [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [options]="options" />
</ng-template>

<ng-template #select let-field="field" let-values="values">
    <app-select
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [values]="values"
        [valuePrefix]="name + '.'"
        [hasEmptyField]="false"
    />
</ng-template>
