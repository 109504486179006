import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractTemplateDialogComponent } from '@shared/component/popup-modal/template-dialog/template-dialog.component';

export interface ProduktDetailErrorDialogData {
    textKey: string;
}

@Component({
    selector: 'app-produkt-detail-error-dialog',
    templateUrl: './produkt-detail-error-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailErrorDialogComponent implements AbstractTemplateDialogComponent {
    data?: ProduktDetailErrorDialogData;

    close(_action: string): void {
        // stub method
    }
}
