<app-card *ngIf="validations.length > 0; else valid">
    <div class="form-error" *ngFor="let validation of validations">
        <h3>{{ validation.name | translate }}</h3>
        <ul>
            <li *ngFor="let field of validation.incorrectFields">
                {{ name + '.field' | translate: { name: validation.name + '.' + field | translate } }}
            </li>
        </ul>
    </div>
</app-card>
<ng-template #valid>
    <app-card spacing="8px">
        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <div [class.readonly]="isProduktDisabled() | async"></div>
            <ng-container [ngSwitch]="field.arrayName">
                <ng-container *ngSwitchCase="'fazit'">
                    <app-textbausteine
                        #fazit
                        [form]="form"
                        [name]="'fazit'"
                        [displayName]="this.name + '.fazit' | translate"
                        [feature]="'Abschluss'"
                        [produktArt]="this.produkt.art"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'abschlussBemerkung'">
                    <app-textbausteine
                        #abschlussBemerkung
                        [form]="form"
                        [name]="'abschlussBemerkung'"
                        [displayName]="this.name + '.abschlussBemerkung' | translate"
                        [feature]="'Abschluss'"
                        [produktArt]="this.produkt.art"
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>

    <app-card spacing="8px">
        <app-produkt-detail-abschluss-entwurf
            [name]="name"
            [produkt]="produkt"
            [fields]="fields"
            [adressen]="adressen"
        />
    </app-card>
    <app-card spacing="8px">
        <app-produkt-detail-abschluss-anhaenge [name]="name" [produkt]="produkt" [statusChanged$]="statusChanged$" />
    </app-card>
    <app-card>
        <app-produkt-detail-abschluss-workflow
            [name]="name"
            [produkt]="produkt"
            (statusChangedEvent)="onStatusChanged($event)"
        />
    </app-card>
</ng-template>
