<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name || field.groupName || field.arrayName">
        <ng-container *ngSwitchCase="'gesamtKarosserie'">
            <h4>{{ name + '.' + field.groupName | translate }}</h4>
            <app-form-group cols="2">
                <app-form-field>
                    <ng-container
                        *ngTemplateOutlet="
                            template;
                            context: {
                                field: {
                                    name: 'gesamtKarosserieVon',
                                },
                            }
                        "
                    />
                </app-form-field>
                <app-form-field>
                    <ng-container
                        *ngTemplateOutlet="
                            template;
                            context: {
                                field: {
                                    name: 'gesamtKarosserieBis',
                                },
                            }
                        "
                    />
                </app-form-field>
            </app-form-group>
        </ng-container>

        <ng-container *ngSwitchCase="'beschreibungen'">
            <div class="title-container">
                <h4>{{ name + '.' + field.arrayName | translate }}</h4>
            </div>
            <app-textbausteine
                [form]="form"
                [name]="field.arrayName"
                [displayName]="name + '.' + field.arrayName"
                [feature]="name"
                [produktArt]="this.produkt.art"
            />
        </ng-container>
    </ng-container>
</ng-container>

<!-- Templates -->
<ng-template #template let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;µm</ng-container>
    </app-input>
</ng-template>
