import { PRODUKT_FIELDS } from '@data/domain/fragments/produkt.fragment';
import gql from 'graphql-tag';
import { Produkt, Textbaustein } from '../schema/type';

export interface GetTextbausteineData {
    getTextbausteine: Textbaustein[];
}

export const getTextbausteine = gql`
    query GetTextbausteine($feature: String, $feld: String) {
        getTextbausteine(feature: $feature, feld: $feld) {
            id
            kurztext
            langtext
            verfuegbarkeit
            produkte
            feature
            feld
            tags
            standard
            erstelltAm
            erstelltVon
        }
    }
`;

// Produkt

export interface GetProdukteData {
    getProdukte: Produkt[];
}

export const getProdukte = gql`
    query GetProdukte($searchText: String, $startDate: String, $endDate: String) {
        getProdukte(searchText: $searchText, startDate: $startDate, endDate: $endDate) {
            id
            idParent
            art
            status
            identnummer
            erstelltAm
            abgeschlossenAm
            anzahlBilder
            auftrag {
                id
                nummer
                vorgangsnummer
                erstellungsTag
            }
            fahrzeug {
                id
                kennzeichen
                identnummer
            }
        }
    }
`;

export interface GetProduktByIdData {
    getProduktById: Produkt;
}

export const getProduktInfoById = gql`
    query GetProduktInfoById($id: ID!) {
        getProduktById(id: $id) {
            id
            status
            identnummer
            versandArt
            rechnungsArt
            erstelltAm
        }
    }
`;

export const getProduktById = gql`
    query GetProduktById($id: ID!) {
        getProduktById(id: $id) {
            ...ProduktFields
        }
    }
    ${PRODUKT_FIELDS}
`;

export const getProduktDuplikat = gql`
    query GetProduktDuplikat($produktId: String, $duplikatProduktId: String, $produktArtDestination: Int) {
        getProduktDuplikat(
            produktId: $produktId
            duplikatProduktId: $duplikatProduktId
            produktArtDestination: $produktArtDestination
        ) {
            ...ProduktFields
        }
    }
    ${PRODUKT_FIELDS}
`;

export interface GetProduktDuplikatData {
    getProduktDuplikat: Produkt;
}

export interface GetAcProdukteData {
    getAlphaControllerProdukte: Produkt[];
}

export const getAlphaControllerProdukte = gql`
    query GetAlphaControllerProdukte($identnummer: String) {
        getAlphaControllerProdukte(identnummer: $identnummer) {
            id
            idParent
            art
            status
            identnummer
            erstelltAm
            anzahlBilder
            acMetaInformation {
                xmlErstelldatum
                acVorgangStatus
            }
            auftrag {
                id
                nummer
                vorgangsnummer
                erstellungsTag
            }
            fahrzeug {
                id
                kennzeichen
                identnummer
            }
            adressen {
                id
                adressen {
                    id
                    createdAt
                    firma
                    anrede
                    anredeFreitext
                    vorname
                    name
                    strasseNr
                    postleitzahl
                    ort
                    telefon
                    mobil
                    email
                    arten
                    externalId
                    bestellnummer
                }
            }
            acAbschluss {
                id
                acVersendet
            }
        }
    }
`;
