import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { OldtimerProdukt, OldtimerProduktInput } from '@data/domain/schema/types';
import { createOldtimerProduktMutation } from '@modules/oldtimer/graphql/mutations/create-oldtimer-produkt.mutation';
import { updateOldtimerProduktQuery } from '@modules/oldtimer/graphql/mutations/update-oldtimer-produkt.mutation';
import { getOldtimerProduktQuery } from '@modules/oldtimer/graphql/queries/oldtimer-produkt.query';
import { Assert } from '@shared/helper/assert';
import { NEVER, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OldtimerGraphqlService {
    constructor(private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    }

    getOldtimerProdukt(id: string): Observable<OldtimerProdukt | undefined> {
        const addTypename = false;
        const client = this.awsAppSyncClientProvider.provide(addTypename);

        const queryPromise = client.query<{
            oldtimerProdukt: OldtimerProdukt;
        }>({
            query: getOldtimerProduktQuery,
            variables: {
                id,
            },
            fetchPolicy: 'no-cache',
        });

        return from(queryPromise).pipe(map((response) => response.data?.oldtimerProdukt));
    }

    createOldtimerProdukt(input: OldtimerProduktInput): Observable<OldtimerProdukt | undefined> {
        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate<{
            createOldtimerProdukt: OldtimerProdukt;
        }>({
            mutation: createOldtimerProduktMutation,
            variables: {
                input,
            },
            fetchPolicy: 'no-cache',
        });

        return from(mutatePromise).pipe(
            map((response) => {
                return response.data?.['createOldtimerProdukt'];
            }),
        );
    }

    updateOldtimerProdukt(input: OldtimerProduktInput, id?: string): Observable<OldtimerProdukt | undefined> {
        if (!id) {
            return NEVER;
        }

        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate<{
            updateOldtimerProdukt: OldtimerProdukt;
        }>({
            mutation: updateOldtimerProduktQuery,
            variables: {
                id,
                input,
            },
            fetchPolicy: 'no-cache',
        });

        return from(mutatePromise).pipe(
            map((response) => {
                return response.data?.['updateOldtimerProdukt'];
            }),
        );
    }
}
