import { Injectable } from '@angular/core';
import { File } from '@app/class/file';
import { FileData } from '@app/class/file-data';
import { getDataFromBlob } from '@app/function/file';
import Painterro from 'painterro';
import { Observable, Subject, throwError } from 'rxjs';

interface ImageEditorInstance {
    hide: () => void;
    clear: () => void;
    show: (url: string) => void;
}

@Injectable({
    providedIn: 'root',
})
export class ImageEditorService {
    private instance: ImageEditorInstance;
    private objectName: string;
    private objectUrl: string;
    private result$: Subject<FileData<ArrayBuffer>>;

    edit(file: File): Observable<FileData<ArrayBuffer>> {
        if (this.objectUrl || this.result$) {
            return throwError(() => new Error('only one instance allowed.'));
        }

        this.objectName = file.name;
        this.objectUrl = URL.createObjectURL(new Blob([file.data]));
        this.result$ = new Subject<FileData<ArrayBuffer>>();

        if (!this.instance) {
            this.instance = this.createInstance();
        }
        this.instance.show(this.objectUrl);
        return this.result$;
    }

    private createInstance(): ImageEditorInstance {
        return Painterro({
            activeColor: '#da1f3d',
            onImageLoaded: this.onImageLoaded.bind(this),
            onClose: this.onClose.bind(this),
            saveHandler: this.onSave.bind(this),
            how_to_paste_actions: ['replace_all'],
            hideByEsc: false,
            saveByEnter: true,
            hiddenTools: ['line', 'brush', 'eraser', 'open', 'settings'],
        });
    }

    private onImageLoaded(): void {
        URL.revokeObjectURL(this.objectUrl);
        this.objectUrl = undefined;
    }

    private onClose(): void {
        this.result$.complete();
        this.result$ = undefined;
    }

    private onSave(
        image: {
            asBlob: (type: string, quality: number) => Blob;
        },
        done: (hide: boolean) => void,
    ): void {
        const blob = image.asBlob('image/jpeg', 1);
        getDataFromBlob(blob).then(
            (data) => {
                this.result$.next({
                    data,
                    name: this.objectName,
                    size: blob.size,
                    type: blob.type,
                });
                this.result$.complete();
                this.result$ = undefined;
                done(true);
            },
            (err) => {
                this.result$.error(err);
                this.result$.complete();
                this.result$ = undefined;
                done(true);
            },
        );
    }
}
