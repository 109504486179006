import { Injectable } from '@angular/core';
import {
    ProduktAdressenNotizenService,
    ProduktAuftragNotizenService,
    ProduktAusstattungNotizenService,
    ProduktCgFeststellungNotizenService,
    ProduktFahrzeugNotizenService,
    ProduktFeatureNotizenService,
    ProduktFehlteileNotizenService,
    ProduktFeststellungenNotizenService,
    ProduktLackmessungNotizenService,
    ProduktRaederNotizenService,
    ProduktSchadenNotizenService,
    ProduktUebersichtNotizenService,
    ProduktUnterlagenNotizenService,
    ProduktVorschadenNotizenService,
    ProduktVtiAuftragNotizenService,
    ProduktWartungNotizenService,
    ProduktWerteNotizenService,
} from '@data/domain/service/feature/notizen';
import { ProduktNfzRaederNotizenService } from '@data/domain/service/feature/notizen/produkt-nfz-raeder-notizen.service';
import { ProduktVtiWerteNotizenService } from '@data/domain/service/feature/notizen/produkt-vti-werte-notizen.service';
import { Assert } from '@shared/helper/assert';
import { PRODUKT_CONFIG_FEATURES } from '../config/produkt-config';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFeatureNotizenServiceProvider {
    constructor(
        private readonly produktAdressenNotizenService: ProduktAdressenNotizenService,
        private readonly produktAuftragNotizenService: ProduktAuftragNotizenService,
        private readonly produktVtiAuftragNotizenService: ProduktVtiAuftragNotizenService,
        private readonly produktAusstattungNotizenService: ProduktAusstattungNotizenService,
        private readonly produktFahrzeugNotizenService: ProduktFahrzeugNotizenService,
        private readonly produktFehlteileNotizenService: ProduktFehlteileNotizenService,
        private readonly produktRaederNotizenService: ProduktRaederNotizenService,
        private readonly produktNfzRaederNotizenService: ProduktNfzRaederNotizenService,
        private readonly produktSchadenNotizenService: ProduktSchadenNotizenService,
        private readonly produktFeststellungenNotizenService: ProduktFeststellungenNotizenService,
        private readonly produktCgFeststellungNotizenService: ProduktCgFeststellungNotizenService,
        private readonly produktUebersichtNotizenService: ProduktUebersichtNotizenService,
        private readonly produktUnterlagenNotizenService: ProduktUnterlagenNotizenService,
        private readonly produktVorschadenNotizenService: ProduktVorschadenNotizenService,
        private readonly produktWartungNotizenService: ProduktWartungNotizenService,
        private readonly produktWerteNotizenService: ProduktWerteNotizenService,
        private readonly produktVtiWerteNotizenService: ProduktVtiWerteNotizenService,
        private readonly produktLackmessungNotizenService: ProduktLackmessungNotizenService,
    ) {
        Assert.notNullOrUndefined(produktAdressenNotizenService, 'produktAdressenNotizenService');
        Assert.notNullOrUndefined(produktAuftragNotizenService, 'produktAuftragNotizenService');
        Assert.notNullOrUndefined(produktVtiAuftragNotizenService, 'produktVtiAuftragNotizenService');
        Assert.notNullOrUndefined(produktAusstattungNotizenService, 'produktAusstattungNotizenService');
        Assert.notNullOrUndefined(produktFahrzeugNotizenService, 'produktFahrzeugNotizenService');
        Assert.notNullOrUndefined(produktFehlteileNotizenService, 'produktFehlteileNotizenService');
        Assert.notNullOrUndefined(produktRaederNotizenService, 'produktRaederNotizenService');
        Assert.notNullOrUndefined(produktSchadenNotizenService, 'produktSchadenNotizenService');
        Assert.notNullOrUndefined(produktFeststellungenNotizenService, 'produktFeststellungenNotizenService');
        Assert.notNullOrUndefined(produktCgFeststellungNotizenService, 'produktCgFeststellungNotizenService');
        Assert.notNullOrUndefined(produktUebersichtNotizenService, 'produktUebersichtNotizenService');
        Assert.notNullOrUndefined(produktUnterlagenNotizenService, 'produktUnterlagenNotizenService');
        Assert.notNullOrUndefined(produktVorschadenNotizenService, 'produktVorschadenNotizenService');
        Assert.notNullOrUndefined(produktWartungNotizenService, 'produktWartungNotizenService');
        Assert.notNullOrUndefined(produktWerteNotizenService, 'produktWerteNotizenService');
        Assert.notNullOrUndefined(produktVtiWerteNotizenService, 'produktVtiWerteNotizenService');
        Assert.notNullOrUndefined(produktLackmessungNotizenService, 'produktLackmessungNotizenService');
    }

    provide(featureName: string): ProduktFeatureNotizenService {
        switch (featureName) {
            case PRODUKT_CONFIG_FEATURES.Adressen.name:
                return this.produktAdressenNotizenService;
            case PRODUKT_CONFIG_FEATURES.Auftrag.name:
                return this.produktAuftragNotizenService;
            case PRODUKT_CONFIG_FEATURES.VtiAuftrag.name:
                return this.produktVtiAuftragNotizenService;
            case PRODUKT_CONFIG_FEATURES.Ausstattung.name:
                return this.produktAusstattungNotizenService;
            case PRODUKT_CONFIG_FEATURES.Fahrzeug.name:
                return this.produktFahrzeugNotizenService;
            case PRODUKT_CONFIG_FEATURES.Fehlteile.name:
                return this.produktFehlteileNotizenService;
            case PRODUKT_CONFIG_FEATURES.Raeder.name:
                return this.produktRaederNotizenService;
            case PRODUKT_CONFIG_FEATURES.NfzRaeder.name:
                return this.produktNfzRaederNotizenService;
            case PRODUKT_CONFIG_FEATURES.Schaden.name:
                return this.produktSchadenNotizenService;
            case PRODUKT_CONFIG_FEATURES.Uebersicht.name:
                return this.produktUebersichtNotizenService;
            case PRODUKT_CONFIG_FEATURES.Unterlagen.name:
                return this.produktUnterlagenNotizenService;
            case PRODUKT_CONFIG_FEATURES.Vorschaden.name:
                return this.produktVorschadenNotizenService;
            case PRODUKT_CONFIG_FEATURES.Feststellungen.name:
                return this.produktFeststellungenNotizenService;
            case PRODUKT_CONFIG_FEATURES.CgFeststellung.name:
                return this.produktCgFeststellungNotizenService;
            case PRODUKT_CONFIG_FEATURES.Wartung.name:
                return this.produktWartungNotizenService;
            case PRODUKT_CONFIG_FEATURES.Werte.name:
                return this.produktWerteNotizenService;
            case PRODUKT_CONFIG_FEATURES.VtiWerte.name:
                return this.produktVtiWerteNotizenService;
            case PRODUKT_CONFIG_FEATURES.Lackmessung.name:
                return this.produktLackmessungNotizenService;
            default:
                throw new Error(`Could not provide 'ProduktFeatureNotizenService' for featureName: '${featureName}'.`);
        }
    }
}
