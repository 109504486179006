import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-spinner',
    templateUrl: './progress-spinner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressSpinnerComponent {
    @Input()
    diameter = 50;
}
