import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { Subscription, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-signin',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninComponent implements OnInit, OnDestroy {
    constructor(
        private readonly authService: AuthService,
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
        private readonly router: Router,
    ) {
        Assert.notNullOrUndefined(authService, 'authService');
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
        Assert.notNullOrUndefined(router, 'router');
    }

    private signinSubscription?: Subscription;

    ngOnInit(): void {
        this.signinSubscription = from(this.awsAppSyncClientProvider.provide().resetStore())
            .pipe(
                catchError(() => of(null)),
                mergeMap(() =>
                    this.authService.completeSignin().pipe(
                        mergeMap(() =>
                            this.router.navigate(['/'], {
                                replaceUrl: true,
                            }),
                        ),
                        catchError((_error) =>
                            this.router.navigate(['auth', 'error'], {
                                replaceUrl: true,
                            }),
                        ),
                    ),
                ),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.signinSubscription?.unsubscribe();
    }
}
