<mat-form-field *ngIf="filterPredicate && customFilterVisible">
    <mat-label>{{ 'table.filter' | translate }}</mat-label>
    <input (keyup)="applyFilter($event)" matInput />
</mat-form-field>
<div class="content-wrapper mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of columns$ | async" [matColumnDef]="column.name">
            <mat-header-cell
                *matHeaderCellDef
                [disabled]="!column.sort"
                [start]="column.start"
                mat-sort-header
                scope="col"
            >
                {{ column.displayName | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="column.template; context: { element: element }" />
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns$ | async" />
        <mat-row
            *matRowDef="let row; columns: displayedColumns$ | async"
            [class.clickable]="action.observers.length > 0"
            (click)="onRowClick(row)"
        />
    </mat-table>
    <mat-paginator *ngIf="pageSizeOptions" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons />
</div>
