import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GetAcProdukteData, getAlphaControllerProdukte } from '@data/domain/graphql/queries';
import { Fahrzeug, Produkt } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';

export enum AC_Vorgang_Status {
    None = 0,
    Offen = 1,
    InBearbeitung = 2,
    Geschlossen = 3,
}

export enum AcProduktStatus {
    None = 0,
    InBearbeitung = 1,
    Abgeschlossen = 2,
    Versendet = 3,
}

export enum AcFahrzeugScheckheft {
    KeineAngabe = 0,
    NichtVollstaendig = 1,
    NichtVorhanden = 2,
    OhneEintraege = 3,
    Vollstaendig = 4,
}

export interface AcProdukt extends Produkt {
    acMetaInformation?: AcMetaInformation;
}

export interface AcMetaInformation {
    id: string; // produktId
    xmlErstelldatum?: string;
    version?: string;
    subdomain?: string;
    xmlVersion?: number;
    globaleId?: string;
    fahrzeugId?: number;
    sender?: string;
    importMode?: string;
    acVorgangStatus?: AcProduktStatus;
}

export interface AcFahrzeug extends Fahrzeug {
    acBauform?: string;
    acFahrzeugart?: string;
    acSchadstoffklasse?: string;
    acGetriebeart?: string;
    acScheckheft?: AcFahrzeugScheckheft;
    acUnfallfahrzeug?: boolean;
    acBaujahr?: number;
    acAntriebstechnologie?: string;
}

const GET_NETWORK_TIMEOUT = 1000 * 5;

@Injectable({
    providedIn: 'root',
})
export class AlphaControllerVorgangService {
    constructor(private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    }

    getVorgaenge(identnummer?: string): Observable<Produkt[]> {
        return this.query<GetAcProdukteData, Produkt[]>(
            {
                query: getAlphaControllerProdukte,
                variables: { identnummer },
            },
            (response) => response.getAlphaControllerProdukte,
            GET_NETWORK_TIMEOUT,
        );
    }

    private query<TResponse, TResult>(
        options: any,
        get: (response: TResponse) => TResult,
        due: number,
    ): Observable<TResult> {
        const client = this.awsAppSyncClientProvider.provide();
        client.hydrated();

        const cache$ = from(
            client.query<TResponse>({
                ...options,
                fetchPolicy: 'cache-only',
            }),
        );
        const network$ = from(
            client.query<TResponse>({
                ...options,
                fetchPolicy: 'network-only',
            }),
        );

        return cache$.pipe(
            mergeMap((cache) => {
                if (cache?.data && get(cache?.data)) {
                    return network$.pipe(
                        timeout(due),
                        catchError(() => of(cache)),
                    );
                } else {
                    return network$;
                }
            }),
            map((response) => get(response.data)),
        );
    }
}
