import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-fluid-container',
    templateUrl: './fluid-container.component.html',
    styleUrls: ['./fluid-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidContainerComponent implements OnInit {
    viewport$: Observable<Viewport>;

    @Input()
    disabled: boolean;

    constructor(private readonly viewport: ViewportService) {}

    ngOnInit(): void {
        this.viewport$ = this.viewport.observe().pipe(map((viewport) => (this.disabled ? Viewport.Mobile : viewport)));
    }
}
