<span>{{ feature }} - {{ feldDisplayName }}</span>

<app-input [form]="data.form" [name]="'kurztext'" [displayName]="'Kurztext'" [disabled]="readonly" type="text" />
<ng-container [formGroup]="data.form">
    <gtuema-max-character-textfield
        class="drucktext"
        [labelText]="'Drucktext'"
        [formControlName]="'langtext'"
        [inputHeight]="'40vh'"
        maxLength="4000"
    />
</ng-container>

<ng-container *ngIf="this.mode === editMode.Verwaltung">
    <!-- <ng-container>
    <div class="slide-container">
      <label>Verfügbarkeit</label>
      <mat-radio-group (change)="onVerfuegbarkeitChange($event)">
        <mat-radio-button [checked]="isVerfuegbarkeitChecked(verfuegbarkeitEnum.GTUE)"
                          [value]="verfuegbarkeitEnum.GTUE" [disabled]="false">GTÜ
        </mat-radio-button>
        <mat-radio-button [checked]="isVerfuegbarkeitChecked(verfuegbarkeitEnum.Buero)"
                          [value]="verfuegbarkeitEnum.Buero">Büro
        </mat-radio-button>
        <mat-radio-button [checked]="isVerfuegbarkeitChecked(verfuegbarkeitEnum.Benutzer)"
                          [value]="verfuegbarkeitEnum.Benutzer">Benutzer
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container> -->

    <!-- <ng-container [formGroup]="data.form">
    <div class="slide-container">
      <label>Produkte</label>
      <mat-slide-toggle [checked]="isProduktChecked(produktArt.Bewertung)" [disabled]="true"
                        (change)="setProdukt(produktArt.Bewertung, $event)">Bewertung
      </mat-slide-toggle>
      <mat-slide-toggle [checked]="isProduktChecked(produktArt.Ruecknahmebewertung)" [disabled]="true"
                        (change)="setProdukt(produktArt.Ruecknahmebewertung, $event)">Rücknahmebewertung
      </mat-slide-toggle>
      <mat-slide-toggle [checked]="isProduktChecked(produktArt.Zustandsbericht)"
                        (change)="setProdukt(produktArt.Zustandsbericht, $event)">Zustandsbericht
      </mat-slide-toggle>
    </div>
  </ng-container> -->

    <div [formGroup]="data.form">
        <label>Als Standard anzeigen</label>
        <div class="slide-container">
            <mat-slide-toggle [formControlName]="'standard'">Standard</mat-slide-toggle>
        </div>
    </div>
</ng-container>
