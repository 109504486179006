import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BueroEinstellungen } from '@data/api-gateway/service/einstellungen.service';

@Injectable({
    providedIn: 'root',
})
export class EinstellungenBueroFormFactory {
    create(buero: BueroEinstellungen): UntypedFormGroup {
        return new UntypedFormGroup({
            logo: new UntypedFormControl(buero.logo),
            logoTop: new UntypedFormControl(buero.logoTop),
            fusszeile: new UntypedFormControl(buero.fusszeile),
            datCustomerNumber: new UntypedFormControl(buero.datCustomerNumber),
            datCustomerLogin: new UntypedFormControl(buero.datCustomerLogin),
            datInterfacePartnerNumber: new UntypedFormControl(buero.datInterfacePartnerNumber),
            datProductVariant: new UntypedFormControl(buero.datProductVariant),
            datInterfacePartnerSignature: new UntypedFormControl(buero.datInterfacePartnerSignature),
        });
    }
}
