import { Component, Input } from '@angular/core';
import { Produkt } from '@data/domain/schema/type';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';

@Component({
    selector: 'app-produkt-detail-lackmessung-gesamt',
    templateUrl: './produkt-detail-lackmessung-gesamt.component.html',
    styleUrls: ['./produkt-detail-lackmessung-gesamt.component.scss'],
})
export class ProduktDetailLackmessungGesamtComponent {
    @Input()
    name: string;

    @Input()
    form: ViewFormGroup;

    @Input()
    fields: FeatureFields;

    @Input()
    produkt: Produkt;

    trackByField = TrackBy.trackByField;

    constructor() {
        // This is intentional
    }
}
