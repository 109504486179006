<ng-container *ngIf="searchable">
    <mat-form-field>
        <input #filter (keyup)="onFilterKeyUp(filter.value)" matInput />
        <ng-container *ngIf="submit.observers.length > 0" matSuffix>
            <button [disabled]="filter.value.length === 0" (click)="onSubmit(filter)" mat-button>
                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
            </button>
        </ng-container>
    </mat-form-field>
</ng-container>
<mat-action-list>
    <button
        *ngFor="let key of keys$ | async; trackBy: trackByKey"
        [disabled]="disabled[key]"
        (click)="onItemClick(key)"
        mat-list-item
    >
        {{ keyValues[key] }}
    </button>
</mat-action-list>
