import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ProduktArt } from '@data/domain/schema/enum';
import { Uebersicht, UebersichtManuell } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailUebersichtSonstigesFormViewFactory } from '@modules/produkt/factory/produkt-detail-uebersicht-sonstiges-form-view.factory';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from './form-view-base.factory';

const TEXTBAUSTEIN_IDS_CAR_GARANTIE = ['a176c4fddce2486a8e072bbf07c0ead4', '7a7816cdc4b842d9a215463d29e7a25b'];

function specificTextbausteineRequiredValidator(ids: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const values = control.value as UebersichtManuell[];

        const missingEntries: string[] = [];

        ids.forEach((id) => {
            for (const value of values) {
                const bezeichnungen = value.bezeichnungen;
                for (const textbaustein of bezeichnungen) {
                    if (textbaustein.id === id && value.bilder?.length > 0) {
                        return;
                    }
                }
            }
            missingEntries.push(id);
        });

        const valid = missingEntries.length === 0;
        return !valid ? { required: true, missingTextbausteinEntries: missingEntries } : null;
    };
}

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailUebersichtFormViewFactory extends FormViewModelBaseFactory<Uebersicht> {
    constructor(private readonly sonstigesFormViewFactory: ProduktDetailUebersichtSonstigesFormViewFactory) {
        super();
    }

    protected createField(model: Uebersicht, name: string, produktArt: ProduktArt): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields;
        switch (name) {
            case fields.Deckblatt.name:
                return new ViewFormControl(model.deckblatt);
            case fields.VorneLinks.name:
                if (produktArt === ProduktArt.CarGarantie && model.fahrzeugVorhanden) {
                    return new ViewFormControl(model.vorneLinks || [], {
                        validators: Validators.required,
                    });
                }
                return new ViewFormControl(model.vorneLinks || []);
            case fields.VorneRechts.name:
                return new ViewFormControl(model.vorneRechts || []);
            case fields.HintenLinks.name:
                return new ViewFormControl(model.hintenLinks || []);
            case fields.HintenRechts.name:
                if (produktArt === ProduktArt.CarGarantie && model.fahrzeugVorhanden) {
                    return new ViewFormControl(model.hintenRechts || [], {
                        validators: Validators.required,
                    });
                }
                return new ViewFormControl(model.hintenRechts || []);
            case fields.Innenraum.name:
                return new ViewFormControl(model.innenraum || []);
            case fields.FahrzeugVorhanden.name:
                return new ViewFormControl(model.fahrzeugVorhanden === null ? true : model.fahrzeugVorhanden, {
                    updateOn: 'change',
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    createArray(
        model: Uebersicht,
        arrayName: string,
        arrayFields: FeatureFields,
        produktArt: ProduktArt,
    ): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields;
        if (arrayName === fields.Sonstiges.name) {
            const sonstiges = model.sonstiges || [];
            if (produktArt === ProduktArt.CarGarantie && model.fahrzeugVorhanden) {
                return new ViewFormArray(
                    sonstiges.map((sonstige) => this.sonstigesFormViewFactory.create(sonstige, arrayFields)),
                    {
                        validators: this.getSonstigeBilderValidators(produktArt),
                    },
                );
            }
            return new ViewFormArray(
                sonstiges.map((sonstige) => this.sonstigesFormViewFactory.create(sonstige, arrayFields)),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }

    private getSonstigeBilderValidators(produktArt: ProduktArt): ValidatorFn[] {
        if (produktArt === ProduktArt.CarGarantie) {
            return [Validators.required, specificTextbausteineRequiredValidator(TEXTBAUSTEIN_IDS_CAR_GARANTIE)];
        }
        return [];
    }
}
