import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-bagde',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
    @Input()
    label: string;

    @Input()
    icon: string;

    @Input()
    color = 'white';
}
