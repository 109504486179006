<ng-container *ngIf="visible$ | async">
    <div class="toolbar" *ngIf="produkt$ | async as produkt">
        <div class="entry">
            <app-select
                class="select"
                *ngIf="produkt.art === produktArt.Bewertung || produkt.art === produktArt.Ruecknahmebewertung"
                [form]="SelectProduktArt"
                [name]="'SelectProduktArt'"
                [displayName]="'Produkt'"
                [values]="
                    produkt.art === produktArt.Bewertung || produkt.art === produktArt.Ruecknahmebewertung
                        ? artBewertungValues
                        : artZustandsberichtValues
                "
                [valuePrefix]="'toolbar' + '.'"
                [hasEmptyField]="false"
                (selectionChange)="onChangeProduktart($event)"
            />
        </div>
    </div>
</ng-container>
