import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatTabLink, MatTabNav, MatTabNavPanel, ScrollDirection } from '@angular/material/tabs';
import { TrackBy } from '@shared/helper/track-by';
import { TabNavLink } from './tab-nav-link';

interface ScrollResult {
    maxScrollDistance: number;
    distance: number;
}

@Component({
    selector: 'app-tab-nav',
    templateUrl: './tab-nav.component.html',
    styleUrls: ['./tab-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavComponent implements OnInit, AfterViewInit {
    private items: MatTabLink[] = [];
    private scrollIndex = 0;

    trackByPath = TrackBy.trackByPath;

    @ViewChild(MatTabNav, { static: true })
    tabNav: MatTabNav;

    @ContentChild('icon', { static: true })
    iconTemplate: TemplateRef<any>;

    @Input()
    tabPanel: MatTabNavPanel;

    @Input()
    links: TabNavLink[] = [];

    @Input()
    disabled = false;

    ngOnInit(): void {
        this.tabNav._scrollHeader = this.internalScrollHeader.bind(this);
        this.tabNav._scrollToLabel = this.internalScrollToLabel.bind(this);
    }

    ngAfterViewInit(): void {
        this.items = this.tabNav._items.toArray();
    }

    onLeft(): void {
        this.tabNav._handlePaginatorClick('after');
    }

    onRight(): void {
        this.tabNav._handlePaginatorClick('before');
    }

    update(): void {
        this.tabNav.updateActiveLink();
    }

    private internalScrollToLabel(labelIndex: number): void {
        const scrollIndex = labelIndex;
        this.scrollTo(scrollIndex);
    }

    private internalScrollHeader(direction: ScrollDirection): ScrollResult {
        const scrollIndex = this.scrollIndex + (direction === 'after' ? 1 : -1);
        return this.scrollTo(scrollIndex);
    }

    private scrollTo(scrollIndex: number): ScrollResult {
        const distance = this.getScrollDistance(scrollIndex);
        const result = (this.tabNav as any)._scrollTo(distance) as ScrollResult;
        if (scrollIndex >= 0 && result.distance !== result.maxScrollDistance) {
            this.scrollIndex = scrollIndex;
        }
        return result;
    }

    private getScrollDistance(scrollIndex: number): number {
        let distance = 0;
        for (let i = 1; i <= scrollIndex; ++i) {
            const item = this.items[i - 1];
            if (item === undefined) {
                continue;
            }
            distance += item.elementRef.nativeElement.clientWidth;
        }
        return distance;
    }
}
