import { Injectable } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { AWSAppSyncClient } from 'aws-appsync';
import * as localforage from 'localforage';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AwsAppSyncClientFactory {
    constructor(private readonly authService: AuthService) {}

    create(url: string, region: string, addTypename = true): AWSAppSyncClient<NormalizedCacheObject> {
        Assert.notNullOrEmpty(url, 'url');
        Assert.notNullOrEmpty(region, 'region');

        return new AWSAppSyncClient({
            url,
            region,
            auth: {
                type: 'AMAZON_COGNITO_USER_POOLS',
                jwtToken: () =>
                    this.authService
                        .getUser()
                        .pipe(map((user) => user.id_token))
                        .toPromise(),
            },
            disableOffline: false,
            offlineConfig: {
                storage: localforage,
                callback: (err, _success) => {
                    if (err) {
                        const { mutation } = err;
                        console.warn(`ERROR for ${mutation}`, err);
                    }
                },
            },
            cacheOptions: {
                addTypename,
                dataIdFromObject: (result) => {
                    if (result.__typename && result.id !== undefined) {
                        return `${result.__typename}:${result.id}`;
                    }
                    return null;
                },
            },
        });
    }
}
