import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Notiz } from '@data/domain/schema/type';
import { ProduktDetailNotizenDialogService } from '@modules/produkt/service/produkt-detail-notizen-dialog.service';
import { Assert } from '@shared/helper/assert';
import { DisplayService } from '@shared/service/display.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-notizen-button',
    templateUrl: './produkt-detail-notizen-button.component.html',
    styleUrls: ['./produkt-detail-notizen-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailNotizenButtonComponent {
    @Input()
    notizen: Notiz[];

    visible$: BehaviorSubject<boolean>;

    @Output()
    notizenChange = new EventEmitter<Notiz[]>();

    private isDragging = false;

    constructor(
        private readonly notizenDialogService: ProduktDetailNotizenDialogService,
        private readonly displayService: DisplayService,
    ) {
        Assert.notNullOrUndefined(notizenDialogService, 'notizenDialogService');
        Assert.notNullOrUndefined(displayService, 'displayService');
        this.visible$ = this.displayService.showNotizButton;
    }

    onAction(): void {
        if (!this.isDragging) {
            this.notizenDialogService.open(this.notizen).subscribe((notizen) => this.setNotizen(notizen));
        }
        this.isDragging = false;
    }

    private setNotizen(notizen: Notiz[]): void {
        if (notizen) {
            this.notizenChange.next(notizen);
        }
    }
}
