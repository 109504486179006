import { Clipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Directive, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProduktArt, ProduktStatus, SchadenObergruppe } from '@data/domain/schema/enum';
import { Notiz, SchadenPositionBase } from '@data/domain/schema/type';
import { ProduktFeatureService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import {
    FeatureField,
    FeatureFieldArray,
    FeatureFieldGroup,
    FeatureFields,
} from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { FormViewModelBaseFactory } from '@modules/produkt/factory/form-view-base.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableRowMoveEvent } from '@shared/component/data-table';
import {
    ColumnCount,
    MaxColumnEntries,
} from '@shared/component/form-controls/extendable-radio-list/extendable-radio-list.component';
import { ExpansionPanelCustomComponent } from '@shared/component/layout/expansion/expansion-panel-custom/expansion-panel-custom.component';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateButtonDisableEvent, TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription, filter, map, mergeMap, of, startWith, take } from 'rxjs';

interface PositionDialogData {
    form: ViewFormGroup;
    fields: FeatureFields;
    produktArt?: ProduktArt;
    relativerWert?: number;
}

@Directive()
export abstract class ProduktDetailSchadenComponentBase<
        TFeature,
        TFeatureInput,
        TFeaturePosition extends { obergruppe?: string; untergruppe?: string },
        TDisplayEnum,
    >
    extends ProduktDetailFeatureInputComponent<TFeature, TFeatureInput>
    implements OnInit, OnDestroy {
    protected abstract featureName: string;
    protected abstract positionenName: string;
    protected abstract costGroupName: string;
    protected abstract positionenFieldConfig: any;

    protected positionen: ViewFormArray;
    protected positionenFields: FeatureFields;

    protected intensitaet: EnumValues;
    protected modelFileConfigs: ModelFileConfig[];

    protected abstract display$: BehaviorSubject<TDisplayEnum>;
    protected loading$ = new BehaviorSubject<boolean>(false);
    protected modelDisabled$ = new BehaviorSubject<boolean>(false);
    protected rows$: Observable<AbstractViewFormControl[]>;
    protected sums$: Observable<string>;
    protected notizen$: Observable<Notiz[]>;
    protected viewport$: Observable<Viewport>;
    protected mobileLandscapeOptimization: Subscription;

    protected trackByField = TrackBy.trackByField;
    protected ButtonType = ButtonType;
    protected maxColumnEntries = MaxColumnEntries;
    protected columnCountEnum = ColumnCount;
    protected viewport = Viewport;
    protected displayEnum: TDisplayEnum;
    protected statusEnum = ProduktStatus;

    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktFeatureService: ProduktFeatureService<TFeature, TFeatureInput>,
        protected positionFormViewFactory: FormViewModelBaseFactory<TFeaturePosition>,
        protected templateDialogService: TemplateDialogService,
        protected snackBarService: SnackBarService,
        private readonly formViewFactory: FormViewModelBaseFactory<TFeature>,
        private readonly currencyFormatter: CurrencyFormatterService,
        private readonly modelFileService: ModelFileService,
        private readonly viewportService: ViewportService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
        private readonly updateWerteService: UpdateWerteService,
        private readonly clipboard: Clipboard,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktFeatureService);
    }

    ngOnInit() {
        this.notizen$ = this.notizenService.init(this.produkt, this.featureName);
        this.viewport$ = this.viewportService.observe();
        this.modelFileConfigs = this.modelFileService.get(
            this.produkt?.fahrzeug?.fahrzeugart,
            this.produkt?.fahrzeug?.bauform,
        );
        this.mobileLandscapeOptimization = this.viewportService.mobileLandscapeOptimization();
        this.init(this.featureName);
    }

    ngOnDestroy(): void {
        this.mobileLandscapeOptimization.unsubscribe();
        super.ngOnDestroy();
    }

    onDisplayChange(display: TDisplayEnum): void {
        Assert.notNullOrUndefined(display, 'display');
        this.display$.next(display);
    }

    onObergruppeAction(index: number | string): void {
        Assert.notNullOrUndefined(index, 'index');
        if (typeof index === 'string') {
            const obergruppe = SchadenObergruppe[index];
            this.createPosition(obergruppe);
            return;
        }
        this.createPosition(index);
    }

    onObergruppeSelect(value: number | string): void {
        Assert.notNullOrUndefined(value, 'value');
        if (typeof value === 'string') {
            const obergruppe = ViewFormControlFormatters.firstLetterToUppercase.format(value) as SchadenObergruppe;
            this.createPosition(obergruppe);
            return;
        }
        this.createPosition(value);
    }

    onRowOpen(row: ViewFormGroup): void {
        Assert.notNullOrUndefined(row, 'row');
        const index = this.positionen?.controls?.indexOf(row);
        if (index) {
            this.editPosition(index, row.getRawValue());
        }
    }

    onRowOpenByIndex(index: number, item: any, panel: ExpansionPanelCustomComponent, $event: MouseEvent): void {
        Assert.notNullOrUndefined(index, 'index');
        $event.stopPropagation();
        panel.close();
        this.editPosition(index, item);
    }

    onRowRemove(row: ViewFormGroup): void {
        Assert.notNullOrUndefined(row, 'row');
        const index = this.positionen.controls.indexOf(row);
        this.positionen.removeAt(index);
        if (this.positionen.length === 0) {
            this.updateWerteService.resetAufwendungen(this.produkt);
        }
    }

    onRowRemoveByIndex(index: number, panel: ExpansionPanelCustomComponent, $event: MouseEvent): void {
        Assert.notNullOrUndefined(index, 'index');
        panel.close();
        $event.stopPropagation();
        this.positionen.removeAt(index);
        if (this.positionen.length === 0) {
            this.updateWerteService.resetAufwendungen(this.produkt);
        }
    }

    onRowMove(event: TableRowMoveEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        const index = this.positionen.controls.indexOf(event.row);
        this.positionen.controls.splice(index, 1);
        this.positionen.controls.splice(index + event.offset, 0, event.row);
        this.positionen.updateValueAndValidity();
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    copy(elementId: any): void {
        const inputValue = (document.getElementById(elementId) as HTMLInputElement).value;
        this.clipboard.copy(inputValue);
        this.snackBarService.success(`Wert kopiert: ${inputValue}`);
    }

    drop(event: CdkDragDrop<string[]>): void {
        const item = this.positionen.at(event.previousIndex);
        this.positionen.controls.splice(event.previousIndex, 1);
        this.positionen.controls.splice(event.currentIndex, 0, item);
        this.positionen.updateValueAndValidity();
    }

    checkFieldBestaetigt(item: SchadenPositionBase) {
        if (!item) {
            console.warn('checkFieldBestaetigt() - warning: item is null or undefined.');
        }
        let ret = false;

        if (!item.extern || (item.extern && item.bestaetigt)) {
            ret = true;
        }

        return ret;
    }

    changeTemplateButtonDisabled(event: TemplateButtonDisableEvent): void {
        if (!event) {
            return;
        }
        if (event.disable) {
            this.templateDialogService.disableButton(event.index);
        } else {
            this.templateDialogService.enableButton(event.index);
        }
    }

    protected createForm(): ViewFormGroup {
        const model = this.produkt[this.featureName];
        const form = this.formViewFactory.create(model, this.fields);
        this.positionen = form.get(this.positionenName) as ViewFormArray;
        this.positionenFields = (
            this.fields.find((x: FeatureFieldArray) => x.arrayName === this.positionenName) as FeatureFieldArray
        ).fields;
        this.rows$ = this.getRows$();
        this.sums$ = this.getSums$();
        return form;
    }

    private getRows$(): Observable<AbstractViewFormControl[]> {
        return this.positionen.valueChanges.pipe(
            startWith({}),
            map(() => [...this.positionen.controls] as AbstractViewFormControl[]),
        );
    }

    private getSums$(): Observable<string> {
        const fields = this.positionenFields.reduce((a, value: FeatureField & FeatureFieldGroup) => {
            if (value.groupName === this.costGroupName) {
                return a.concat(value.fields.map((field) => field.name));
            }

            if (value.name === this.costGroupName) {
                a.push(value.name);
            }
            return a;
        }, []);

        return this.positionen.valueChanges.pipe(
            startWith({}),
            map(() => {
                const bestaetigtePositionen: ViewFormArray = this.getBestaetigtePositionen();
                const sums = fields.map((field) => {
                    const sum = bestaetigtePositionen.controls.reduce(
                        (a, b: ViewFormGroup) => a + b.getRawValue()[field],
                        0,
                    );
                    return `${this.currencyFormatter.format(sum)} €`;
                });
                return sums.join(' | ');
            }),
        );
    }

    private editPosition(index: number, position: TFeaturePosition): void {
        this.openPosition(position)
            .pipe(take(1))
            .subscribe((update) => {
                (this.positionen.controls[index] as ViewFormGroup) = update;
                this.positionen.updateValueAndValidity();
            });
    }

    private createPosition(obergruppe: number | string): void {
        this.selectUntergruppe(obergruppe)
            .pipe(
                filter((value) => !!value),
                mergeMap((value) => this.openPosition(value)),
                take(1),
            )
            .subscribe((position) => {
                this.positionen.push(position);
            });
    }

    private openPosition(position: TFeaturePosition): Observable<ViewFormGroup> {
        if (!this.dialogTemplate) {
            console.error('ProduktDetailSchadenBase - openPosition(), no dialogTemplate found');
            return of(null);
        }
        const obergruppe = position.obergruppe || '';
        const untergruppe = position.untergruppe || '';
        const title = obergruppe === untergruppe ? obergruppe : `${obergruppe} / ${untergruppe}`;

        const fields = this.positionenFields;
        const form = this.positionFormViewFactory.create(position, fields);
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const produktArt = this.produkt?.art;
        const data: PositionDialogData = { form, fields, produktArt };

        data.relativerWert =
            produktArt === ProduktArt.Ruecknahmebewertung || produktArt === ProduktArt.AlphaController
                ? this.produkt?.werte?.relativerWert
                : null;
        return this.templateDialogService.openTemplate(title, buttons, this.dialogTemplate, data, true).pipe(
            take(1),
            filter((result) => result?.name === this.templateDialogService.getSaveButtonSetting().title),
            map((result) => result.data.form),
        );
    }

    private getBestaetigtePositionen(): ViewFormArray {
        const bestaetigtePositionen: ViewFormArray = new ViewFormArray([]);
        if (!this.positionen?.controls || !this.positionenFieldConfig) {
            console.warn(
                'Unable to get bestaetigtePositionen: positionen.controls or positionenFIeldConfig is undefined.',
            );
            return bestaetigtePositionen;
        }

        for (const control of this.positionen.controls) {
            const extern = control.get(this.positionenFieldConfig.Extern.name)?.value;
            const bestaetigt = control.get(this.positionenFieldConfig.Bestaetigt.name)?.value;

            if (!extern || (extern && bestaetigt)) {
                bestaetigtePositionen.push(control);
            }
        }

        return bestaetigtePositionen;
    }

    protected abstract onModelLoad(modelLoadStatus: ModelLoadResult): void;
    protected abstract setModelAndDisplay(): void;
    protected abstract selectUntergruppe(obergruppe: any): Observable<TFeaturePosition>;
}
