import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tab',
    template: `
        <ng-template #content>
            <ng-content />
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
    @Input()
    label: string;

    @ViewChild('content', { static: true })
    template: TemplateRef<any>;
}
