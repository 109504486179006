import { Injectable } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BenutzerEinstellungen } from '@data/api-gateway/service/einstellungen.service';

@Injectable({
    providedIn: 'root',
})
export class EinstellungenBenutzerFormFactory {
    create(benutzer: BenutzerEinstellungen): UntypedFormGroup {
        return new UntypedFormGroup({
            datCustomerNumber: new UntypedFormControl(benutzer.datCustomerNumber),
            datCustomerLogin: new UntypedFormControl(benutzer.datCustomerLogin),
            datInterfacePartnerNumber: new UntypedFormControl(benutzer.datInterfacePartnerNumber),
            datProductVariant: new UntypedFormControl(benutzer.datProductVariant),
            datInterfacePartnerSignature: new UntypedFormControl(benutzer.datInterfacePartnerSignature),
            datCustomerPassword: new UntypedFormControl(benutzer.datCustomerPassword),
            watermarkEnable: new UntypedFormControl(benutzer.watermarkEnable),
            directFileSync: new UntypedFormControl(benutzer.directFileSync || false),
            deactivate3dModel: new UntypedFormControl(benutzer.deactivate3dModel || false),
            wifiOnlyFileSync: new UntypedFormControl(benutzer.wifiOnlyFileSync),
            stempel: new UntypedFormControl(benutzer.stempel),
            unterschrift: new UntypedFormControl(benutzer.unterschrift),
            sachverstaendigerText: new UntypedFormControl(benutzer.sachverstaendigerText),
            errorHandlingSnackbar: new UntypedFormControl(
                benutzer.errorHandlingSnackbar ? benutzer.errorHandlingSnackbar : false,
            ),
            errorHandlingCloudSink: new UntypedFormControl(
                benutzer.errorHandlingCloudSink ? benutzer.errorHandlingCloudSink : false,
            ),
            customTextBewertung: new FormControl(
                benutzer.customTextBewertung ? benutzer.customTextBewertung : 'Bewertung',
            ),
            customTextRuecknahmebewertung: new FormControl(
                benutzer.customTextRuecknahmebewertung ? benutzer.customTextRuecknahmebewertung : 'Rücknahmebewertung',
            ),
            customTextZustandsbericht: new FormControl(
                benutzer.customTextZustandsbericht ? benutzer.customTextZustandsbericht : 'Zustandsbericht',
            ),
            customDescriptionBewertung: new FormControl(
                benutzer.customDescriptionBewertung ? benutzer.customDescriptionBewertung : '',
            ),
            customDescriptionRuecknahmebewertung: new FormControl(
                benutzer.customDescriptionRuecknahmebewertung ? benutzer.customDescriptionRuecknahmebewertung : '',
            ),
            customDescriptionZustandsbericht: new FormControl(
                benutzer.customDescriptionZustandsbericht ? benutzer.customDescriptionZustandsbericht : '',
            ),
        });
    }
}
