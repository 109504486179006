import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {
    @Input()
    disabled: boolean;

    @Output()
    action = new EventEmitter<MouseEvent>();
}
