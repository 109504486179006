import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-mat-icon',
    templateUrl: './mat-icon.component.html',
    styleUrls: ['./mat-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatIconComponent {
    @HostBinding('style.width')
    width: string;

    @HostBinding('style.height')
    height: string;

    style = {};

    @Input()
    icon: string;

    @Input()
    newStyle: boolean;

    @Input()
    color: string;

    @Input()
    svgIcon: string;

    @Input()
    set size(size: string) {
        this.width = size;
        this.height = size;
        this.style = {
            'font-size': size,
            width: size,
            height: size,
        };
    }
}
