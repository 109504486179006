import { Injectable } from '@angular/core';
import { ProduktStatus } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { FilterValues } from '@modules/produkt/page/produkt-uebersicht-gtue-em/produkt-uebersicht-gtue-em.component';

@Injectable({
    providedIn: 'root',
})
export class ProduktUebersichtFilterService {
    static checkStatusNotOpen(filterObject: FilterValues, produkt: Produkt): boolean {
        return filterObject.status && produkt.status !== ProduktStatus.Offen;
    }

    static checkProduktArtNotMatching(filterObject: FilterValues, produkt: Produkt): boolean {
        return filterObject.art && filterObject.art !== produkt.art;
    }

    static checkSearchText(filterObject: FilterValues, produkt: Produkt): boolean {
        if (!filterObject.searchText) {
            return true;
        }
        return (
            produkt.fahrzeug?.identnummer?.toLowerCase().includes(filterObject.searchText.toLowerCase()) ||
            produkt.fahrzeug?.kennzeichen?.toLowerCase().includes(filterObject.searchText.toLowerCase()) ||
            produkt.auftrag?.nummer?.toLowerCase().includes(filterObject.searchText.toLowerCase())
        );
    }

    static filterByDate(filterObject: FilterValues, produkt: Produkt): boolean {
        let result;
        if (ProduktUebersichtFilterService.checkVonAndBisIsTrue(filterObject)) {
            if (
                new Date(filterObject.von).getTime() <= new Date(produkt.erstelltAm).getTime() &&
                new Date(filterObject.bis).getTime() >= new Date(produkt.erstelltAm).getTime()
            ) {
                result = true;
            } else {
                return false;
            }
        } else if (!filterObject.von && filterObject.bis) {
            if (new Date(filterObject.bis).getTime() >= new Date(produkt.erstelltAm).getTime()) {
                result = true;
            } else {
                return false;
            }
        } else if (!filterObject.bis && filterObject.von) {
            if (new Date(filterObject.von).getTime() <= new Date(produkt.erstelltAm).getTime()) {
                result = true;
            } else {
                return false;
            }
        }
        return result;
    }

    static checkVonAndBisIsTrue(filterObject: FilterValues): boolean {
        return !!filterObject.von && !!filterObject.bis;
    }
}
