import { Injectable } from '@angular/core';
import { ProduktArt } from '@data/domain/schema/enum';
import { Fehlteile, Produkt, Schaden, Vorschaden, Wartung, Werte, WerteInput } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { ProduktWerteService } from './produkt-werte.service';

interface AbzugAufschlagDto {
    roundValue: number;
    aufwendungen: number;
    mehrwerte?: number;
    fehlteile?: number;
    wartung?: number;
    schaden?: number;
    vorschaden?: number;
}

@Injectable({
    providedIn: 'root',
})
export class UpdateWerteService {
    private isAutomaticCalculatedKey = 'werte.isAutomaticCalculated';

    constructor(private readonly produktWerteService: ProduktWerteService) {
        Assert.notNullOrUndefined(produktWerteService, 'produktWerteService');
    }

    get isAutomaticCalculated(): boolean {
        if (!localStorage.getItem(this.isAutomaticCalculatedKey)) {
            localStorage.setItem(this.isAutomaticCalculatedKey, String(false));
            return false;
        }
        return localStorage.getItem(this.isAutomaticCalculatedKey) === 'true';
    }

    set isAutomaticCalculated(value: boolean) {
        localStorage.setItem(this.isAutomaticCalculatedKey, String(value));
    }

    resetAufwendungen(produkt: Produkt): void {
        this.saveWerte(produkt, 0);
    }

    updateWerteBerechneteFelder(produkt: Produkt): void {
        const hekBrutto = produkt.werte?.haendlereinkaufswert;

        const hekAbzuegeBrutto = produkt.werte?.haendlereinkaufswertAbzuege;
        let hekAbzuegeBruttoBerechnet: number | undefined;

        const hekNetto = produkt.werte?.haendlereinkaufswertNetto;
        const hekAbzuegeNetto = produkt.werte?.haendlereinkaufswertAbzuegeNetto;
        let hekAbzuegeNettoBerechnet: number | undefined;

        const hvkBrutto = produkt.werte?.haendlerverkaufswert;
        const hvkAbzeugeBrutto = produkt.werte?.haendlerverkaufswertAbzuege;
        let hvkAbzuegeBruttoBerechnet: number | undefined;

        const hvkNetto = produkt.werte?.haendlerverkaufswertNetto;
        const hvkNettoAbzuegeNetto = produkt.werte?.haendlerverkaufswertAbzuegeNetto;
        let hvkAbzuegeNettoBerechnet: number | undefined;

        const dto = this.createAbzugAufschlagDto(produkt);
        let saveWerte = false;

        if (hekBrutto && hekBrutto > 0) {
            hekAbzuegeBruttoBerechnet = this.calcAbzuegeZuzuege(hekBrutto, dto);
            if (hekAbzuegeBrutto !== hekAbzuegeBruttoBerechnet) {
                saveWerte = true;
            }
        }
        if (hekNetto && hekNetto > 0) {
            hekAbzuegeNettoBerechnet = this.calcAbzuegeZuzuege(hekNetto, dto);
            if (hekAbzuegeNetto !== hekAbzuegeNettoBerechnet) {
                saveWerte = true;
            }
        }
        if (hvkBrutto && hvkBrutto > 0) {
            hvkAbzuegeBruttoBerechnet = this.calcAbzuegeZuzuege(hvkBrutto, dto);
            if (hvkAbzeugeBrutto !== hvkAbzuegeBruttoBerechnet) {
                saveWerte = true;
            }
        }
        if (hvkNetto && hvkNetto > 0) {
            hvkAbzuegeNettoBerechnet = this.calcAbzuegeZuzuege(hvkNetto, dto);
            if (hvkNettoAbzuegeNetto !== hvkAbzuegeNettoBerechnet) {
                saveWerte = true;
            }
        }

        const aufwendungenBerechnet = this.calcAufwendungen(dto);
        if (aufwendungenBerechnet !== dto.aufwendungen) {
            saveWerte = true;
        }

        if (saveWerte) {
            this.saveWerte(
                produkt,
                aufwendungenBerechnet,
                hekAbzuegeBruttoBerechnet,
                hekAbzuegeNettoBerechnet,
                hvkAbzuegeBruttoBerechnet,
                hvkAbzuegeNettoBerechnet,
            );
        }
    }

    private createAbzugAufschlagDto(produkt: Produkt): AbzugAufschlagDto {
        const result: AbzugAufschlagDto = {
            roundValue: produkt.werte.roundValue,
            aufwendungen:
                produkt.werte.aufwendungen === null || produkt.werte.aufwendungen === undefined
                    ? 0
                    : produkt.werte.aufwendungen,
            mehrwerte: this.addMehrwerte(produkt.werte),
            fehlteile: this.addFehlteile(produkt.fehlteile),
            wartung: this.addWartung(produkt.wartung),
            schaden: this.addSchaden(produkt.schaden, produkt.art),
            vorschaden: this.addVorschaden(produkt.vorschaden),
        };
        return result;
    }

    private calcAbzuegeZuzuege(basisWert: number, d: AbzugAufschlagDto): number {
        let result = basisWert;
        const isAufwendungenManuell =
            d.mehrwerte > 0 || d.fehlteile > 0 || d.wartung > 0 || d.schaden > 0 || d.vorschaden > 0 ? false : true;

        if (!isAufwendungenManuell) {
            result = basisWert - d.wartung - d.fehlteile - d.schaden - d.vorschaden + d.mehrwerte;
            return Math.max(0, result);
        }

        if (d.aufwendungen > 0) {
            result = basisWert - d.aufwendungen;
            return Math.max(0, result);
        }

        return result;
    }

    private calcAufwendungen(d: AbzugAufschlagDto): number {
        if (d.aufwendungen === null || d.aufwendungen === undefined) {
            return 0;
        }

        const aufwendungenCalculated = d.fehlteile + d.schaden + d.vorschaden + d.wartung - d.mehrwerte;
        const isAufwendungenManuell = !this.atLeastOneAufwendungPositionSet(d);

        if (isAufwendungenManuell) {
            return d.aufwendungen;
        }

        return aufwendungenCalculated;
    }

    private atLeastOneAufwendungPositionSet(d: AbzugAufschlagDto): boolean {
        return d.mehrwerte > 0 || d.fehlteile > 0 || d.wartung > 0 || d.schaden > 0 || d.vorschaden > 0;
    }

    private saveWerte(
        produkt: Produkt,
        aufwendungenBerechnet,
        hekAbzuegeBruttoBerechnet?: number,
        hekAbzuegeNettoBerechnet?: number,
        hvkAbzuegeBruttoBerechnet?: number,
        hvkAbzuegeNettoBerechnet?: number,
    ): void {
        if (!produkt?.werte) {
            return;
        }
        const werteInput: WerteInput = {
            haendlereinkaufswertAbzuege: hekAbzuegeBruttoBerechnet || produkt.werte.haendlereinkaufswertAbzuege,
            haendlereinkaufswertAbzuegeNetto:
                hekAbzuegeNettoBerechnet || produkt.werte.haendlereinkaufswertAbzuegeNetto,
            haendlerverkaufswertAbzuege: hvkAbzuegeBruttoBerechnet || produkt.werte.haendlerverkaufswertAbzuege,
            haendlerverkaufswertAbzuegeNetto:
                hvkAbzuegeNettoBerechnet || produkt.werte.haendlerverkaufswertAbzuegeNetto,
            bezugsdatum: produkt.werte.bezugsdatum,
            roundValue: produkt.werte.roundValue,
            aufwendungen: aufwendungenBerechnet,
            aufwendungenDrucken:
                (produkt.werte.aufwendungenDrucken === null || produkt.werte.aufwendungenDrucken === undefined) &&
                aufwendungenBerechnet > 0
                    ? true
                    : produkt.werte.aufwendungenDrucken,
            aufwendungenDetailsDrucken: produkt.werte.aufwendungenDetailsDrucken,
            externalServicesReferenceId: produkt.werte.externalServicesReferenceId,
            haendlereinkaufswert: produkt.werte.haendlereinkaufswert,
            neuwert: produkt.werte.neuwert,
            neuwertDrucken: produkt.werte.neuwertDrucken,
            haendlereinkaufswertAbzuegeDrucken: produkt.werte.haendlereinkaufswertAbzuegeDrucken,
            haendlereinkaufswertAbzuegeNettoDrucken: produkt.werte.haendlereinkaufswertAbzuegeNettoDrucken,
            haendlereinkaufswertDrucken: produkt.werte.haendlereinkaufswertDrucken,
            haendlereinkaufswertNetto: produkt.werte.haendlereinkaufswertNetto,
            haendlereinkaufswertNettoDrucken: produkt.werte.haendlereinkaufswertNettoDrucken,
            relativerWert: produkt.werte.relativerWert,
            relativerWertDrucken: produkt.werte.relativerWertDrucken,
            haendlerverkaufswert: produkt.werte.haendlerverkaufswert,
            haendlerverkaufswertAbzuegeDrucken: produkt.werte.haendlerverkaufswertAbzuegeDrucken,
            haendlerverkaufswertAbzuegeNettoDrucken: produkt.werte.haendlerverkaufswertAbzuegeNettoDrucken,
            haendlerverkaufswertBesteuerung: produkt.werte.haendlerverkaufswertBesteuerung,
            haendlerverkaufswertDrucken: produkt.werte.haendlerverkaufswertDrucken,
            haendlerverkaufswertNetto: produkt.werte.haendlerverkaufswertNetto,
            haendlerverkaufswertNettoDrucken: produkt.werte.haendlerverkaufswertNettoDrucken,
            datHvkNettoDifferenz: produkt.werte.datHvkNettoDifferenz,
            manuelleWerteDrucken: produkt.werte.manuelleWerteDrucken,
            manuelleWerte: produkt.werte.manuelleWerte,
            datHvkNettoRegel: produkt.werte.datHvkNettoRegel,
            wertAmMarkt: produkt.werte.wertAmMarkt,
            wertAmMarktBemerkung: produkt.werte.wertAmMarktBemerkung,
            wertAmMarktBemerkungen: produkt.werte.wertAmMarktBemerkungen,
            restwertAusUnfallschaden: produkt.werte.restwertAusUnfallschaden,
            restwertAusUnfallschadenNetto: produkt.werte.restwertAusUnfallschadenNetto,
            werterhoehenderReparaturzustand: produkt.werte.werterhoehenderReparaturzustand,
            werterhoehenderWartungszustand: produkt.werte.werterhoehenderWartungszustand,
            werterhoehendesZubehoer: produkt.werte.werterhoehendesZubehoer,
            zweiterRadsatzAnteilig: produkt.werte.zweiterRadsatzAnteilig,
            id: produkt.id,
        };

        this.produktWerteService.save(produkt.id, werteInput);
    }

    private addFehlteile(fehlteile: Fehlteile): number {
        if (!fehlteile) {
            return 0;
        }
        return (fehlteile.positionen || []).reduce((x, y) => x + (y.preis || 0), 0);
    }

    private addWartung(wartung: Wartung): number {
        if (!wartung) {
            return 0;
        }

        return [
            wartung.naechsterServiceFaelligKosten,
            wartung.hauptAbgasUntersuchungFaelligKosten,
            wartung.zahnriemenFaelligKosten,
            (wartung.positionen || []).reduce((x, y) => x + (y.kosten || 0), 0),
        ].reduce((x, y) => x + (y || 0), 0);
    }

    private addMehrwerte(werte: Werte): number {
        if (!werte) {
            return 0;
        }

        return (
            (werte.werterhoehendesZubehoer || 0) +
            (werte.werterhoehenderWartungszustand || 0) +
            (werte.werterhoehenderReparaturzustand || 0) +
            (werte.zweiterRadsatzAnteilig || 0)
        );
    }

    private addSchaden(schaden: Schaden, produktArt: number): number {
        if (!schaden || !schaden.positionen || schaden.positionen.length === 0) {
            return 0;
        }
        const bestaetigtePositionen = schaden.positionen.filter((pos) => (pos.extern ? pos.bestaetigt : true));
        if (bestaetigtePositionen.length === 0) {
            return 0;
        }

        if (produktArt === ProduktArt.Ruecknahmebewertung || produktArt === ProduktArt.AlphaController) {
            return (bestaetigtePositionen || []).reduce((x, y) => x + (y.minderwert || 0), 0);
        }
        return (bestaetigtePositionen || []).reduce((x, y) => x + (y.preis || 0), 0);
    }

    private addVorschaden(vorschaden: Vorschaden): number {
        if (!vorschaden || !vorschaden.positionen || vorschaden.positionen.length === 0) {
            return 0;
        }
        const bestaetigtePositionen = vorschaden.positionen.filter((pos) => (pos.extern ? pos.bestaetigt : true));
        if (bestaetigtePositionen.length === 0) {
            return 0;
        }

        return (bestaetigtePositionen || []).reduce((x, y) => x + (y.wertminderung || 0), 0);
    }
}
