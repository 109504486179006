import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CsvDownloadService {
    private readonly restApiUrl: string;

    constructor(private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/datei/csv`;
    }

    get(startDate: Date, endDate: Date): Observable<HttpResponse<any>> {
        const params = {
            headers: {
                Accept: 'text/csv; charset=utf-8',
            },
            responseType: 'text' as 'json',
            observe: 'response' as 'body',
            params: {
                startDate: startDate.toString(),
                endDate: endDate.toString(),
            },
        };

        return this.httpClient.get<any>(this.restApiUrl, params);
    }
}
