import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Notiz, Unterlagen, UnterlagenInput } from '@data/domain/schema/type';
import { ProduktUnterlagenService } from '@data/domain/service/feature';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { UNTERLAGEN } from '@modules/produkt/config/produkt-unterlagen.config';
import { ProduktDetailUnterlagenFormViewFactory } from '@modules/produkt/factory/unterlagen/produkt-detail-unterlagen-form-view.factory';
import { ProduktDetailUnterlagenPositionFormViewFactory } from '@modules/produkt/factory/unterlagen/produkt-detail-unterlagen-position-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable, take } from 'rxjs';

interface ProduktDetailUnerlagenBilderDialogData {
    form: ViewFormGroup;
}

@Component({
    selector: 'app-produkt-detail-unterlagen',
    templateUrl: './produkt-detail-unterlagen.component.html',
    styleUrls: ['./produkt-detail-unterlagen.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailUnterlagenComponent
    extends ProduktDetailFeatureInputComponent<Unterlagen, UnterlagenInput>
    implements OnInit {
    private positionenName = PRODUKT_CONFIG_FEATURES.Unterlagen.fields.Positionen.name;

    trackByField = TrackBy.trackByField;

    notizen$: Observable<Notiz[]>;

    positionen: ViewFormArray;
    positionenFields: FeatureFields;
    positionenValues = UNTERLAGEN;

    schluesselFahrzeugAuswahl = [0, 1, 2, 3, 4, 5];
    schluesselAHKAuswahl = [0, 1, 2];
    fernbedienungStandheizungAuswahl = [0, 1, 2];

    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktUnterlagenService: ProduktUnterlagenService,
        private readonly formViewFactory: ProduktDetailUnterlagenFormViewFactory,
        private readonly positionFormViewFactory: ProduktDetailUnterlagenPositionFormViewFactory,
        private readonly templateDialogService: TemplateDialogService,
        private readonly snackBarService: SnackBarService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktUnterlagenService);
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Unterlagen.name;
        this.notizen$ = this.notizenService.init(this.produkt, name);
        this.init(name);
    }

    onPositionenAdd(bezeichnung: string): void {
        Assert.notNullOrEmpty(bezeichnung, 'bezeichnung');
        const position = this.positionFormViewFactory.create(
            {
                bezeichnung,
            },
            this.positionenFields,
        );
        this.positionen.push(position);
    }

    onPositionenRemove(index: number): void {
        Assert.notNullOrUndefined(index, 'index');
        this.positionen.removeAt(index);
    }

    onAddBilderClick(index: string): void {
        Assert.notNullOrUndefined(index, 'index');
        const form = this.positionen.controls[index];
        const title = form.get('bezeichnung').value;

        const buttons = [this.templateDialogService.getCloseButtonSetting()];
        const data: ProduktDetailUnerlagenBilderDialogData = { form };

        this.templateDialogService.openTemplate(title, buttons, this.dialogTemplate, data);
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    protected createForm(): ViewFormGroup {
        const form = this.formViewFactory.create(this.produkt.unterlagen, this.fields);
        this.positionen = form.get(this.positionenName) as ViewFormArray;
        this.positionenFields = (
            this.fields.find((x: FeatureFieldArray) => x.arrayName === this.positionenName) as FeatureFieldArray
        ).fields;
        return form;
    }
}
