<app-accordion #accordion>
    <app-expansion-panel *ngIf="form.get('obergruppe') as control">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.obergruppe' | translate"></span>
            </app-panel-title>
            <app-panel-value [value]="(control.valueChanges | async) || control.value || '-'" />
        </app-expansion-header>
        <ng-container
            *ngTemplateOutlet="list; context: { field: 'obergruppe', values: obergruppe, keys: obergruppen }"
        />
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('untergruppe') as control">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.untergruppe' | translate"></span>
            </app-panel-title>
            <app-panel-value [value]="(control.valueChanges | async) || control.value || '-'" />
        </app-expansion-header>
        <ng-container
            *ngTemplateOutlet="
                listUntergruppe;
                context: { field: 'untergruppe', values: untergruppe, keys: untergruppen }
            "
        />
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('zustand') as control" [expanded]="true">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.zustand' | translate"></span>
            </app-panel-title>
        </app-expansion-header>
        <app-textbausteine
            #zustand
            [form]="form"
            [name]="'zustand'"
            [displayName]="'Zustand Textbausteine'"
            [feature]="feature"
            [produktArt]="this.produktArt"
        />
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('beschaedigungart') as control" [expanded]="!form.get('zustand')">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.beschaedigungart' | translate"></span>
            </app-panel-title>
            <app-panel-value [value]="(control.valueChanges | async) || control.value || '-'" />
        </app-expansion-header>
        <ng-container *ngTemplateOutlet="list; context: { field: 'beschaedigungart', values: art, keys: arten }" />
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('reparaturweg') as control">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.reparaturweg' | translate"></span>
            </app-panel-title>
            <app-panel-value [value]="(control.valueChanges | async) || control.value || '-'" />
        </app-expansion-header>
        <ng-container
            *ngTemplateOutlet="list; context: { field: 'reparaturweg', values: reparatur, keys: reparaturen }"
        />
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('bilder') as control">
        <app-expansion-header>
            <app-panel-title>
                {{ name + '.bilder' | translate }}
            </app-panel-title>
            <app-panel-value [value]="((control.valueChanges | async) || control.value || []).length" [next]="false" />
        </app-expansion-header>
        <ng-container *ngIf="(control.valueChanges | async) || control.value as files">
            <label>{{ name + '.bilder' | translate }}</label>
            <app-file-gallery
                [fileIds]="files"
                [dialogTitle]="name + '.addfile'"
                [showCamera]="true"
                [showFolder]="true"
                [resetable]="isFileResetable.bind(this)"
                (add)="onFileAdd(control, $event)"
                (delete)="onFileDelete(control, $event)"
                (update)="onFileUpdate(control, $event)"
                (reset)="onFileReset(control, $event)"
            />
        </ng-container>
        <app-action-row>
            <app-button (action)="accordion.next()">
                {{ name + '.next' | translate }}
            </app-button>
        </app-action-row>
    </app-expansion-panel>

    <app-expansion-panel>
        <app-expansion-header>
            <app-panel-title>
                <div class="title">
                    {{ name + '.schaden' | translate }}
                    <div class="spacer"></div>
                    <div class="value">
                        <ng-container *ngFor="let field of fields; trackBy: trackByField; last as last">
                            <ng-container [ngSwitch]="field.name || field.groupName">
                                <!-- Intensität -->
                                <ng-container *ngSwitchCase="'intensitaet'">
                                    <ng-container *ngIf="form.get(field.name) as control">
                                        <ng-container
                                            *ngIf="(control.valueChanges | async) || control.value as value; else empty"
                                        >
                                            {{ name + '.' + intensitaet.values[value] | lowercase | translate }}
                                        </ng-container>
                                        <span *ngIf="!last">&nbsp;|&nbsp;</span>
                                    </ng-container>
                                </ng-container>
                                <!-- Preis -->
                                <ng-container *ngSwitchCase="'preis'">
                                    <ng-container *ngTemplateOutlet="value; context: { field: 'preis' }" />
                                    <span *ngIf="!last">&nbsp;|&nbsp;</span>
                                </ng-container>
                                <!-- Minderwert -->
                                <ng-container *ngSwitchCase="'minderwert'">
                                    <ng-container *ngTemplateOutlet="value; context: { field: 'minderwert' }" />
                                    <span *ngIf="!last">&nbsp;|&nbsp;</span>
                                </ng-container>
                                <!-- MinderwertGroup -->
                                <ng-container *ngSwitchCase="'minderwertGroup'">
                                    <ng-container *ngTemplateOutlet="value; context: { field: 'preis' }" />
                                    <span>&nbsp;|&nbsp;</span>
                                    <ng-container *ngTemplateOutlet="value; context: { field: 'minderwert' }" />
                                    <span *ngIf="!last">&nbsp;|&nbsp;</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </app-panel-title>
        </app-expansion-header>
        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <!-- Intensität -->
                <ng-container *ngSwitchCase="'intensitaet'">
                    <app-slider
                        [form]="form"
                        [name]="field.name"
                        [displayName]="name + '.' + field.name"
                        [values]="intensitaet"
                        [valuePrefix]="name + '.'"
                        [thumbLabel]="false"
                        [rangeLabel]="false"
                        min="1"
                        max="5"
                    />
                </ng-container>
                <!-- RelativerWert -->
                <ng-container *ngSwitchCase="'relativerWert'">
                    <ng-container *ngIf="hasRelativerWert">
                        <button (click)="onRelativerWertClicked()" mat-flat-button>
                            Relativer Wert:&nbsp;{{ relativerWert }}%
                        </button>
                    </ng-container>
                </ng-container>
                <!-- Preis -->
                <ng-container *ngSwitchCase="'preis'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
                <!-- Minderwert -->
                <ng-container *ngSwitchCase="'minderwert'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
                <!-- MinderwertGroup -->
                <ng-container *ngSwitchCase="'minderwertGroup'">
                    <ng-container *ngTemplateOutlet="minderwertGroup" />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-expansion-panel>
</app-accordion>

<div class="validation" *ngIf="getEmptyFieldsForValidation().length > 0">
    {{ 'schaden.position.validierung.text' | translate }}
    <ng-container *ngFor="let field of getEmptyFieldsForValidation()">
        <br />
        <span class="validation" [innerHTML]="name + '.' + field | translate"></span>
    </ng-container>
</div>

<!-- MinderwertGroup -->
<ng-template #minderwertGroup>
    <app-form-group cols="2">
        <!-- Preis -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    currency;
                    context: {
                        field: {
                            name: 'preis',
                        },
                    }
                "
            />
        </app-form-field>
        <!-- Minderwert -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    currency;
                    context: {
                        field: {
                            name: 'minderwert',
                        },
                    }
                "
            />
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- RelativerWertGroup -->
<ng-template #relativerWertGroup>
    <app-form-group cols="2">
        <!-- Preis -->
        <app-form-field />
        <!-- Minderwert -->
        <app-form-field />
    </app-form-group>
</ng-template>

<!-- Templates -->
<ng-template #currency let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
</ng-template>

<ng-template #percent let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;%</ng-container>
    </app-input>
</ng-template>

<ng-template #list let-field="field" let-values="values" let-keys="keys">
    <app-extendable-radio-list
        [form]="form"
        [name]="field"
        [displayName]="name + '.' + field"
        [values]="values"
        [keys]="keys"
        [columnCount]="(viewport$ | async) !== viewport.Mobile ? columnCountEnum.THREE : columnCountEnum.ONE"
    />
</ng-template>

<ng-template #listUntergruppe let-field="field" let-values="values" let-keys="keys">
    <app-extendable-radio-list
        [form]="form"
        [name]="field"
        [displayName]="name + '.' + field"
        [values]="values"
        [keys]="keys"
        [columnCount]="(viewport$ | async) !== viewport.Mobile ? columnCountEnum.TWO : columnCountEnum.ONE"
    />
</ng-template>

<ng-template #value let-field="field">
    <ng-container *ngIf="form.get(field) as control">
        <ng-container *ngIf="(control.valueChanges | async) || control.value as value; else empty">
            {{ control.getRawValue() | number: '0.2-2' }}&nbsp;€
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #empty>-</ng-template>
