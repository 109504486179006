import { Injectable } from '@angular/core';
import { SchadenIntensitaet } from '@data/domain/schema/enum';
import { Assert } from '@shared/helper/assert';

@Injectable({
    providedIn: 'root',
})
export class SchadenMinderwertService {
    getMinderwertIntensitaet(preis: number, intensitaet: SchadenIntensitaet): number {
        Assert.notNullOrUndefined(preis, 'preis');
        Assert.notNullOrUndefined(intensitaet, 'intensitaet');

        switch (intensitaet) {
            case SchadenIntensitaet.Gebrauchsspur: {
                return 0;
            }
            case SchadenIntensitaet.Leicht: {
                return Math.round(preis * 0.25);
            }
            case SchadenIntensitaet.Mittel: {
                return Math.round(preis * 0.5);
            }
            case SchadenIntensitaet.Schwer: {
                return Math.round(preis * 0.75);
            }
            case SchadenIntensitaet.Grob: {
                return Math.round(preis);
            }
        }
    }

    getMinderwertRelativerWert(preis: number, relativerWert: number): number {
        Assert.notNullOrUndefined(preis, 'preis');
        Assert.notNullOrUndefined(relativerWert, 'relativerWert');

        const minderWertAnteil = preis * (relativerWert / 100);
        let minderwertGerundet = 0;
        minderwertGerundet = Math.round(minderWertAnteil);

        if (minderwertGerundet > preis) {
            minderwertGerundet = preis;
        }
        return minderwertGerundet;
    }
}
