import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-drop-list',
    templateUrl: './drop-list.component.html',
    styleUrls: ['./drop-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropListComponent {
    viewport = Viewport;
    viewport$: Observable<Viewport>;

    @Input()
    disabled = false;

    @Output()
    drop = new EventEmitter<CdkDragDrop<string[]>>();

    constructor(private readonly viewportService: ViewportService) {
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        this.viewport$ = this.viewportService.observe();
    }
}
