import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AdresseAnrede } from '@data/domain/schema/enum';
import { Adresse } from '@data/domain/schema/type';
import { PRODUKT_ADRESSEN_ARTEN, ProduktAdresseArt } from '@modules/produkt/config/produkt-adressen-arten.config';
import {
    AbstractViewFormControl,
    CalculatedViewFormControl,
    ViewFormControl,
} from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { PRODUKT_CONFIG_FEATURES } from '../../config/produkt-config';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

function requiredIfValidator(predicate: (parent: UntypedFormGroup) => boolean): ValidatorFn {
    return (formControl) => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate(formControl.parent as UntypedFormGroup)) {
            return Validators.required(formControl);
        }
        return null;
    };
}

function artenContainsArt(arten: string[], art: ProduktAdresseArt): boolean {
    const stringArt = PRODUKT_ADRESSEN_ARTEN[art].toLowerCase();
    return (arten || []).map((x: string) => x.toLowerCase()).indexOf(stringArt) !== -1;
}

function requiredIfArtenContains(art: ProduktAdresseArt): ValidatorFn {
    return requiredIfValidator((form) => {
        const arten = (
            form.get(PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.fields.Arten.name) as AbstractViewFormControl
        ).getRawValue();
        return artenContainsArt(arten, art);
    });
}

function requiredIfAnrede(...anreden: AdresseAnrede[]): ValidatorFn {
    return requiredIfValidator((form) => {
        const anrede = form.get(PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.fields.Anrede.name).value;
        return anreden.includes(anrede);
    });
}

function requiredIfAnredeNot(...anreden: AdresseAnrede[]): ValidatorFn {
    return requiredIfValidator((form) => {
        const anrede = form.get(PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.fields.Anrede.name).value;
        return !anreden.includes(anrede);
    });
}

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailAdressenAdresseFormViewFactory extends FormViewModelBaseFactory<Adresse> {
    protected createField(model: Adresse, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.fields;
        switch (name) {
            case fields.Id.name:
                return new ViewFormControl(model.id);
            case fields.CreatedAt.name:
                return new ViewFormControl(model.createdAt);
            case fields.Firma.name:
                return new ViewFormControl(model.firma, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    validators: [requiredIfAnrede(AdresseAnrede.Firma)],
                });
            case fields.Anrede.name:
                return new ViewFormControl(model.anrede, {
                    validators: [
                        Validators.required,
                        (control) => {
                            if (control.parent) {
                                [
                                    fields.Vorname.name,
                                    fields.Name.name,
                                    fields.Firma.name,
                                    fields.AnredeFreitext.name,
                                ].forEach((childName) => {
                                    const child = control.parent.get(childName);
                                    if (child) {
                                        child.updateValueAndValidity();
                                        child.markAsTouched();
                                    }
                                });
                            }
                            return null;
                        },
                    ],
                });
            case fields.AnredeFreitext.name:
                return new ViewFormControl(model.anredeFreitext, {
                    validators: [requiredIfAnrede(AdresseAnrede.Freitext)],
                });
            case fields.Vorname.name:
                return new ViewFormControl(model.vorname, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    validators: [requiredIfAnredeNot(AdresseAnrede.Firma)],
                });
            case fields.Name.name:
                return new ViewFormControl(model.name, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    validators: [requiredIfAnredeNot(AdresseAnrede.Firma)],
                });
            case fields.StrasseNr.name:
                return new ViewFormControl(model.strasseNr, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    validators: [requiredIfArtenContains(ProduktAdresseArt.Auftraggeber)],
                });
            case fields.Postleitzahl.name:
                return new ViewFormControl(model.postleitzahl, {
                    validators: [requiredIfArtenContains(ProduktAdresseArt.Auftraggeber)],
                });
            case fields.Ort.name:
                return new ViewFormControl(model.ort, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                    validators: [requiredIfArtenContains(ProduktAdresseArt.Auftraggeber)],
                });
            case fields.Telefon.name:
                return new ViewFormControl(model.telefon);
            case fields.Mobil.name:
                return new ViewFormControl(model.mobil);
            case fields.Email.name:
                return new ViewFormControl(model.email, {
                    formatter: ViewFormControlFormatters.toLowercase,
                });
            case fields.Arten.name:
                return new ViewFormControl(model.arten || [], {
                    validators: [
                        (control) => {
                            if (control.parent) {
                                [
                                    fields.Anrede.name,
                                    fields.Email.name,
                                    fields.StrasseNr.name,
                                    fields.Postleitzahl.name,
                                    fields.Ort.name,
                                ].forEach((childName) => {
                                    const child = control.parent.get(childName);
                                    if (child) {
                                        child.updateValueAndValidity();
                                        child.markAsTouched();
                                    }
                                });
                            }
                            return null;
                        },
                    ],
                });
            case fields.ExternalId.name:
                return new ViewFormControl(model.externalId, {
                    formatter: ViewFormControlFormatters.toLowercase,
                });
            case fields.Bestellnummer.name:
                return new ViewFormControl(model.bestellnummer, {
                    formatter: ViewFormControlFormatters.toLowercase,
                });
            case fields.NameGroup.name:
                return new CalculatedViewFormControl({
                    calculateValue: (_parent: UntypedFormGroup, form: Adresse) =>
                        `${form.firma || '-'} ${form.vorname || '-'} ${form.name || '-'}`,
                    continuous: true,
                });
            case fields.DruckArt.name:
                return new CalculatedViewFormControl({
                    calculateValue: (_parent: UntypedFormGroup, form: Adresse) =>
                        artenContainsArt(form.arten, ProduktAdresseArt.Auftraggeber) ? 'Original' : 'Kopie',
                    continuous: true,
                });
            case fields.Versand.name:
                return new CalculatedViewFormControl({
                    calculateValue: (parent: UntypedFormGroup) => !parent.invalid,
                    continuous: false,
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
