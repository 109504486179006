<app-produkt-detail-toolbar />
<app-produkt-detail-nav #nav [tabPanel]="tabPanel" />
<div class="produkt-detail-content-wrapper">
    <app-produkt-detail-content
        [class.footer-active]="(this.viewport$ | async) !== viewport.Desktop"
        (prev)="nav.prev()"
        (next)="nav.next()"
    >
        <mat-tab-nav-panel #tabPanel>
            <router-outlet />
        </mat-tab-nav-panel>
    </app-produkt-detail-content>
</div>
