import { Injectable } from '@angular/core';
import { FileService } from '@app/service/file.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailAbschlussHelperService {
    private readonly INITIAL_TIME_FOR_AN_IMAGE_TO_PROCESSED = 2000;
    private readonly TIME_FOR_AN_IMAGE_TO_PROCESSED = 600;

    constructor(
        private readonly fileService: FileService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(fileService, 'fileService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }
    onSyncClicked(loading$: BehaviorSubject<boolean>) {
        this.fileService
            .sync()
            .pipe(take(1))
            .subscribe((success) => {
                if (success) {
                    loading$.next(true);
                    this.fileService
                        .syncCount()
                        .pipe(take(1))
                        .subscribe((count) => {
                            setTimeout(
                                () => {
                                    this.snackBarService.success('fileService.sync.success');
                                    loading$.next(false);
                                },
                                this.INITIAL_TIME_FOR_AN_IMAGE_TO_PROCESSED +
                                    this.TIME_FOR_AN_IMAGE_TO_PROCESSED * count,
                            );
                        });
                } else {
                    this.snackBarService.error('fileService.sync.error');
                }
            });
    }
}
