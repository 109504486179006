import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AC_PROFILTIEFE_VALUES } from '@data/domain/schema/const';
import {
    ProduktArt,
    RadBauart,
    RadFelge,
    RadGeschwindigkeitsindex,
    RadReifenTyp,
    RadZulaessig,
} from '@data/domain/schema/enum';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ArrayStringKeysNumberValues, EnumValues } from '@shared/helper/values';

@Component({
    selector: 'app-produkt-detail-raeder-rad',
    templateUrl: './produkt-detail-raeder-rad.component.html',
    styleUrls: ['./produkt-detail-raeder-rad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailRaederRadComponent {
    trackByField = TrackBy.trackByField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    produktArt: ProduktArt;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    reifentyp = new EnumValues(RadReifenTyp);
    felge = new EnumValues(RadFelge);
    bauart = new EnumValues(RadBauart);
    geschwindigkeitsindex = new EnumValues(RadGeschwindigkeitsindex);
    zulaessig = new EnumValues(RadZulaessig);
    acProfiltiefeSelectValues = new ArrayStringKeysNumberValues(AC_PROFILTIEFE_VALUES);
    protected readonly ProduktArt = ProduktArt;

    constructor() {
        this.sortGeschwindigkeitsIndexFromHighToLow();
    }

    isAlphacontrollerProdukt(): boolean {
        return this.produktArt === ProduktArt.AlphaController;
    }

    private sortGeschwindigkeitsIndexFromHighToLow() {
        this.geschwindigkeitsindex?.keys?.sort((one, two) => (one > two ? -1 : 1));
    }
}
