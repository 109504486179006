import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-textbausteine-dialog',
    templateUrl: './textbausteine-dialog.component.html',
    styleUrls: ['./textbausteine-dialog.component.scss'],
})
export class TextbausteineDialogComponent implements OnInit {
    @Input()
    feature = '';

    @Input()
    feld = '';

    @Input()
    feldDisplayName = '';

    @Input()
    form: UntypedFormGroup;

    @Input()
    textbausteine$ = new BehaviorSubject<Textbaustein[]>([]);

    @Input()
    produktArt: ProduktArt = undefined;

    @Output()
    textbausteinEventEmitter = new EventEmitter<Textbaustein>();

    constructor(private readonly textbausteineService: TextbausteineService) {}

    ngOnInit(): void {
        this.getTextbausteine(this.feature, this.feld, this.produktArt);
    }

    getTextbausteine(feature, feld, produktArt): Textbaustein[] {
        this.textbausteineService
            .getTextbausteine(feature, feld)
            .pipe(take(1))
            .subscribe({
                next: (res) => {
                    const sortedList = this.textbausteineService
                        .sortByErstelltAmAscending(res)
                        .filter((value) => (this.produktArt ? value.produkte.includes(produktArt) : true));
                    this.textbausteine$.next(sortedList);
                    return sortedList;
                },
                error: (error) => {
                    console.error(error);
                },
            });
        return [];
    }
}
