import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { GridTile } from '@shared/component';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, first } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    tiles: GridTile[] = [
        {
            icon: 'product_gtue',
            link: '/auftraege',
            id: 'id-auftraege',
            title: 'Aufträge',
            disabled: true,
        },
        {
            icon: 'product_add',
            link: '/produkt/erstellen',
            id: 'id-neues-produkt',
            title: 'Neues Produkt',
        },
        {
            icon: 'product_my',
            link: '/produkt/uebersicht/gtue-em',
            id: 'id-meine-produkte',
            title: 'Meine Produkte',
        },
        {
            icon: 'alphacontroller',
            link: '/produkt/uebersicht-ac',
            id: 'id-alphacontroller',
            title: 'AlphaController',
            disabled: true,
        },
        {
            icon: 'settings',
            link: '/verwaltung',
            id: 'id-verwaltung',
            title: 'Einstellungen',
        },
    ];
    tiles$ = new BehaviorSubject<GridTile[]>([]);

    constructor(private auth: AuthService) {
        Assert.notNullOrUndefined(auth, 'authService');
    }

    ngOnInit(): void {
        this.auth
            .getClaims()
            .pipe(first())
            .subscribe({
                next: (claims) => {
                    if (claims) {
                        const acTile = this.tiles.find((tile) => tile.id === 'id-alphacontroller');
                        if (acTile && claims.istAc) {
                            acTile.disabled = false;
                        }
                    } else {
                        console.error('User claims is undefined.');
                    }
                    this.tiles$.next(this.tiles);
                },
                error: (err) => {
                    console.error(`Unable to get user claims: ${err}. Showing default tiles.`);
                    this.tiles$.next(this.tiles);
                },
            });
    }
}
