import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
} from '@angular/core';
import { TrackBy } from '@shared/helper/track-by';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
    selector: 'app-form-group',
    templateUrl: './form-group.component.html',
    styleUrls: ['./form-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent {
    trackByInstance = TrackBy.trackByInstance;

    readonly rowHeight = '5.5rem';
    readonly gutterSize = '0.5rem';

    @Input()
    cols = 1;

    @Input()
    hasDeleteButton = false;

    @Output()
    delete = new EventEmitter();

    @ContentChildren(FormFieldComponent)
    fields: QueryList<FormFieldComponent>;
}
