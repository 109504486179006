import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Produkt } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { FeatureFields, ProduktFeatureFieldsMap } from '../config/produkt-config';
import { ProduktDetailFeatureFormViewProvider } from '../provider/produkt-detail-feature-form-view.provider';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFeatureValidService {
    constructor(private readonly produktDetailFeatureFormViewProvider: ProduktDetailFeatureFormViewProvider) {
        Assert.notNullOrUndefined(produktDetailFeatureFormViewProvider, 'produktDetailFeatureFormViewProvider');
    }

    isValid(produkt: Produkt, config: ProduktFeatureFieldsMap): boolean {
        return Object.keys(config).every((featureName) =>
            this.isFeatureValid(produkt, featureName, config[featureName]),
        );
    }

    isFeatureValid(produkt: Produkt, featureName: string, fields: FeatureFields): boolean {
        const { valid } = this.produktDetailFeatureFormViewProvider.provide(produkt, featureName, fields);
        return valid;
    }

    getFeatureIncorrectFields(produkt: Produkt, featureName: string, fields: FeatureFields): string[] {
        const form = this.produktDetailFeatureFormViewProvider.provide(produkt, featureName, fields);

        const result = [];
        const checkControls = (controls: { [name: string]: AbstractControl }) => {
            Object.getOwnPropertyNames(controls).forEach((name) => {
                if (name === 'length') {
                    return;
                }

                const control = controls[name];
                if (control.updateValueAndValidity) {
                    control.updateValueAndValidity();
                }

                if (!control.valid) {
                    result.push(name);
                } else {
                    const array = control as UntypedFormGroup;
                    if (array.controls) {
                        checkControls(array.controls);
                    }
                }
            });
        };
        checkControls(form.controls || {});
        return result;
    }
}
