import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveFehlteile, SaveFehlteileData } from '../../graphql/mutations';
import { Fehlteile, FehlteileInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktFehlteileService extends ProduktFeatureService<Fehlteile, FehlteileInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Fehlteile): FehlteileInput {
        const input: FehlteileInput = {
            id: produktId,
            positionen: (feature.positionen || []).map((position, index) => ({
                ...position,
                id: `${produktId}:${index}`,
            })),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveFehlteile;
    }

    protected getSaveVariables(input: FehlteileInput): any {
        return {
            fehlteile: input,
        };
    }

    protected getSaveOptimisticResponse(input: FehlteileInput): SaveFehlteileData {
        const data: SaveFehlteileData = {
            saveFehlteile: {
                ...input,
                __typename: 'Fehlteile',
                positionen: (input.positionen || []).map((position) => ({
                    ...position,
                    __typename: 'FehlteilePosition',
                })),
            },
        };
        return data;
    }

    protected getSaveResponse(response: GraphQLResponse<SaveFehlteileData>): Fehlteile {
        return response.data.saveFehlteile;
    }

    protected update(produkt: Produkt, feature: Fehlteile): void {
        produkt.fehlteile.id = feature.id;
        produkt.fehlteile.positionen = feature.positionen;
    }
}
