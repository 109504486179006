import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { ObjectViewControl, ObjectViewHorizontal, ObjectViewVertical } from '@shared/helper/three/object-view-control';
import { Object3D, PerspectiveCamera } from 'three';

@Component({
    selector: 'app-object-view-control',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectViewControlComponent implements OnDestroy {
    private control = new ObjectViewControl();
    private animating = false;

    @Input()
    horizontal = ObjectViewHorizontal.Left;

    @Input()
    vertical = ObjectViewVertical.Top;

    @Input()
    set disabled(disabled: boolean) {
        this.control.enable = !disabled;
    }

    @Input()
    set target(target: Object3D) {
        if (target) {
            this.control.target(target);
            this.control.focus(this.horizontal, this.vertical);
            this.control.performInversion();
            this.requestRendering(true);
        }
    }

    @Output()
    userTap = this.control.userTap;

    @Output()
    animatingChange = new EventEmitter<boolean>();

    ngOnDestroy(): void {
        this.control.dispose();
    }

    init(camera: PerspectiveCamera, element: HTMLElement, requestRenderingCallback: Function): void {
        Assert.notNullOrUndefined(camera, 'camera');
        Assert.notNullOrUndefined(element, 'element');
        this.control.init(camera, element, requestRenderingCallback);
    }

    animate(): void {
        const animating = this.control.animate();
        if (this.animating !== animating) {
            this.animating = animating;
            this.animatingChange.next(this.animating);
        }
    }

    update(): void {
        this.control.updateCamera();
    }

    invert(zoom: boolean): void {
        this.control.invert = zoom;
        this.control.performInversion();
    }

    setInvert(zoom: boolean): void {
        this.control.invert = zoom;
    }

    lockAxis(x: boolean, y: boolean): void {
        this.control.lockAxis.set(x ? 1 : 0, y ? 1 : 0);
    }

    requestRendering(force: boolean) {
        this.control.requestRendering(force);
    }
}
