import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Breakpoint } from '@shared/service/viewport.service';
import { Clock, Object3D, PerspectiveCamera, Scene, WebGLRenderer, sRGBEncoding } from 'three';
import { GltfComponent } from '../gltf/gltf.component';
import { ObjectViewControlComponent } from '../object-view-control/object-view-control.component';

@Component({
    selector: 'app-scene',
    template: '<div class="scene" #container>' + '<ng-content></ng-content>' + '</div>',
    styleUrls: ['./scene.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SceneComponent implements AfterViewInit, OnDestroy {
    private animationLoopRunning = false;
    private scene: Scene;
    private camera: PerspectiveCamera;
    private renderer: WebGLRenderer;

    private timeOfLastRenderingRequest: number;
    private clock: Clock;
    private readonly desiredFps: number = 30;
    private readonly renderingIntervalInMs: number = 1000 / this.desiredFps;
    private readonly renderingTimeOffsetInMs: number = 200;

    @ViewChild('container', { static: true })
    container: ElementRef<HTMLElement>;

    @ContentChild(GltfComponent, { static: true })
    gltf: GltfComponent;

    @ContentChild(ObjectViewControlComponent, { static: true })
    objectViewControl: ObjectViewControlComponent;

    @HostListener('window:resize')
    onResize(): void {
        this.update();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.init(), 1);
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    // STARTUP & SHUTDOWN

    private init(): void {
        this.create();
        if (this.objectViewControl) {
            this.objectViewControl.init(this.camera, this.container.nativeElement, this.requestRendering.bind(this));
        }
        if (this.gltf) {
            this.gltf.init(this.camera, this.renderer, this.scene);
        }
        this.requestRendering(false);
        this.startAnimationLoop();
    }

    private destroy(): void {
        this.stopAnimationLoop();
        this.cleanup();
    }

    // CREATE

    private create(): void {
        this.createScene();
        this.createCamera();
        this.createRenderer();
    }

    private createScene(): void {
        this.scene = new Scene();
    }

    private createCamera(): void {
        this.camera = new PerspectiveCamera(45, this.getAspectRatio(), 0.1, 1500);
        this.camera.position.set(0, 2500, 0);
    }

    private createRenderer(): void {
        const { clientWidth, clientHeight } = this.getClientSize();

        this.renderer = new WebGLRenderer({
            antialias: true,
        });
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.shadowMap.enabled = true;
        this.renderer.setClearColor(0xffffff, 1);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(clientWidth, clientHeight);

        const container = this.container.nativeElement;
        container.appendChild(this.renderer.domElement);
    }

    // ANIMATION

    private requestRendering(force: boolean): void {
        if (force) {
            this.render();
        }
        this.timeOfLastRenderingRequest = this.elapsedTimeInMs();
    }

    private startAnimationLoop(): void {
        this.animationLoopRunning = true;
        this.animate(true);
    }

    private stopAnimationLoop(): void {
        this.animationLoopRunning = false;
    }

    private render(): void {
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
            if (this.gltf) {
                this.gltf.animate();
            }
            if (this.objectViewControl) {
                this.objectViewControl.animate();
            }
        }
    }

    private animate(force: boolean): void {
        const renderingStartTimeInMs = this.elapsedTimeInMs();
        if (this.animationLoopRunning) {
            if (force || renderingStartTimeInMs - this.timeOfLastRenderingRequest <= this.renderingTimeOffsetInMs) {
                this.render();
            }
            const renderingEndTimeInMs = this.elapsedTimeInMs();
            setTimeout(
                () => {
                    this.animate(false);
                },
                Math.max(1, Math.round(this.renderingIntervalInMs - (renderingEndTimeInMs - renderingStartTimeInMs))),
            );
        }
    }

    // UPDATE

    private update(): void {
        if (this.container) {
            if (this.camera) {
                this.updateCamera();
            }
            if (this.renderer) {
                this.updateRenderer();
            }
            if (this.objectViewControl) {
                this.objectViewControl.update();
            }
        }
    }

    private updateCamera(): void {
        this.camera.aspect = this.getAspectRatio();
        this.camera.updateProjectionMatrix();
    }

    private updateRenderer(): void {
        const { clientWidth, clientHeight } = this.getClientSize();
        this.renderer.setSize(clientWidth, clientHeight);
    }

    // CLEANUP

    private cleanup(): void {
        if (this.scene) {
            this.disposeObj(this.scene);
        }

        if (this.renderer) {
            this.renderer.forceContextLoss();
            this.renderer.renderLists.dispose();
            this.renderer.dispose();
        }

        delete this.renderer;
        delete this.camera;
        delete this.scene;
    }

    private disposeObj(obj: Object3D): void {
        obj.traverse((child: any) => {
            [child.material, child.orgMaterial, child.darkMaterial].forEach((material) => {
                if (material) {
                    material.dispose();
                    if (material.map) {
                        material.map?.dispose();
                    }
                    if (material.lightMap) {
                        material.lightMap.dispose();
                    }
                    if (material.aoMap) {
                        material.aoMap.dispose();
                    }
                    if (material.emissiveMap) {
                        material.emissiveMap.dispose();
                    }
                    if (material.bumpMap) {
                        material.bumpMap.dispose();
                    }
                    if (material.normalMap) {
                        material.normalMap.dispose();
                    }
                    if (material.displacementMap) {
                        material.displacementMap.dispose();
                    }
                    if (material.roughnessMap) {
                        material.roughnessMap.dispose();
                    }
                    if (material.metalnessMap) {
                        material.metalnessMap.dispose();
                    }
                    if (material.alphaMap) {
                        material.alphaMap.dispose();
                    }
                }
            });

            if (child.geometry) {
                child.geometry.dispose();
                if (child.geometry.attributes) {
                    child.geometry.attributes.color = {};
                    child.geometry.attributes.normal = {};
                    child.geometry.attributes.position = {};
                    child.geometry.attributes.uv = {};
                    child.geometry.attributes = {};
                }
            }
            child.material = {};
        });
    }

    // HELPER

    private elapsedTimeInMs(): number {
        if (!this.clock) {
            this.clock = new Clock();
            this.clock.start();
        }
        return this.clock.getElapsedTime() * 1000;
    }

    private getAspectRatio(): number {
        const { clientWidth, clientHeight } = this.getClientSize();
        return clientWidth / clientHeight;
    }

    private getClientSize(): {
        clientWidth: number;
        clientHeight: number;
    } {
        const container = this.container.nativeElement;
        const factor = this.isDesktopView() ? 1 / 2 : 1;
        const clientWidth = window.innerWidth * factor;
        const clientHeight = container.clientHeight;

        return { clientWidth, clientHeight };
    }

    isDesktopView(): boolean {
        return window.innerWidth > Breakpoint.Desktop;
    }
}
