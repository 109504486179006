<div class="user-menu-wrapper" *ngIf="userClaims$ | async as userClaims; else noUserClaimsLoaded">
    <div class="content-wrapper" *ngIf="!loggingOut; else spinner">
        <h3>{{ userClaims.given_name }} {{ userClaims.name }}</h3>
        <p>{{ userClaims.email }}</p>
        <p>{{ userClaims.bueroId }}-{{ userClaims.userId }}</p>

        <app-divider />
        <app-menu-button (action)="onLogoutClick()">
            <app-mat-icon icon="logout" />
            {{ 'usermenu.logout' | translate }}
        </app-menu-button>
    </div>
</div>

<ng-template #noUserClaimsLoaded>
    <div class="content-wrapper" *ngIf="!loggingOut; else spinner">
        <p>{{ 'usermenu.noUserClaims' | translate }}</p>
        <app-menu-button (action)="onLoginClick()">
            <app-mat-icon icon="exit_to_app" />
            {{ 'usermenu.login' | translate }}
        </app-menu-button>
    </div>
</ng-template>

<ng-template #spinner>
    <app-progress-spinner class="loading-spinner" [diameter]="150" />
</ng-template>
