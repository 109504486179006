import { FahrzeugArt, FahrzeugBauform } from '@data/domain/schema/enum';

export interface ModelFileConfig {
    file: string;
    yOffset: number;
    isFallback?: boolean;
}

const fallbackVehicle = {
    [FahrzeugBauform.Cabriolet]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Coupe]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Kleinwagen]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Kombi]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Limousine]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Multivan]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.SUV]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Sonstige]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
    [FahrzeugBauform.Manuell]: {
        file: 'generic-car',
        yOffset: -90.5,
        isFallback: true,
    },
};

export const MODEL_FILE_POSITION: {
    [art: number]: {
        [bauform: number]: ModelFileConfig;
    };
} = {
    [FahrzeugArt.Manuell]: {
        [FahrzeugBauform.Cabriolet]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Coupe]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Kleinwagen]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Kombi]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Limousine]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Multivan]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.SUV]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Sonstige]: {
            file: 'generic-car',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Manuell]: {
            file: 'multivan',
            yOffset: -90.5,
        },
    },
    [FahrzeugArt.Pkw]: {
        [FahrzeugBauform.Cabriolet]: {
            file: 'cabriolet',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Coupe]: {
            file: 'coupe',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Kleinwagen]: {
            file: 'kleinwagen',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Kombi]: {
            file: 'generic-car',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Limousine]: {
            file: 'limousine',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Multivan]: {
            file: 'multivan',
            yOffset: -90.5,
        },
        [FahrzeugBauform.SUV]: {
            file: 'suv',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Sonstige]: {
            file: 'generic-car',
            yOffset: -90.5,
        },
        [FahrzeugBauform.Manuell]: {
            file: 'multivan',
            yOffset: -90.5,
        },
    },
    [FahrzeugArt.Transporter]: fallbackVehicle,
    [FahrzeugArt.Lkw]: fallbackVehicle,
    [FahrzeugArt.Kraftrad]: fallbackVehicle,
    [FahrzeugArt.Omnibus]: fallbackVehicle,
};
