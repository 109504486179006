import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { OldtimerGraphqlService } from '@modules/oldtimer/graphql/oldtimer-graphql.service';
import { OldtimerRoutes } from '@modules/oldtimer/oldtimer.routes';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const createOldtimerResolver: () => Observable<Promise<boolean>> = () => {
    const oldtimerGraphqlService = inject(OldtimerGraphqlService);
    const router = inject(Router);
    return oldtimerGraphqlService.createOldtimerProdukt({}).pipe(
        catchError((_error) => {
            return of(undefined);
        }),
        map((response) => {
            if (!response) {
                return router.navigateByUrl('/dashboard');
            }

            return router.navigate([OldtimerRoutes.Oldtimer, response.id]);
        }),
    );
};
