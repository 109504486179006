import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FileData } from '@app/class/file-data';
import { TrackBy } from '@shared/helper/track-by';
import { AudioRecorderService } from '@shared/service/audio-recorder.service';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-audio-gallery',
    templateUrl: './audio-gallery.component.html',
    styleUrls: ['./audio-gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioGalleryComponent implements OnDestroy {
    private timerSubscription: Subscription = null;

    trackByKey = TrackBy.trackByKey;

    timer$ = new BehaviorSubject<number>(-1);

    @Input()
    files: string[];

    @Input()
    title: string;

    @Output()
    add = new EventEmitter<FileData<ArrayBuffer>>();

    @Output()
    delete = new EventEmitter<string>();

    @Output()
    error = new EventEmitter<void>();

    constructor(private readonly audioRecorderService: AudioRecorderService) {}

    ngOnDestroy(): void {
        this.audioRecorderService.stop().subscribe().unsubscribe();
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    onAction(input: MatInput): void {
        if (!this.timerSubscription) {
            this.audioRecorderService.start().subscribe({
                next: () => this.onRecordStart(),
                error: (error) => this.onError(error),
            });
        } else {
            this.timer$.next(-1);
            this.timerSubscription.unsubscribe();
            const name = input.value;
            input.value = '';
            this.audioRecorderService.stop().subscribe({
                next: (data) => this.onRecordStop(data, name),
                error: (error) => this.onError(error),
            });
        }
    }

    private onRecordStart(): void {
        const timer = interval(1000).pipe(tap(() => this.timer$.next(this.timer$.value + 1)));
        this.timer$.next(0);
        this.timerSubscription = timer.subscribe();
    }

    private onRecordStop(data: FileData<ArrayBuffer>, name: string): void {
        if (data) {
            data.name = name;
            this.add.emit(data);
        }
        this.timerSubscription = null;
    }

    private onError(error: Error): void {
        console.warn('An unexpected error occured while recording.', error);
        this.error.next();
    }
}
