<label>{{ name + '.bilder' | translate }}</label>
<ng-container *ngIf="form.get('bilder') as control">
    <ng-container *ngIf="(control.valueChanges | async) || control.value as files">
        <app-file-gallery
            [fileIds]="files"
            [dialogTitle]="name + '.addfile'"
            [showCamera]="true"
            [showFolder]="true"
            [resetable]="isFileResetable.bind(this)"
            (add)="onFileAdd(control, $event)"
            (delete)="onFileDelete(control, $event)"
            (update)="onFileUpdate(control, $event)"
            (reset)="onFileReset(control, $event)"
        />
    </ng-container>
</ng-container>
