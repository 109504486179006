import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Assert } from '@shared/helper/assert';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { BehaviorSubject } from 'rxjs';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-extendable-list',
    templateUrl: './extendable-list.component.html',
    styleUrls: ['./extendable-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendableListComponent extends FormControlBase implements OnInit {
    filteredValues$ = new BehaviorSubject<string[]>([]);

    @ViewChild(MatInput, { static: true, read: ElementRef })
    input: ElementRef<HTMLInputElement>;

    @Input()
    values = [];

    @Input()
    inputmode = 'text';

    @Input()
    property = '';

    @Input()
    searchable = false;

    @Input()
    hasImages = false;

    @Output()
    add = new EventEmitter<string>();

    @Output()
    remove = new EventEmitter<number>();

    @Output()
    addImage = new EventEmitter<number>();

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (!(event.key === 'Enter' || event.keyCode === 13)) {
            return;
        }
        if (!event.shiftKey) {
            this.next();
        } else {
            this.prev();
        }
    }

    constructor(formControlFocusService: FormControlFocusService) {
        super(formControlFocusService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filter();
    }

    onAddClick(): void {
        const value = this.input.nativeElement.value;
        if (value.length <= 0) {
            return;
        }
        this.input.nativeElement.value = '';
        this.addValue(value);
    }

    onRemoveClick(index: number): void {
        Assert.notNullOrUndefined(index, 'index');
        this.removeValue(index);
    }

    onItemClick(value: string): void {
        Assert.notNullOrEmpty(value, 'value');
        this.addValue(value);
    }

    onFilterKeyUp(): void {
        this.filter();
    }

    onAddImagesClick(index: number) {
        this.addImage.emit(index);
    }

    blur(): void {
        this.input.nativeElement.blur();
    }

    focus(): void {
        this.input.nativeElement.focus();
    }

    private addValue(value: string): void {
        this.add.next(value);
        this.filter();
    }

    private removeValue(index: number): void {
        this.remove.next(index);
        this.filter();
    }

    private filter(): void {
        if (this.inputmode === 'text') {
            const filterValue = (this.input.nativeElement.value || '').toLocaleLowerCase();
            const values = this.control.value as any[];
            this.filteredValues$.next(
                this.values.filter(
                    (value) =>
                        value.toLowerCase().indexOf(filterValue) !== -1 &&
                        !values.find((x) => x[this.property] === value),
                ),
            );
        }
    }
}
