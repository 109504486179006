import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveSchaden, SaveSchadenData } from '../../graphql/mutations';
import { Produkt, Schaden, SchadenInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktSchadenService extends ProduktFeatureService<Schaden, SchadenInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Schaden): SchadenInput {
        const input: SchadenInput = {
            id: produktId,
            positionen: (feature.positionen || []).map((position, index) => ({
                ...position,
                id: `${produktId}:${index}`,
            })),
            beschreibungen: (feature.beschreibungen || []).map((beschreibungen, _beschreibungenIndex) => ({
                ...beschreibungen,
            })),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveSchaden;
    }

    protected getSaveVariables(input: SchadenInput): any {
        return {
            schaden: input,
        };
    }

    protected getSaveOptimisticResponse(input: SchadenInput): any {
        return {
            saveSchaden: {
                ...input,
                __typename: 'Schaden',
                positionen: (input.positionen || []).map((position) => ({
                    ...position,
                    __typename: 'Position',
                })),
                beschreibungen: (input.beschreibungen || []).map((beschreibungen) => ({
                    ...beschreibungen,
                    __typename: 'Textbaustein',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveSchadenData>): Schaden {
        return response.data.saveSchaden;
    }

    protected update(produkt: Produkt, feature: Schaden): void {
        produkt.schaden.id = feature.id;
        produkt.schaden.positionen = feature.positionen;
        produkt.schaden.beschreibungen = feature.beschreibungen;
    }
}
