import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, timeout } from 'rxjs/operators';
import { ProduktFactory } from '../factory/produkt.factory';
import {
    createTextbaustein,
    CreateTextbausteintData,
    deleteTextbaustein,
    GraphQLResponse,
    saveTextbaustein,
    SaveTextbausteintData,
} from '../graphql/mutations';
import { getTextbausteine, GetTextbausteineData } from '../graphql/queries';
import { ProduktArt } from '../schema/enum';
import { Textbaustein, TextbausteinInput } from '../schema/type';

const GET_NETWORK_TIMEOUT = 1000 * 5;

@Injectable({
    providedIn: 'root',
})
export class TextbausteineService {
    constructor(
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
        private readonly produktFactory: ProduktFactory,
    ) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
        Assert.notNullOrUndefined(produktFactory, 'produktFactory');
    }

    getTextbausteine(feature: string, feld: string): Observable<Textbaustein[]> {
        return this.query<GetTextbausteineData, Textbaustein[]>(
            {
                query: getTextbausteine,
                variables: { feature, feld },
            },
            (response) => response.getTextbausteine,
            GET_NETWORK_TIMEOUT,
        );
    }

    sortByErstelltAmAscending(textbausteine: Textbaustein[]): Textbaustein[] {
        if (textbausteine && textbausteine.length > 0) {
            return textbausteine.sort((a, b) => new Date(b.erstelltAm).getTime() - new Date(a.erstelltAm).getTime());
        }
        return textbausteine;
    }

    createTextbaustein(textbaustein: TextbausteinInput): Observable<any> {
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate<CreateTextbausteintData>({
            mutation: createTextbaustein,
            variables: {
                textbaustein: {
                    ...textbaustein,
                },
            },
            optimisticResponse: {
                createTextbaustein: {
                    ...textbaustein,
                    __typename: 'Textbaustein',
                },
            },
            // },
            // update: (store) => {
            //   this.updateGetCache(store, produkte => {
            //     produkte.push(produkt);
            //     return produkte;
            //   });
            //   this.updateGetByIdCache(store, id, () => produkt, false);
            // }
        });
        return from(mutatePromise).pipe(
            map((response: GraphQLResponse<CreateTextbausteintData>) => response.data.createTextbaustein),
        );
    }

    saveTextbaustein(textbaustein: TextbausteinInput): Observable<Textbaustein> {
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate<SaveTextbausteintData>({
            mutation: saveTextbaustein,
            variables: {
                textbaustein: {
                    ...textbaustein,
                },
            },
            optimisticResponse: {
                saveTextbaustein: {
                    ...textbaustein,
                    __typename: 'Textbaustein',
                },
            },
            // },
            // update: (store) => {
            //   this.updateGetCache(store, produkte => {
            //     produkte.push(produkt);
            //     return produkte;
            //   });
            //   this.updateGetByIdCache(store, id, () => produkt, false);
            // }
        });
        return from(mutatePromise).pipe(
            map((response: GraphQLResponse<SaveTextbausteintData>) => response.data.saveTextbaustein),
        );
    }

    deleteTextbaustein(id: string): Observable<boolean> {
        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate({
            mutation: deleteTextbaustein,
            variables: {
                id,
            },
            optimisticResponse: {
                deleteTextbaustein: true,
            },
        });
        return from(mutatePromise).pipe(map((response) => response.data.deleteTextbaustein));
    }

    private query<TResponse, TResult>(
        options: any,
        get: (response: TResponse) => TResult,
        due: number,
    ): Observable<TResult> {
        const client = this.awsAppSyncClientProvider.provide();
        client.hydrated();

        const cache$ = from(
            client.query<TResponse>({
                ...options,
                fetchPolicy: 'cache-only',
            }),
        );
        const network$ = from(
            client.query<TResponse>({
                ...options,
                fetchPolicy: 'network-only',
            }),
        );

        return cache$.pipe(
            mergeMap((cache) => {
                if (cache && cache.data && get(cache.data)) {
                    return network$.pipe(
                        timeout(due),
                        catchError(() => of(cache)),
                    );
                } else {
                    return network$;
                }
            }),
            map((response) => get(response.data)),
        );
    }

    createTextbausteinFormGroup(textbaustein: Textbaustein): ViewFormGroup {
        return new ViewFormGroup({
            id: new ViewFormControl(textbaustein.id),
            kurztext: new ViewFormControl(textbaustein.kurztext),
            langtext: new ViewFormControl(textbaustein.langtext),
            verfuegbarkeit: new ViewFormControl(textbaustein.verfuegbarkeit),
            produkte: new ViewFormControl(textbaustein.produkte),
            feature: new ViewFormControl(textbaustein.feature),
            feld: new ViewFormControl(textbaustein.feld),
            tags: new ViewFormControl(textbaustein.tags),
            standard: new ViewFormControl(textbaustein.standard),
            erstelltAm: new ViewFormControl(textbaustein.erstelltAm),
            erstelltVon: new ViewFormControl(textbaustein.erstelltVon),
        });
    }

    prefillWithStandardTextbausteine(
        feature: string,
        field: string,
        produktArt: ProduktArt,
        form: ViewFormGroup,
        textbausteinComponent: TextbausteineComponent,
    ): void {
        this.getTextbausteine(feature, field)
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    const resultFiltered = result.filter(
                        (value) => value.standard && value.produkte.includes(produktArt),
                    );
                    resultFiltered.forEach((baustein) =>
                        (form.get(field) as ViewFormArray).push(this.createTextbausteinFormGroup(baustein)),
                    );
                },
            });
    }
}
