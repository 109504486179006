import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbschlussDownloadService } from '@data/api-gateway';
import { BilderDownloadService } from '@data/api-gateway/service/bilder-download.service';
import { CsvDownloadService } from '@data/api-gateway/service/csv-download.service';
import {
    ProduktArt,
    ProduktArtNachbewertung,
    ProduktArtUebersicht,
    ProduktIcon,
    ProduktStatus,
} from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { OldtimerRoutes } from '@modules/oldtimer/oldtimer.routes';
import { ProduktDetailNachbewertungDialogComponent } from '@modules/produkt/component/produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktUebersichtFilterService } from '@modules/produkt/service/produkt-uebersicht-filter.service';
import { ProduktUebersichtResolveService } from '@modules/produkt/service/produkt-uebersicht-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableComponent } from '@shared/component/data-table';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';

interface ProduktNachbewertungDialogData {
    produkt: Produkt;
}

export interface FilterValues {
    art: number;
    searchText: string;
    von: string;
    bis: string;
    status: number;
}

@Component({
    selector: 'app-produkt-uebersicht-gtue-em',
    templateUrl: './produkt-uebersicht-gtue-em.component.html',
    styleUrls: ['./produkt-uebersicht-gtue-em.component.scss'],
})
export class ProduktUebersichtGtueEmComponent implements OnInit, OnDestroy {
    trackById = TrackBy.trackById;

    produkte: Produkt[];
    name = 'produktUebersicht';
    produktStatus = new EnumValues(ProduktStatus);
    produktStatusEnum = ProduktStatus;
    loading$ = new BehaviorSubject(false);
    loadingCsv$ = new BehaviorSubject(false);

    searchTerm = '';
    form: ViewFormGroup;
    produktArt = ProduktArt;
    produktIcon = ProduktIcon;
    produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);
    readonly ButtonType = ButtonType;

    produktArtEnumValues = new EnumValues(ProduktArtUebersicht);

    filter$ = new BehaviorSubject<string>('');
    filterValues: FilterValues = {
        art: undefined,
        searchText: '',
        von: '',
        bis: '',
        status: 0,
    };

    minDate: Date = null;
    maxDate: Date = new Date();
    viewport$: Observable<Viewport>;
    viewport = Viewport;

    private csvFilenamePrefix = 'EM_Produktdaten_';
    private csvFilenameDateFormat = 'yyyy-MM-dd_HH-mm';

    @ViewChild('table')
    table: TableComponent;

    @ViewChild('nachbewertungArtSelection')
    selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

    @ViewChild('dialogConfirmDuplicate', { static: true })
    dialogConfirmDuplicateTemplate: TemplateRef<any>;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private readonly produktUebersichtResolveService: ProduktUebersichtResolveService,
        private readonly router: Router,
        private readonly viewportService: ViewportService,
        private readonly bilderDownloadService: BilderDownloadService,
        private readonly csvDownloadService: CsvDownloadService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly produktService: ProduktService,
        private readonly filterService: ProduktUebersichtFilterService,
        private readonly abschlussDownloadService: AbschlussDownloadService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(produktUebersichtResolveService, 'produktUebersichtResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(bilderDownloadService, 'bilderDownloadService');
        Assert.notNullOrUndefined(csvDownloadService, 'csvDownloadService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(filterService, 'filterService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        this.viewport$ = this.viewportService.observe();
    }

    ngOnInit(): void {
        this.produkte = this.produktUebersichtResolveService.get();
        this.setProdukteErstelltAmMitAuftragDatum();
        this.setProdukteAuftragNummer();
        this.minDate = this.getMinDate(this.produkte);
        this.form = new ViewFormGroup({
            searchTerm: new ViewFormControl(this.searchTerm, {
                formatter: ViewFormControlFormatters.toUppercase,
            }),
            art: new ViewFormControl(0),
            searchText: new ViewFormControl(''),
            von: new ViewFormControl(this.minDate),
            bis: new ViewFormControl(this.maxDate),
            dateToggle: new ViewFormControl(false),
            status: new ViewFormControl(false),
        });
        this.createFilterSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    onAction(produkt: Produkt): void {
        Assert.notNullOrUndefined(produkt, 'produkt');

        if (produkt.art === ProduktArt.OldTimer || !produkt.art) {
            this.router.navigate([OldtimerRoutes.Oldtimer, produkt.id]);
            return;
        }

        this.router.navigate(['produkt', 'detail', produkt.id]);
    }

    onDownloadClick(event: MouseEvent, identnummer: string, disabled: boolean): void {
        event.stopPropagation();
        if (disabled) {
            return;
        }
        this.abschlussDownloadService
            .get(identnummer)
            .pipe(take(1))
            .subscribe((response) => {
                if (response.url) {
                    this.onDownloadResponse(response.url);
                }
            });
    }

    filterPredicate(produkt: Produkt, filter: string): boolean {
        const filterObject = JSON.parse(filter) as FilterValues;

        if (ProduktUebersichtFilterService.checkStatusNotOpen(filterObject, produkt)) {
            return false;
        }

        if (ProduktUebersichtFilterService.checkProduktArtNotMatching(filterObject, produkt)) {
            return false;
        }

        if (produkt.erstelltAm && filterObject.von && filterObject.bis) {
            const dateResult = ProduktUebersichtFilterService.filterByDate(filterObject, produkt);
            if (dateResult !== undefined) {
                if (!dateResult) {
                    return false;
                }
            }
        }

        if (ProduktUebersichtFilterService.checkSearchText(filterObject, produkt)) {
            return true;
        }
        return false;
    }

    onBilderDownload(event: MouseEvent, produktId: string, disabled: boolean): void {
        Assert.notNullOrUndefined(produktId, 'produktId');
        event.stopPropagation();

        if (disabled) {
            return;
        }

        this.loading$.next(true);
        this.bilderDownloadService
            .get(produktId)
            .pipe(take(1))
            .subscribe({
                next: (url) => {
                    if (url) {
                        window.open(url);
                        this.loading$.next(false);
                    }
                },
                error: (error) => {
                    this.loading$.next(false);
                    error.status === 404
                        ? this.snackBarService.info('produkt.uebersicht.downloadKeineBilder')
                        : this.snackBarService.error('produkt.uebersicht.downloadBilderError');
                },
            });
    }

    onClickProduktSearch(): void {
        this.searchProdukt();
    }

    onResetIdentAndSearch(): void {
        this.form.patchValue({ searchTerm: '' });
        this.searchProdukt();
    }

    alphaNumericOnly(e: KeyboardEvent): boolean {
        return String(e.key).match(/[^a-zA-Z0-9]/g) === null;
    }

    onClickDuplicate($event: MouseEvent, element: Produkt): void {
        const title = 'nachbewertung.title';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktNachbewertungDialogData = { produkt: element };
        $event.stopPropagation();

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogConfirmDuplicateTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    this.loading$.next(true);
                    const selectedProduktArt = this.selectedNachbewertungArt.getSelectedValue();
                    this.produktService
                        .create(selectedProduktArt)
                        .pipe(first())
                        .subscribe((produkt) => {
                            this.subscriptions.push(
                                this.produktService
                                    .getDuplikat(element.id, produkt.id, selectedProduktArt)
                                    .pipe(first())
                                    .subscribe({
                                        next: (v) => {
                                            this.loading$.next(false);
                                            this.router.navigateByUrl(`/produkt/detail/${produkt.id}`);
                                        },
                                        error: (err) => {
                                            this.loading$.next(false);
                                            console.error(err);
                                            this.snackBarService.error('Fehler beim duplizieren des Produktes!');
                                        },
                                    }),
                            );
                        });
                }
            });
    }

    onResetFilter(): void {
        this.form.patchValue({
            art: undefined,
            searchText: '',
            von: this.minDate,
            bis: this.maxDate,
            status: 0,
            dateToggle: false,
        });
    }

    onCsvDownload(event: any): void {
        event.stopPropagation();

        this.loadingCsv$.next(true);
        this.csvDownloadService
            .get(this.form.get('von').value, this.form.get('bis').value)
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    if (data.status === 204) {
                        this.snackBarService.info('produkt.uebersicht.downloadCSVNoData');
                        this.loadingCsv$.next(false);
                        return;
                    }

                    if (data.body) {
                        const csvData = this.base64DecodeEncode(data.body);
                        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${this.csvFilenamePrefix}${formatDate(new Date(), this.csvFilenameDateFormat, this.locale)}.csv`;
                        link.click();
                    }
                    this.loadingCsv$.next(false);
                },
                error: (error) => {
                    console.error(error);
                    this.snackBarService.error('produkt.uebersicht.downloadCSVError');
                    this.loadingCsv$.next(false);
                },
            });
    }

    protected nachbewertungEnabled(produktArt: ProduktArt): boolean {
        if (produktArt === ProduktArt.VtiTooling || produktArt === ProduktArt.CarGarantie) {
            return false;
        }
        return true;
    }

    private setProdukteErstelltAmMitAuftragDatum(): void {
        this.produkte.forEach((p) => {
            if (p.erstelltAm === null && p.auftrag?.erstellungsTag) {
                p.erstelltAm = p.auftrag.erstellungsTag;
            }
        });
    }

    private setProdukteAuftragNummer(): void {
        this.produkte.forEach((p) => {
            if (!p.auftrag?.nummer && p.auftrag?.vorgangsnummer) {
                p.auftrag.nummer = p.auftrag.vorgangsnummer;
            }
        });
    }

    private base64DecodeEncode(input: string): Uint8Array {
        // Decode the base64
        const binaryString = atob(input);
        // Convert the bytes to a string
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const decoder = new TextDecoder('utf-8');

        let decodedString = decoder.decode(bytes);
        // Add BOM to the start of the string, to fix excel encoding issues
        decodedString = `\ufeff${decodedString}`;
        const encoder = new TextEncoder();
        return encoder.encode(decodedString);
    }

    private searchProdukt(): void {
        const searchTextControl = this.form.get('searchText');
        const startDateControl = this.form.get('von');
        const endDateControl = this.form.get('bis');
        const dateToggleControl = this.form.get('dateToggle');
        const searchText = searchTextControl?.value || '';

        this.loading$.next(true);

        let startDate = startDateControl?.value;
        let endDate = endDateControl?.value;

        if (dateToggleControl.value === true) {
            startDate = null;
            endDate = null;
        }
        this.produktUebersichtResolveService
            .resolve(searchText, startDate, endDate)
            .pipe(first())
            .subscribe({
                next: (result) => {
                    this.loading$.next(false);
                    if (result.length < 1) {
                        this.snackBarService.info('produkt.uebersicht.IdentSucheNothingFound');
                        return;
                    }
                    this.produkte = result;
                    this.table.refresh(this.produkte);
                },
                error: (error) => {
                    this.loading$.next(false);
                    this.snackBarService.info('produkt.uebersicht.Suche', error);
                },
            });
    }

    private onDownloadResponse(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    private createFilterSubscriptions() {
        const startDateControl = this.form.get('von');
        const endDateControl = this.form.get('bis');
        const statusControl = this.form.get('status');
        const produktartControl = this.form.get('art');
        const dateToggleControl = this.form.get('dateToggle');
        const searchTextControl = this.form.get('searchText');

        if (searchTextControl) {
            this.subscriptions.push(
                searchTextControl.valueChanges.subscribe((searchText) => {
                    this.filterValues.searchText = searchText;
                    this.filter$.next(JSON.stringify(this.filterValues));
                }),
            );
        }

        if (produktartControl) {
            this.subscriptions.push(
                produktartControl.valueChanges.subscribe((art) => {
                    this.filterValues.art = art;
                    this.filter$.next(JSON.stringify(this.filterValues));
                }),
            );
        }

        if (startDateControl) {
            this.subscriptions.push(
                startDateControl.valueChanges.subscribe((von) => {
                    this.filterValues.von = von;
                    this.filter$.next(JSON.stringify(this.filterValues));
                }),
            );
        }

        if (endDateControl) {
            this.subscriptions.push(
                endDateControl.valueChanges.subscribe((bis) => {
                    this.filterValues.bis = bis;
                    this.filter$.next(JSON.stringify(this.filterValues));
                }),
            );
        }

        if (statusControl) {
            this.subscriptions.push(
                statusControl.valueChanges.subscribe((status) => {
                    this.filterValues.status = status;
                    this.filter$.next(JSON.stringify(this.filterValues));
                }),
            );
        }

        if (dateToggleControl) {
            this.subscriptions.push(
                dateToggleControl.valueChanges.subscribe((dateToggle) => {
                    if (startDateControl && endDateControl && dateToggle) {
                        startDateControl.disable();
                        endDateControl.disable();
                        this.filterValues.von = null;
                        this.filterValues.bis = null;
                        this.filter$.next(JSON.stringify(this.filterValues));
                    } else {
                        startDateControl.enable();
                        endDateControl.enable();
                        this.filterValues.von = startDateControl.value;
                        this.filterValues.bis = endDateControl.value;
                        this.filter$.next(JSON.stringify(this.filterValues));
                    }
                }),
            );
        }
    }

    private getMinDate(produkte: Produkt[]): Date {
        if (!produkte || produkte.length === 0) {
            return new Date();
        }
        produkte.sort((a, b) => new Date(b.erstelltAm).getTime() - new Date(a.erstelltAm).getTime());
        return new Date(produkte[produkte.length - 1].erstelltAm);
    }
}
