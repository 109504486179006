import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { EnumValues } from '@shared/helper/values';

@Component({
    selector: 'app-form-table-column',
    template: `
        <ng-template #content>
            <ng-content />
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFormColumnComponent {
    @Input()
    name: string;

    @Input()
    displayName: string;

    @Input()
    footerDisplayName: string;

    @Input()
    type: string;

    @Input()
    values: EnumValues;

    @Input()
    valuePrefix = '';

    @Input()
    suffix = '';

    @Input()
    fullWidth = false;

    @ViewChild('content', { static: true })
    template: TemplateRef<any>;
}
