import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Produkt } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProduktUebersichtResolveService } from '../service/produkt-uebersicht-resolve.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktUebersichtResolve {
    constructor(
        private readonly produktUebersichtResolveService: ProduktUebersichtResolveService,
        private readonly router: Router,
        private readonly location: Location,
        private readonly snackbar: SnackBarService,
    ) {
        Assert.notNullOrUndefined(produktUebersichtResolveService, 'produktUebersichtResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(location, 'location');
        Assert.notNullOrUndefined(snackbar, 'snackbar');
    }

    resolve(): Observable<Produkt[]> {
        return this.produktUebersichtResolveService.resolve().pipe(
            catchError((error) => {
                this.snackbar.warning('produkt.uebersicht.resolveError');
                this.router.navigateByUrl(this.location.path());
                console.error(error);
                return EMPTY;
            }),
        );
    }
}
