import { Injectable } from '@angular/core';
import { Schaden } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailSchadenPositionFormViewFactory } from './produkt-detail-schaden-position-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailSchadenFormViewFactory extends FormViewModelBaseFactory<Schaden> {
    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly positionFormViewFactory: ProduktDetailSchadenPositionFormViewFactory,
    ) {
        super();
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    }

    protected createField(_: Schaden, name: string): AbstractViewFormControl {
        throw new Error(`Could not create field for name: '${name}'.`);
    }

    protected createArray(model: Schaden, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Schaden.fields;
        if (arrayName === fields.Positionen.name) {
            const positionen = model.positionen || [];
            return new ViewFormArray(
                positionen.map((position) => this.positionFormViewFactory.create(position, arrayFields)),
            );
        } else if (arrayName === fields.Beschreibungen.name) {
            const beschreibungen = model.beschreibungen || [];
            return new ViewFormArray(
                beschreibungen.map((bemerkung) =>
                    this.produktDetailTextbausteinFormViewFactory.create(bemerkung, arrayFields),
                ),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
