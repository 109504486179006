<h1 class="title" *ngIf="settings.title" mat-dialog-title>
    {{ settings.title | translate }}
    <button class="close" mat-button tabindex="-1" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div class="content-wrapper" mat-dialog-content>
    <input #files [multiple]="settings.multiple" [accept]="settings.accept" (change)="onChange()" type="file" />
</div>
<div mat-dialog-actions></div>
