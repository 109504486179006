import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TrackBy } from '@shared/helper/track-by';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StepComponent } from '../step/step.component';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements OnInit, OnDestroy {
    @ViewChild('stepperVertical', { static: true })
    private stepperVertical: MatStepper;

    @ViewChild('stepperHorizontal', { static: true })
    private stepperHorizontal: MatStepper;

    trackByInstance = TrackBy.trackByInstance;

    selectedIndexChange = new BehaviorSubject<number>(0);
    selectedIndexSubsription: Subscription;

    @Input()
    set selectedIndex(index: number) {
        this.selectedIndexChange.next(index);
    }

    @Input()
    linear: boolean;

    @Input()
    horizontal = false;

    @ContentChildren(StepComponent)
    steps: QueryList<StepComponent>;

    ngOnInit() {
        this.selectedIndexSubsription = this.selectedIndexChange.subscribe((index) => this.setIndex(index));
    }

    ngOnDestroy() {
        this.selectedIndexSubsription.unsubscribe();
    }

    next(): void {
        if (this.horizontal) {
            this.stepperHorizontal.next();
        } else {
            this.stepperVertical.next();
        }
    }

    prev(): void {
        if (this.horizontal) {
            this.stepperHorizontal.previous();
        } else {
            this.stepperVertical.previous();
        }
    }

    setIndex(index: number) {
        if (index) {
            if (this.horizontal) {
                this.stepperHorizontal.linear = false;
                this.stepperHorizontal.selectedIndex = index;
                setTimeout(() => {
                    this.stepperHorizontal.linear = true;
                });
            } else {
                this.stepperVertical.linear = false;
                this.stepperVertical.selectedIndex = index;
                setTimeout(() => {
                    this.stepperVertical.linear = true;
                });
            }
        }
    }
}
