import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'app-mini-fab',
    templateUrl: './mini-fab.component.html',
    styleUrls: ['./mini-fab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniFabComponent {
    @Input()
    disabled: boolean;

    @Input()
    count = 0;

    @Input()
    color: ThemePalette = 'accent';

    @Input()
    size = '1.5rem';

    @Input()
    tooltip = '';

    @Output()
    action = new EventEmitter<MouseEvent>();
}
