<app-table
    [data]="cpoVorgaenge"
    [pageSizeOptions]="[25, 50, 100]"
    [filterPredicate]="filterPredicate"
    (action)="onAction($event)"
    active="erstelltAm"
    direction="desc"
>
    <app-table-column [displayName]="name + '.art'" name="art">
        <ng-template let-element="element">
            <app-mat-icon *ngIf="element.art == 301" [svgIcon]="'kfz-haftpflichtschaden'" color="primary" />
            <app-mat-icon *ngIf="element.art == 302" [svgIcon]="'kfz-kaskoschaden'" color="primary" />
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.vorgang'" [sort]="true" name="'nummer'">
        <ng-template let-element="element">{{ element.vorgang }}</ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.kennzeichen'" name="fahrzeug.kennzeichen">
        <ng-template let-element="element">
            {{ element.kennzeichen }}
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.schadenDatum'" [sort]="true" name="schadenDatum" start="desc">
        <ng-template let-element="element">{{ element.schadendatum | date }}</ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.status'" [sort]="true" name="status">
        <ng-template let-element="element">{{ cpoStatus.values[element.status] }}</ng-template>
    </app-table-column>
</app-table>
