<app-card *ngIf="validations.length > 0; else valid">
    <div class="form-error" *ngFor="let validation of validations">
        <h3>{{ validation.name | translate }}</h3>
        <ul>
            <li *ngFor="let field of validation.incorrectFields">
                {{ 'abschluss' + '.field' | translate: { name: validation.name + '.' + field | translate } }}
            </li>
        </ul>
    </div>
</app-card>
<ng-template #valid>
    <app-card spacing="8px">
        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.arrayName">
                <ng-container *ngSwitchCase="'fazit'">
                    <app-textbausteine
                        #fazit
                        [form]="form"
                        [name]="'fazit'"
                        [displayName]="'abschluss.fazit' | translate"
                        [feature]="'CgAbschluss'"
                        [produktArt]="7"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'abschlussBemerkung'">
                    <app-textbausteine
                        #abschlussBemerkung
                        [form]="form"
                        [name]="'abschlussBemerkung'"
                        [displayName]="'abschluss.abschlussBemerkung' | translate"
                        [feature]="'CgAbschluss'"
                        [produktArt]="7"
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>

    <app-card spacing="8px">
        <app-produkt-detail-abschluss-entwurf [produkt]="produkt" [fields]="fields" [adressen]="adressen" />
    </app-card>
    <app-card spacing="8px">
        <app-produkt-detail-abschluss-anhaenge [produkt]="produkt" [statusChanged$]="statusChanged$" />
    </app-card>
    <app-card>
        <app-produkt-detail-abschluss-workflow-simple
            [produkt]="produkt"
            (statusChangedEvent)="onStatusChanged($event)"
        />
    </app-card>
</ng-template>
