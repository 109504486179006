import { Injectable, TemplateRef, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonSetting, ButtonType } from '@shared/component/button-indicator/button/button.component';
import {
    TemplateDialogComponent,
    TemplateDialogSettings,
} from '@shared/component/popup-modal/template-dialog/template-dialog.component';
import { Assert } from '@shared/helper/assert';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable } from 'rxjs';

export interface TemplateDialogResult<TData> {
    name: string;
    data: TData;
}

export interface TemplateButtonDisableEvent {
    index: number;
    disable: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class TemplateDialogService {
    viewport$: Observable<Viewport>;
    viewport = Viewport;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly viewportService: ViewportService,
    ) {
        Assert.notNullOrUndefined(matDialog, 'matDialog');
        Assert.notNullOrUndefined(matDialog, 'matDialog');
        this.viewport$ = this.viewportService.observe();
    }

    private currentDialog: MatDialogRef<any>;

    openTemplate<TData>(
        title: string,
        buttons: ButtonSetting[],
        templateOrComponent: TemplateRef<any> | Type<any>,
        templateData?: TData,
        disableClose: boolean = false,
    ): Observable<TemplateDialogResult<TData>> {
        this.currentDialog = this.open(title, buttons, templateOrComponent, templateData, disableClose);
        return this.currentDialog.afterClosed();
    }

    open<TData>(
        title: string,
        buttons: ButtonSetting[],
        templateOrComponent: TemplateRef<any> | Type<any>,
        templateData?: TData,
        disableClose: boolean = false,
    ): MatDialogRef<any, { name: string; data: TData }> {
        Assert.notNullOrUndefined(buttons, 'buttons');
        Assert.notNullOrUndefined(templateOrComponent, 'templateOrComponent');

        const settings: TemplateDialogSettings = {
            title,
            buttons,
            templateOrComponent,
            templateData,
            disableClose,
        };

        return this.matDialog.open(TemplateDialogComponent, {
            data: settings,
            disableClose,
        });
    }

    setCurrentDialog(dialog: MatDialogRef<any>): void {
        this.currentDialog = dialog;
    }

    enableButton(index: number): void {
        this.currentDialog.componentInstance?.enableButton(index);
    }

    disableButton(index: number): void {
        this.currentDialog.componentInstance?.disableButton(index);
    }

    closeAll() {
        return this.matDialog.closeAll();
    }

    getCancelButtonSetting(): ButtonSetting {
        return {
            title: 'feature.cancel',
            type: ButtonType.SECONDARY,
        };
    }

    getConfirmButtonSetting(): ButtonSetting {
        return {
            title: 'feature.confirm',
            type: ButtonType.PRIMARY,
        };
    }

    getSaveButtonSetting(): ButtonSetting {
        return {
            title: TemplateAction.Save,
            type: ButtonType.PRIMARY,
        };
    }

    getCopyButtonSetting(): ButtonSetting {
        return {
            title: 'feature.copy',
            type: ButtonType.PRIMARY,
        };
    }

    getSkipButtonSetting(): ButtonSetting {
        return {
            title: 'feature.skip',
            type: ButtonType.SECONDARY,
        };
    }

    getSynchronizeButtonSetting(): ButtonSetting {
        return {
            title: 'feature.synchronize',
            type: ButtonType.PRIMARY,
        };
    }

    getRetryButtonSetting(): ButtonSetting {
        return {
            title: 'feature.retry',
            type: ButtonType.PRIMARY,
        };
    }

    getDeleteButtonSetting(): ButtonSetting {
        return {
            title: TemplateAction.Delete,
            type: ButtonType.PRIMARY,
        };
    }

    getCloseButtonSetting(): ButtonSetting {
        return {
            title: 'feature.close',
            type: ButtonType.SECONDARY,
        };
    }
}

export enum TemplateAction {
    Save = 'feature.save',
    Delete = 'feature.delete',
}
