import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { ProduktFeatureService } from '@data/domain/service/feature/produkt-feature.service';
import { ProduktService } from '@data/domain/service/produkt.service';
import { GraphQLResponse, saveAcAbschluss, SaveAcAbschlussData } from '../../graphql/mutations';
import { AcAbschluss, AcAbschlussInput, Produkt } from '../../schema/type';

@Injectable({
    providedIn: 'root',
})
export class ProduktAcAbschlussService extends ProduktFeatureService<AcAbschluss, AcAbschlussInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: AcAbschluss): AcAbschlussInput {
        const input: AcAbschlussInput = {
            id: produktId,
            ...feature,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveAcAbschluss;
    }

    protected getSaveVariables(input: AcAbschlussInput): any {
        return {
            acAbschluss: {
                id: input.id,
                acVersendet: input.acVersendet,
            },
        };
    }

    protected getSaveOptimisticResponse(input: AcAbschlussInput): any {
        return {
            saveAcAbschluss: {
                __typename: 'AcAbschluss',
                id: input.id,
                acVersendet: input.acVersendet,
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveAcAbschlussData>): AcAbschluss {
        return response.data.saveAcAbschluss;
    }

    protected update(produkt: Produkt, feature: AcAbschluss): void {
        produkt.acAbschluss.id = feature.id;
        produkt.acAbschluss.acVersendet = feature.acVersendet;
    }
}
