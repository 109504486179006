import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveFeststellungen, SaveFeststellungenData } from '../../graphql/mutations';
import { Feststellungen, FeststellungenInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktFeststellungenService extends ProduktFeatureService<Feststellungen, FeststellungenInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Feststellungen): FeststellungenInput {
        const input: FeststellungenInput = {
            id: produktId,
            umfaenge: (feature.umfaenge || []).map((umfang, index) => ({
                ...umfang,
                id: `${produktId}:${index}`,
            })),
            positionen: (feature.positionen || []).map((position, index) => ({
                ...position,
                id: `${produktId}:${index}`,
            })),
            beschreibungen: (feature.beschreibungen || []).map((beschreibungen, _beschreibungenIndex) => ({
                ...beschreibungen,
            })),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveFeststellungen;
    }

    protected getSaveVariables(input: FeststellungenInput): any {
        return {
            feststellungen: input,
        };
    }

    protected getSaveOptimisticResponse(input: FeststellungenInput): any {
        return {
            saveFeststellungen: {
                ...input,
                __typename: 'Feststellungen',
                umfaenge: (input.umfaenge || []).map((umfang) => ({
                    ...umfang,
                    beschreibung: (umfang.beschreibung || []).map((beschreibung) => ({
                        ...beschreibung,
                        __typename: 'Textbaustein',
                    })),
                    __typename: 'Umfang',
                })),
                positionen: (input.positionen || []).map((position) => ({
                    ...position,
                    zustand: (position.zustand || []).map((zustand) => ({
                        ...zustand,
                        __typename: 'Textbaustein',
                    })),
                    __typename: 'Position',
                })),
                beschreibungen: (input.beschreibungen || []).map((beschreibungen) => ({
                    ...beschreibungen,
                    __typename: 'Textbaustein',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveFeststellungenData>): Feststellungen {
        return response.data.saveFeststellungen;
    }

    protected update(produkt: Produkt, feature: Feststellungen): void {
        produkt.feststellungen.id = feature.id;
        produkt.feststellungen.positionen = feature.positionen;
        produkt.feststellungen.umfaenge = feature.umfaenge;
        produkt.feststellungen.beschreibungen = feature.beschreibungen;
    }
}
