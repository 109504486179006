import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RadBauart, RadFelge, RadGeschwindigkeitsindex, RadReifenTyp, RadZulaessig } from '@data/domain/schema/enum';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { EnumValues } from '@shared/helper/values';

@Component({
    selector: 'app-produkt-detail-nfz-raeder-rad',
    templateUrl: './produkt-detail-nfz-raeder-rad.component.html',
    styleUrls: ['./produkt-detail-nfz-raeder-rad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailNfzRaederRadComponent {
    trackByField = TrackBy.trackByField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    reifentyp = new EnumValues(RadReifenTyp);
    felge = new EnumValues(RadFelge);
    bauart = new EnumValues(RadBauart);
    geschwindigkeitsindex = new EnumValues(RadGeschwindigkeitsindex);
    zulaessig = new EnumValues(RadZulaessig);
}
