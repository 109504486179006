<app-card class="main-content">
    <app-input-search
        [form]="searchForm"
        [readonly]="false"
        [minlength]="amsSearchInputMax"
        [maxlength]="amsSearchInputMax"
        (searchClick)="onSearch($event)"
        name="searchField"
        displayName="Suche"
        placeholder="AMS"
        type="number"
        inputmode="number"
    />

    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name || field.groupName || field.arrayName">
            <ng-container *ngSwitchCase="'art'">
                <app-radio
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [values]="art"
                    [valuePrefix]="name + '.'"
                    [disabled]="artDisabled"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'nummer'">
                <app-input
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [disabled]="true"
                    type="text"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'auftragstyp'">
                <app-input
                    [disabled]="true"
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    type="text"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'bemerkung'">
                <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>

<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<ng-template #dialogConfirm>
    <mat-label *ngIf="searchResult">
        {{ 'vtiAuftrag.suche.success' | translate }}
        <br />
        VTI-Auftragsnummer: {{ searchResult.vti_auftragsnummer }}
        <br />
        VTI-Auftragstyp: {{ searchResult.vti_auftragstyp }}
        <br />
        Kennzeichen: {{ searchResult.fahrzeug.kennzeichen }}
        <br />
        FIN: {{ searchResult.fahrzeug.fin }}
        <br />
    </mat-label>
</ng-template>
