import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TEXTBAUSTEINE_VERWALTUNG_CONFIG } from '@modules/textbausteine-verwalten/config/textbausteine-verwalten.config';
import { Assert } from '@shared/helper/assert';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-textbausteine-verwalten',
    templateUrl: './textbausteine-verwalten.component.html',
    styleUrls: ['./textbausteine-verwalten.component.scss'],
})
export class TextbausteineVerwaltenComponent {
    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    textbausteineConfigList = TEXTBAUSTEINE_VERWALTUNG_CONFIG;
    viewport$: Observable<Viewport>;
    viewport = Viewport;

    constructor(private readonly viewportService: ViewportService) {
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        this.viewport$ = this.viewportService.observe();
    }
}
