<mat-form-field>
    <input #input matInput />
    <ng-container matSuffix>
        <button [disabled]="input.value.length === 0" (click)="onAddClick(input)" mat-button>
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
        </button>
    </ng-container>
</mat-form-field>

<ng-container *ngIf="!(loading$ | async); else loading">
    <mat-radio-group class="single" #group>
        <div class="list-item key" *ngFor="let value of textbausteine$ | async">
            <mat-radio-button [value]="value" (change)="textbausteinEventEmitter.emit(value)">
                {{ value.kurztext }}
            </mat-radio-button>

            <div class="show-icon-button" *ngIf="isGTUEStandard(value)">
                <app-icon-button (click)="onShowTextbausteinClicked(value)">
                    <app-mat-icon icon="remove_red_eye" />
                </app-icon-button>
                <app-icon-button>
                    <app-mat-icon svgIcon="gtue_logo" />
                </app-icon-button>
            </div>

            <div class="show-icon-button" *ngIf="!isGTUEStandard(value)">
                <app-icon-button (click)="onEditTextbausteinClicked(value)">
                    <app-mat-icon icon="edit" />
                </app-icon-button>
                <app-icon-button (click)="onDeleteTextbausteinClicked(value)">
                    <app-mat-icon icon="delete" />
                </app-icon-button>
            </div>
        </div>
    </mat-radio-group>
</ng-container>

<ng-template #loading>
    <app-progress-spinner />
</ng-template>

<!-- Show dialog -->
<ng-template #dialogShow let-data="data">
    <app-textbausteine-dialog-edit
        [feature]="feature"
        [feld]="feld"
        [mode]="editMode.Textbaustein"
        [form]="data.form"
        [data]="data"
        [feldDisplayName]="feldDisplayName"
    />
</ng-template>

<!-- Edit dialog -->
<ng-template #dialogEdit let-data="data">
    <app-textbausteine-dialog-edit
        [feature]="feature"
        [feld]="feld"
        [mode]="editMode.Verwaltung"
        [form]="data.form"
        [data]="data"
        [feldDisplayName]="feldDisplayName"
    />
</ng-template>

<!-- Delete dialog -->
<ng-template #dialogDelete>
    <app-delete-dialog />
</ng-template>
