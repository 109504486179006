import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSetting, ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { PullToRefreshService } from '@shared/service/pull-to-refresh.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable, take } from 'rxjs';

export interface TemplateDialogSettings {
    title: string;
    buttons: ButtonSetting[];
    templateOrComponent: TemplateRef<any> | Type<any>;
    templateData: any;
    disableClose: boolean;
}

export interface AbstractTemplateDialogComponent {
    close(action: string): void;
}

@Component({
    selector: 'app-template-dialog',
    templateUrl: './template-dialog.component.html',
    styleUrls: ['./template-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateDialogComponent implements OnInit, OnDestroy {
    private componentRef: ComponentRef<any>;

    trackByKey = TrackBy.trackByKey;
    Buttontype = ButtonType;

    @ViewChild('container', { read: ViewContainerRef, static: true })
    container: ViewContainerRef;

    template: TemplateRef<any>;

    disabledButtons: number[] = [];

    viewport$: Observable<Viewport>;
    viewport = Viewport;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        readonly settings: TemplateDialogSettings,
        readonly dialogRef: MatDialogRef<TemplateDialogComponent>,
        readonly pullToRefreshService: PullToRefreshService,
        private readonly viewportService: ViewportService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
    ) {
        Assert.notNullOrUndefined(settings, 'settings');
        Assert.notNullOrUndefined(dialogRef, 'dialogRef');
        Assert.notNullOrUndefined(pullToRefreshService, 'pullToRefreshService');
        Assert.notNullOrUndefined(pullToRefreshService, 'pullToRefreshService');
        Assert.notNullOrUndefined(componentFactoryResolver, 'componentFactoryResolver');
    }

    ngOnInit(): void {
        this.viewport$ = this.viewportService.observe();
        if (this.settings.templateOrComponent instanceof Type) {
            const component = this.settings.templateOrComponent;
            const factory = this.componentFactoryResolver.resolveComponentFactory(component);
            this.componentRef = this.container.createComponent(factory);
            this.componentRef.instance.data = this.settings.templateData;
            this.componentRef.instance.close = (action: string) => this.closeDialog(action);
        } else {
            this.template = this.settings.templateOrComponent;
        }
        this.pullToRefreshService.deactivate();
    }

    ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    onAction(action: string): void {
        if (this.componentRef?.instance?.onAction) {
            this.componentRef.instance
                .onAction(action)
                .pipe(take(1))
                .subscribe((close) => {
                    if (close) {
                        this.closeDialog(action);
                    }
                });
        } else {
            this.closeDialog(action);
        }
    }

    disableButton(index: number): void {
        if (!index) {
            return;
        }
        if (!this.disabledButtons.includes(index)) {
            this.disabledButtons.push(index);
        }
    }

    enableButton(index): void {
        if (!index) {
            return;
        }
        if (this.disabledButtons.includes(index)) {
            const indexOfButtonInList = this.disabledButtons.indexOf(index);
            this.disabledButtons.splice(indexOfButtonInList, 1);
        }
    }

    isButtonDisabled(index: number): boolean {
        return this.disabledButtons.includes(index);
    }

    private closeDialog(action: string): void {
        this.dialogRef.close({
            name: action,
            data: this.settings.templateData,
        });
        this.pullToRefreshService.activate();
    }
}
