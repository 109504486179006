import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/guard/auth.guard';
import { PrivacyGuard } from '@shared/guard/privacy.guard';
import { ContentLayoutComponent } from './layout';

const routes: Routes = [
    {
        path: '',
        component: ContentLayoutComponent,
        canActivate: [PrivacyGuard],
        children: [
            {
                path: 'auth',
                loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule),
            },
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'produkt',
                canActivate: [AuthGuard],
                loadChildren: () => import('@modules/produkt/produkt.module').then((m) => m.ProduktModule),
            },
            {
                path: 'verwaltung',
                canActivate: [AuthGuard],
                loadChildren: () => import('@modules/verwaltung/verwaltung.module').then((m) => m.VerwaltungModule),
            },
            {
                path: 'oldtimer',
                loadChildren: () => import('@modules/oldtimer/oldtimer.routes').then((mod) => mod.OLDTIMER_ROUTES),
            },
            { path: '**', redirectTo: 'dashboard' },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
