import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from '@modules/dashboard/dashboard-routing.module';
import { SharedModule } from '@shared/shared.module';
import { DashboardComponent } from './page/dashboard/dashboard.component';

@NgModule({
    declarations: [DashboardComponent],
    imports: [DashboardRoutingModule, SharedModule],
})
export class DashboardModule {}
