<h1 class="title" *ngIf="settings.title" mat-dialog-title>
    {{ settings.title | translate }}
    <button class="close" mat-button tabindex="-1" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div class="content-wrapper" mat-dialog-content>
    <input
        #files
        [style.display]="captureSupported ? 'block' : 'none'"
        (change)="onChange()"
        type="file"
        accept="image/*"
        capture="true"
    />
    <ng-container *ngIf="!captureSupported && getUserMediaSupported && (state$ | async) !== 5">
        <app-camera-view
            #camera
            [options]="settings"
            (stateChange)="onStateChange($event)"
            (imageSave)="onImageSave($event)"
        />
    </ng-container>
    <ng-container *ngIf="(!captureSupported && !getUserMediaSupported) || (state$ | async) === 5">
        {{ 'capturedialog.notsupported' | translate }}
    </ng-container>
</div>
<div mat-dialog-actions align="center">
    <ng-container *ngIf="!captureSupported && getUserMediaSupported && camera && (state$ | async) !== 5">
        <app-camera-controls
            [state]="state$ | async"
            (save)="camera.save()"
            (trigger)="camera.trigger($event)"
            (flip)="camera.flip()"
        />
    </ng-container>
</div>
