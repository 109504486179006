<mat-table [class.disabled]="disabled" [dataSource]="dataSource" [class.mat-elevation-z8]="elevation">
    <ng-container *ngFor="let column of columns$ | async; trackBy: trackByName" [matColumnDef]="column.name">
        <mat-header-cell
            *matHeaderCellDef
            [class.number]="column.type === 'currency'"
            [innerHTML]="column.displayName | translate"
            scope="col"
        />
        <mat-cell
            *matCellDef="let row"
            [class.number]="column.type === 'currency'"
            [class.full-width]="column.fullWidth"
        >
            <ng-container *ngIf="row.get(column.name) as col">
                <ng-container [ngSwitch]="col.value === undefined || col.value === null ? '-' : column.type">
                    <ng-container *ngSwitchCase="'currency'">{{ col.value }}&nbsp;€</ng-container>
                    <ng-container *ngSwitchCase="'array'">{{ (col.value || []).length }}</ng-container>
                    <ng-container *ngSwitchCase="'array-number'">
                        <ng-container *ngFor="let item of col.value">
                            <div>{{ item }}</div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">{{ col.value | date }}</ng-container>
                    <ng-container *ngSwitchCase="'text'">{{ col.value | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'select'">
                        {{ column.valuePrefix + column.values.values[col.value] | lowercase | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'-'">-</ng-container>
                    <ng-container *ngSwitchDefault>{{ col.value }}</ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="column.suffix">&nbsp;{{ column.suffix }}</ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef [class.number]="column.type === 'currency'">
            <ng-container *ngIf="column.type !== 'currency'">
                {{ column.footerDisplayName | translate }}
            </ng-container>
            <ng-container *ngIf="column.type === 'currency'">{{ getTotal(column.name) }}&nbsp;€</ng-container>
        </mat-footer-cell>
    </ng-container>
    <div matColumnDef="remove">
        <mat-header-cell class="single-icon-col-width" *matHeaderCellDef scope="col" />
        <mat-cell class="single-icon-col-width" *matCellDef="let row">
            <ng-container *ngIf="!row.value.readonly">
                <app-icon-button class="button delete-button" (action)="onRowRemoveClick(row)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-icon-button>
            </ng-container>
        </mat-cell>
        <mat-footer-cell class="single-icon-col-width" class="single-icon-col-width" *matFooterCellDef />
    </div>
    <div matColumnDef="sortable">
        <mat-header-cell class="sortable-indicator-col-width" *matHeaderCellDef scope="col" />
        <mat-cell class="sortable-indicator-col-width" *matCellDef="let row; let first = first; let last = last">
            <div class="sortable">
                <app-mat-icon
                    *ngIf="!first"
                    (click)="onRowMove($event, row, -1)"
                    icon="keyboard_arrow_up"
                    size="18px"
                />
                <app-mat-icon
                    *ngIf="!last"
                    (click)="onRowMove($event, row, 1)"
                    icon="keyboard_arrow_down"
                    size="18px"
                />
            </div>
        </mat-cell>
        <mat-footer-cell class="sortable-indicator-col-width" *matFooterCellDef />
    </div>
    <mat-header-row *matHeaderRowDef="names$ | async" [class.hidden]="!header" />
    <mat-row
        *matRowDef="let row; columns: names$ | async"
        [class.clickable]="!row.value.readonly && rowOpen.observers.length > 0"
        [class.invalid]="!(row.validChanges | async) && row.invalid"
        (click)="onRowClick(row)"
    />
    <mat-footer-row *matFooterRowDef="names$ | async" [class.hidden]="!footer" />
</mat-table>
