import { Injectable } from '@angular/core';
import { FahrzeugArt, FahrzeugBauform } from '@data/domain/schema/enum';
import { MODEL_FILE_POSITION, ModelFileConfig } from '@modules/produkt/config/produkt-model-config';

@Injectable({
    providedIn: 'root',
})
export class ModelFileService {
    get(art: FahrzeugArt, bauform: FahrzeugBauform): ModelFileConfig[] {
        if (!MODEL_FILE_POSITION[art]) {
            return undefined;
        }

        const modelFileConfig = MODEL_FILE_POSITION[art][bauform];
        return [modelFileConfig, MODEL_FILE_POSITION[art][FahrzeugBauform.Sonstige]];
    }
}
