import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExpansionPanelComponent } from '../expansion-panel/expansion-panel.component';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    trackByInstance = TrackBy.trackByInstance;

    @Input()
    width = '25rem';

    @Input()
    multi = false;

    @Input()
    set active(active: number) {
        this.active$.next(active);
    }

    @ContentChildren(ExpansionPanelComponent)
    panels: QueryList<ExpansionPanelComponent>;

    panels$ = new BehaviorSubject<ExpansionPanelComponent[]>([]);
    active$ = new BehaviorSubject<number>(-1);

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.panels$.next(this.panels.toArray());
        }, 1);
        this.subscriptions.push(this.panels.changes.subscribe(() => this.updatePanels$()));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    onPanelOpened(panel: ExpansionPanelComponent, active: number): void {
        if (this.multi) {
            return;
        }

        Assert.notNullOrUndefined(panel, 'panel');
        panel.opened.emit();
        if (this.active$.value !== active) {
            this.active$.next(active);
        }
    }

    next(): void {
        const active = Math.min(this.panels$.value.length, this.active$.value + 1);
        if (this.active$.value !== active) {
            this.active$.next(active);
        }
    }

    prev(): void {
        const active = Math.max(0, this.active$.value - 1);
        if (this.active$.value !== active) {
            this.active$.next(active);
        }
    }

    private updatePanels$(): void {
        this.panels$.next(this.panels.toArray());
        this.next();
        this.prev();
    }
}
