import { NgModule } from '@angular/core';
import { VerwaltungComponent } from '@modules/verwaltung/page/verwaltung/verwaltung.component';
import { VerwaltungRoutingModule } from '@modules/verwaltung/verwaltung-routing.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [VerwaltungComponent],
    imports: [VerwaltungRoutingModule, SharedModule],
})
export class VerwaltungModule {}
