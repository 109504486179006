<mat-form-field (click)="onFieldClick()">
    <mat-chip-grid #list>
        <mat-chip-row
            *ngFor="let value of control.value; let index = index; trackBy: trackByKey"
            (removed)="onOptionRemove(index)"
        >
            {{ value }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
            #input
            #trigger="matAutocompleteTrigger"
            [placeholder]="displayName | translate"
            [id]="name"
            [formControl]="viewControl"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="list"
            [matChipInputSeparatorKeyCodes]="seperators"
            (matChipInputTokenEnd)="onOptionAdd($event, input)"
            matInput
        />
    </mat-chip-grid>
    <mat-autocomplete #autocomplete (optionSelected)="onOptionSelected($event, input)">
        <mat-option *ngFor="let option of optionsFiltered$ | async" [value]="option">
            {{ option }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
