<ng-container *ngIf="!saving; else spinner">
    <div class="settings-container">
        <h4>{{ name + '.druckprodukt.title' | translate }}</h4>
        <div class="setting">
            <label>{{ name + '.' + 'logo' | translate }}</label>
            <app-inline-image
                [form]="form"
                [size]="1024 * maxFileUploadSizeInKb"
                [displayName]="name + '.logo'"
                name="logo"
                isEinstellungen="true"
            />
        </div>
        <div class="setting">
            <label>{{ name + '.' + 'logoTop' | translate }}</label>
            <app-inline-image
                [form]="form"
                [size]="1024 * maxFileUploadSizeInKb"
                [displayName]="name + '.logoTop'"
                name="logoTop"
                isEinstellungen="true"
            />
        </div>
        <app-input
            class="setting"
            [form]="form"
            [displayName]="name + '.fusszeile'"
            name="fusszeile"
            type="text"
            inputmode="text"
        />
    </div>

    <div class="settings-container">
        <h4>{{ name + '.dat.buero.title' | translate }}</h4>
        <app-input
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datCustomerNumber'"
            name="datCustomerNumber"
            type="text"
            inputmode="text"
        />
        <app-input
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datCustomerLogin'"
            name="datCustomerLogin"
            type="text"
            inputmode="text"
        />
        <app-input
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datInterfacePartnerNumber'"
            name="datInterfacePartnerNumber"
            type="text"
            inputmode="text"
        />
        <app-input
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datProductVariant'"
            name="datProductVariant"
            type="text"
            inputmode="text"
        />
        <app-input
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datInterfacePartnerSignature'"
            name="datInterfacePartnerSignature"
            type="text"
            inputmode="text"
        />
    </div>
</ng-container>
<ng-template #spinner>
    <app-progress-spinner />
</ng-template>
<div align="end">
    <app-button [type]="ButtonType.PRIMARY" [disabled]="!form.valid || saving" (action)="form.valid && onSave()">
        {{ name + '.save' | translate }}
    </app-button>
</div>
