import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { ProduktStatus } from '@data/domain/schema/enum';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const ANIMATION_THRESHOLD_START_PX = 25;
const ANIMATION_THRESHOLD_START_PY = 25;
const ANIMATION_THRESHOLD_FINISH_PX = 100;
const ANIMATION_DURATION = 320;

@Component({
    selector: 'app-produkt-detail-content',
    templateUrl: './produkt-detail-content.component.html',
    styleUrls: ['./produkt-detail-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailContentComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    private isUserInteracting = false;
    private startX = 0;
    private startY = 0;

    private finishStartTick = 0;
    private finished = false;

    @ViewChild('content', { static: true })
    private content: ElementRef<HTMLElement>;

    @Output()
    next = new EventEmitter();

    @Output()
    prev = new EventEmitter();

    disabled$ = new BehaviorSubject(false);

    constructor(
        private readonly router: Router,
        private readonly produkt: ProduktDetailResolveService,
    ) {
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(produkt, 'produkt');
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.router.events
                .pipe(
                    filter(
                        (event) =>
                            event instanceof NavigationEnd ||
                            event instanceof NavigationCancel ||
                            event instanceof NavigationError,
                    ),
                )
                .subscribe(() => this.onNavigationChange()),
        );
        this.update();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    onTouchStart(event: TouchEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        const target = event.target as HTMLElement;
        if (!this.isValidTarget(target)) {
            return;
        }
        this.isUserInteracting = true;
        this.setStartFromEvent(event);
    }

    onTouchMove(event: TouchEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        if (!this.isUserInteracting || this.finished) {
            return;
        }
        this.animate(event);
    }

    onTouchEnd(event: TouchEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        this.isUserInteracting = false;
        if (!this.finished) {
            this.resetAnimation();
        }
    }

    private onNavigationChange(): void {
        this.update();
        this.reset();
    }

    private reset(): void {
        const diff = Date.now() - this.finishStartTick;
        setTimeout(
            () => {
                this.removeAnimationClass('smooth', 'finish', 'left', 'right');
                setTimeout(() => {
                    this.resetAnimation();
                    this.finished = false;
                }, 30);
            },
            Math.max(0, ANIMATION_DURATION - diff),
        );
    }

    private update(): void {
        const produkt = this.produkt.get();
        const disabled =
            produkt &&
            produkt.status !== ProduktStatus.Offen &&
            !this.router.routerState.snapshot.url.endsWith('/abschluss') &&
            !this.router.routerState.snapshot.url.endsWith('/vtiAbschluss') &&
            !this.router.routerState.snapshot.url.endsWith('/cgAbschluss') &&
            !this.router.routerState.snapshot.url.endsWith('/acAbschluss');
        this.disabled$.next(disabled);
    }

    private isValidTarget(target: HTMLElement): boolean {
        if (!target || !target.className || !target.className.indexOf) {
            return true;
        }
        return target.className.indexOf('mat-slider') === -1;
    }

    private setStartFromEvent(event: TouchEvent): void {
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY;
    }

    /* ANIMATION */

    private animate(event: TouchEvent): void {
        const offsetY = this.startY - event.touches[0].clientY;
        const distY = Math.abs(offsetY);
        if (distY > ANIMATION_THRESHOLD_START_PY) {
            this.isUserInteracting = false;
            this.resetAnimation();
        } else {
            const offset = this.startX - event.touches[0].clientX;
            if (offset > ANIMATION_THRESHOLD_FINISH_PX) {
                this.finishAnimation(1);
                this.next.emit();
            } else if (offset < -ANIMATION_THRESHOLD_FINISH_PX) {
                this.finishAnimation(-1);
                this.prev.emit();
            } else {
                this.updateAnimation(offset);
            }
        }
    }

    private updateAnimation(offset: number): void {
        this.removeAnimationClass('smooth');

        if (offset > ANIMATION_THRESHOLD_START_PX) {
            offset -= ANIMATION_THRESHOLD_START_PX;
            this.setAnimationOffset(offset);
        }
        if (offset < -ANIMATION_THRESHOLD_START_PX) {
            offset += ANIMATION_THRESHOLD_START_PX;
            this.setAnimationOffset(offset);
        }
    }

    private resetAnimation(): void {
        this.addAnimationClass('smooth');
        this.setAnimationOffset(0);
    }

    private finishAnimation(sign: number): void {
        this.finishStartTick = Date.now();
        this.finished = true;

        this.addAnimationClass('smooth', 'finish', sign > 0 ? 'left' : 'right');
        this.setAnimationOffset(-sign * 100, '%');
    }

    private setAnimationOffset(offset: number, unit: string = 'px'): void {
        const style = this.content.nativeElement.style;
        style.marginLeft = -offset + unit;
        style.marginRight = offset + unit;
    }

    private addAnimationClass(...cssClasses: string[]): void {
        cssClasses.forEach((cssClass) => {
            this.content.nativeElement.classList.add(cssClass);
        });
    }

    private removeAnimationClass(...cssClasses: string[]): void {
        cssClasses.forEach((cssClass) => {
            this.content.nativeElement.classList.remove(cssClass);
        });
    }
}
