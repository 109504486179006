import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    QueryList,
    Self,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { VinDigitService } from '../../service/vin-digit.service';

class MatInputBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public stateChanges: Subject<void>,
        /** @docs-private */
        public ngControl: NgControl,
    ) {}
}
const _MatInputMixinBase: typeof MatInputBase = mixinErrorState(MatInputBase);

@Component({
    selector: 'app-vin',
    templateUrl: './vin.component.html',
    styleUrls: ['./vin.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: VinComponent }, VinDigitService, Subject],
})
export class VinComponent
    extends _MatInputMixinBase
    implements
        MatFormFieldControl<string>,
        ControlValueAccessor,
        OnInit,
        OnDestroy,
        AfterViewInit,
        CanUpdateErrorState,
        DoCheck {
    get value(): string {
        return this._value;
    }

    set value(value: string) {
        value = value || '';
        if (this._value !== value) {
            this._value = value;
            if (this.onChangeFn) {
                this.onChangeFn(this._value);
            }
        }
        this.chars.forEach((char, i) => (char.value = this._value[i] || ''));
        this.digit.nativeElement.value = this.vinDigitService.calculateDigit(value);
        this.stateChanges.next();
    }

    get placeholder(): string {
        return this._placeholder;
    }
    @Input()
    set placeholder(placeholder: string) {
        this._placeholder = placeholder;
        this.stateChanges.next();
    }

    get focused(): boolean {
        const focused = this.chars.find((x) => x === document.activeElement) !== undefined;
        if (focused !== this._focused) {
            this.focused = focused;
        }
        return focused || this._touched;
    }

    set focused(focused: boolean) {
        if (!this._focused && focused) {
            if (!this._touched) {
                if (this.empty) {
                    this.focus();
                }
                if (this.onTouchedFn) {
                    this.onTouchedFn();
                }
            }
            this._touched = true;
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
        }
        this._focused = focused;
    }

    get empty(): boolean {
        return !this.value || this.value.trim().length <= 0;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    vinDisabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    constructor(
        private readonly vinDigitService: VinDigitService,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        _stateChanges: Subject<void> = new Subject<void>(),
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, _stateChanges, ngControl);

        if (this.ngControl !== null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    static nextId = 0;
    private _value: string;
    private _placeholder: string;
    private _required = false;
    private _disabled = false;
    private _focused = false;
    private _touched = false;
    private timeout: any;

    private chars: HTMLInputElement[] = [];

    private onChangeFn: any;
    private onTouchedFn: any;

    stateChanges = new Subject<void>();

    errorStateMatcher: ErrorStateMatcher;

    @HostBinding()
    id = `vin-input-${VinComponent.nextId++}`;

    errorState = false;
    controlType = 'vin-input';

    @HostBinding('attr.aria-describedby')
    describedBy = '';

    autofilled = false;

    parts: string[] = [];

    @ViewChildren('char')
    charsQuery: QueryList<ElementRef<HTMLInputElement>>;

    @ViewChild('digit', { static: true })
    digit: ElementRef<HTMLInputElement>;

    trackByIndex = (index: number) => index;

    updateErrorState(): void {
        const oldState = this.errorState;
        const parent = this._parentFormGroup || this._parentForm;
        const matcher = this.errorStateMatcher || this._defaultErrorStateMatcher;
        const control = this.ngControl ? this.ngControl.control : null;
        const newState = matcher.isErrorState(control, parent);

        if (newState !== oldState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
    }

    ngOnInit(): void {
        const value = this.value || '';
        this.parts = Array.from({ length: 17 }, (_, i) => value[i] || '');
    }

    ngAfterViewInit(): void {
        this.chars = this.charsQuery.toArray().map((x) => x.nativeElement);
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.focus();
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    focus(): void {
        const el = this.chars[0];
        if (el) {
            el.focus();
        }
    }

    blur(): void {
        this.chars.forEach((char) => char.blur());
        this.stateChanges.next();
    }

    onFocusout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this._touched = false;
            this.timeout = undefined;
            this.stateChanges.next();
        }, 400);
    }

    onFocus(index: number): void {
        this.chars[index].select();
    }

    onPaste(event: ClipboardEvent): void {
        event.preventDefault();
        const vin = event.clipboardData.getData('text');
        this.writeValue(vin);
    }

    onKeyDown(event: KeyboardEvent, index: number): void {
        if (event.ctrlKey || event.altKey) {
            return;
        }

        event.preventDefault();

        switch (event.key) {
            case 'Control':
            case 'Alt':
                break;
            case 'End':
                this.chars[this.chars.length - 1].focus();
                break;
            case 'Home':
                this.chars[0].focus();
                break;
            case 'Tab':
            case 'Enter':
                if (!((index === 0 && event.shiftKey) || (index === this.chars.length - 1 && !event.shiftKey))) {
                    this.processInput(index, event.shiftKey ? -1 : 1);
                    event.stopPropagation();
                }
                break;
            case 'Backspace':
                this.chars[index].value = '';
                this.processInput(index, -1);
                break;
            case 'ArrowLeft':
                this.processInput(index, -1);
                break;
            case 'ArrowRight':
                this.processInput(index, 1);
                break;
            case 'Unidentified':
                setTimeout(() => {
                    const key = this.chars[index].value.replace('-', '');
                    if (/^[a-zA-Z0-9-_ ]{1}$/.test(key)) {
                        this.chars[index].value = key;
                        this.processInput(index, 1);
                    } else {
                        this.chars[index].value = '';
                        this.processInput(index);
                    }
                }, 1);
                break;
            default:
                if (/^[a-zA-Z0-9-_ ]{1}$/.test(event.key)) {
                    this.chars[index].value = event.key;
                    this.processInput(index, 1);
                } else {
                    this.chars[index].value = '';
                    this.processInput(index);
                }
                break;
        }
    }

    private processInput(index: number, next?: number): void {
        if (this.chars) {
            if (next !== undefined && this.chars[index + next]) {
                this.chars[index + next].focus();
            } else {
                this.chars[index].select();
            }

            const vin = this.chars.map((x) => ((x.value || '').trim() === '' ? '-' : x.value[0])).join('');
            this.writeValue(vin);
        }
    }
}
