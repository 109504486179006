import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-step',
    template: `
        <ng-template #content>
            <ng-content />
        </ng-template>
        <ng-template #labelContent>
            <ng-content select="app-step-label" />
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent {
    completedChange = new BehaviorSubject<boolean>(undefined);
    editableChange = new BehaviorSubject<boolean>(false);

    @Input()
    set completed(completed: boolean) {
        this.completedChange.next(completed);
    }

    @Input()
    set editable(editable: boolean) {
        this.editableChange.next(editable);
    }

    @ViewChild('content', { static: true })
    content: TemplateRef<any>;

    @ViewChild('labelContent', { static: true })
    labelContent: TemplateRef<any>;
}
