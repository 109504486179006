import { Injectable } from '@angular/core';
import { guid } from '@app/function/guid';
import { WartungPosition } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailWartungPositionFormViewFactory extends FormViewModelBaseFactory<WartungPosition> {
    constructor(private readonly currencyFormatter: CurrencyFormatterService) {
        super();
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    }

    protected createField(model: WartungPosition, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Wartung.fields.Positionen.fields;
        switch (name) {
            case fields.ExternalId.name:
                return new ViewFormControl(model.externalId || guid());
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung);
            case fields.Kosten.name:
                return new ViewFormControl(model.kosten, {
                    formatter: this.currencyFormatter,
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
