<app-card [class.no-padding]="(viewport$ | async) !== viewport.Desktop">
    <div class="toggle">
        <app-toggle-group [value]="display$ | async" (valueChange)="onDisplayChange($event)">
            <app-toggle [value]="cgFestellungVersicherungsartEnum.Bauteileversichert">
                {{ name + '.bauteileVersichert' | translate }}
            </app-toggle>
            <app-toggle [value]="cgFestellungVersicherungsartEnum.Fahrzeugversichert">
                {{ name + '.fahrzeugVersichert' | translate }}
            </app-toggle>
        </app-toggle-group>
    </div>
    <ng-container [ngSwitch]="display$ | async">
        <ng-container *ngSwitchCase="cgFestellungVersicherungsartEnum.Bauteileversichert">
            <app-produkt-detail-cg-feststellung-baugruppen
                [produkt]="produkt"
                [featureFields]="fields"
                [positionen$]="positionenBauteilversichert"
                [name]="name"
                [selectedView]="display$ | async"
            />
        </ng-container>
        <ng-container *ngSwitchCase="cgFestellungVersicherungsartEnum.Fahrzeugversichert">
            <app-produkt-detail-cg-feststellung-baugruppen
                [produkt]="produkt"
                [featureFields]="fields"
                [positionen$]="positionenFahrzeugversichert"
                [name]="name"
                [selectedView]="display$ | async"
            />
        </ng-container>
    </ng-container>
</app-card>

<app-card>
    <app-produkt-detail-cg-feststellung-beurteilung [form]="form" [name]="name" [fields]="fields" />
</app-card>

<!--side panel-->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <h4>
        {{
            (name + '.erfassteBauteile' | translate) +
                '&nbsp;(' +
                (currentPositionen | async).controls.length +
                ')&nbsp;'
        }}
    </h4>
    <ng-container *ngTemplateOutlet="positionen" />
</app-side-panel>

<!--bottom sheet-->
<app-bottom-sheet
    *ngIf="(viewport$ | async) !== viewport.Desktop"
    [title]="
        (name + '.erfassteBauteile' | translate) + '&nbsp;(' + (currentPositionen | async).controls.length + ')&nbsp;'
    "
    [seamless]="true"
>
    <ng-container *ngTemplateOutlet="positionen" />
</app-bottom-sheet>

<!--notizen button-->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!--Templates-->
<ng-template #positionen let-data="data">
    <!--header-->
    <div class="header">
        <div class="group-header">
            <label>{{ name + '.' + 'baugruppe' | translate }}</label>
            <label>{{ name + '.' + 'bauteil' | translate }}</label>
        </div>
        <ng-container *ngFor="let field of bauteileFields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <ng-container *ngSwitchCase="'bilder'">
                    <div class="group-header">
                        <label>{{ name + '.bilder' | translate }}</label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'prueftext'">
                    <div class="group-header">
                        <label>{{ name + '.prueftext' | translate }}</label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <!--header of expansion panel-->
    <div
        class="drop-list"
        [class.mobile]="(viewport$ | async) !== viewport.Desktop"
        [class.disabled]="produkt.status !== statusEnum.Offen"
    >
        <app-expansion-panel-custom
            #panel
            *ngFor="let item of (currentPositionen | async).getRawValue(); index as index; trackBy: trackByField"
            [hasDragIndicator]="false"
            [tableColumn]="3"
        >
            <app-expansion-panel-header-custom>
                <ng-container *ngFor="let field of bauteileFields; trackBy: trackByField">
                    <ng-container [ngSwitch]="field.name || field.groupName">
                        <ng-container *ngSwitchCase="'baugruppe'">
                            <div class="group-header">
                                <label>{{ item.baugruppe }}</label>
                                <label>{{ item.bezeichnung }}</label>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'bilder'">
                            <div class="group-header">
                                <label>{{ item.bilder?.length ? item.bilder.length : 0 }}</label>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'prueftext'">
                            <div class="group-header">
                                <label>{{ item.prueftext | abbreviateText: 40 }}</label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </app-expansion-panel-header-custom>

            <!-- body of expansion panel-->
            <div class="button-group">
                <app-icon-button class="button edit-button" (action)="onRowOpenByIndex(index, item, panel, $event)">
                    <app-mat-icon [icon]="'edit'" />
                </app-icon-button>
                <app-icon-button class="button delete-button" (action)="onRowRemoveByIndex(index, panel, $event)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-icon-button>
            </div>
        </app-expansion-panel-custom>
    </div>
</ng-template>
