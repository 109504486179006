<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name || field.groupName">
        <!-- Hersteller -->
        <ng-container *ngSwitchCase="'hersteller'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
        <!-- ReifenTyp -->
        <ng-container *ngSwitchCase="'typ'">
            <ng-container *ngTemplateOutlet="select; context: { field: field, values: reifentyp }" />
        </ng-container>
        <!-- ReifenGroup -->
        <ng-container *ngSwitchCase="'reifenGroup'">
            <ng-container *ngTemplateOutlet="reifenGroup" />
        </ng-container>
        <!-- Profil / DOT Group -->
        <ng-container *ngSwitchCase="'profilDotGroup'">
            <ng-container *ngTemplateOutlet="profilDotGroup" />
        </ng-container>
        <!-- Profil -->
        <ng-container *ngSwitchCase="'profiltiefe'">
            <ng-container *ngTemplateOutlet="mm; context: { field: field }" />
        </ng-container>
        <ng-container *ngSwitchCase="'reifenDruck'">
            <ng-container *ngTemplateOutlet="number; context: { field: field }" />
        </ng-container>
        <!-- Zulaessig -->
        <ng-container *ngSwitchCase="'zustand'">
            <ng-container *ngTemplateOutlet="select; context: { field: field, values: zulaessig }" />
        </ng-container>
        <!-- Runflat -->
        <ng-container *ngSwitchCase="'runflat'">
            <ng-container *ngTemplateOutlet="slider; context: { field: field }" />
        </ng-container>
        <!-- RadFelge -->
        <ng-container *ngSwitchCase="'felge'">
            <ng-container *ngTemplateOutlet="select; context: { field: field, values: felge }" />
        </ng-container>
        <!-- Felgenhersteller -->
        <ng-container *ngSwitchCase="'felgenhersteller'">
            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
        </ng-container>
    </ng-container>
</ng-container>

<!-- ReifenGroup -->
<ng-template #reifenGroup>
    <app-form-group cols="6">
        <!-- Reifenbreite -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    reifenbreite;
                    context: {
                        field: {
                            name: 'reifenbreite',
                        },
                    }
                "
            />
        </app-form-field>
        <!-- Querschnitt -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    querschnitt;
                    context: {
                        field: {
                            name: 'querschnitt',
                        },
                    }
                "
            />
        </app-form-field>
        <!-- Bauart -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    select;
                    context: {
                        field: {
                            name: 'bauart',
                        },
                        values: bauart,
                    }
                "
            />
        </app-form-field>
        <!-- Felgengroesse -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    felgengroesse;
                    context: {
                        field: {
                            name: 'felgengroesse',
                        },
                    }
                "
            />
        </app-form-field>
        <!-- Tragfaehigkeit -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    tragfaehigkeit;
                    context: {
                        field: {
                            name: 'tragfaehigkeit',
                        },
                    }
                "
            />
        </app-form-field>
        <!-- Geschwindigkeitsindex -->
        <app-form-field>
            <ng-container
                *ngTemplateOutlet="
                    select;
                    context: {
                        field: {
                            name: 'geschwindigkeitsindex',
                        },
                        values: geschwindigkeitsindex,
                    }
                "
            />
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- Profil / Dot Group -->
<ng-template #profilDotGroup>
    <app-form-group cols="10">
        <!-- Profil -->
        <app-form-field colspan="4">
            <ng-container>
                <ng-container *ngIf="produktArt === ProduktArt.AlphaController">
                    <ng-template *ngTemplateOutlet="acProfilTiefe; context: { field: { name: 'profil' } }" />
                </ng-container>
                <ng-container *ngIf="produktArt !== ProduktArt.AlphaController">
                    <ng-template *ngTemplateOutlet="mm; context: { field: { name: 'profil' } }" />
                </ng-container>
            </ng-container>
        </app-form-field>
        <!-- DOT -->
        <app-form-field colspan="6">
            <ng-template *ngTemplateOutlet="number; context: { field: { name: 'dot' }, config: { maxLength: 4 } }" />
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- Templates -->
<ng-template #number let-field="field" let-config="config">
    <app-input
        [form]="form"
        [maxLength]="config?.maxLength"
        [onlyNumber]="true"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    />
</ng-template>
<ng-template #mm let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;mm</ng-container>
    </app-input>
</ng-template>
<ng-template #reifenbreite let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        autoTabLength="3"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #querschnitt let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        autoTabLength="2"
        autoTabIndexSkip="2"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #felgengroesse let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        autoTabLength="2"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #tragfaehigkeit let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        autoTabLength="3"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #text let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
</ng-template>
<ng-template #number let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    />
</ng-template>
<ng-template #select let-field="field" let-values="values">
    <app-select
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [values]="values"
        [valuePrefix]="name + '.'"
    />
</ng-template>
<ng-template #slider let-field="field">
    <app-slide-toggle [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" />
</ng-template>
