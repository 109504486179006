import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AdresseAnrede } from '@data/domain/schema/enum';
import { PRODUKT_ADRESSEN_ARTEN } from '@modules/produkt/config/produkt-adressen-arten.config';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { EnumValues } from '@shared/helper/values';

@Component({
    selector: 'app-produkt-detail-adressen-adresse',
    templateUrl: './produkt-detail-adressen-adresse.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAdressenAdresseComponent {
    trackByField = TrackBy.trackByField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    anrede = new EnumValues(AdresseAnrede, 0);
    arten = PRODUKT_ADRESSEN_ARTEN;

    isCustomAnrede(): boolean {
        const anredeFieldName = PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.fields.Anrede.name;
        return this.form?.get(anredeFieldName)?.value === AdresseAnrede.Freitext;
    }
}
