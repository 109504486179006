import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface AbschlussPostResponse {
    error?: string;
    message?: string;
}

export enum AbschlussWorkflowStatus {
    None = 'NONE',
    Aborted = 'ABORTED',
    Running = 'RUNNING',
    Succeeded = 'SUCCEEDED',
}

export enum AbschlussWorkflowStep {
    InvoiceTypeDecision = 'InvoiceTypeDecision',
    ProductDeliveryTypeDecision = 'ProductDeliveryTypeDecision',
    WaitForInvoice = 'WaitForInvoice',
    FinishProduct = 'FinishProduct',
    StartBilling = 'StartBilling',
    Abgebrochen = 'Abgebrochen',
}

export interface AbschlussGetResponse {
    execId: string;
    status: AbschlussWorkflowStatus;
    details: {
        step: AbschlussWorkflowStep;
    };
}

@Injectable({
    providedIn: 'root',
})
export class AbschlussService {
    private readonly restApiUrl: string;

    constructor(private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/abschluss`;
    }

    get(produktId: string): Observable<AbschlussGetResponse> {
        Assert.notNullOrUndefined(produktId, 'produktId');
        return this.httpClient.get<AbschlussGetResponse>(this.restApiUrl, {
            params: {
                produktId,
            },
        });
    }

    post(produktId: string): Observable<AbschlussPostResponse> {
        Assert.notNullOrUndefined(produktId, 'produktId');
        return this.httpClient.post<AbschlussPostResponse>(this.restApiUrl, {
            produktId,
        });
    }

    delete(produktId: string): Observable<AbschlussPostResponse> {
        Assert.notNullOrUndefined(produktId, 'produktId');
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: { produktId },
        };
        return this.httpClient.delete<AbschlussPostResponse>(this.restApiUrl, httpOptions);
    }
}
