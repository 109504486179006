<div class="template-wrapper" [class.medium-width]="(viewport$ | async) !== viewport.Mobile">
    <div class="title-container">
        <h1 class="title" *ngIf="settings.title" mat-dialog-title>
            {{ settings.title | translate }}
        </h1>
        <app-icon-button class="close" *ngIf="!settings.disableClose" mat-button tabindex="-1" mat-dialog-close>
            <app-mat-icon icon="close" />
        </app-icon-button>
    </div>

    <div class="template-content" mat-dialog-content>
        <ng-container *ngIf="template">
            <ng-container
                *ngTemplateOutlet="template; context: { data: settings.templateData }"
                (disableTemplateButton)="disableButton($event)"
            />
        </ng-container>
        <ng-container #container (disableTemplateButton)="disableButton($event)" />
    </div>
    <div mat-dialog-actions align="end">
        <ng-container *ngFor="let button of settings.buttons; trackBy: trackByKey; let i = index">
            <app-button [type]="button.type" [disabled]="isButtonDisabled(i)" (action)="onAction(button.title)">
                {{ button.title | translate }}
            </app-button>
        </ng-container>
    </div>
</div>
