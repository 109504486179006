import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-textbausteine-verwalten-list',
    templateUrl: './textbausteine-verwalten-list.component.html',
    styleUrls: ['./textbausteine-verwalten-list.component.scss'],
})
export class TextbausteineVerwaltenListComponent {
    @Input()
    feature = '';

    @Input()
    feld = '';
}
