import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { guid } from '@app/function/guid';
import {
    AusstattungDto,
    ExternalDataServiceResponseCode,
    ExternalDataServiceResponseType,
    FahrzeugDto,
    FahrzeugService,
    FahrzeugServiceRequest,
    FahrzeugServiceResponse,
} from '@data/api-gateway';
import {
    AbrechnungProvider,
    AbrechnungService,
    FahrzeugAntriebsart,
    FahrzeugAntriebstechnologie,
    FahrzeugAnzahlVorbesitzer,
    FahrzeugAnzahlVorbesitzerLegacy,
    FahrzeugArt,
    FahrzeugBauform,
    FahrzeugGetriebart,
    FahrzeugKraftradBauform,
    FahrzeugLaufleistungBeschreibung,
    FahrzeugLaufleistungEinheit,
    FahrzeugSchadstoffklasse,
    FahrzeugScheckheftGepflegt,
    ProduktArt,
} from '@data/domain/schema/enum';
import {
    Abrechnung,
    Ausstattung,
    AusstattungGruppe,
    AusstattungTeil,
    FahrzeugExternalServicesReference,
    Produkt,
} from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import {
    FeatureField,
    FeatureFieldArray,
    FeatureFieldGroup,
    FeatureFields,
    PRODUKT_CONFIG_FEATURES,
} from '@modules/produkt/config/produkt-config';
import { ProduktDetailFahrzeugLaufleistungenFormViewFactory } from '@modules/produkt/factory/fahrzeug/produkt-detail-fahrzeug-laufleistungen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { DatSearchService } from '@modules/produkt/service/dat-search.service';
import { ProduktDetailFahrzeugDialogService } from '@modules/produkt/service/produkt-detail-fahrzeug-dialog.service';
import { ProduktDetailFahrzeugscheinDialogService } from '@modules/produkt/service/produkt-detail-fahrzeugschein-dialog.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues, ObjectValues, Values } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, finalize, first, map, take, tap } from 'rxjs/operators';

interface ProduktSelectDialogData {
    identnummer: string;
    produkte: Produkt[];
}

interface FahrzeugServiceResultMessageObject {
    identifier: string;
    value: string;
}

@Component({
    selector: 'app-produkt-detail-fahrzeug-form',
    templateUrl: './produkt-detail-fahrzeug-form.component.html',
    styleUrls: ['./produkt-detail-fahrzeug-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailFahrzeugFormComponent implements OnInit, AfterViewInit, OnDestroy {
    trackByField = TrackBy.trackByField;
    trackByIndex = TrackBy.trackByIndex;

    now = new Date();
    fieldBemerkungen = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bemerkungen.name;

    @Input()
    laufleistungenVisible = false;

    @Input()
    produkt: Produkt;

    @Input()
    form: ViewFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    @Input()
    visibleFields: FeatureFields;

    @Output()
    ausstattungResolved = new EventEmitter<Ausstattung>();

    @Output()
    abrechnungResolved = new EventEmitter<Abrechnung>();

    @Input()
    fluid = true;

    @Input()
    manuellFahrzeugartDisabled = true;

    @Input()
    manuellBauformDisabled = true;

    @Input()
    vinDisabled = false;

    @Output()
    save = new EventEmitter<Produkt>();

    isAcProdukt = false;
    fahrzeugart = new EnumValues(FahrzeugArt);
    currentBauform$ = new BehaviorSubject(new EnumValues(FahrzeugBauform));
    bauform = new EnumValues(FahrzeugBauform);
    kraftradBauform = new EnumValues(FahrzeugKraftradBauform);
    fahrzeugLaufleistungEinheit = new EnumValues(FahrzeugLaufleistungEinheit);
    antriebsart = new EnumValues(FahrzeugAntriebsart);
    antriebstechnologie = new EnumValues(FahrzeugAntriebstechnologie);
    schadstoffklasse = new EnumValues(FahrzeugSchadstoffklasse);
    getriebeart = new EnumValues(FahrzeugGetriebart);
    scheckheftGepflegt = new EnumValues(FahrzeugScheckheftGepflegt);
    anzahlVorbesitzer = new EnumValues(FahrzeugAnzahlVorbesitzer);

    kbaRequestActiveChange = new BehaviorSubject<boolean>(false);
    datRequestActive$: BehaviorSubject<boolean>;
    datRequestEnabled$ = new BehaviorSubject<boolean>(true);

    get identnummer(): ViewFormControl<string> {
        const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
        return this.form.get(fields.Identnummer.name) as ViewFormControl<string>;
    }

    manuellFahrzeugartDisabled$ = new BehaviorSubject<boolean>(true);
    manuellBauformDisabled$ = new BehaviorSubject<boolean>(true);

    Buttontype = ButtonType;
    laufleistungenFields: FeatureFields;
    formLaufleistungen: ViewFormArray;
    formLaufleistungenFirst: ViewFormGroup = undefined;
    formLaufleistungenSecond: ViewFormGroup = undefined;
    buttonLaufleistungenIndex0Visible$ = new BehaviorSubject<boolean>(true);
    buttonLaufleistungenIndex1Visible$ = new BehaviorSubject<boolean>(false);
    laufleistungBeschreibung$ = new BehaviorSubject<Values>(new ObjectValues(FahrzeugLaufleistungBeschreibung));
    laufleistungenBeschreibungFirst$ = new BehaviorSubject<Values>(new ObjectValues(FahrzeugLaufleistungBeschreibung));
    laufleistungenBeschreibungSecond$ = new BehaviorSubject<Values>(new ObjectValues(FahrzeugLaufleistungBeschreibung));

    @ViewChild('bemerkungen')
    bemerkungenElement: TextbausteineComponent;

    @ViewChild('dialogConfirm', { static: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogConfirmTemplate: TemplateRef<any>;

    @ViewChild('dialogSelectProdukt', { static: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogSelectProduktTemplate: TemplateRef<any>;

    private produkteWithSameIdentnummer: Produkt[];
    private selectedProduktIndex = 0;
    private subscriptions: Subscription[] = [];

    constructor(
        private readonly datSearchService: DatSearchService,
        private readonly produktService: ProduktService,
        private readonly fahrzeugService: FahrzeugService,
        private readonly snackBarService: SnackBarService,
        private readonly fahrzeugDialogService: ProduktDetailFahrzeugDialogService,
        private readonly textbausteineService: TextbausteineService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly produktDetailFahrzeugLaufleistungenFormViewFactory: ProduktDetailFahrzeugLaufleistungenFormViewFactory,
        private readonly fahrzeugscheinDialogService: ProduktDetailFahrzeugscheinDialogService,
    ) {
        Assert.notNullOrUndefined(datSearchService, 'datSearchService');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(fahrzeugService, 'fahrzeugService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(fahrzeugDialogService, 'fahrzeugDialogService');
        Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(
            produktDetailFahrzeugLaufleistungenFormViewFactory,
            'produktDetailFahrzeugLaufleistungenFormViewFactory',
        );
        Assert.notNullOrUndefined(fahrzeugscheinDialogService, 'fahrzeugscheinDialogService');
    }

    ngOnInit(): void {
        this.manuellFahrzeugartDisabled$.next(this.manuellFahrzeugartDisabled);
        this.manuellBauformDisabled$.next(this.manuellBauformDisabled);
        this.datRequestActive$ = this.datSearchService.getDatRequestActive();
        this.laufleistungenFields = (
            this.fields.find(
                (x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistungen.name,
            ) as FeatureFieldArray
        ).fields;
        this.formLaufleistungen = this.form.get(
            PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistungen.name,
        ) as ViewFormArray;

        this.datRequestEnabled$.next(
            !(this.hasKalkulation() || this.hasWerteBewertung() || this.hasVtiWerteBewertung()),
        );

        this.initLaufleistung();

        this.isAcProdukt = this.produkt?.art === ProduktArt.AlphaController;

        this.subscriptions.push(this.getFahrzeugartSubscription());
    }

    ngAfterViewInit(): void {
        if (this.produkt && this.form.get(this.fieldBemerkungen) && this.produkt.fahrzeug.bemerkungen === null) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.name,
                this.fieldBemerkungen,
                this.produkt.art,
                this.form,
                this.bemerkungenElement,
            );
        }
        if (this.produkt) {
            this.anzahlVorbesitzer = this.getAnzahlVorbesitzerEnum(this.produkt.fahrzeug.anzahlVorbesitzer);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    onVinSearchClick(event: MouseEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        event.stopPropagation();
        this.handleIdentnummer();
    }

    setProdukteWithSameIdentnummer(produkte: Produkt[]): void {
        this.produkteWithSameIdentnummer = produkte;
    }

    onVinCaptureClick(event: MouseEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        event.stopPropagation();

        this.fahrzeugscheinDialogService
            .open('fahrzeugschein.vin')
            .pipe(take(1))
            .subscribe((vin) => {
                if (vin) {
                    this.identnummer.setRawValue(vin);
                    this.handleIdentnummer();
                }
            });
    }

    onKbaSearchClick(event: MouseEvent): void {
        Assert.notNullOrUndefined(event, 'event');
        event.stopPropagation();

        const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
        const typCode = (this.form.get(fields.TypCode.name) as ViewFormControl<string>).getRawValue();
        const herstellerCode = (this.form.get(fields.HerstellerCode.name) as ViewFormControl<string>).getRawValue();

        const kba = `${herstellerCode}/${typCode}`;
        this.kbaRequestActiveChange.next(true);
        this.executeFahrzeugSearch({
            kba,
        })
            .pipe(
                finalize(() => this.kbaRequestActiveChange.next(false)),
                take(1),
            )
            .subscribe();
    }

    onChangeBauformGroup(value: number, name: string): void {
        Assert.notNullOrUndefined(name, 'name');

        const fahrzeugartControlName = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart.name;
        const bauformControlName = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform.name;

        if (name === fahrzeugartControlName) {
            if (value === FahrzeugArt.Manuell) {
                this.manuellFahrzeugartDisabled$.next(false);
                return;
            }
            this.manuellFahrzeugartDisabled$.next(true);
        }

        if (name === bauformControlName) {
            if (value === FahrzeugBauform.Manuell) {
                this.manuellBauformDisabled$.next(false);
                return;
            }
            this.manuellBauformDisabled$.next(true);
        }
    }

    setSelectedProduktIndex(index: number): void {
        this.selectedProduktIndex = index;
    }

    onAddLaufleistungen(index: number): void {
        Assert.notNullOrUndefined(index, 'index');
        this.formLaufleistungen.controls.push(this.createLaufleistung(index));
        this.formLaufleistungen.updateValueAndValidity();
        if (index === 0) {
            this.buttonLaufleistungenIndex0Visible$.next(false);
            this.buttonLaufleistungenIndex1Visible$.next(true);
        } else if (index === 1) {
            this.buttonLaufleistungenIndex1Visible$.next(false);
        }
        this.initLaufleistungBeschreibungen();
    }

    onRemoveLaufleistungen(index: number): void {
        Assert.notNullOrUndefined(index, 'index');
        this.formLaufleistungen.controls.splice(index, 1);
        this.formLaufleistungen.updateValueAndValidity();
        if (this.formLaufleistungen.controls.length === 0) {
            this.buttonLaufleistungenIndex0Visible$.next(true);
            this.buttonLaufleistungenIndex1Visible$.next(false);
        } else if (this.formLaufleistungen.controls.length === 1) {
            this.buttonLaufleistungenIndex0Visible$.next(false);
            this.buttonLaufleistungenIndex1Visible$.next(true);
        }
        this.initLaufleistungBeschreibungen();
    }

    onBeschreibungChange($event: MatSelect): void {
        this.form.patchValue({
            laufleistungBeschreibung: $event.value,
        });
        this.form.updateValueAndValidity();
        this.initLaufleistungBeschreibungen();
    }

    onLaufleistungenBeschreibungChange($event: MatSelect, index: number): void {
        if (index === 0) {
            this.form.patchValue({
                laufleistungen: [
                    {
                        wert: this.form.get('laufleistungen').value[0].wert,
                        einheit: this.form.get('laufleistungen').value[0].einheit,
                        beschreibung: $event.value,
                    },
                ],
            });
            this.form.updateValueAndValidity();
            this.initLaufleistungBeschreibungen();
        }
    }

    private handleIdentnummer(): void {
        // this.produktService.get(this.identnummer.getRawValue()).pipe(first()).subscribe(
        //   produkte => {
        //     this.setProdukteWithSameIdentnummer(produkte.filter(value => value.id !== this.produkt.id)
        //       .sort((produktA, produktB) => Date.parse(produktB.erstelltAm) - Date.parse(produktA.erstelltAm))
        //       .slice(0, this.showMaxProdukte));
        //
        //     if (this.produkteWithSameIdentnummer.length > 0) {
        //       this.openSelectProduktDialog();
        //     } else {
        //       this.searchFahrzeugWithConfirm();
        //     }
        //   }, _err => {
        //     this.searchFahrzeugWithConfirm();
        //   }
        // );
        this.searchFahrzeugWithConfirm();
    }

    private searchFahrzeugWithConfirm(): void {
        if (
            this.form.get('fahrzeugExternalServiceReference')?.value &&
            (this.form.get('fahrzeugExternalServiceReference')?.value as FahrzeugExternalServicesReference)?.source !==
                null
        ) {
            this.openConfirmWerteDialog();
        } else {
            this.callExecuteFahrzeugSearch();
        }
    }

    private executeFahrzeugSearch(request: FahrzeugServiceRequest): Observable<void> {
        return this.fahrzeugService.getVehicleIdentification(request).pipe(
            tap((response) => this.handleResponse(response, request)),
            map(() => null),
            catchError(() => {
                this.snackBarService.error(
                    'fahrzeug.externalDataServiceResponseCode.error',
                    this.createMessageObject(request),
                );
                return of(null);
            }),
        );
    }

    private handleResponse(response: FahrzeugServiceResponse, request: FahrzeugServiceRequest): void {
        if (!response || response.responseCode === ExternalDataServiceResponseCode.Error) {
            this.snackBarService.error(
                'fahrzeug.externalDataServiceResponseCode.error',
                this.createMessageObject(request),
            );
            return;
        }
        switch (response.responseCode) {
            case ExternalDataServiceResponseCode.SuccessWithoutResult:
                this.snackBarService.warning(
                    'fahrzeug.externalDataServiceResponseCode.successWithoutResult',
                    this.createMessageObject(request),
                );
                break;
            case ExternalDataServiceResponseCode.ErrorCouldNotIdentifiedByKba:
                this.snackBarService.warning(
                    'fahrzeug.externalDataServiceResponseCode.errorCouldNotIdentifiedByKba',
                    this.createMessageObject(request),
                );
                break;
            case ExternalDataServiceResponseCode.SuccessWithIdentification:
                this.fahrzeugDialogService
                    .open('fahrzeug.produktDetailFahrzeugDialogService.title', response)
                    .pipe(take(1))
                    .subscribe({
                        next: (result) => {
                            if (result) {
                                this.snackBarService.success(
                                    'fahrzeug.externalDataServiceResponseCode.success',
                                    this.createMessageObject(request),
                                );
                                this.handleSuccessResponse(result);
                            }
                        },
                        error: () =>
                            this.snackBarService.error(
                                'fahrzeug.externalDataServiceResponseCode.error',
                                this.createMessageObject(request),
                            ),
                    });
                break;
            default:
                this.snackBarService.success(
                    'fahrzeug.externalDataServiceResponseCode.success',
                    this.createMessageObject(request),
                );
                this.handleSuccessResponse(response);
                break;
        }
    }

    private handleSuccessResponse(response: FahrzeugServiceResponse): void {
        if (!response) {
            console.error('handleSuccessResponse - response is null or undefined');
            return;
        }

        this.handleAusstattungResponse(response.ausstattung);
        this.handleFahrzeugResponse(response.fahrzeug, response.responseType);

        if (response.responseType === ExternalDataServiceResponseType.DatVinAbfrage) {
            this.handleAbrechnung();
        }
    }

    private handleAusstattungResponse(ausstattungDto: AusstattungDto): void {
        const ausstattung: Ausstattung = {
            id: null,
            gruppen: (ausstattungDto.gruppen || []).map((x) => {
                const gruppe: AusstattungGruppe = {
                    name: x.name,
                    sonderausstattung: x.sonderausstattung,
                    teile: (x.teile || []).map((y) => {
                        const teil: AusstattungTeil = {
                            name: y.herstellerName,
                            vorhanden: y.vorhanden,
                            externalServiceId: y.externalServiceId,
                        };
                        return teil;
                    }),
                };
                return gruppe;
            }),
        };
        this.ausstattungResolved.emit(ausstattung);
    }

    private handleFahrzeugResponse(fahrzeug: FahrzeugDto, responseType: ExternalDataServiceResponseType): void {
        const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;

        if (responseType === ExternalDataServiceResponseType.DatVinAbfrage) {
            this.form.setControlValue(fields.VinAbfrageErfolgt.name, true);
        }

        this.fields.forEach((field: FeatureField & FeatureFieldGroup) => {
            const name = field.name || field.groupName;

            switch (name) {
                case fields.FahrzeugExternalServiceReference.name:
                    this.form.setControlValue(
                        fields.FahrzeugExternalServiceReference.name,
                        fahrzeug.externalServicesReference,
                    );
                    break;
                case 'codeGroup':
                    this.form.setControlValue(fields.TypCode.name, fahrzeug.typCode);
                    this.form.setControlValue(fields.HerstellerCode.name, fahrzeug.herstellerCode);
                    break;
                case 'bauformGroup':
                    this.form.setControlValue(fields.Fahrzeugart.name, fahrzeug.art);
                    this.form.setControlValue(fields.Bauform.name, fahrzeug.bauform);
                    break;
                case fields.Hersteller.name:
                    this.form.setControlValue(name, fahrzeug.hersteller);
                    break;
                case fields.DatECode.name:
                    this.form.setControlValue(name, fahrzeug.datECode);
                    break;
                case fields.Modell.name:
                    this.form.setControlValue(name, fahrzeug.modell);
                    break;
                case fields.Untertyp.name:
                    this.form.setControlValue(name, fahrzeug.untertyp);
                    break;
                case fields.Lackcode.name:
                    this.form.setControlValue(name, fahrzeug.lackcode);
                    break;
                case fields.Innenausstattung.name:
                    this.form.setControlValue(name, fahrzeug.innenausstattung);
                    break;
                case fields.Polsterfarbe.name:
                    this.form.setControlValue(name, fahrzeug.polsterfarbe);
                    break;
                case fields.Polstermaterial.name:
                    this.form.setControlValue(name, fahrzeug.polstermaterial);
                    break;
                case fields.Antriebstechnologie.name:
                    this.form.setControlValue(name, fahrzeug.antriebstechnologie);
                    break;
                case fields.Antriebsart.name:
                    this.form.setControlValue(name, fahrzeug.antriebsart);
                    break;
                case fields.Schadstoffklasse.name:
                    this.form.setControlValue(name, fahrzeug.schadstoffklasse);
                    break;
                case 'motorGroup':
                    this.form.setControlValue(fields.Hubraum.name, fahrzeug.hubraum);
                    this.form.setControlValue(fields.Leistung.name, fahrzeug.leistung);
                    this.form.setControlValue(fields.Zylinder.name, fahrzeug.zylinder);
                    break;
                case 'getriebeGroup':
                    this.form.setControlValue(fields.Getriebeart.name, fahrzeug.getriebeart);
                    this.form.setControlValue(fields.AnzahlGaenge.name, fahrzeug.anzahlGaenge);
                    break;
                case 'gewichtGroup':
                    this.form.setControlValue(fields.Leergewicht.name, fahrzeug.leergewicht);
                    this.form.setControlValue(fields.ZulaessigesGesamtgewicht.name, fahrzeug.zulaessigesGesamtgewicht);
                    break;
                case 'anzahlGroup':
                    this.form.setControlValue(fields.AnzahlTueren.name, fahrzeug.anzahlTueren);
                    this.form.setControlValue(fields.AnzahlSitze.name, fahrzeug.anzahlSitze);
                    break;
                case 'masseGroup':
                    this.form.setControlValue(fields.MasseLaenge.name, fahrzeug.masseLaenge);
                    this.form.setControlValue(fields.MasseBreite.name, fahrzeug.masseBreite);
                    this.form.setControlValue(fields.MasseHoehe.name, fahrzeug.masseHoehe);
                    break;
                default:
                    break;
            }
        });
    }

    private createMessageObject(request: FahrzeugServiceRequest): FahrzeugServiceResultMessageObject {
        return {
            identifier: request.kba ? 'KBA' : 'Identnummer',
            value: request.kba ? request.kba : request.vin,
        };
    }

    private setLetzteZulassung(value: Moment): void {
        if (value && !this.form.get('letzteZulassung').value) {
            this.form.patchValue({ letzteZulassung: value });
        }
    }

    private CreateAbrechnung(): Abrechnung {
        return {
            id: guid(),
            datum: moment.utc().toISOString(),
            externalProvider: AbrechnungProvider.Dat,
            externalService: AbrechnungService.Vin,
            identifier: this.identnummer.getRawValue(),
        };
    }

    private handleAbrechnung(): void {
        const abrechnung = this.CreateAbrechnung();
        return this.abrechnungResolved.emit(abrechnung);
    }

    private openConfirmWerteDialog(): void {
        const title = 'DAT Abfrage';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogConfirmTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                    this.callExecuteFahrzeugSearch();
                }
            });
    }

    private openSelectProduktDialog(): void {
        const title = `${this.name}.selectProduktTitle`;
        const buttons = [
            this.templateDialogService.getCopyButtonSetting(),
            this.templateDialogService.getSkipButtonSetting(),
        ];

        const produkte = this.produkteWithSameIdentnummer;
        const identnummer = this.form.get('identnummer').value;
        const data: ProduktSelectDialogData = { identnummer, produkte };

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogSelectProduktTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getCopyButtonSetting().title) {
                    this.copySelectedProdukt();
                } else {
                    this.searchFahrzeugWithConfirm();
                }
            });
    }

    private callExecuteFahrzeugSearch(): void {
        this.datSearchService.getDatRequestActive().next(true);
        this.executeFahrzeugSearch({
            produktId: this.produkt.id,
            kennzeichen: this.produkt.fahrzeug?.kennzeichen,
            vin: this.identnummer.getRawValue(),
        })
            .pipe(
                finalize(() => this.datSearchService.getDatRequestActive().next(false)),
                take(1),
            )
            .subscribe();
    }

    private getAnzahlVorbesitzerEnum(anzahlVorbesitzer: number): EnumValues {
        if (anzahlVorbesitzer > 0 && anzahlVorbesitzer < 10) {
            return new EnumValues(FahrzeugAnzahlVorbesitzerLegacy);
        } else {
            return new EnumValues(FahrzeugAnzahlVorbesitzer);
        }
    }

    private copySelectedProdukt(): void {
        this.produktService
            .getDuplikat(
                this.produkteWithSameIdentnummer[this.selectedProduktIndex].id,
                this.produkt.id,
                this.produkt.art,
            )
            .pipe(first())
            .subscribe({
                next: (value) => {
                    this.produkt = value;
                    this.save.emit(this.produkt);
                    window.location.reload();
                },
                error: (error) => {
                    console.error(error);
                    this.snackBarService.error('Ausgewähltes Produkt konnte nicht übernommen werden.');
                },
            });
    }

    private createLaufleistung(index: number): ViewFormGroup {
        const laufleistungenControl = this.form?.get('laufleistungen');
        const laufleistungBeschreibungControl = this.form?.get('laufleistungBeschreibung');

        if (index === 0) {
            const values = new ObjectValues(FahrzeugLaufleistungBeschreibung);
            values.keys = values.keys.filter(
                (beschreibungen) => !beschreibungen.includes(laufleistungBeschreibungControl?.value),
            );
            return this.createLaufleistungFormGroup(values);
        }

        if (index === 1) {
            const values = new ObjectValues(FahrzeugLaufleistungBeschreibung);
            if (this.form) {
                this.form.updateValueAndValidity();
            }

            values.keys = values.keys.filter(
                (beschreibungen) => !beschreibungen.includes(laufleistungBeschreibungControl?.value),
            );
            values.keys = values.keys.filter(
                (beschreibungen) => !beschreibungen.includes(laufleistungenControl?.value[0]?.beschreibung),
            );
            return this.createLaufleistungFormGroup(values);
        }
    }

    private createLaufleistungFormGroup(values: ObjectValues): ViewFormGroup {
        return this.produktDetailFahrzeugLaufleistungenFormViewFactory.create(
            {
                wert: null,
                einheit: FahrzeugLaufleistungEinheit.Km,
                beschreibung: values.keys[0],
            },
            this.laufleistungenFields,
        );
    }

    private hasKalkulation(): boolean {
        return this.produkt?.kalkulation?.dossierId > 0;
    }

    private hasWerteBewertung(): boolean {
        return +this.produkt?.werte?.externalServicesReferenceId > 0;
    }

    private hasVtiWerteBewertung(): boolean {
        return +this.produkt?.vtiWerte?.externalServicesReferenceId > 0;
    }

    private initLaufleistung(): void {
        if (this.laufleistungenVisible) {
            if (this.produkt?.fahrzeug && this.produkt.fahrzeug.laufleistungen === null) {
                this.produkt.fahrzeug.laufleistungen = [];
            }
            this.buttonLaufleistungenIndex0Visible$.next(this.produkt?.fahrzeug?.laufleistungen?.length === 0);
            this.buttonLaufleistungenIndex1Visible$.next(this.produkt?.fahrzeug?.laufleistungen?.length === 1);

            this.initLaufleistungBeschreibungen();
            this.initLaufleistungBeschreibungSubscriptions();
        }
    }

    private initLaufleistungBeschreibungen(): void {
        const newLaufleistungBeschreibung = new ObjectValues(FahrzeugLaufleistungBeschreibung);
        const newLaufleistungenBeschreibungFirst = new ObjectValues(FahrzeugLaufleistungBeschreibung);
        const newLaufleistungenBeschreibungSecond = new ObjectValues(FahrzeugLaufleistungBeschreibung);
        const laufleistungenControl = this.form?.get('laufleistungen');
        const laufleistungBeschreibungControl = this.form.get('laufleistungBeschreibung');

        if (!laufleistungenControl?.value || laufleistungenControl.value.length <= 0) {
            this.laufleistungBeschreibung$.next(newLaufleistungBeschreibung);
            return;
        }

        if (laufleistungenControl.value.length === 1) {
            newLaufleistungBeschreibung.keys = newLaufleistungBeschreibung.keys.filter(
                (beschreibungen) => !beschreibungen.includes(laufleistungenControl.value[0].beschreibung),
            );
            this.laufleistungBeschreibung$.next(newLaufleistungBeschreibung);

            newLaufleistungenBeschreibungFirst.keys = newLaufleistungenBeschreibungFirst.keys.filter(
                (beschreibungen) => !beschreibungen.includes(laufleistungBeschreibungControl.value),
            );
            this.laufleistungenBeschreibungFirst$.next(newLaufleistungenBeschreibungFirst);
        }

        if (laufleistungenControl.value.length === 2) {
            newLaufleistungBeschreibung.keys = newLaufleistungBeschreibung.keys.filter((beschreibungen) =>
                beschreibungen.includes(laufleistungBeschreibungControl.value),
            );
            this.laufleistungBeschreibung$.next(newLaufleistungBeschreibung);

            newLaufleistungenBeschreibungFirst.keys = newLaufleistungenBeschreibungFirst.keys.filter((beschreibungen) =>
                beschreibungen.includes(laufleistungenControl.value[0].beschreibung),
            );
            this.laufleistungenBeschreibungFirst$.next(newLaufleistungenBeschreibungFirst);

            newLaufleistungenBeschreibungSecond.keys = newLaufleistungenBeschreibungSecond.keys.filter(
                (beschreibungen) => beschreibungen.includes(laufleistungenControl.value[1].beschreibung),
            );
            this.laufleistungenBeschreibungSecond$.next(newLaufleistungenBeschreibungSecond);
        }
    }

    private initLaufleistungBeschreibungSubscriptions(): void {
        const laufleistungenControl = this.form?.get('laufleistungen');
        const laufleistungBeschreibungControl = this.form?.get('laufleistungBeschreibung');

        this.subscriptions.push(
            laufleistungBeschreibungControl?.valueChanges.pipe().subscribe((_laufleistungBeschreibung) => {
                if (!laufleistungenControl?.value || laufleistungenControl.value.length <= 0) {
                    return;
                }
                this.initLaufleistungBeschreibungen();
            }),
        );
    }

    private getFahrzeugartSubscription(): Subscription {
        return this.form?.get('fahrzeugart')?.valueChanges?.subscribe((art) => {
            if (art === FahrzeugArt.Kraftrad) {
                this.form?.patchValue([{ bauform: '' }]);
                this.currentBauform$?.next(this.kraftradBauform);
            } else {
                this.currentBauform$?.next(this.bauform);
            }
        });
    }
}
