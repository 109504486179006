import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnDestroy {
    @Input()
    userClaims$: Observable<any>;

    loggingOut = false;

    private signOutSubscription: Subscription;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {
        Assert.notNullOrUndefined(authService, 'authService');
        Assert.notNullOrUndefined(router, 'router');
    }

    ngOnDestroy(): void {
        if (this.signOutSubscription) {
            this.signOutSubscription.unsubscribe();
        }
    }

    onLogoutClick(): void {
        this.loggingOut = true;
        this.signOutSubscription = this.authService.startSignout().subscribe(() => {
            this.loggingOut = false;
        });
    }

    onLoginClick(): void {
        this.router.navigate(['/']);
    }
}
