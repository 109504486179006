import { UntypedFormGroup } from '@angular/forms';
import { AbstractViewFormControl, CalculatedViewFormControl, ViewFormControl } from './view-form-control';

export class ViewFormGroup extends UntypedFormGroup {
    getRawValue(): any {
        const groupValue = {};
        Object.keys(this.controls).forEach((key) => {
            const control = this.controls[key] as AbstractViewFormControl;
            if (control instanceof CalculatedViewFormControl) {
                return;
            }

            let value = control.getRawValue();
            if (value === undefined || value === '') {
                value = null;
            }
            groupValue[key] = value;
        });
        return groupValue;
    }

    setRawValue(value: any): void {
        Object.keys(this.controls).forEach((key) => {
            const control = this.controls[key] as AbstractViewFormControl;
            control.setRawValue(value[key]);
        });
    }

    setControlValue<TValue>(name: string, value: TValue): void {
        const control = this.get(name) as ViewFormControl<TValue>;
        if (control) {
            control.setRawValue(value);
        }
    }
}
