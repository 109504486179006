import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    AbschlussDownloadResponse,
    AbschlussDownloadService,
    AbschlussGetResponse,
    AbschlussRechnungsArt,
    AbschlussRechnungsService,
    AbschlussService,
    AbschlussVersandArt,
    AbschlussVersandService,
    AbschlussWorkflowStatus,
    AbschlussWorkflowStep,
} from '@data/api-gateway';
import { ProduktRechnungsArt, ProduktStatus, ProduktVersandArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject, Observable, PartialObserver, Subscription, iif, of, throwError } from 'rxjs';
import { concatMap, delay, finalize, mergeMap, retryWhen, take, tap } from 'rxjs/operators';

const STATUS_INTERVAL = 1000;
const STATUS_INTERVAL_FACTOR = [3, 2, 1, 2, 3, 5, 5, 10, 10, 15, 30];

@Component({
    selector: 'app-produkt-detail-abschluss-workflow-simple',
    templateUrl: './produkt-detail-abschluss-workflow-simple.component.html',
    styleUrls: ['./produkt-detail-abschluss-workflow-simple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAbschlussWorkflowSimpleComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    trackByField = TrackBy.trackByField;

    @Input()
    name = 'abschluss';

    @Input()
    produkt: Produkt;

    @ViewChild(StepperComponent)
    stepper: StepperComponent;

    @ViewChild('vtiAbschlussResetDialog', { static: true })
    vtiAbschlussResetTemplate: TemplateRef<any>;

    @Output()
    statusChangedEvent = new EventEmitter<ProduktStatus>();

    statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

    rechnungsArt = new EnumValues(ProduktRechnungsArt);
    rechnungsArtChanged = new BehaviorSubject<ProduktRechnungsArt>(undefined);

    versandArt = new EnumValues(ProduktVersandArt);
    versandArtChanged = new BehaviorSubject<ProduktVersandArt>(undefined);

    loading$ = new BehaviorSubject(false);
    downloadUrl$ = new BehaviorSubject<string>(undefined);
    protected readonly ButtonType = ButtonType;

    constructor(
        private readonly abschlussService: AbschlussService,
        private readonly abschlussRechnungsService: AbschlussRechnungsService,
        private readonly abschlussVersandService: AbschlussVersandService,
        private readonly abschlussDownloadService: AbschlussDownloadService,
        private readonly snackbarService: SnackBarService,
        private readonly produktService: ProduktService,
        private readonly templateDialogService: TemplateDialogService,
    ) {
        Assert.notNullOrUndefined(abschlussService, 'abschlussService');
        Assert.notNullOrUndefined(abschlussRechnungsService, 'abschlussRechnungsService');
        Assert.notNullOrUndefined(abschlussVersandService, 'abschlussVersandService');
        Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
        Assert.notNullOrUndefined(snackbarService, 'snackbarService');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    }

    ngOnInit(): void {
        if (this.produkt?.status) {
            this.statusChanged$.next(this.produkt.status);
        }
        this.subscriptions.push(this.checkStatus().subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    onProduktCloseClick(): void {
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.close.failed`);
        this.loading$.next(true);
        this.subscriptions.push(this.checkStatus(this.abschlussService.post(this.produkt.id)).subscribe(observer));
    }

    onProduktOpenClick(): void {
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.reopen.failed`);
        this.subscriptions.push(
            this.checkStatus(
                this.abschlussRechnungsService.post(this.produkt.id, AbschlussRechnungsArt.Aborted),
            ).subscribe(observer),
        );
    }

    private postDefaultRechnungsart() {
        const art = AbschlussRechnungsArt.Without;
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.rechnung.failed`);
        this.subscriptions.push(
            this.checkStatus(this.abschlussRechnungsService.post(this.produkt.id, art)).subscribe(observer),
        );
    }

    private postDefaultVersandArt() {
        const art = AbschlussVersandArt.NoDelivery;
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.versand.failed`);
        this.subscriptions.push(
            this.checkStatus(this.abschlussVersandService.post(this.produkt.id, art)).subscribe(observer),
        );
    }

    onDownloadClick(): void {
        const observer = this.getActionObserver<AbschlussDownloadResponse>(
            (response) => this.onDownloadResponse(response),
            `${this.name}.workflow.download.failed`,
        );
        this.loading$.next(true);
        this.subscriptions.push(
            this.abschlussDownloadService
                .get(this.produkt.id)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(observer),
        );
    }

    onOpenDownloadClick(): void {
        const { value } = this.downloadUrl$;
        window.open(value, '_blank');
    }

    onAbschlussResetClick(): void {
        this.openAbschlussResetConfirmDialog();
    }

    private openAbschlussResetConfirmDialog() {
        const title = `${this.name}.workflow.reset.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        this.subscriptions.push(
            this.templateDialogService
                .openTemplate(title, buttons, this.vtiAbschlussResetTemplate)
                .pipe(take(1))
                .subscribe((result) => {
                    if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                        this.resetAbschluss();
                    }
                }),
        );
    }

    private resetAbschluss() {
        const observer = this.getActionObserver(() => window.location.reload(), `${this.name}.workflow.reset.failed`);
        this.subscriptions.push(this.abschlussService.delete(this.produkt.id).subscribe(observer));
    }

    private nextStep(count: number = 1): void {
        setTimeout(() => this.onNextStep(count), 1);
    }

    private updateProdukt(): Observable<Produkt> {
        return this.produktService.getInfoById(this.produkt.id).pipe(tap((produkt) => this.onUpdateProdukt(produkt)));
    }

    private getActionObserver<T>(action: (value: T) => void, error: string): PartialObserver<T> {
        return {
            next: (value) => action(value),
            error: (ex) => {
                console.warn('An unexpected error occured while checking the status', ex);
                this.snackbarService.error(error);
            },
        };
    }

    private getCheckStatusObserver(error: string): PartialObserver<boolean> {
        return {
            next: (success: boolean) => {
                if (!success) {
                    this.snackbarService.error(error);
                }
            },
            error: (ex) => {
                console.warn('An unexpected error occured while checking the status', ex);
                this.snackbarService.error(error);
            },
        };
    }

    private checkStatus(start?: Observable<any>): Observable<boolean | Produkt> {
        if (this.produkt?.status === ProduktStatus.Beendet) {
            if (this.produkt?.rechnungsArt) {
                this.rechnungsArtChanged.next(this.produkt.rechnungsArt);
            }
            if (this.produkt?.versandArt) {
                this.versandArtChanged.next(this.produkt.versandArt);
            }
            this.nextStep(6);
            return of(true);
        }

        return (start ? start : of(null)).pipe(
            mergeMap(() =>
                this.waitUntilStep([
                    AbschlussWorkflowStep.Abgebrochen,
                    AbschlussWorkflowStep.InvoiceTypeDecision,
                    AbschlussWorkflowStep.ProductDeliveryTypeDecision,
                    AbschlussWorkflowStep.WaitForInvoice,
                    AbschlussWorkflowStep.StartBilling,
                    AbschlussWorkflowStep.FinishProduct,
                ]),
            ),
            mergeMap((reached) => iif(() => reached, this.updateProdukt(), of(null))),
            tap(() => this.nextStep(6)),
        );
    }

    private waitUntilStep(requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
        return this.abschlussService.get(this.produkt.id).pipe(
            mergeMap((response) => this.onStatusResponse(response, requiredSteps)),
            retryWhen((errors) => errors.pipe(concatMap((error, retry) => this.onStatusError(error, retry)))),
        );
    }

    private onNextStep(count: number): void {
        if (this.stepper) {
            for (let i = 0; i < count; ++i) {
                this.stepper.next();
            }
        }
    }

    private onUpdateProdukt(produkt: Produkt): void {
        this.statusChanged$.next(produkt.status);
        this.statusChangedEvent.emit(produkt.status);
        this.rechnungsArtChanged.next(produkt.rechnungsArt);
        this.versandArtChanged.next(produkt.versandArt);
    }

    private onDownloadResponse(response: AbschlussDownloadResponse): void {
        if (response?.url?.length) {
            window.open(response.url, '_blank');
            this.downloadUrl$.next(response.url);
        }
    }

    private onStatusError(error: any, retry: number): Observable<void> {
        const retryDelay = (STATUS_INTERVAL_FACTOR[retry] || 30) * STATUS_INTERVAL;
        return of(error).pipe(delay(retryDelay));
    }

    private onStatusResponse(
        response: AbschlussGetResponse,
        requiredSteps: AbschlussWorkflowStep[],
    ): Observable<boolean> {
        if (!response) {
            return throwError(() => new Error('could not retrieve status.'));
        }

        const { status } = response;
        if (status === AbschlussWorkflowStatus.None || status === AbschlussWorkflowStatus.Aborted) {
            return of(true);
        }
        if (!response.details || !response.details.step) {
            return throwError(() => new Error('could not retrieve details.'));
        }

        const { details } = response;
        if (status !== AbschlussWorkflowStatus.Running && status !== AbschlussWorkflowStatus.Succeeded) {
            return of(false);
        }

        const { step } = details;
        if (!requiredSteps.includes(step)) {
            return throwError(() => new Error('step not reached yet.'));
        }

        switch (step) {
            case AbschlussWorkflowStep.InvoiceTypeDecision:
                this.postDefaultRechnungsart();
                break;
            case AbschlussWorkflowStep.ProductDeliveryTypeDecision:
                this.postDefaultVersandArt();
                break;
            case AbschlussWorkflowStep.FinishProduct:
                this.loading$.next(false);
                break;
            case AbschlussWorkflowStep.StartBilling:
                this.loading$.next(false);
                break;
        }

        return of(true);
    }
}
