<div class="main-content">
    <app-card>
        <app-action-list
            [values]="bezeichnung"
            [valuePrefix]="name + '.'"
            [searchable]="true"
            (action)="onFehlteilSelect($event)"
            (submit)="onFehlteilSubmit($event)"
        />
    </app-card>
</div>

<!--side panel-->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <label>{{ (name + '.fehlteile' | translate) + '&nbsp;(' + positionen.controls.length + ')' }}</label>
    <ng-container *ngTemplateOutlet="fehlteileKosten" />
</app-side-panel>

<!--bottom sheet-->
<app-bottom-sheet
    *ngIf="(viewport$ | async) !== viewport.Desktop"
    [title]="(name + '.fehlteile' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'"
    [value]="sums$ | async"
    [seamless]="true"
>
    <ng-container *ngTemplateOutlet="fehlteileKosten" />
</app-bottom-sheet>

<!--notizen button-->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!--templates-->
<ng-template #fehlteileKosten>
    <app-form-table
        [rows]="rows$ | async"
        [disabled]="produkt.status !== statusEnum.Offen"
        (rowOpen)="onRowOpen($event)"
        (rowRemove)="onRowRemove($event)"
        (rowMove)="onRowMove($event)"
    >
        <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'bezeichnung'">
                    <app-form-table-column
                        [name]="field.name"
                        [displayName]="name + '.' + field.name"
                        [fullWidth]="true"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'preis'">
                    <app-form-table-column
                        [name]="field.name"
                        [displayName]="name + '.' + field.name"
                        type="currency"
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-form-table>
</ng-template>

<ng-template #dialog let-data="data">
    <app-produkt-detail-fehlteile-position [form]="data.form" [name]="name" [fields]="data.fields" />
</ng-template>
