<h3>{{ name + '.upload.title' | translate }}</h3>

<app-file-gallery
    *ngIf="produkt.art !== produktArt.VtiTooling"
    [fileIds]="[]"
    [dialogTitle]="name + '.upload.addfile'"
    [showCamera]="false"
    [showFolder]="true"
    [accept]="acceptPDF"
    [fileValidationType]="fileValidationType"
    [multiple]="multiple"
    [disabled]="disabled$ | async"
    [resetable]="isFileResetable.bind(this)"
    (add)="onFileAdd(control, $event)"
    (delete)="onFileDelete(control, $event)"
    (update)="onFileUpdate(control, $event)"
    (reset)="onFileReset(control, $event)"
/>

<div class="file-row" *ngFor="let anhang of anhaenge$ | async; let index = index">
    <ng-container *ngIf="anhang">
        <app-button class="file-button" [disabled]="disabled$ | async">
            <app-mat-icon icon="picture_as_pdf" size="2rem" />
        </app-button>
        <label class="file-label">{{ anhang.bezeichnung }}</label>
        <button
            class="delete-button"
            *ngIf="produkt.art !== produktArt.VtiTooling"
            [disabled]="disabled$ | async"
            (click)="onDelete(index, anhang)"
            type="button"
            mat-icon-button
        >
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
        </button>
    </ng-container>
</div>
