import { PRODUKT_CONFIG_FEATURES, ProduktFeatureFieldsMap } from './produkt-config';

// THIS FIELDS NEEDS TO BE MARKED ATLEAST AS REQUIRED IN THE FORMVIEWFACTORY

export const PRODUKT_UEBERSICHT_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_RAEDER_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_VORSCHADEN_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_LACKMESSUNG_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_SCHADEN_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_FESTSTELLUNGEN_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_KALKULATION_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Fahrzeugart,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bauform,
    ],
};

export const PRODUKT_WERTE_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Identnummer,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Erstzulassung,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistung,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.LaufleistungEinheit,
    ],
};

export const PRODUKT_RESTWERTPROGNOSE_REQUIRED: ProduktFeatureFieldsMap = {
    [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: [
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Identnummer,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Erstzulassung,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistung,
        PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.LaufleistungEinheit,
    ],
};
