<ng-container [formGroup]="form">
    <div
        class="gtue-information input-component-wrapper"
        [ngClass]="
            ((control.touchedChanges | async) &&
                !(control.validChanges | async) &&
                (control.hasError('required') || control.hasError('pattern'))) ||
            control.hasError('minlength')
                ? 'error'
                : ''
        "
    >
        <h4 class="mat-caption text-field-label" *ngIf="displayName">
            {{ (displayName | translate) + (required ? ' *' : '') }}
        </h4>
        <mat-form-field
            [class.read_only]="readonly"
            [class.mat-form-field-disabled]="disabled"
            appearance="fill"
            floatLabel="never"
        >
            <app-vin
                *ngIf="type === 'vin'"
                [placeholder]="displayName | translate"
                [id]="name"
                [formControlName]="name"
                [vinDisabled]="vinDisabled"
            />
            <input
                *ngIf="type !== 'vin'"
                [type]="type"
                [readonly]="disabled || readonly"
                [class.input-disabled]="disabled"
                [placeholder]="placeholder ? placeholder : (displayName | translate)"
                [formControlName]="name"
                [name]="name"
                [id]="id ? id : name"
                [attr.inputmode]="inputmode"
                [autocomplete]="inputmode === 'text' ? '' : 'off'"
                [appOnlyNumber]="onlyNumber"
                [maxLength]="maxLength"
                matInput
            />
            <span [class.readonly]="readonly" matSuffix>
                <ng-content select="[appSuffix]" />
            </span>
        </mat-form-field>
        <mat-error>
            {{
                control.hasError('pattern')
                    ? ('control.pattern' | translate: { name: (displayName | translate) })
                    : null
            }}
            {{
                control.hasError('required')
                    ? ('control.required' | translate: { name: (displayName | translate) })
                    : null
            }}
            {{
                control.hasError('minlength')
                    ? ('control.minlength' | translate: { minlength: control?.errors?.minlength?.requiredLength })
                    : null
            }}
        </mat-error>
    </div>
</ng-container>
