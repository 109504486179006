import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
    DatKalkulationDto,
    DatKalkulationService,
    KalkulationStatus,
} from '@data/api-gateway/service/dat-kalkulation.service';
import { AnhangId } from '@data/domain/schema/enum';
import { KalkulationInput, Produkt } from '@data/domain/schema/type';
import { ProduktAnhaengeService } from '@data/domain/service/feature/produkt-anhaenge.service';
import { ProduktKalkulationService } from '@data/domain/service/feature/produkt-kalkulation.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { LoadingSpinnerGlobalService } from '@shared/service/loading-spinner-global.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailKalkulationCanActivate {
    constructor(
        private readonly datKalkulationService: DatKalkulationService,
        private readonly produktService: ProduktDetailResolveService,
        private readonly produktAnhaengeService: ProduktAnhaengeService,
        private readonly produktKalkulationService: ProduktKalkulationService,
        private readonly snackBarService: SnackBarService,
        private readonly loadingSpinnerGlobalService: LoadingSpinnerGlobalService,
        readonly router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        Assert.notNullOrUndefined(route, 'route');
        const produktId = route.paramMap.get('id') ?? route.parent.paramMap.get('id');
        const dossierId = route.paramMap.get('dossierId') ?? route.parent.paramMap.get('dossierId');
        const subject = new Subject<boolean>();
        this.loadingSpinnerGlobalService.set(true);
        this.datKalkulationService
            .get(+dossierId, produktId)
            .pipe(first())
            .subscribe(
                (response) => {
                    if (response && response.kalkulation) {
                        this.produktService
                            .resolve(produktId, false)
                            .pipe(first())
                            .subscribe((produkt) => {
                                const input = this.mapToKalkulationInput(
                                    produktId,
                                    JSON.parse(JSON.stringify(response.kalkulation)) as DatKalkulationDto,
                                );
                                this.produktKalkulationService.save(produktId, input);
                                this.saveKalkulationInAnhaenge(produkt);
                                this.datKalkulationService.setKalkulationStatus(produktId, KalkulationStatus.SUCCEEDED);
                                this.loadingSpinnerGlobalService.set(false);
                                window.close();
                                this.navigateToBaseRoute(produktId);
                                subject.next(true);
                            });
                    } else {
                        this.loadingSpinnerGlobalService.set(false);
                        this.snackBarService.error('Kalkulation konnte nicht mit EM synchronisiert werden');
                        this.navigateToBaseRoute(produktId);
                        subject.next(true);
                        console.error('Response /dat/kalkulation GET is null ', response);
                    }
                },
                (error) => {
                    this.loadingSpinnerGlobalService.set(false);
                    this.snackBarService.error('Kalkulation konnte nicht mit EM synchronisiert werden');
                    console.error(error);
                    this.navigateToBaseRoute(produktId);
                    subject.next(true);
                },
            );
        return subject.asObservable();
    }

    protected navigateToBaseRoute(produktId: string): void {
        this.router.navigate(['produkt', 'detail', produktId, 'kalkulation']);
    }

    private mapToKalkulationInput(produktId: string, datKalkulationDto: DatKalkulationDto): KalkulationInput {
        return {
            id: produktId,
            bezugsdatum: datKalkulationDto.bezugsdatum,
            dossierId: datKalkulationDto.dossierId,
            reparaturkostenNetto: datKalkulationDto.reparaturkostenNetto,
            reparaturkostenBrutto: datKalkulationDto.reparaturkostenBrutto,
            dokument: `${produktId}/kalkulation`,
        };
    }

    private saveKalkulationInAnhaenge(produkt: Produkt): void {
        this.produktAnhaengeService.saveAnhang(produkt.id, AnhangId.Kalkulation, {
            id: AnhangId.Kalkulation,
            bezeichnung: 'kalkulation.pdf',
            quelle: `${produkt.id}/kalkulation`,
        });
    }
}
