<app-card>
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'schluesselFahrzeug'">
                <ng-container
                    *ngTemplateOutlet="
                        slider;
                        context: { field: field, selections: schluesselFahrzeugAuswahl, default: 2 }
                    "
                />
            </ng-container>
            <ng-container *ngSwitchCase="'schluesselAHK'">
                <ng-container
                    *ngTemplateOutlet="slider; context: { field: field, selections: schluesselAHKAuswahl, default: 0 }"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'fernbedienungStandheizung'">
                <ng-container
                    *ngTemplateOutlet="
                        slider;
                        context: { field: field, selections: fernbedienungStandheizungAuswahl, default: 0 }
                    "
                />
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>
<app-card>
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.arrayName">
            <ng-container *ngSwitchCase="'positionen'">
                <app-extendable-list
                    [form]="form"
                    [name]="field.arrayName"
                    [displayName]="name + '.' + field.arrayName"
                    [values]="positionenValues"
                    [hasImages]="true"
                    (add)="onPositionenAdd($event)"
                    (remove)="onPositionenRemove($event)"
                    (addImage)="onAddBilderClick($event)"
                    property="bezeichnung"
                />
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<ng-template #slider let-field="field" let-selections="selections" let-default="default">
    <app-button-group
        class="button-group"
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [selections]="selections"
        [default]="default"
    />
</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
    <app-produkt-detail-unterlagen-bilder [form]="data.form" [name]="name" />
</ng-template>
