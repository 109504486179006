import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
} from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { TabComponent } from '../tab/tab.component';

@Component({
    selector: 'app-tab-group',
    templateUrl: './tab-group.component.html',
    styleUrls: ['./tab-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent {
    trackByInstance = TrackBy.trackByInstance;

    @ContentChildren(TabComponent)
    tabs: QueryList<TabComponent>;

    @Input()
    selectedIndex = 0;

    @Output()
    selectedIndexChange = new EventEmitter<number>();

    onSelectedIndexChange(selectedIndex: number): void {
        Assert.notNullOrUndefined(selectedIndex, 'selectedIndex');
        this.selectedIndex = selectedIndex;
        this.selectedIndexChange.emit(this.selectedIndex);
    }

    onLeft(): void {
        if (this.selectedIndex > 0) {
            this.selectedIndex--;
        }
    }

    onRight(): void {
        if (this.selectedIndex < this.tabs.length) {
            this.selectedIndex++;
        }
    }
}
