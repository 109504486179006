<div class="main-content">
    <app-slide-toggle
        *ngIf="produkt.art === ProduktArt.CarGarantie"
        [form]="form"
        [name]="'fahrzeugVorhanden'"
        [displayName]="name + '.fahrzeugVorhanden.' + (fahrzeugVorhanden$ | async)"
        (change)="fahrzeugVorhanden$.next($event.checked)"
    />

    <div class="first-row">
        <div class="toggle">
            <app-toggle-group [value]="uploadArt$ | async" (valueChange)="onUploadArtChange($event)">
                <app-toggle [value]="fileGalleryAddType.Capture">
                    <app-mat-icon icon="photo_camera" />
                </app-toggle>
                <app-toggle [value]="fileGalleryAddType.File">
                    <app-mat-icon icon="folder" />
                </app-toggle>
            </app-toggle-group>
        </div>
        <app-button class="delete-all-button" [disabled]="!(hasImages$ | async)" (action)="onDeleteAll()">
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" size="2rem" />
        </app-button>
    </div>

    <div class="image-container">
        <app-image [class.opacity50]="!(fahrzeugVorhanden$ | async)" [src]="vehicleFileSrc" />

        <div class="button-container">
            <ng-container *ngFor="let field of fields; trackBy: trackByField">
                <ng-container [ngSwitch]="field.name">
                    <ng-container *ngSwitchCase="'vorneLinks'">
                        <ng-container *ngIf="fahrzeugVorhanden$ | async">
                            <ng-container *ngTemplateOutlet="buttonFixed; context: { field: field }" />
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'vorneRechts'">
                        <ng-container *ngIf="produkt.art !== ProduktArt.CarGarantie">
                            <ng-container *ngTemplateOutlet="buttonFixed; context: { field: field }" />
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'hintenLinks'">
                        <ng-container *ngIf="produkt.art !== ProduktArt.CarGarantie">
                            <ng-container *ngTemplateOutlet="buttonFixed; context: { field: field }" />
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'hintenRechts'">
                        <ng-container *ngIf="fahrzeugVorhanden$ | async">
                            <ng-container *ngTemplateOutlet="buttonFixed; context: { field: field }" />
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'innenraum'">
                        <ng-container *ngIf="produkt.art !== ProduktArt.CarGarantie">
                            <ng-container *ngTemplateOutlet="buttonFixed; context: { field: field }" />
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let field of fields; trackBy: trackByField">
                <ng-container [ngSwitch]="field.arrayName">
                    <ng-container *ngSwitchCase="'sonstiges'">
                        <div class="drop-list" (cdkDropListDropped)="drop($event)" cdkDropList>
                            <ng-container *ngTemplateOutlet="buttonFixedSonstiges; context: { field: field }" />
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<!-- side panel -->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <h3>{{ 'uebersicht.bilder' | translate }}</h3>
    <ng-container *ngTemplateOutlet="uebersichtBilder" />
</app-side-panel>

<!-- bottom sheet -->
<app-bottom-sheet *ngIf="(viewport$ | async) !== viewport.Desktop" [title]="'uebersicht.bilder' | translate">
    <ng-container *ngTemplateOutlet="uebersichtBilder" />
</app-bottom-sheet>

<!-- notizen button -->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!-- templates -->
<ng-template #uebersichtBilder>
    <div [class.disabled]="produkt.status !== statusEnum.Offen">
        <ng-container [ngSwitch]="count$ | async">
            <div *ngSwitchCase="0">{{ 'uebersicht.keineBilder' | translate }}</div>
            <ng-container *ngSwitchDefault>
                <app-file-gallery
                    class="deckblatt-img"
                    *ngIf="this.form.get('deckblatt').value"
                    [title]="name + '.' + 'deckblatt'"
                    [fileIds]="[this.form.get('deckblatt').value]"
                    [showCamera]="false"
                    [showFolder]="false"
                    [resetable]="isFileResetable.bind(this)"
                />
                <ng-container *ngFor="let field of fields; trackBy: trackByField">
                    <ng-container *ngIf="field.name !== 'deckblatt' && form.get(field.name) as control">
                        <ng-container *ngIf="(control.valueChanges | async) || control.value as files">
                            <app-file-gallery
                                *ngIf="files.length > 0"
                                [title]="name + '.' + field.name"
                                [fileIds]="files"
                                [buttonLabel]="'Deckblatt Bild'"
                                [showCamera]="false"
                                [showFolder]="false"
                                [resetable]="isFileResetable.bind(this)"
                                (buttonClick)="onDeckblattSelect($event)"
                                (delete)="onFileDelete(control, $event)"
                                (update)="onFileUpdate(control, $event)"
                                (reset)="onFileReset(control, $event)"
                            />
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let field of fields; trackBy: trackByField">
                    <ng-container *ngIf="form.get(field.arrayName) as sonstiges">
                        <div
                            class="drop-list"
                            [class.mobile]="(viewport$ | async) !== viewport.Desktop"
                            (cdkDropListDropped)="drop($event)"
                            cdkDropList
                        >
                            <ng-container *ngFor="let sonstige of sonstiges.controls; trackBy: trackById; index as id">
                                <ng-container *ngIf="sonstige as control">
                                    <ng-container *ngIf="control.get('bilder').value as files">
                                        <app-expansion-panel-custom
                                            class="drag-container"
                                            #panel
                                            [expanded]="true"
                                            cdkDrag
                                        >
                                            <app-expansion-panel-header-custom>
                                                <app-textbausteine
                                                    [form]="this.getSonstigesFormGroup(id)"
                                                    [name]="sonstigeFields.Bezeichnungen.name"
                                                    [displayName]="'Bezeichnung'"
                                                    [feature]="'Uebersicht'"
                                                    [produktArt]="this.produkt.art"
                                                    [multiple]="false"
                                                />
                                                <app-icon-button class="button drag-button" cdkDragHandle>
                                                    <app-mat-icon [icon]="'drag_indicator'" />
                                                </app-icon-button>
                                            </app-expansion-panel-header-custom>

                                            <app-file-gallery
                                                *ngIf="files.length > 0"
                                                [fileIds]="files"
                                                [showCamera]="false"
                                                [showFolder]="false"
                                                [resetable]="isFileResetable.bind(this)"
                                                (delete)="onFileDeleteSonstiges(control, $event, id)"
                                                (update)="onSonstigeBilderUpdate(control, $event)"
                                                (reset)="onFileReset(control, $event)"
                                            />
                                        </app-expansion-panel-custom>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- Field Templates -->
<ng-template #button let-field="field">
    <ng-container *ngIf="form.get(field.name) as control">
        <app-fab
            [count]="((control.valueChanges | async) || control.value).length"
            (click)="onIndicatorClick(control, field.name)"
        >
            <div class="icon">
                <app-mat-icon [icon]="icon$ | async" />
                <div class="text">
                    {{ name + '.' + field.name + '.short' | translate }}
                </div>
            </div>
        </app-fab>
    </ng-container>
</ng-template>

<ng-template #buttonSonstiges let-field="field">
    <ng-container *ngIf="form.get(field.arrayName) as control">
        <app-fab
            [count]="((control.valueChanges | async) || control.value).length"
            (click)="onSonstigesClick(field.arrayName)"
        >
            <div class="icon">
                <app-mat-icon [icon]="iconSonstiges" />
                <div class="text">{{ name + '.' + field.arrayName + '.short' | translate }}</div>
            </div>
        </app-fab>
    </ng-container>
</ng-template>

<ng-template #buttonFixed let-field="field">
    <div [class]="'button ' + (field.name | lowercase)">
        <ng-container *ngTemplateOutlet="button; context: { field: field }" />
    </div>
</ng-template>

<ng-template #buttonFixedSonstiges let-field="field">
    <div [class]="'button ' + (field.arrayName | lowercase)">
        <ng-container *ngTemplateOutlet="buttonSonstiges; context: { field: field }" />
    </div>
</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
    <app-produkt-detail-uebersicht-sonstiges
        [form]="data?.form"
        [name]="name"
        [fields]="data?.fields"
        [produktArt]="data?.produktArt"
        [uploadArt$]="uploadArt$"
    />
</ng-template>

<!-- Delete all dialog -->
<ng-template #dialogDeleteAll>
    <mat-label>{{ 'uebersicht.deleteAll.text' | translate }}</mat-label>
</ng-template>
