import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-expansion-panel-custom',
    templateUrl: './expansion-panel-custom.component.html',
    styleUrls: ['./expansion-panel-custom.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelCustomComponent {
    @Input()
    expanded = false;

    @Input()
    tableColumn = 4;

    @ViewChild('panel', { static: true })
    panel: MatExpansionPanel;
    close(): void {
        this.panel?.close();
    }
}
