import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abbreviateText',
    pure: false,
})
export class AbbreviateTextPipe implements PipeTransform {
    transform(text: string, numberOfChars: number = 20): string {
        if (!text?.length || !text?.substring) {
            return '';
        }
        if (text.length <= numberOfChars) {
            return text;
        }
        return `${text.substring(0, numberOfChars).trimEnd()}...`;
    }
}
