<ng-container>
    <app-action-list
        [values]="baugruppenListe"
        [searchable]="this.selectedView === cgFestellungVersicherungsartEnum.Fahrzeugversichert"
        (action)="onBaugruppeClick($event)"
        (submit)="onBaugruppeAdd($event)"
    />
</ng-container>

<ng-template #bauteilSelect let-data="data">
    <app-extendable-radio-list
        [form]="data.form"
        [name]="'bezeichnung'"
        [displayName]="'cgFeststellung.bauteil'"
        [values]="data.values"
        [enableExtendedList]="false"
        [keys]="(viewport$ | async) === viewport.Mobile ? maxColumnEntries.MOBILE : maxColumnEntries.TABLET"
        [columnCount]="(viewport$ | async) !== viewport.Mobile ? columnCountEnum.THREE : columnCountEnum.ONE"
    />
</ng-template>

<ng-template #bauteilDetail let-data="data">
    <ng-container *ngFor="let field of data.fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'bezeichnung'">
                <ng-container *ngIf="this.selectedView === cgFestellungVersicherungsartEnum.Fahrzeugversichert">
                    <app-input [form]="data.form" [name]="field.name" [displayName]="name + '.bauteil'" type="text" />
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'prueftext'">
                <app-textarea
                    [form]="data.form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    type="text"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'bilder'">
                <ng-container *ngIf="data.form.get('bilder') as control">
                    <ng-container *ngIf="(control.valueChanges | async) || control.value as files">
                        <label>{{ name + '.bilder' | translate }}</label>
                        <app-file-gallery
                            [fileIds]="files"
                            [dialogTitle]="name + '.addfile'"
                            [showCamera]="true"
                            [showFolder]="true"
                            [resetable]="isFileResetable.bind(this)"
                            (add)="onFileAdd(control, $event)"
                            (delete)="onFileDelete(control, $event)"
                            (update)="onFileUpdate(control, $event)"
                            (reset)="onFileReset(control, $event)"
                        />
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>
