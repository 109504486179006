<div
    class="content-wrapper"
    (touchstart)="onTouchStart($event)"
    (touchend)="onTouchEnd($event)"
    (touchmove)="onTouchMove($event)"
>
    <div class="readonly" *ngIf="disabled$ | async"></div>
    <div #content>
        <ng-content />
    </div>
</div>
