import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, AuthUserClaims } from '@app/service/auth.service';
import {
    BenutzerEinstellungen,
    BueroEinstellungen,
    EinstellungenService,
} from '@data/api-gateway/service/einstellungen.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
    selector: 'app-einstellungen',
    templateUrl: './einstellungen.component.html',
    styleUrls: ['./einstellungen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinstellungenComponent implements OnInit, OnDestroy {
    claims$: Observable<AuthUserClaims>;
    saving$ = new BehaviorSubject(false);
    loggingOut = false;

    protected readonly ButtonType = ButtonType;

    private logoutSubscription: Subscription;

    constructor(
        private readonly auth: AuthService,
        private readonly einstellungenService: EinstellungenService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(auth, 'auth');
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }

    ngOnInit(): void {
        this.claims$ = this.auth.getClaims();
    }

    ngOnDestroy(): void {
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
    }

    onBueroSave(einstellungen: BueroEinstellungen): void {
        this.saving$.next(true);
        this.einstellungenService
            .putBuero(einstellungen)
            .pipe(
                finalize(() => this.saving$.next(false)),
                take(1),
            )
            .subscribe({
                next: () => this.snackBarService.info('einstellungen.buero.putBueroEinstellungen.success'),
                error: (error) => this.snackBarService.error('einstellungen.buero.putBueroEinstellungen.error', error),
            });
    }

    onBenutzerSave(einstellungen: BenutzerEinstellungen): void {
        einstellungen = this.trimCustomTexts(einstellungen);
        this.saving$.next(true);
        this.einstellungenService
            .putBenutzer(einstellungen)
            .pipe(
                finalize(() => this.saving$.next(false)),
                take(1),
            )
            .subscribe({
                next: () => this.snackBarService.info('einstellungen.benutzer.putBenutzerEinstellungen.success'),
                error: (error) =>
                    this.snackBarService.error('einstellungen.benutzer.putBenutzerEinstellungen.error', error),
            });
    }

    onLogoutClick(): void {
        this.loggingOut = true;
        this.logoutSubscription = this.auth.startSignout().subscribe(() => {
            this.loggingOut = false;
        });
    }

    private trimCustomTexts(einstellungen: BenutzerEinstellungen): BenutzerEinstellungen {
        const einstellungenTrimmed = einstellungen;
        einstellungenTrimmed.customTextBewertung = einstellungen.customTextBewertung.trim();
        einstellungenTrimmed.customTextRuecknahmebewertung = einstellungen.customTextRuecknahmebewertung.trim();
        einstellungenTrimmed.customTextZustandsbericht = einstellungen.customTextZustandsbericht.trim();
        einstellungenTrimmed.customDescriptionBewertung = einstellungen.customDescriptionBewertung.trim();
        einstellungenTrimmed.customDescriptionRuecknahmebewertung =
            einstellungen.customDescriptionRuecknahmebewertung.trim();
        einstellungenTrimmed.customDescriptionZustandsbericht = einstellungen.customDescriptionZustandsbericht.trim();

        return einstellungenTrimmed;
    }
}
