import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveWartung, SaveWartungData } from '../../graphql/mutations';
import { Produkt, Wartung, WartungInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktWartungService extends ProduktFeatureService<Wartung, WartungInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Wartung): WartungInput {
        const input: WartungInput = {
            ...feature,
            positionen: (feature.positionen || []).map((position, index) => ({
                ...position,
                id: `${produktId}:${index}`,
            })),
            id: produktId,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveWartung;
    }

    protected getSaveVariables(input: WartungInput): any {
        return {
            wartung: input,
        };
    }

    protected getSaveOptimisticResponse(input: WartungInput): any {
        return {
            saveWartung: {
                ...input,
                __typename: 'Wartung',
                positionen: (input.positionen || []).map((position) => ({
                    ...position,
                    __typename: 'WartungPosition',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveWartungData>): Wartung {
        return response.data.saveWartung;
    }

    protected update(produkt: Produkt, feature: Wartung): void {
        produkt.wartung = {
            ...produkt.wartung,
            ...feature,
        };
    }
}
