import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailAbschlussEntwurfFormViewFactory extends FormViewBaseFactory {
    protected createField(name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Abschluss.fields;
        const acFields = PRODUKT_CONFIG_FEATURES.AcAbschluss.fields;
        switch (name) {
            case fields.Adresse.name:
                return new ViewFormControl('', {
                    validators: Validators.required,
                });
            case acFields.AcVersendet.name:
                return new ViewFormControl('');
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
