import { Injectable } from '@angular/core';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { ProduktConfig } from '../config/produkt-config';
import { ProduktConfigService } from './produkt-config.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktConfigResolveService {
    private config: ProduktConfig;

    constructor(
        private readonly produktConfigService: ProduktConfigService,
        private readonly produktService: ProduktService,
    ) {
        Assert.notNullOrUndefined(produktConfigService, 'produktConfigService');
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    get(): ProduktConfig {
        return this.config;
    }

    resolve(produktId: string): Observable<ProduktConfig> {
        return this.produktService.getById(produktId, false).pipe(
            mergeMap((produkt) => {
                if (!produkt) {
                    return throwError(() => new Error('produkt not found.'));
                } else {
                    const { art } = produkt;
                    const subart = 0; // TODO: Add Subart
                    return of(this.produktConfigService.get(art, subart));
                }
            }),
            tap((config) => (this.config = config)),
        );
    }
}
