import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { of, PartialObserver } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { ProduktDetailFileService } from './produkt-detail-file.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFileFieldService {
    constructor(private readonly produktDetailFileService: ProduktDetailFileService) {
        Assert.notNullOrUndefined(produktDetailFileService, 'produktDetailFileService');
    }

    isUpdateable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.produktDetailFileService.isUpdated(fileId);
    }

    add(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
        if (!files || !field) {
            return;
        }
        const observer = this.createAddObserver(field);
        of(files)
            .pipe(
                take(1),
                filter((x) => !!x && x.length > 0),
                mergeMap((x) => x.map((file) => this.produktDetailFileService.put(file))),
                mergeMap((id) => id),
            )
            .subscribe(observer);
    }

    remove(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        const observer = this.createDeleteObserver(field, fileId);
        this.produktDetailFileService.delete(fileId).pipe(take(1)).subscribe(observer);
    }

    update(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        const { id, data } = event;
        const observer = this.createUpdateObserver(field, id);
        this.produktDetailFileService.update(id, data).pipe(take(1)).subscribe(observer);
    }

    reset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        const observer = this.createUpdateObserver(field, fileId);
        this.produktDetailFileService.reset(fileId).pipe(take(1)).subscribe(observer);
    }

    private createAddObserver(field: UntypedFormControl): PartialObserver<string> {
        const observer: PartialObserver<string> = {
            next: (id) => {
                const newValue = [...field.value, id];
                field.setValue(newValue);
            },
        };
        return observer;
    }

    private createDeleteObserver(field: UntypedFormControl, fileId: string): PartialObserver<void> {
        const observer: PartialObserver<void> = {
            next: () => {
                if (field?.value) {
                    const index = field.value?.indexOf(fileId);
                    if (index !== -1) {
                        const newValue = [...field.value];
                        newValue.splice(index, 1);
                        field.setValue(newValue);
                    }
                }
            },
        };
        return observer;
    }

    private createUpdateObserver(field: UntypedFormControl, fileId: string): PartialObserver<string> {
        const { value } = field;
        const observer: PartialObserver<string> = {
            next: (newId) => {
                if (value) {
                    const index = value.indexOf(fileId);
                    if (index !== -1) {
                        const newValue = [...value];
                        newValue[index] = newId;
                        field.setValue(newValue);
                    }
                }
            },
        };
        return observer;
    }
}
