<ng-container *ngIf="result$ | async as result; else loading">
    <ng-container *ngIf="result.file; else reload">
        <div [class.clickable]="result.thumb" (click)="onFileClick(result)">
            <app-image *ngIf="result.image" [file]="result.file" />
        </div>
    </ng-container>
    <ng-template #reload>
        <button (click)="onReloadClick()" mat-button>
            {{ 'file.reload' | translate }}
        </button>
    </ng-template>
</ng-container>
<ng-template #loading>
    <app-progress-spinner />
</ng-template>
