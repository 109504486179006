<input #fileInput [accept]="accept" (change)="onSelected(fileInput)" hidden type="file" />
<ng-container [formGroup]="form">
    <mat-form-field>
        <div class="img-container" *ngIf="(control.valueChanges | async) || control.value as value">
            <img class="img" [src]="img ? img : value" alt="" />
            <app-button class="delete-button" (action)="onClear()">
                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
            </app-button>
        </div>
        <input [formControlName]="name" matInput />
        <span matSuffix>
            <button class="select-button" (click)="onSelect(fileInput)" mat-button>
                <mat-icon>image_search</mat-icon>
            </button>
        </span>
        <mat-error
            *ngIf="!(control.validChanges | async) && (control.hasError('required') || control.hasError('pattern'))"
        >
            {{
                control.hasError('pattern')
                    ? ('control.pattern' | translate: { name: (displayName | translate) })
                    : ('control.required' | translate: { name: (displayName | translate) })
            }}
        </mat-error>
    </mat-form-field>
</ng-container>
