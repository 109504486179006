import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Observable, from } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SwService {
    constructor(private readonly sw: SwUpdate) {}

    versionUpdates(): Observable<VersionEvent> {
        return this.sw.versionUpdates;
    }

    activateUpdate(): Observable<boolean> {
        return from(this.sw.activateUpdate());
    }
}
