import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deleteAnhang, saveAnhang } from '../../graphql/mutations';
import { Anhang, AnhangInput, Produkt } from '../../schema/type';

@Injectable({
    providedIn: 'root',
})
export class ProduktAnhaengeService {
    constructor(
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
        private readonly produktService: ProduktService,
    ) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    saveAnhang(produktId: string, anhangId: string, anhang: Anhang): Observable<boolean> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(anhangId, 'anhaengeId');
        Assert.notNullOrUndefined(anhang, 'anhang');

        const input = this.mapInput(anhang);
        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate({
            mutation: saveAnhang,
            variables: {
                id: produktId,
                anhaengeId: anhangId,
                anhang: input,
            },
            optimisticResponse: {
                saveAnhang: true,
            },
        });
        return from(mutatePromise).pipe(map((response) => response.data.saveAnhang));
    }

    deleteAnhang(produktId: string, produktInput: Produkt, anhangId: string): Observable<boolean> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(anhangId, 'anhangId');

        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate({
            mutation: deleteAnhang,
            variables: {
                id: produktId,
                anhaengeId: anhangId,
            },
            optimisticResponse: {
                deleteAnhang: true,
            },

            update: (store) =>
                this.produktService.updateGetByIdCache(store, produktId, (produkt) =>
                    this.delete(produktInput, produktId, anhangId),
                ),
        });
        return from(mutatePromise).pipe(map((response) => response.data.deleteAdresse));
    }

    private delete(produkt: Produkt, produktId: string, anhangId: string): Produkt {
        const anhangIndex = produkt.anhaenge.anhaenge.findIndex((x) => x.id === anhangId);
        if (anhangIndex !== -1) {
            produkt.anhaenge.anhaenge.splice(anhangIndex, 1);
        }
        return produkt;
    }

    private mapInput(anhang: Anhang): AnhangInput {
        const input: AnhangInput = {
            ...anhang,
        };
        return input;
    }
}
