import { Injectable } from '@angular/core';
export const DEFAULT_FORMAT = 'DD.MM.YYYY';
export const MONTH_YEAR_FORMAT = 'MM.YYYY';

@Injectable({
    providedIn: 'root',
})
export class DateFormatService {
    locale = 'de-DE';
    format = DEFAULT_FORMAT;
}
