<div class="content-wrapper">
    <h3>{{ 'produkt.uebersicht' | translate }}</h3>

    <nav [tabPanel]="tabPanel" mat-tab-nav-bar color="accent">
        <a
            class="link"
            #rla="routerLinkActive"
            [id]="'produkt-uebersicht-gtue-em'"
            [routerLink]="'gtue-em'"
            [active]="rla.isActive"
            mat-tab-link
            routerLinkActive
        >
            {{ 'produkt.uebersicht.gtue-em' | translate }}
        </a>
        <a
            class="link"
            #rla2="routerLinkActive"
            [id]="'produkt-uebersicht-gtue-modul'"
            [routerLink]="'gtue-modul'"
            [active]="rla2.isActive"
            mat-tab-link
            routerLinkActive
        >
            {{ 'produkt.uebersicht.gtue-modul' | translate }}
        </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
        <router-outlet />
    </mat-tab-nav-panel>
</div>
