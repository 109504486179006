import { Injectable } from '@angular/core';
import { Feststellungen } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailFeststellungenPositionFormViewFactory } from '@modules/produkt/factory/feststellungen/produkt-detail-feststellungen-position-form-view.factory';
import { ProduktDetailFeststellungenUmfangFormViewFactory } from '@modules/produkt/factory/feststellungen/produkt-detail-feststellungen-umfang-form-view.factory';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFeststellungenFormViewFactory extends FormViewModelBaseFactory<Feststellungen> {
    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly umfangFormViewFactory: ProduktDetailFeststellungenUmfangFormViewFactory,
        private readonly positionFormViewFactory: ProduktDetailFeststellungenPositionFormViewFactory,
    ) {
        super();
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    }

    protected createField(_: Feststellungen, name: string): AbstractViewFormControl {
        throw new Error(`Could not create field for name: '${name}'.`);
    }

    protected createArray(model: Feststellungen, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Feststellungen.fields;
        switch (arrayName) {
            case fields.Umfaenge.name:
                const umfaenge = model.umfaenge || [];
                return new ViewFormArray(
                    umfaenge.map((umfang) => this.umfangFormViewFactory.create(umfang, arrayFields)),
                );
            case fields.Positionen.name:
                const positionen = model.positionen || [];
                return new ViewFormArray(
                    positionen.map((position) => this.positionFormViewFactory.create(position, arrayFields)),
                );
            case fields.Beschreibungen.name:
                const beschreibungen = model.beschreibungen || [];
                return new ViewFormArray(
                    beschreibungen.map((bemerkung) =>
                        this.produktDetailTextbausteinFormViewFactory.create(bemerkung, arrayFields),
                    ),
                );
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
