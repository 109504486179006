import { Injectable } from '@angular/core';
import { Notiz } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ProduktDetailNotizenDialogComponent,
    ProduktDetailNotizenDialogData,
} from '../component/produkt-detail-notizen-dialog/produkt-detail-notizen-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailNotizenDialogService {
    constructor(private readonly templateDialog: TemplateDialogService) {
        Assert.notNullOrUndefined(templateDialog, 'templateDialog');
    }

    open(notizen: Notiz[]): Observable<Notiz[]> {
        Assert.notNullOrUndefined(notizen, 'notizen');
        const data: ProduktDetailNotizenDialogData = {
            notizen: JSON.parse(JSON.stringify(notizen)),
        };
        const buttons = [this.templateDialog.getCloseButtonSetting()];
        return this.templateDialog
            .openTemplate(undefined, buttons, ProduktDetailNotizenDialogComponent, data, true)
            .pipe(map((result) => result.data.notizen));
    }
}
