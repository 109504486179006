<ng-container [formGroup]="form">
    <label [innerHTML]="displayName | translate"></label>
    <mat-radio-group
        class="single"
        #group
        [formControlName]="name"
        [class.two-columns]="columnCount === columnCountEnum.TWO"
        [class.three-columns]="columnCount === columnCountEnum.THREE"
    >
        <mat-radio-button class="key" *ngFor="let value of visibleValues; trackBy: trackByKey" [value]="value">
            {{ value }}
        </mat-radio-button>
        <ng-container *ngIf="(control.valueChanges | async) || control.value as value">
            <mat-radio-button *ngIf="value && visibleValues.indexOf(value) === -1" [value]="value">
                {{ value }}
            </mat-radio-button>
        </ng-container>
    </mat-radio-group>
</ng-container>

<ng-container *ngIf="enableExtendedList">
    <mat-divider />

    <button (click)="onShowListClick()" mat-button>
        {{ 'list.more' | translate }}
    </button>
</ng-container>

<ng-template #list let-data="data">
    <mat-form-field>
        <input #input (keyup)="onFilterKeyUp(input)" matInput />
        <ng-container matSuffix>
            <app-button [disabled]="input.value.length === 0" (action)="onAddClick(input)">
                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
            </app-button>
        </ng-container>
    </mat-form-field>
    <ng-container *ngIf="filteredValues$ | async as filteredValues">
        <mat-radio-group
            class="single"
            [(ngModel)]="data.value"
            [class.two-columns]="columnCount === columnCountEnum.TWO"
            [class.three-columns]="columnCount === columnCountEnum.THREE"
            (ngModelChange)="onValueChange($event)"
        >
            <mat-radio-button class="key" *ngFor="let value of filteredValues; trackBy: trackByKey" [value]="value">
                {{ value }}
            </mat-radio-button>
        </mat-radio-group>
    </ng-container>
</ng-template>
