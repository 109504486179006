<div class="main-content">
    <div class="toggle">
        <app-toggle-group [value]="display$ | async" (valueChange)="onDisplayChange($event)">
            <app-toggle [value]="vorschadenDisplay.Modell" [disabled]="modelDisabled$ | async">
                {{ 'vorschaden.modell' | translate }}
            </app-toggle>
            <app-toggle [value]="vorschadenDisplay.Manuell">
                {{ 'vorschaden.manuell' | translate }}
            </app-toggle>
        </app-toggle-group>
    </div>

    <ng-container [ngSwitch]="display$ | async">
        <app-produkt-detail-vorschaden-scene
            *ngSwitchCase="vorschadenDisplay.Modell"
            [modelFileConfigs]="modelFileConfigs"
            [activeObergruppen]="activeObergruppen$ | async"
            (obergruppeSelect)="onObergruppeSelect($event)"
            (modelLoad)="onModelLoad($event)"
        />
        <app-card *ngSwitchCase="vorschadenDisplay.Manuell">
            <app-action-list
                [values]="obergruppenManuell"
                [searchable]="true"
                (action)="onObergruppeAction($event)"
                (submit)="onObergruppeSelect($event)"
            />
        </app-card>
    </ng-container>
</div>

<!--side panel-->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <h4>{{ ('vorschaden.vorschaeden' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;' }}</h4>
    <ng-container *ngTemplateOutlet="vorschadenKosten" />
</app-side-panel>

<!--bottom sheet-->
<app-bottom-sheet
    *ngIf="(viewport$ | async) !== viewport.Desktop"
    [title]="('vorschaden.vorschaeden' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'"
    [value]="sums$ | async"
    [seamless]="true"
>
    <ng-container *ngTemplateOutlet="vorschadenKosten" />
</app-bottom-sheet>

<!--notizen button-->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!--templates-->
<ng-template #vorschadenKosten>
    <!--header-->
    <div class="header">
        <div class="group-header">
            <label>{{ name + '.' + 'obergruppe' | translate }}</label>
            <label>{{ name + '.' + 'untergruppe' | translate }}</label>
        </div>
        <div class="group-header">
            <label>{{ name + '.' + 'art' | translate }}</label>
            <label>{{ name + '.' + 'reparatur' | translate }}</label>
        </div>
        <div class="group-header">
            <label>{{ name + '.' + 'schadendatum' | translate }}</label>
            <label>{{ name + '.' + 'bilder' | translate }}</label>
        </div>
        <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <ng-container *ngSwitchCase="'wertminderungGroup'">
                    <div class="group-header align-right">
                        <label>{{ name + '.' + 'schadenhoehe' | translate }}</label>
                        <label>{{ name + '.' + 'wertminderung' | translate }}</label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <!--header of expansion panel-->
    <div
        class="drop-list"
        [class.mobile]="(viewport$ | async) !== viewport.Desktop"
        [class.disabled]="produkt.status !== statusEnum.Offen"
        (cdkDropListDropped)="drop($event)"
        cdkDropList
    >
        <app-expansion-panel-custom
            #panel
            *ngFor="let item of positionen.getRawValue(); index as index; trackBy: trackByField"
            cdkDrag
        >
            <app-expansion-panel-header-custom>
                <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
                    <ng-container [ngSwitch]="field.name || field.groupName">
                        <ng-container *ngSwitchCase="'obergruppe'">
                            <div class="group-header">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{ item.obergruppe }}</label>
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{ item.untergruppe }}</label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'art'">
                            <div class="group-header">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.art ? art.values[item.art] : '-' }}
                                </label>
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.reparatur ? item.reparatur : '-' }}
                                </label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'bilder'">
                            <div class="group-header">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.schadendatum ? (item.schadendatum | date) : '-' }}
                                </label>
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.bilder.length == 0 ? 0 : item.bilder.length }}
                                </label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'wertminderungGroup'">
                            <div class="group-header align-right">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ (item.schadenhoehe ? item.schadenhoehe : '0') | number: '0.2-2' }}€
                                </label>
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ (item.wertminderung ? item.wertminderung : '0') | number: '0.2-2' }}€
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <app-icon-button class="button drag-button" cdkDragHandle>
                    <app-mat-icon [icon]="'drag_indicator'" />
                </app-icon-button>
            </app-expansion-panel-header-custom>

            <!--body of expansion panel-->
            <div class="button-group">
                <app-icon-button class="button edit-button" (action)="onRowOpenByIndex(index, item, panel, $event)">
                    <app-mat-icon [icon]="'edit'" />
                </app-icon-button>
                <app-icon-button class="button delete-button" (action)="onRowRemoveByIndex(index, panel, $event)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-icon-button>
            </div>
        </app-expansion-panel-custom>
    </div>

    <!--footer-->
    <div class="footer">
        <label>{{ sums$ | async }}</label>
    </div>
</ng-template>

<!--templates-->
<ng-template #untergruppe let-data="data">
    <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'untergruppe'">
                <app-extendable-radio-list
                    [form]="data.form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [values]="data.values"
                    [keys]="(viewport$ | async) === viewport.Mobile ? maxColumnEntries.MOBILE : maxColumnEntries.TABLET"
                    [columnCount]="
                        (viewport$ | async) !== viewport.Mobile ? columnCountEnum.THREE : columnCountEnum.ONE
                    "
                />
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #dialog let-data="data">
    <app-produkt-detail-vorschaden-position
        [form]="data.form"
        [name]="name"
        [fields]="data.fields"
        [produktArt]="data.produktArt"
        (disableTemplateButton)="changeTemplateButtonDisabled($event)"
    />
</ng-template>
