import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileData } from '@app/class/file-data';
import { guid } from '@app/function/guid';
import { FileValidationService, FileValidationTypes } from '@app/service/file-validation.service';
import { Notiz } from '@data/domain/schema/type';
import { ProduktDetailFileService } from '@modules/produkt/service/produkt-detail-file.service';
import { ChatMessage } from '@shared/component/layout/chat/chat.component';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-produkt-detail-notizen-form',
    templateUrl: './produkt-detail-notizen-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailNotizenFormComponent implements OnInit {
    dateien$ = new BehaviorSubject<string[]>([]);
    sprachnachrichten$ = new BehaviorSubject<string[]>([]);
    textnachrichten$ = new BehaviorSubject<ChatMessage[]>([]);

    @Input()
    name: string;

    @Input()
    notizen: Notiz[];

    @Output()
    notizenChange = new EventEmitter<Notiz[]>();

    constructor(
        private readonly produktDetailFileService: ProduktDetailFileService,
        private readonly snackBarService: SnackBarService,
        private readonly fileValidationService: FileValidationService,
    ) {
        Assert.notNullOrUndefined(produktDetailFileService, 'produktDetailFileService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }

    ngOnInit(): void {
        this.update();
    }

    onFileAdd(files: FileData<ArrayBuffer>[]): void {
        Assert.notNullOrUndefined(files, 'files');

        const validFiles = this.fileValidationService.validateFileTypeAndExtension(
            files,
            FileValidationTypes.Image,
        ).validFiles;
        if (validFiles) {
            of(validFiles)
                .pipe(
                    mergeMap((x) => x.map((file) => this.produktDetailFileService.put(file))),
                    mergeMap((id) => id),
                )
                .subscribe((id) => {
                    const notiz: Notiz = {
                        id: guid(),
                        createdAt: `${Date.now()}`,
                        datei: id,
                    };
                    this.addNotiz(notiz);
                });
        }
    }

    onAudioAdd(file: FileData<ArrayBuffer>): void {
        Assert.notNullOrUndefined(file, 'file');
        this.produktDetailFileService.put(file).subscribe((id) => {
            const notiz: Notiz = {
                id: guid(),
                createdAt: `${Date.now()}`,
                sprachnachricht: id,
            };
            this.addNotiz(notiz);
        });
    }

    onChatAdd(text: string): void {
        Assert.notNullOrEmpty(text, 'text');
        const notiz: Notiz = {
            id: guid(),
            createdAt: `${Date.now()}`,
            textnachricht: text,
        };
        this.addNotiz(notiz);
    }

    onFileDelete(fileId: string): void {
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.produktDetailFileService.delete(fileId).subscribe(() => {
            this.removeNotiz(this.notizen.findIndex((x) => x.datei === fileId));
        });
    }

    onAudioDelete(audioId: string): void {
        Assert.notNullOrEmpty(audioId, 'audioId');
        this.produktDetailFileService.delete(audioId).subscribe(() => {
            this.removeNotiz(this.notizen.findIndex((x) => x.sprachnachricht === audioId));
        });
    }

    onChatDelete(messageId: string): void {
        Assert.notNullOrEmpty(messageId, 'messageId');
        this.removeNotiz(this.notizen.findIndex((x) => x.id === messageId));
    }

    onAudioError(): void {
        this.snackBarService.warning('notizen.audioError');
    }

    private addNotiz(notiz: Notiz): void {
        this.notizen.push(notiz);
        this.notizenChange.emit(this.notizen);
        this.update();
    }

    private removeNotiz(index: number): void {
        this.notizen.splice(index, 1);
        this.notizenChange.emit(this.notizen);
        this.update();
    }

    private update(): void {
        const dateien = this.notizen.map((x) => x.datei).filter((x) => !!x);
        this.dateien$.next(dateien);

        const sprachnachrichten = this.notizen.map((x) => x.sprachnachricht).filter((x) => !!x);
        this.sprachnachrichten$.next(sprachnachrichten);

        const textnachrichten = this.notizen
            .filter((x) => !!x.textnachricht)
            .map((x) => {
                const message: ChatMessage = {
                    id: x.id,
                    date: x.createdAt,
                    text: x.textnachricht,
                };
                return message;
            });
        this.textnachrichten$.next(textnachrichten);
    }
}
