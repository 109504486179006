export enum Verfuegbarkeit {
    GTUE = 'GTUE',
    Buero = 'Buero',
    Benutzer = 'Benutzer',
}

export interface TextbausteineVerwaltenConfig {
    feature: string;
    feld: string;
    featureDisplayName: string;
    feldDisplayName: string;
    icon?: string;
}

export const TEXTBAUSTEINE_VERWALTUNG_CONFIG: TextbausteineVerwaltenConfig[] = [
    {
        feature: 'Auftrag',
        feld: 'bemerkungen',
        featureDisplayName: 'Auftrag',
        feldDisplayName: 'Bemerkungen',
        icon: 'order',
    },
    {
        feature: 'Auftrag',
        feld: 'auftragsbeschreibung',
        featureDisplayName: 'Auftrag',
        feldDisplayName: 'Auftragsbeschreibung',
        icon: 'order',
    },
    {
        feature: 'Auftrag',
        feld: 'besichtigungsbedingungen',
        featureDisplayName: 'Auftrag',
        feldDisplayName: 'Besichtigungsbedingungen',
        icon: 'order',
    },
    {
        feature: 'Fahrzeug',
        feld: 'bemerkungen',
        featureDisplayName: 'Fahrzeug',
        feldDisplayName: 'Bemerkungen',
        icon: 'car',
    },
    {
        feature: 'Werte',
        feld: 'wertAmMarktBemerkungen',
        featureDisplayName: 'Werte',
        feldDisplayName: 'Wert am Markt Bemerkungen',
        icon: 'value',
    },
    {
        feature: 'Uebersicht',
        feld: 'bezeichnungen',
        featureDisplayName: 'Übersicht',
        feldDisplayName: 'Bezeichnung',
        icon: 'overview',
    },
    {
        feature: 'Räder',
        feld: 'bemerkungen',
        featureDisplayName: 'Räder',
        feldDisplayName: 'Bemerkungen',
        icon: 'wheels',
    },
    {
        feature: 'Lackmessung',
        feld: 'bemerkungen',
        featureDisplayName: 'Lackmessung',
        feldDisplayName: 'Bemerkungen',
        icon: 'paint',
    },
    {
        feature: 'Lackmessung',
        feld: 'beschreibungen',
        featureDisplayName: 'Lackmessung',
        feldDisplayName: 'Untersuchungsergebnis',
        icon: 'paint',
    },
    {
        feature: 'Schaden',
        feld: 'beschreibungen',
        featureDisplayName: 'Schaden',
        feldDisplayName: 'Beschreibungen',
        icon: 'damage',
    },
    {
        feature: 'Feststellungen',
        feld: 'zustand',
        featureDisplayName: 'Feststellungen',
        feldDisplayName: 'Zustand',
        icon: 'damage',
    },
    {
        feature: 'Feststellungen',
        feld: 'zustand',
        featureDisplayName: 'Feststellungen',
        feldDisplayName: 'Beschreibungen',
        icon: 'damage',
    },
    /*
     * <Anfang> Feststellungen - Umfang
     */
    {
        feature: 'KarosserieLackierungUndAufbau',
        feld: 'beschreibung',
        featureDisplayName: 'Karosserie, Lackierung und Aufbau',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'MotorMotorraumUndAntrieb',
        feld: 'beschreibung',
        featureDisplayName: 'Motor, Motorraum und Antrieb',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'Bremsanlage',
        feld: 'beschreibung',
        featureDisplayName: 'Bremsanlage',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'FahrwerkUndLenkung',
        feld: 'beschreibung',
        featureDisplayName: 'Fahrwerk und Lenkung',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'RaederUndReifen',
        feld: 'beschreibung',
        featureDisplayName: 'Räder und Reifen',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'BeleuchtungsUndWarnvorrichtungen',
        feld: 'beschreibung',
        featureDisplayName: 'Beleuchtungs- und Warnvorrichtungen',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'InnenraumUndSicherheit',
        feld: 'beschreibung',
        featureDisplayName: 'Innenraum und Sicherheit',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'Bodengruppe',
        feld: 'beschreibung',
        featureDisplayName: 'Bodengruppe',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    {
        feature: 'Sonderfeststellungen',
        feld: 'beschreibung',
        featureDisplayName: 'Sonderfeststellungen',
        feldDisplayName: 'Beschreibung',
        icon: 'damage',
    },
    /*
     * </Ende> Feststellungen - Umfang
     */
    {
        feature: 'Ausstattung',
        feld: 'individualaufbauten',
        featureDisplayName: 'Ausstattung',
        feldDisplayName: 'Individualaufbauten',
        icon: 'equipment',
    },
    {
        feature: 'Wartung',
        feld: 'naechsterServicebeschreibung',
        featureDisplayName: 'Wartung',
        feldDisplayName: 'Beschreibung',
        icon: 'service',
    },
    {
        feature: 'Abschluss',
        feld: 'fazit',
        featureDisplayName: 'Abschluss',
        feldDisplayName: 'Zusammenfassung',
        icon: 'closure',
    },
    {
        feature: 'Abschluss',
        feld: 'abschlussBemerkung',
        featureDisplayName: 'Abschluss',
        feldDisplayName: 'Abschlussbemerkung',
        icon: 'closure',
    },
];
