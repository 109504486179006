<div class="inputs" (focusout)="onFocusout()">
    <input
        class="char"
        #char
        *ngFor="let part of parts; trackBy: trackByIndex; index as index"
        [value]="part"
        [disabled]="vinDisabled"
        (keydown)="onKeyDown($event, index)"
        (focus)="onFocus(index)"
        (paste)="onPaste($event)"
        type="text"
        maxlength="1"
    />
    <input class="digit" #digit disabled />
</div>
