import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionRowComponent } from '../action-row/action-row.component';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent {
    @Input()
    set expanded(expanded: boolean) {
        this.expanded$.next(expanded);
    }

    expanded$ = new BehaviorSubject<boolean>(undefined);

    @Input()
    dynamicHeight = true;

    @Output()
    opened = new EventEmitter<void>();

    @ViewChild('header', { static: true })
    header: TemplateRef<any>;

    @ViewChild('content', { static: true })
    content: TemplateRef<any>;

    @ViewChild('row', { static: true })
    row: TemplateRef<any>;

    @ContentChild(ActionRowComponent, { static: true })
    actionRow: ActionRowComponent;
}
