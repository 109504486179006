import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ProduktStatusService } from '@app/service/produkt-status.service';
import { ProduktStatus } from '@data/domain/schema/enum';
import { AcAbschluss, AcAbschlussInput } from '@data/domain/schema/type';
import { ProduktAcAbschlussService } from '@data/domain/service/feature/produkt-ac-abschluss.service';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { Feature, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailAcAbschlussFormViewFactory } from '@modules/produkt/factory/ac-abschluss/produkt-detail-ac-abschluss-form-view.factory';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureValidService } from '@modules/produkt/service/produkt-detail-feature-valid.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { BehaviorSubject } from 'rxjs';

interface FeatureValidation {
    name: string;
    incorrectFields: string[];
}

@Component({
    selector: 'app-produkt-detail-ac-abschluss',
    templateUrl: './produkt-detail-ac-abschluss.component.html',
    styleUrls: ['./produkt-detail-ac-abschluss.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAcAbschlussComponent
    extends ProduktDetailFeatureInputComponent<AcAbschluss, AcAbschlussInput>
    implements OnInit {
    feature = PRODUKT_CONFIG_FEATURES.AcAbschluss.name;

    form: ViewFormGroup;

    adressen: Feature;

    validations: FeatureValidation[] = [];

    statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        private readonly featureValidService: ProduktDetailFeatureValidService,
        private readonly produktAcAbschlussService: ProduktAcAbschlussService,
        private readonly updateWerteService: UpdateWerteService,
        private readonly produktDetailAcAbschlussFormViewFactory: ProduktDetailAcAbschlussFormViewFactory,
        private readonly produktStatusService: ProduktStatusService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktAcAbschlussService);
        Assert.notNullOrUndefined(featureValidService, 'featureValidService');
        Assert.notNullOrUndefined(updateWerteService, 'updateWerteService');
        Assert.notNullOrUndefined(produktStatusService, 'produktStatusService');
    }

    ngOnInit(): void {
        this.init(PRODUKT_CONFIG_FEATURES.AcAbschluss.name);
        this.adressen = this.getFeatureByName(PRODUKT_CONFIG_FEATURES.Adressen.name);

        this.getFeatures().forEach(({ name, fields }) => {
            const incorrectFields = this.featureValidService.getFeatureIncorrectFields(this.produkt, name, fields);
            if (incorrectFields.length > 0) {
                this.validations.push({ name, incorrectFields });
            }
        });

        this.updateWerteService.updateWerteBerechneteFelder(this.produkt);
    }

    onStatusChanged($event: ProduktStatus): void {
        this.statusChanged$.next($event);
        this.produktStatusService.update($event);
    }

    protected createForm(): ViewFormGroup {
        return this.produktDetailAcAbschlussFormViewFactory.create(this.produkt.acAbschluss, this.fields);
    }
}
