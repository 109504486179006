import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { concatMap, from, Observable, of, switchMap } from 'rxjs';
import { FileService } from './file.service';

const APPSYNC_KEY = 'reduxPersist::appsync';
const APPSYNC_METADATA_KEY = 'reduxPersist::appsync-metadata';
const OFFLINE_KEY = 'reduxPersist::offline';
const FILE_DB_NAME = 'files';

@Injectable({
    providedIn: 'root',
})
export class QuotaExceededService {
    constructor(private readonly fileService: FileService) {}
    handle(): Observable<boolean> {
        return from(localforage.removeItem(APPSYNC_KEY)).pipe(
            switchMap(() => from(localforage.removeItem(APPSYNC_METADATA_KEY))),
            switchMap(() => from(localforage.removeItem(OFFLINE_KEY))),
            switchMap(() => this.fileService?.sync()),
            concatMap((result) => {
                if (result) {
                    return this.openFileDb();
                } else {
                    return of(null);
                }
            }),
            concatMap((db) => {
                if (db) {
                    return this.checkFileDbEmpty(db);
                } else {
                    return of(null);
                }
            }),
            concatMap((result) => {
                if (result) {
                    return this.deleteFileDb();
                } else {
                    return of(null);
                }
            }),
        );
    }

    private openFileDb(): Observable<IDBDatabase> {
        const result = new Promise<IDBDatabase>((resolve, reject) => {
            const openRequest = indexedDB.open(FILE_DB_NAME);
            let db: IDBDatabase = null;

            openRequest.onsuccess = () => {
                db = openRequest.result;
                return resolve(db);
            };
            openRequest.onerror = (error) => {
                console.error(`Unable to open indexedDB database with name ${FILE_DB_NAME} - error: `, error);
                return reject(error);
            };
        });

        return from(result);
    }

    private checkFileDbEmpty(openDb: IDBDatabase): Observable<boolean> {
        if (!openDb) {
            return of(false);
        }
        const result = new Promise<boolean>((resolve, reject) => {
            const checkRequest = openDb.transaction(FILE_DB_NAME).objectStore(FILE_DB_NAME).getAllKeys();
            checkRequest.onsuccess = () => {
                const keyCount = checkRequest.result?.length;
                console.info(`Get all keys transaction finished on database ${FILE_DB_NAME}`);
                if (keyCount === 0) {
                    return resolve(true);
                } else {
                    return resolve(false);
                }
            };
            checkRequest.onerror = (error) => {
                console.error(`Unable to get keys of indexedDB database with name ${FILE_DB_NAME} - error: `, error);
                return reject(error);
            };
        });

        return from(result);
    }

    private deleteFileDb(): Observable<boolean> {
        const result = new Promise<boolean>((resolve, reject) => {
            const deleteFileDb = indexedDB.deleteDatabase(FILE_DB_NAME);
            deleteFileDb.onsuccess = () => {
                console.info('IndexedDB files database deleted successfully');
                return resolve(true);
            };
            deleteFileDb.onerror = (error) => {
                console.error('IndexedDB files database could not be deleted: ', error);
                return reject(error);
            };
            deleteFileDb.onblocked = (error) => {
                console.warn('IndexedDB files database could not be deleted - blocked: ', error);
            };
        });

        return from(result);
    }
}
