import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { TrackBy } from '@shared/helper/track-by';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { BehaviorSubject } from 'rxjs';
import { TableFormColumnComponent } from '../table-form-column/table-form-column.component';

export interface TableTotalCalculator {
    calculate(rows: AbstractControl[]): number;
}

export interface TableRowMoveEvent {
    row: AbstractControl;
    offset: -1 | 1;
}

@Component({
    selector: 'app-form-table',
    templateUrl: './table-form.component.html',
    styleUrls: ['./table-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFormComponent implements AfterViewInit {
    trackByName = TrackBy.trackByName;

    columns$ = new BehaviorSubject<TableFormColumnComponent[]>([]);
    names$ = new BehaviorSubject<string[]>([]);
    dataSource = new MatTableDataSource<AbstractControl>();

    @ContentChildren(TableFormColumnComponent, { descendants: true })
    columnQuery: QueryList<TableFormColumnComponent>;

    @Input()
    set rows(rows: AbstractControl[]) {
        this.dataSource.data = rows;
    }

    @Input()
    elevation = true;

    @Input()
    footer = true;

    @Input()
    header = true;

    @Input()
    disabled = false;

    @Input()
    totalCalculators: {
        [name: string]: TableTotalCalculator;
    } = {};

    @Output()
    rowOpen = new EventEmitter<AbstractControl>();

    @Output()
    rowRemove = new EventEmitter<AbstractControl>();

    @Output()
    rowMove = new EventEmitter<TableRowMoveEvent>();

    constructor(private readonly currencyFormatter: CurrencyFormatterService) {
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const columns = this.columnQuery.toArray();
            this.columns$.next(columns);

            const names = columns.map((column) => column.name);
            if (this.rowRemove.observers.length > 0) {
                names.push('remove');
            }
            if (this.rowMove.observers.length > 0) {
                names.unshift('sortable');
            }
            this.names$.next(names);
        }, 1);
    }

    onRowClick(row: AbstractControl): void {
        Assert.notNullOrUndefined(row, 'row');
        if (row.valid && !row.value.readonly) {
            this.rowOpen.emit(row);
        }
    }

    onRowRemoveClick(row: AbstractControl): void {
        Assert.notNullOrUndefined(row, 'row');
        this.rowRemove.emit(row);
    }

    onRowMove(event: MouseEvent, row: AbstractControl, offset: -1 | 1): void {
        Assert.notNullOrUndefined(event, 'event');
        Assert.notNullOrUndefined(row, 'row');
        Assert.notNullOrUndefined(offset, 'offset');
        event.stopPropagation();
        this.rowMove.next({ row, offset });
    }

    getTotal(name: string): string {
        const total = this.totalCalculators[name]
            ? this.totalCalculators[name].calculate(this.dataSource.data)
            : this.dataSource.data.reduce((cv, cy: ViewFormGroup) => cv + cy.getRawValue()[name] || 0, 0);
        return this.currencyFormatter.format(total);
    }
}
