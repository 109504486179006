import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { DatFirstPage, DatKalkulationService } from '@data/api-gateway/service/dat-kalkulation.service';
import { KalkulationDownloadService } from '@data/api-gateway/service/kalkulation-download.service';
import { Kalkulation, KalkulationInput } from '@data/domain/schema/type';
import { ProduktKalkulationService } from '@data/domain/service/feature/produkt-kalkulation.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailKalkulationFormViewFactory } from '@modules/produkt/factory/kalkulation/produkt-detail-kalkulation-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { DatCalculateProService } from '@modules/produkt/service/dat-calculate-pro-service';
import { DatSearchService } from '@modules/produkt/service/dat-search.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

declare let SphinxClassCP: any;

export enum KalkulationDisplay {
    Daten,
    KalkulationDisplay,
}

@Component({
    selector: 'app-produkt-detail-kalkulation',
    templateUrl: './produkt-detail-kalkulation.component.html',
    styleUrls: ['./produkt-detail-kalkulation.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailKalkulationComponent
    extends ProduktDetailFeatureInputComponent<Kalkulation, KalkulationInput>
    implements OnInit, OnDestroy {
    trackByField = TrackBy.trackByField;
    ButtonType = ButtonType;

    loading$ = new BehaviorSubject<boolean>(false);
    loadingGetKalkulationData$ = new BehaviorSubject<boolean>(false);
    hasFahrzeugExternalServiceReference = false;

    sphinx;
    kalkulation: ViewFormGroup;
    kalkulationFields;

    displayEnum = KalkulationDisplay;
    display$ = new BehaviorSubject(this.displayEnum.Daten);

    private subscriptions: Subscription[] = [];

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        private clipboard: Clipboard,
        private readonly auth: AuthService,
        private readonly produktService: ProduktDetailResolveService,
        private readonly datCalculateProService: DatCalculateProService,
        private readonly produktKalkulationService: ProduktKalkulationService,
        private readonly formViewFactory: ProduktDetailKalkulationFormViewFactory,
        private readonly datKalkulationService: DatKalkulationService,
        private readonly kalkulationDownloadService: KalkulationDownloadService,
        private readonly snackBarService: SnackBarService,
        private readonly datSearchService: DatSearchService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktKalkulationService);
        Assert.notNullOrUndefined(clipboard, 'clipboard');
        Assert.notNullOrUndefined(auth, 'auth');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(datCalculateProService, 'datCalculateProService');
        Assert.notNullOrUndefined(produktKalkulationService, 'produktKalkulationService');
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(datKalkulationService, 'datKalkulationService');
        Assert.notNullOrUndefined(kalkulationDownloadService, 'kalkulationDownloadService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(datSearchService, 'datSearchService');
    }

    ngOnInit(): void {
        const nameKalkulation = PRODUKT_CONFIG_FEATURES.Kalkulation.name;
        this.init(nameKalkulation);
        const { fields } = this.getFeatureByName(nameKalkulation);
        this.kalkulationFields = fields;
        this.hasFahrzeugExternalServiceReference =
            !!this.produkt.fahrzeug?.fahrzeugExternalServiceReference?.identifier;
        this.subscriptions.push(
            this.datKalkulationService.getKalkulationStatusSubscription(
                this.produkt,
                this.form,
                this.loadingGetKalkulationData$,
            ),
        );
        this.sphinx = SphinxClassCP.getInstance();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.datKalkulationService.removeKalkulationStorageListener(this.produkt.id);
        super.ngOnDestroy();
    }

    onDisplayChange(display: number): void {
        Assert.notNullOrUndefined(display, 'display');
        this.display$.next(display);
        if (display === this.displayEnum.KalkulationDisplay) {
            const datPageConfig = {
                firstPage: DatFirstPage.GRAPHICAL_SELECTION_PAGE,
                currentPage: DatFirstPage.GRAPHICAL_SELECTION_PAGE,
            };
            this.datKalkulationService.initIframe(
                document,
                this.sphinx,
                datPageConfig,
                this.loading$,
                this.produkt,
                this.form,
            );
        }
    }

    copy(elementId: any): void {
        const inputValue = (document.getElementById(elementId) as HTMLInputElement)?.value;
        this.clipboard.copy(inputValue);
        this.snackBarService.success(`Wert kopiert: ${inputValue}`);
    }

    onOpenKalkulationInTab(): void {
        this.datKalkulationService.addKalkulationStorageListener(this.produkt.id);
        const datPageConfig = {
            firstPage: DatFirstPage.GRAPHICAL_SELECTION_PAGE,
            currentPage: DatFirstPage.GRAPHICAL_SELECTION_PAGE,
        };
        this.datKalkulationService.initIframe(
            document,
            this.sphinx,
            datPageConfig,
            this.loading$,
            this.produkt,
            this.form,
            true,
        );
    }

    downloadKalkulation(): void {
        this.auth
            .getClaims()
            .pipe(first())
            .subscribe((claims) => {
                if (claims && claims['custom:buero_id']) {
                    this.kalkulationDownloadService
                        .download(claims['custom:buero_id'], this.produkt.id)
                        .pipe(first())
                        .subscribe((url) => {
                            if (url) {
                                window.open(url);
                            } else {
                                this.snackBarService.error('schaden.kalkulation.download.error');
                            }
                        });
                }
            });
    }

    protected createForm(): ViewFormGroup {
        const form = this.formViewFactory.create(this.produkt.kalkulation, this.fields);
        this.kalkulation = form.get('kalkulation') as ViewFormGroup;
        return form;
    }
}
