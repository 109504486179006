import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import {
    CgBaugruppeBauteileversichert,
    CgBaugruppeFahrzeugversichert,
    CgVersicherungsart,
    ProduktStatus,
} from '@data/domain/schema/enum';
import { Bauteil, CgFeststellung, CgFeststellungInput, Notiz } from '@data/domain/schema/type';
import { ProduktCgFeststellungService } from '@data/domain/service/feature/produkt-cg-feststellung.service';
import { ProduktDetailCgFeststellungBaugruppenComponent } from '@modules/produkt/component/produkt-detail-cg-feststellung-baugruppen/produkt-detail-cg-feststellung-baugruppen.component';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailCgFeststellungFormViewFactory } from '@modules/produkt/factory/cg-feststellung/produkt-detail-cg-feststellung-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ExpansionPanelCustomComponent } from '@shared/component/layout/expansion/expansion-panel-custom/expansion-panel-custom.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-cg-feststellung',
    templateUrl: './produkt-detail-cg-feststellung.component.html',
    styleUrls: ['./produkt-detail-cg-feststellung.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailCgFeststellungComponent
    extends ProduktDetailFeatureInputComponent<CgFeststellung, CgFeststellungInput>
    implements OnInit {
    cgFestellungVersicherungsartEnum = CgVersicherungsart;
    baugruppenBauteileversichertEnum = CgBaugruppeBauteileversichert;
    baugruppenFahrzeugversichertEnum = CgBaugruppeFahrzeugversichert;

    positionenBauteilversichert: BehaviorSubject<ViewFormArray>;
    positionenFahrzeugversichert: BehaviorSubject<ViewFormArray>;
    positionenBauteilversichertName = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields.PositionenBauteileversichert.name;
    positionenFahrzeugversichertName = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields.PositionenFahrzeugversichert.name;
    verschleissbedingtName = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields.Verschleissbedingt.name;

    protected bauteileFields: FeatureFields;
    protected display$ = new BehaviorSubject(CgVersicherungsart.None);
    protected viewport$: Observable<Viewport>;
    protected viewport = Viewport;
    protected notizen$: Observable<Notiz[]>;
    protected featureName: string = PRODUKT_CONFIG_FEATURES.CgFeststellung.name;
    protected trackByField = TrackBy.trackByField;
    protected statusEnum = ProduktStatus;

    @ViewChild(ProduktDetailCgFeststellungBaugruppenComponent)
    private baugruppenComponent: ProduktDetailCgFeststellungBaugruppenComponent;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktCgFeststellungService: ProduktCgFeststellungService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
        private readonly viewportService: ViewportService,
        private readonly formViewFactory: ProduktDetailCgFeststellungFormViewFactory,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktCgFeststellungService);
    }

    ngOnInit(): void {
        this.notizen$ = this.notizenService.init(this.produkt, this.featureName);
        this.init(this.featureName);

        this.positionenBauteilversichert = new BehaviorSubject(
            this.form.get(this.positionenBauteilversichertName) as ViewFormArray,
        );
        this.positionenFahrzeugversichert = new BehaviorSubject(
            this.form.get(this.positionenFahrzeugversichertName) as ViewFormArray,
        );
        this.display$.next(this.produkt?.cgFeststellung?.versicherungsart || CgVersicherungsart.Bauteileversichert);

        this.bauteileFields = (
            this.fields.find((x: FeatureFieldArray) => x.arrayName === this.getPositionenName()) as FeatureFieldArray
        ).fields;

        this.viewport$ = this.viewportService.observe();
        this.setInitialVerschleissbedingt();
    }

    onDisplayChange(display: CgVersicherungsart): void {
        Assert.notNullOrUndefined(display, 'display');
        this.display$.next(display);
        this.form.patchValue({ versicherungsart: display });
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    get currentPositionen(): BehaviorSubject<ViewFormArray> {
        switch (this.display$.value) {
            case CgVersicherungsart.Bauteileversichert:
                return this.positionenBauteilversichert;
            case CgVersicherungsart.Fahrzeugversichert:
                return this.positionenFahrzeugversichert;
            default:
                throw new Error('Invalid display.');
        }
    }

    onRowOpenByIndex(index: number, item: Bauteil, panel: ExpansionPanelCustomComponent, $event: MouseEvent): void {
        Assert.notNullOrUndefined(index, 'index');
        $event.stopPropagation();
        panel.close();
        this.editPosition(index, item);
    }

    onRowRemoveByIndex(index: number, panel: ExpansionPanelCustomComponent, $event: MouseEvent): void {
        Assert.notNullOrUndefined(index, 'index');
        panel.close();
        $event.stopPropagation();
        this.currentPositionen.value.removeAt(index);
    }

    protected createForm(): ViewFormGroup {
        return this.formViewFactory.create(this.produkt.cgFeststellung, this.fields);
    }

    private getPositionenName(): string {
        switch (this.display$.value) {
            case CgVersicherungsart.Bauteileversichert:
                return this.positionenBauteilversichertName;
            case CgVersicherungsart.Fahrzeugversichert:
                return this.positionenFahrzeugversichertName;
            default:
                throw new Error('Invalid display.');
        }
    }

    private editPosition(index: number, position: Bauteil): void {
        this.baugruppenComponent
            .openBauteilDetailDialog(position)
            .pipe(take(1))
            .subscribe((update) => {
                (this.currentPositionen.value.controls[index] as ViewFormGroup) = update;
                this.currentPositionen.value.updateValueAndValidity();
                this.baugruppenComponent.sortPositionen();
            });
    }

    private setInitialVerschleissbedingt(): void {
        if (this.form?.get(this.verschleissbedingtName)?.value === null) {
            this.form.patchValue({ verschleissbedingt: true });
        }
    }
}
