import { Injectable } from '@angular/core';
import { CombiPlusOnlineDto, CombiPlusOnlineService } from '@data/api-gateway/service/bvws-combi-plus-online.service';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProduktUebersichtCpoResolveService {
    private cpoVorgaenge: CombiPlusOnlineDto[];

    constructor(private readonly combiPlusOnlineService: CombiPlusOnlineService) {
        Assert.notNullOrUndefined(combiPlusOnlineService, 'combiPlusOnlineService');
    }

    get(): CombiPlusOnlineDto[] {
        return this.cpoVorgaenge;
    }

    resolve(): Observable<CombiPlusOnlineDto[]> {
        return this.combiPlusOnlineService.getVorgaenge().pipe(tap((vorgaenge) => (this.cpoVorgaenge = vorgaenge)));
    }
}
