import { TEXTBAUSTEIN_FIELDS } from '@data/domain/fragments/textbaustein.fragment';
import gql from 'graphql-tag';

export const PRODUKT_FIELDS = gql`
    fragment ProduktFields on Produkt {
        id
        idParent
        art
        status
        identnummer
        versandArt
        rechnungsArt
        erstelltAm
        anzahlBilder
        abrechnungen {
            id
            abrechnungen {
                id
                datum
                externalProvider
                externalService
                identifier
            }
        }
        auftrag {
            id
            art
            nummer
            vorgangsnummer
            auftragstyp
            bezugsAuftragsnummer
            kommentar
            erstellungsTag
            erstellungsUhrzeit
            besichtigungsTag
            besichtigungsUhrzeit
            fertigstellungsTag
            fertigstellungsUhrzeit
            bemerkungen {
                ...TextbausteinFields
            }
            auftragsbeschreibung {
                ...TextbausteinFields
            }
            besichtigungsbedingungen {
                ...TextbausteinFields
            }
            bemerkung
            hinweise {
                id
                name
                vorhanden
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        vtiAuftrag {
            id
            art
            nummer
            vorgangsnummer
            auftragstyp
            bezugsAuftragsnummer
            bemerkung
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        fahrzeug {
            id
            kennzeichen
            identnummer
            datECode
            typCode
            herstellerCode
            erstzulassung
            letzteZulassung
            laufleistung
            laufleistungEinheit
            laufleistungBeschreibung
            laufleistungen {
                id
                wert
                einheit
                beschreibung
            }
            fahrzeugart
            fahrzeugartManuell
            bauform
            bauformManuell
            hersteller
            modell
            untertyp
            lackcode
            innenausstattung
            polsterfarbe
            polstermaterial
            hubraum
            leistung
            zylinder
            schadstoffklasse
            leergewicht
            zulaessigesGesamtgewicht
            masseLaenge
            masseBreite
            masseHoehe
            anzahlTueren
            anzahlSitze
            antriebstechnologie
            antriebsart
            getriebeart
            anzahlGaenge
            anzahlVorbesitzer
            scheckheftGepflegt
            hvBatterie
            acFahrzeugart
            acBauform
            acLackfarbe
            acLackierung
            acAntriebstechnologie
            acGetriebeart
            acSchadstoffklasse
            acEhemaligeNutzung
            bemerkungen {
                ...TextbausteinFields
            }
            fahrzeugExternalServiceReference {
                id
                source
                identifier
                container
                constructionTime
                vxsData
            }
            vinAbfrageErfolgt
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        adressen {
            id
            adressen {
                id
                createdAt
                firma
                anrede
                anredeFreitext
                vorname
                name
                strasseNr
                postleitzahl
                ort
                telefon
                mobil
                email
                arten
                externalId
                bestellnummer
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        raeder {
            id
            radSaetze {
                id
                lagerort
                rads {
                    id
                    radPosition
                    hersteller
                    reifenTyp
                    radFelge
                    reifenbreite
                    querschnitt
                    bauart
                    felgengroesse
                    tragfaehigkeit1
                    geschwindigkeitsindex
                    zulaessig
                    profil
                    felgenhersteller
                    runflat
                    dot
                }
            }
            notlaufeinrichtung
            notlaufeinrichtungProfiltiefe
            bemerkungen {
                ...TextbausteinFields
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        nfzRaeder {
            id
            radSaetze {
                id
                lagerort
                achsen {
                    id
                    seiten {
                        id
                        achsenRaeder {
                            id
                            position
                            hersteller
                            typ
                            felge
                            reifenbreite
                            querschnitt
                            bauart
                            felgengroesse
                            tragfaehigkeit
                            geschwindigkeitsindex
                            zustand
                            profiltiefe
                            reifenDruck
                            felgenart
                            felgenhersteller
                            runflat
                            dot
                        }
                        seite
                    }
                    bewertung
                }
            }
            notlaufeinrichtung
            notlaufeinrichtungProfiltiefe
            bemerkungen {
                ...TextbausteinFields
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        vorschaden {
            id
            positionen {
                id
                externalId
                obergruppe
                untergruppe
                art
                reparatur
                bilder
                schadendatum
                schadenhoehe
                wertminderung
                extern
                bestaetigt
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        fehlteile {
            id
            positionen {
                id
                externalId
                bezeichnung
                preis
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        uebersicht {
            id
            deckblatt
            vorneLinks
            vorneRechts
            hintenLinks
            hintenRechts
            innenraum
            sonstiges {
                id
                bilder
                bezeichnung
                bezeichnungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            fahrzeugVorhanden
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        schaden {
            id
            positionen {
                id
                externalId
                obergruppe
                untergruppe
                beschaedigungart
                reparaturweg
                bilder
                intensitaet
                preis
                minderwert
                extern
                bestaetigt
            }
            beschreibungen {
                ...TextbausteinFields
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        cgFeststellung {
            id
            versicherungsart
            positionenBauteileversichert {
                id
                bezeichnung
                baugruppe
                prueftext
                bilder
            }
            positionenFahrzeugversichert {
                id
                bezeichnung
                baugruppe
                prueftext
                bilder
            }
            verschleissbedingt
            diagnose
            internerKommentar
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        feststellungen {
            id
            umfaenge {
                id
                obergruppeName
                obergruppe
                beschreibung {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            positionen {
                id
                externalId
                obergruppe
                untergruppe
                zustand {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                beschaedigungart
                reparaturweg
                bilder
                preis
                extern
                bestaetigt
            }
            beschreibungen {
                ...TextbausteinFields
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        ausstattung {
            id
            gruppen {
                id
                name
                sonderausstattung
                teile {
                    id
                    name
                    vorhanden
                    externalServiceId
                }
            }
            individualaufbauten {
                ...TextbausteinFields
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        unterlagen {
            id
            schluesselFahrzeug
            schluesselAHK
            fernbedienungStandheizung
            positionen {
                id
                bezeichnung
                bilder
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        wartung {
            id
            letzterServiceNichtBekannt
            letzterServiceBei
            letzterServiceFaelligEinheit
            letzterServiceAm
            naechsterServiceFaelligNichtBekannt
            naechsterServiceFaelligIn
            naechsterServiceFaelligEinheit
            naechsterServiceFaelligInZeit
            naechsterServiceFaelligEinheitZeit
            naechsterServiceFaelligAm
            naechsterServiceFaelligKosten
            naechsterServiceFaelligExternalId
            naechsterServiceBeschreibung {
                ...TextbausteinFields
            }
            naechsteHauptAbgasUntersuchungNichtBekannt
            naechsteHauptAbgasUntersuchung
            naechsteHauptAbgasUntersuchungExternalId
            hauptAbgasUntersuchungFaelligKosten
            zahnriemenwechselBei
            zahnriemenwechselFaelligEinheit
            zahnriemenwechselAm
            zahnriemenFaelligKosten
            zahnriemenFaelligExternalId
            positionen {
                id
                externalId
                bezeichnung
                kosten
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        werte {
            id
            bezugsdatum
            roundValue
            haendlereinkaufswert
            haendlereinkaufswertDrucken
            haendlereinkaufswertNetto
            haendlereinkaufswertNettoDrucken
            haendlerverkaufswert
            haendlerverkaufswertBesteuerung
            haendlerverkaufswertDrucken
            haendlerverkaufswertNetto
            haendlerverkaufswertNettoDrucken
            datHvkNettoDifferenz
            datHvkNettoRegel
            aufwendungen
            aufwendungenDrucken
            aufwendungenDetailsDrucken
            haendlereinkaufswertAbzuege
            haendlereinkaufswertAbzuegeDrucken
            haendlereinkaufswertAbzuegeNetto
            haendlereinkaufswertAbzuegeNettoDrucken
            haendlerverkaufswertAbzuege
            haendlerverkaufswertAbzuegeDrucken
            haendlerverkaufswertAbzuegeNetto
            haendlerverkaufswertAbzuegeNettoDrucken
            manuelleWerteDrucken
            manuelleWerte {
                bezeichnung
                wert
            }
            wertAmMarkt
            wertAmMarktBemerkung
            wertAmMarktBemerkungen {
                ...TextbausteinFields
            }
            restwertAusUnfallschaden
            restwertAusUnfallschadenNetto
            neuwert
            neuwertDrucken
            relativerWert
            relativerWertDrucken
            werterhoehendesZubehoer
            werterhoehenderWartungszustand
            werterhoehenderReparaturzustand
            zweiterRadsatzAnteilig
            externalServicesReferenceId
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        vtiWerte {
            id
            bezugsdatum
            haendlereinkaufswert
            haendlereinkaufswertDrucken
            haendlereinkaufswertNetto
            haendlereinkaufswertNettoDrucken
            haendlerverkaufswert
            haendlerverkaufswertBesteuerung
            haendlerverkaufswertDrucken
            haendlerverkaufswertNetto
            haendlerverkaufswertNettoDrucken
            wertAmMarkt
            wertAmMarktBemerkungen {
                ...TextbausteinFields
            }
            neuwert
            neuwertDrucken
            relativerWert
            relativerWertDrucken
            externalServicesReferenceId
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        lackmessung {
            id
            messungen {
                id
                position
                dicke
                ergebnis
                beschreibung
                bilder
                bemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            gesamtKarosserieVon
            gesamtKarosserieBis
            untersuchungsErgebnis
            beschreibungen {
                ...TextbausteinFields
            }
            notizen {
                id
                createdAt
                datei
                sprachnachricht
                textnachricht
            }
        }
        anhaenge {
            id
            anhaenge {
                id
                quelle
                bezeichnung
            }
        }
        restwertprognose {
            id
            bezugsdatum
            vertragsnummer
            vertragsbeginn
            vertragsende
            vereinbarteKilometer
            tatsaechlicheKilometer
            differenzKilometer
            datDossierId
            restwertNetto
            restwertBrutto
            dokument
        }
        kalkulation {
            id
            bezugsdatum
            dossierId
            reparaturkostenNetto
            reparaturkostenBrutto
            dokument
        }
        vtiAbschluss {
            id
        }
        cgAbschluss {
            id
            fazit {
                ...TextbausteinFields
            }
            abschlussBemerkung {
                ...TextbausteinFields
            }
        }
        acAbschluss {
            id
            acVersendet
        }
        abschluss {
            id
            fazit {
                ...TextbausteinFields
            }
            abschlussBemerkung {
                ...TextbausteinFields
            }
        }
    }

    ${TEXTBAUSTEIN_FIELDS}
`;
