import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { deleteNfzRaederNotiz, saveNfzRaederNotiz } from '@data/domain/graphql/mutations';
import { Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '../../produkt.service';
import { ProduktFeatureNotizenService } from './produkt-feature-notizen.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktNfzRaederNotizenService extends ProduktFeatureNotizenService {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    getNotizen(produkt: Produkt): Notiz[] {
        if (!produkt.nfzRaeder.notizen) {
            produkt.nfzRaeder.notizen = [];
        }
        return produkt.nfzRaeder.notizen;
    }

    protected getSaveNotizMutation(): any {
        return saveNfzRaederNotiz;
    }

    protected getSaveNotizMutationName(): string {
        return 'saveNfzRaederNotiz';
    }

    protected getDeleteNotizMutation(): any {
        return deleteNfzRaederNotiz;
    }

    protected getDeleteNotizMutationName(): any {
        return 'deleteNfzRaederNotiz';
    }
}
