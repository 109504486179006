import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { TrackBy } from '@shared/helper/track-by';
import { Subscription } from 'rxjs';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends FormControlBase implements OnDestroy {
    private checkboxChangeSubscription$: Subscription = undefined;

    trackByKey = TrackBy.trackByKey;

    @Input()
    checked: boolean;

    @Input()
    valuePrefix = '';

    @Input()
    disabled = false;

    @ViewChild(MatCheckbox, { static: true })
    checkbox: MatCheckbox;

    @Output()
    valueChanged = new EventEmitter<MatCheckbox>();

    ngOnDestroy(): void {
        this.checkboxChangeSubscription$.unsubscribe();
    }

    blur(): void {
        if (this.checkboxChangeSubscription$) {
            this.checkboxChangeSubscription$.unsubscribe();
            this.checkboxChangeSubscription$ = undefined;
        }
    }

    focus(): void {
        this.checkbox.focus();
        if (this.checkboxChangeSubscription$ === undefined) {
            this.checkboxChangeSubscription$ = this.checkbox.change.subscribe((value: boolean) => {
                if (!value) {
                    this.next();
                }
            });
        }
    }
}
