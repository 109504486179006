import { Feature } from '@modules/produkt/config/produkt-config';

export class ProduktFeatureConfig {
    static createAuftrag(d: any): Feature {
        return {
            name: d.Auftrag.name,
            fields: [
                d.Auftrag.fields.Art,
                d.Auftrag.fields.Nummer,
                d.Auftrag.fields.Vorgangsnummer,
                {
                    groupName: 'erstellungsTagGroup',
                    fields: [d.Auftrag.fields.ErstellungsTag, d.Auftrag.fields.ErstellungsUhrzeit],
                },
                {
                    groupName: 'besichtigungsTagGroup',
                    fields: [d.Auftrag.fields.BesichtigungsTag, d.Auftrag.fields.BesichtigungsUhrzeit],
                },
                {
                    groupName: 'fertigstellungsTagGroup',
                    fields: [d.Auftrag.fields.FertigstellungsTag, d.Auftrag.fields.FertigstellungsUhrzeit],
                },
                {
                    arrayName: d.Auftrag.fields.Bemerkungen.name,
                    fields: [
                        d.Auftrag.fields.Bemerkungen.fields.Id,
                        d.Auftrag.fields.Bemerkungen.fields.Kurztext,
                        d.Auftrag.fields.Bemerkungen.fields.Langtext,
                        d.Auftrag.fields.Bemerkungen.fields.Verfuegbarkeit,
                        d.Auftrag.fields.Bemerkungen.fields.Produkte,
                        d.Auftrag.fields.Bemerkungen.fields.Feature,
                        d.Auftrag.fields.Bemerkungen.fields.Feld,
                        d.Auftrag.fields.Bemerkungen.fields.Tags,
                        d.Auftrag.fields.Bemerkungen.fields.Standard,
                        d.Auftrag.fields.Bemerkungen.fields.ErstelltAm,
                        d.Auftrag.fields.Bemerkungen.fields.ErstelltVon,
                    ],
                },
                {
                    arrayName: d.Auftrag.fields.Auftragsbeschreibung.name,
                    fields: [
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Id,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Kurztext,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Langtext,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Verfuegbarkeit,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Produkte,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Feature,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Feld,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Tags,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.Standard,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.ErstelltAm,
                        d.Auftrag.fields.Auftragsbeschreibung.fields.ErstelltVon,
                    ],
                },
                {
                    arrayName: d.Auftrag.fields.Besichtigungsbedingungen.name,
                    fields: [
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Id,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Kurztext,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Langtext,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Verfuegbarkeit,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Produkte,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Feature,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Feld,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Tags,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.Standard,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.ErstelltAm,
                        d.Auftrag.fields.Besichtigungsbedingungen.fields.ErstelltVon,
                    ],
                },
                {
                    arrayName: d.Auftrag.fields.Hinweise.name,
                    fields: [d.Auftrag.fields.Hinweise.fields.Name, d.Auftrag.fields.Hinweise.fields.Vorhanden],
                },
            ],
        };
    }

    static createVtiAuftrag(d: any): Feature {
        return {
            name: d.VtiAuftrag.name,
            fields: [
                d.VtiAuftrag.fields.Art,
                d.VtiAuftrag.fields.Nummer,
                d.VtiAuftrag.fields.Auftragstyp,
                d.VtiAuftrag.fields.Vorgangsnummer,
                d.VtiAuftrag.fields.BezugsAuftragsnummer,
                d.VtiAuftrag.fields.Bemerkung,
            ],
        };
    }

    static createFahrzeug(d: any): Feature {
        return {
            name: d.Fahrzeug.name,
            fields: [
                d.Fahrzeug.fields.Kennzeichen,
                d.Fahrzeug.fields.Identnummer,
                d.Fahrzeug.fields.FahrzeugExternalServiceReference,
                d.Fahrzeug.fields.VinAbfrageErfolgt,
                {
                    groupName: 'codeGroup',
                    fields: [d.Fahrzeug.fields.HerstellerCode, d.Fahrzeug.fields.TypCode],
                },
                {
                    groupName: 'zulassungGroup',
                    fields: [d.Fahrzeug.fields.Erstzulassung, d.Fahrzeug.fields.LetzteZulassung],
                },
                d.Fahrzeug.fields.Laufleistung,
                d.Fahrzeug.fields.LaufleistungEinheit,
                d.Fahrzeug.fields.LaufleistungBeschreibung,
                {
                    arrayName: d.Fahrzeug.fields.Laufleistungen.name,
                    fields: [
                        d.Fahrzeug.fields.Laufleistungen.fields.Wert,
                        d.Fahrzeug.fields.Laufleistungen.fields.Einheit,
                        d.Fahrzeug.fields.Laufleistungen.fields.Beschreibung,
                    ],
                },
                {
                    groupName: 'bauformGroup',
                    fields: [
                        d.Fahrzeug.fields.Fahrzeugart,
                        d.Fahrzeug.fields.Bauform,
                        d.Fahrzeug.fields.FahrzeugartManuell,
                        d.Fahrzeug.fields.BauformManuell,
                    ],
                },
                d.Fahrzeug.fields.Hersteller,
                d.Fahrzeug.fields.Modell,
                d.Fahrzeug.fields.Untertyp,
                d.Fahrzeug.fields.Lackcode,
                d.Fahrzeug.fields.Innenausstattung,
                d.Fahrzeug.fields.Polsterfarbe,
                d.Fahrzeug.fields.Polstermaterial,
                d.Fahrzeug.fields.Antriebsart,
                d.Fahrzeug.fields.Antriebstechnologie,
                d.Fahrzeug.fields.Schadstoffklasse,
                {
                    groupName: 'motorGroup',
                    fields: [d.Fahrzeug.fields.Hubraum, d.Fahrzeug.fields.Leistung, d.Fahrzeug.fields.Zylinder],
                },
                {
                    groupName: 'getriebeGroup',
                    fields: [d.Fahrzeug.fields.Getriebeart, d.Fahrzeug.fields.AnzahlGaenge],
                },
                {
                    groupName: 'gewichtGroup',
                    fields: [d.Fahrzeug.fields.Leergewicht, d.Fahrzeug.fields.ZulaessigesGesamtgewicht],
                },
                {
                    groupName: 'anzahlGroup',
                    fields: [d.Fahrzeug.fields.AnzahlTueren, d.Fahrzeug.fields.AnzahlSitze],
                },
                {
                    groupName: 'masseGroup',
                    fields: [
                        d.Fahrzeug.fields.MasseLaenge,
                        d.Fahrzeug.fields.MasseBreite,
                        d.Fahrzeug.fields.MasseHoehe,
                    ],
                },
                d.Fahrzeug.fields.AnzahlVorbesitzer,
                d.Fahrzeug.fields.ScheckheftGepflegt,
                d.Fahrzeug.fields.DatECode,
                {
                    arrayName: d.Fahrzeug.fields.Bemerkungen.name,
                    fields: [
                        d.Fahrzeug.fields.Bemerkungen.fields.Id,
                        d.Fahrzeug.fields.Bemerkungen.fields.Kurztext,
                        d.Fahrzeug.fields.Bemerkungen.fields.Langtext,
                        d.Fahrzeug.fields.Bemerkungen.fields.Verfuegbarkeit,
                        d.Fahrzeug.fields.Bemerkungen.fields.Produkte,
                        d.Fahrzeug.fields.Bemerkungen.fields.Feature,
                        d.Fahrzeug.fields.Bemerkungen.fields.Feld,
                        d.Fahrzeug.fields.Bemerkungen.fields.Tags,
                        d.Fahrzeug.fields.Bemerkungen.fields.Standard,
                        d.Fahrzeug.fields.Bemerkungen.fields.ErstelltAm,
                        d.Fahrzeug.fields.Bemerkungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createAcFahrzeug(d: any): Feature {
        return {
            name: d.Fahrzeug.name,
            fields: [
                d.Fahrzeug.fields.Kennzeichen,
                d.Fahrzeug.fields.Identnummer,
                d.Fahrzeug.fields.FahrzeugExternalServiceReference,
                d.Fahrzeug.fields.VinAbfrageErfolgt,
                {
                    groupName: 'codeGroup',
                    fields: [d.Fahrzeug.fields.HerstellerCode, d.Fahrzeug.fields.TypCode],
                },
                {
                    groupName: 'zulassungGroup',
                    fields: [d.Fahrzeug.fields.Erstzulassung, d.Fahrzeug.fields.LetzteZulassung],
                },
                d.Fahrzeug.fields.Laufleistung,
                d.Fahrzeug.fields.LaufleistungEinheit,
                d.Fahrzeug.fields.LaufleistungBeschreibung,
                {
                    arrayName: d.Fahrzeug.fields.Laufleistungen.name,
                    fields: [
                        d.Fahrzeug.fields.Laufleistungen.fields.Wert,
                        d.Fahrzeug.fields.Laufleistungen.fields.Einheit,
                        d.Fahrzeug.fields.Laufleistungen.fields.Beschreibung,
                    ],
                },
                {
                    groupName: 'bauformGroup',
                    fields: [d.Fahrzeug.fields.Fahrzeugart, d.Fahrzeug.fields.Bauform],
                },
                {
                    groupName: 'acBauformGroup',
                    fields: [d.Fahrzeug.fields.AcFahrzeugart, d.Fahrzeug.fields.AcBauform],
                },
                d.Fahrzeug.fields.Hersteller,
                d.Fahrzeug.fields.Modell,
                d.Fahrzeug.fields.Untertyp,
                {
                    groupName: 'acLackfarbeGroup',
                    fields: [d.Fahrzeug.fields.AcLackfarbe, d.Fahrzeug.fields.AcLackierung],
                },
                d.Fahrzeug.fields.Innenausstattung,
                d.Fahrzeug.fields.Polsterfarbe,
                d.Fahrzeug.fields.Polstermaterial,
                d.Fahrzeug.fields.Antriebsart,
                d.Fahrzeug.fields.AcAntriebstechnologie,
                d.Fahrzeug.fields.AcSchadstoffklasse,
                {
                    groupName: 'motorGroup',
                    fields: [d.Fahrzeug.fields.Hubraum, d.Fahrzeug.fields.Leistung, d.Fahrzeug.fields.Zylinder],
                },
                {
                    groupName: 'getriebeGroup',
                    fields: [d.Fahrzeug.fields.AcGetriebeart, d.Fahrzeug.fields.AnzahlGaenge],
                },
                {
                    groupName: 'gewichtGroup',
                    fields: [d.Fahrzeug.fields.Leergewicht, d.Fahrzeug.fields.ZulaessigesGesamtgewicht],
                },
                {
                    groupName: 'anzahlGroup',
                    fields: [d.Fahrzeug.fields.AnzahlTueren, d.Fahrzeug.fields.AnzahlSitze],
                },
                {
                    groupName: 'masseGroup',
                    fields: [
                        d.Fahrzeug.fields.MasseLaenge,
                        d.Fahrzeug.fields.MasseBreite,
                        d.Fahrzeug.fields.MasseHoehe,
                    ],
                },
                d.Fahrzeug.fields.AnzahlVorbesitzer,
                d.Fahrzeug.fields.ScheckheftGepflegt,
                d.Fahrzeug.fields.DatECode,
                d.Fahrzeug.fields.HvBatterie,
                d.Fahrzeug.fields.AcEhemaligeNutzung,
                {
                    arrayName: d.Fahrzeug.fields.Bemerkungen.name,
                    fields: [
                        d.Fahrzeug.fields.Bemerkungen.fields.Id,
                        d.Fahrzeug.fields.Bemerkungen.fields.Kurztext,
                        d.Fahrzeug.fields.Bemerkungen.fields.Langtext,
                        d.Fahrzeug.fields.Bemerkungen.fields.Verfuegbarkeit,
                        d.Fahrzeug.fields.Bemerkungen.fields.Produkte,
                        d.Fahrzeug.fields.Bemerkungen.fields.Feature,
                        d.Fahrzeug.fields.Bemerkungen.fields.Feld,
                        d.Fahrzeug.fields.Bemerkungen.fields.Tags,
                        d.Fahrzeug.fields.Bemerkungen.fields.Standard,
                        d.Fahrzeug.fields.Bemerkungen.fields.ErstelltAm,
                        d.Fahrzeug.fields.Bemerkungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createWerte(d: any, hasRestwertAusUnfallschaden: boolean): Feature {
        return {
            name: d.Werte.name,
            fields: [
                d.Werte.fields.Bezugsdatum,
                d.Werte.fields.RoundValue,
                d.Werte.fields.Haendlereinkaufswert,
                d.Werte.fields.HaendlereinkaufswertDrucken,
                d.Werte.fields.HaendlereinkaufswertNetto,
                d.Werte.fields.HaendlereinkaufswertNettoDrucken,
                d.Werte.fields.Haendlerverkaufswert,
                d.Werte.fields.HaendlerverkaufswertDrucken,
                d.Werte.fields.HaendlerverkaufswertBesteuerung,
                d.Werte.fields.HaendlerverkaufswertNetto,
                d.Werte.fields.HaendlerverkaufswertNettoDrucken,
                d.Werte.fields.DatHvkNettoDifferenz,
                d.Werte.fields.DatHvkNettoRegel,
                d.Werte.fields.Aufwendungen,
                d.Werte.fields.AufwendungenDrucken,
                d.Werte.fields.AufwendungenDetailsDrucken,
                d.Werte.fields.ManuelleWerteDrucken,
                d.Werte.fields.HaendlereinkaufswertAbzuege,
                d.Werte.fields.HaendlereinkaufswertAbzuegeDrucken,
                d.Werte.fields.HaendlereinkaufswertAbzuegeNetto,
                d.Werte.fields.HaendlereinkaufswertAbzuegeNettoDrucken,
                d.Werte.fields.HaendlerverkaufswertAbzuege,
                d.Werte.fields.HaendlerverkaufswertAbzuegeDrucken,
                d.Werte.fields.HaendlerverkaufswertAbzuegeNetto,
                d.Werte.fields.HaendlerverkaufswertAbzuegeNettoDrucken,
                {
                    arrayName: d.Werte.fields.ManuelleWerte.name,
                    fields: [d.Werte.fields.ManuelleWerte.fields.Bezeichnung, d.Werte.fields.ManuelleWerte.fields.Wert],
                },
                d.Werte.fields.WertAmMarkt,
                {
                    arrayName: d.Werte.fields.WertAmMarktBemerkungen.name,
                    fields: [
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Id,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Kurztext,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Langtext,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Verfuegbarkeit,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Produkte,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Feature,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Feld,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Tags,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.Standard,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.ErstelltAm,
                        d.Werte.fields.WertAmMarktBemerkungen.fields.ErstelltVon,
                    ],
                },
                hasRestwertAusUnfallschaden ? d.Werte.fields.RestwertAusUnfallschaden : {},
                hasRestwertAusUnfallschaden ? d.Werte.fields.RestwertAusUnfallschadenNetto : {},
                d.Werte.fields.Neuwert,
                d.Werte.fields.NeuwertDrucken,
                d.Werte.fields.RelativerWert,
                d.Werte.fields.RelativerWertDrucken,
                d.Werte.fields.WerterhoehendesZubehoer,
                d.Werte.fields.WerterhoehenderWartungszustand,
                d.Werte.fields.WerterhoehenderReparaturzustand,
                d.Werte.fields.ZweiterRadsatzAnteilig,
                d.Werte.fields.ExternalServicesReferenceId,
            ],
        };
    }

    static createVtiWerte(d: any): Feature {
        return {
            name: d.VtiWerte.name,
            fields: [
                d.VtiWerte.fields.Bezugsdatum,
                d.VtiWerte.fields.Haendlereinkaufswert,
                d.VtiWerte.fields.HaendlereinkaufswertDrucken,
                d.VtiWerte.fields.HaendlereinkaufswertNetto,
                d.VtiWerte.fields.HaendlereinkaufswertNettoDrucken,
                d.VtiWerte.fields.Haendlerverkaufswert,
                d.VtiWerte.fields.HaendlerverkaufswertDrucken,
                d.VtiWerte.fields.HaendlerverkaufswertBesteuerung,
                d.VtiWerte.fields.HaendlerverkaufswertNetto,
                d.VtiWerte.fields.HaendlerverkaufswertNettoDrucken,
                d.VtiWerte.fields.WertAmMarkt,
                {
                    arrayName: d.VtiWerte.fields.WertAmMarktBemerkungen.name,
                    fields: [
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Id,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Kurztext,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Langtext,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Verfuegbarkeit,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Produkte,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Feature,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Feld,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Tags,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.Standard,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.ErstelltAm,
                        d.VtiWerte.fields.WertAmMarktBemerkungen.fields.ErstelltVon,
                    ],
                },
                d.VtiWerte.fields.Neuwert,
                d.VtiWerte.fields.NeuwertDrucken,
                d.VtiWerte.fields.RelativerWert,
                d.VtiWerte.fields.RelativerWertDrucken,
                d.VtiWerte.fields.ExternalServicesReferenceId,
            ],
        };
    }

    static createAdressen(d: any): Feature {
        return {
            name: d.Adressen.name,
            fields: [
                d.Adressen.fields.Auftraggeber,
                {
                    arrayName: d.Adressen.fields.Adressen.name,
                    fields: [
                        d.Adressen.fields.Adressen.fields.Id,
                        d.Adressen.fields.Adressen.fields.CreatedAt,
                        d.Adressen.fields.Adressen.fields.Anrede,
                        d.Adressen.fields.Adressen.fields.AnredeFreitext,
                        d.Adressen.fields.Adressen.fields.Firma,
                        d.Adressen.fields.Adressen.fields.Vorname,
                        d.Adressen.fields.Adressen.fields.Name,
                        d.Adressen.fields.Adressen.fields.StrasseNr,
                        d.Adressen.fields.Adressen.fields.Postleitzahl,
                        d.Adressen.fields.Adressen.fields.Ort,
                        d.Adressen.fields.Adressen.fields.Telefon,
                        d.Adressen.fields.Adressen.fields.Mobil,
                        d.Adressen.fields.Adressen.fields.Email,
                        d.Adressen.fields.Adressen.fields.ExternalId,
                        d.Adressen.fields.Adressen.fields.Arten,
                        d.Adressen.fields.Adressen.fields.NameGroup,
                        d.Adressen.fields.Adressen.fields.DruckArt,
                        d.Adressen.fields.Adressen.fields.Versand,
                    ],
                },
            ],
        };
    }

    static createUebersicht(d: any): Feature {
        return {
            name: d.Uebersicht.name,
            fields: [
                d.Uebersicht.fields.Deckblatt,
                d.Uebersicht.fields.VorneLinks,
                d.Uebersicht.fields.VorneRechts,
                d.Uebersicht.fields.HintenLinks,
                d.Uebersicht.fields.HintenRechts,
                d.Uebersicht.fields.Innenraum,
                {
                    arrayName: d.Uebersicht.fields.Sonstiges.name,
                    fields: [
                        d.Uebersicht.fields.Sonstiges.fields.Bilder,
                        d.Uebersicht.fields.Sonstiges.fields.Bezeichnung,
                        {
                            arrayName: d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.name,
                            fields: [
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Id,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Kurztext,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Langtext,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Verfuegbarkeit,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Produkte,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Feature,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Feld,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Tags,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.Standard,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.ErstelltAm,
                                d.Uebersicht.fields.Sonstiges.fields.Bezeichnungen.fields.ErstelltVon,
                            ],
                        },
                    ],
                },
                d.Uebersicht.fields.FahrzeugVorhanden,
            ],
        };
    }

    static createRaeder(d: any): Feature {
        return {
            name: d.Raeder.name,
            fields: [
                {
                    arrayName: d.Raeder.fields.RadSaetze.name,
                    fields: [
                        d.Raeder.fields.RadSaetze.fields.Lagerort,
                        {
                            arrayName: d.Raeder.fields.RadSaetze.fields.Rads.name,
                            fields: [
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.RadPosition,
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.Hersteller,
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.ReifenTyp,
                                {
                                    groupName: 'reifenGroup',
                                    fields: [
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Reifenbreite,
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Querschnitt,
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Bauart,
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Felgengroesse,
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Tragfaehigkeit1,
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Geschwindigkeitsindex,
                                    ],
                                },
                                {
                                    groupName: 'profilDotGroup',
                                    fields: [
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Profil,
                                        d.Raeder.fields.RadSaetze.fields.Rads.fields.Dot,
                                    ],
                                },
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.Zulaessig,
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.Runflat,
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.RadFelge,
                                d.Raeder.fields.RadSaetze.fields.Rads.fields.Felgenhersteller,
                            ],
                        },
                    ],
                },
                d.Raeder.fields.Notlaufeinrichtung,
                d.Raeder.fields.NotlaufeinrichtungProfiltiefe,
                {
                    arrayName: d.Raeder.fields.Bemerkungen.name,
                    fields: [
                        d.Raeder.fields.Bemerkungen.fields.Id,
                        d.Raeder.fields.Bemerkungen.fields.Kurztext,
                        d.Raeder.fields.Bemerkungen.fields.Langtext,
                        d.Raeder.fields.Bemerkungen.fields.Verfuegbarkeit,
                        d.Raeder.fields.Bemerkungen.fields.Produkte,
                        d.Raeder.fields.Bemerkungen.fields.Feature,
                        d.Raeder.fields.Bemerkungen.fields.Feld,
                        d.Raeder.fields.Bemerkungen.fields.Tags,
                        d.Raeder.fields.Bemerkungen.fields.Standard,
                        d.Raeder.fields.Bemerkungen.fields.ErstelltAm,
                        d.Raeder.fields.Bemerkungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createNfzRaeder(d: any): Feature {
        return {
            name: d.NfzRaeder.name,
            fields: [
                {
                    arrayName: d.NfzRaeder.fields.RadSaetze.name,
                    fields: [
                        d.NfzRaeder.fields.RadSaetze.fields.Lagerort,
                        {
                            arrayName: d.NfzRaeder.fields.RadSaetze.fields.Achsen.name,
                            fields: [
                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Bewertung,
                                {
                                    arrayName: d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.name,
                                    fields: [
                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields.Seite,
                                        {
                                            arrayName:
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.name,
                                            fields: [
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.Position,
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.Hersteller,
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.Typ,
                                                {
                                                    groupName: 'reifenGroup',
                                                    fields: [
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Reifenbreite,
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Querschnitt,
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Bauart,
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Felgengroesse,
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Tragfaehigkeit,
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Geschwindigkeitsindex,
                                                    ],
                                                },
                                                {
                                                    groupName: 'profilDotGroup',
                                                    fields: [
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Profiltiefe,
                                                        d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                            .AchsenRaeder.fields.Dot,
                                                    ],
                                                },
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.Zustand,
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.ReifenDruck,
                                                d.Raeder.fields.RadSaetze.fields.Rads.fields.Runflat,
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.Felge,
                                                d.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields
                                                    .AchsenRaeder.fields.Felgenhersteller,
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                d.NfzRaeder.fields.Notlaufeinrichtung,
                d.NfzRaeder.fields.NotlaufeinrichtungProfiltiefe,
                {
                    arrayName: d.NfzRaeder.fields.Bemerkungen.name,
                    fields: [
                        d.NfzRaeder.fields.Bemerkungen.fields.Id,
                        d.NfzRaeder.fields.Bemerkungen.fields.Kurztext,
                        d.NfzRaeder.fields.Bemerkungen.fields.Langtext,
                        d.NfzRaeder.fields.Bemerkungen.fields.Verfuegbarkeit,
                        d.NfzRaeder.fields.Bemerkungen.fields.Produkte,
                        d.NfzRaeder.fields.Bemerkungen.fields.Feature,
                        d.NfzRaeder.fields.Bemerkungen.fields.Feld,
                        d.NfzRaeder.fields.Bemerkungen.fields.Tags,
                        d.NfzRaeder.fields.Bemerkungen.fields.Standard,
                        d.NfzRaeder.fields.Bemerkungen.fields.ErstelltAm,
                        d.NfzRaeder.fields.Bemerkungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createLackmessung(d: any): Feature {
        return {
            name: d.Lackmessung.name,
            fields: [
                {
                    arrayName: d.Lackmessung.fields.Messungen.name,
                    fields: [
                        d.Lackmessung.fields.Messungen.fields.Position,
                        d.Lackmessung.fields.Messungen.fields.Dicke,
                        d.Lackmessung.fields.Messungen.fields.Ergebnis,
                        d.Lackmessung.fields.Messungen.fields.Beschreibung,
                        d.Lackmessung.fields.Messungen.fields.Bilder,
                        {
                            arrayName: d.Lackmessung.fields.Messungen.fields.Bemerkungen.name,
                            fields: [
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Id,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Kurztext,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Langtext,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Verfuegbarkeit,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Produkte,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Feature,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Feld,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Tags,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.Standard,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.ErstelltAm,
                                d.Lackmessung.fields.Messungen.fields.Bemerkungen.fields.ErstelltVon,
                            ],
                        },
                    ],
                },
                {
                    groupName: 'gesamtKarosserie',
                    fields: [d.Lackmessung.fields.GesamtKarosserieVon, d.Lackmessung.fields.GesamtKarosserieBis],
                },
                d.Lackmessung.fields.UntersuchungsErgebnis,
                {
                    arrayName: d.Lackmessung.fields.Beschreibungen.name,
                    fields: [
                        d.Lackmessung.fields.Beschreibungen.fields.Id,
                        d.Lackmessung.fields.Beschreibungen.fields.Kurztext,
                        d.Lackmessung.fields.Beschreibungen.fields.Langtext,
                        d.Lackmessung.fields.Beschreibungen.fields.Verfuegbarkeit,
                        d.Lackmessung.fields.Beschreibungen.fields.Produkte,
                        d.Lackmessung.fields.Beschreibungen.fields.Feature,
                        d.Lackmessung.fields.Beschreibungen.fields.Feld,
                        d.Lackmessung.fields.Beschreibungen.fields.Tags,
                        d.Lackmessung.fields.Beschreibungen.fields.Standard,
                        d.Lackmessung.fields.Beschreibungen.fields.ErstelltAm,
                        d.Lackmessung.fields.Beschreibungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createVorschaden(d: any): Feature {
        return {
            name: d.Vorschaden.name,
            fields: [
                {
                    arrayName: d.Vorschaden.fields.Positionen.name,
                    fields: [
                        d.Vorschaden.fields.Positionen.fields.Obergruppe,
                        d.Vorschaden.fields.Positionen.fields.Untergruppe,
                        d.Vorschaden.fields.Positionen.fields.Art,
                        d.Vorschaden.fields.Positionen.fields.Reparatur,
                        d.Vorschaden.fields.Positionen.fields.Bilder,
                        d.Vorschaden.fields.Positionen.fields.Schadendatum,
                        d.Vorschaden.fields.Positionen.fields.ExternalId,
                        d.Vorschaden.fields.Positionen.fields.Extern,
                        d.Vorschaden.fields.Positionen.fields.Bestaetigt,
                        {
                            groupName: 'wertminderungGroup',
                            fields: [
                                d.Vorschaden.fields.Positionen.fields.Schadenhoehe,
                                d.Vorschaden.fields.Positionen.fields.Wertminderung,
                            ],
                        },
                    ],
                },
            ],
        };
    }

    static createSchaden(d: any, minderwert: boolean): Feature {
        return {
            name: d.Schaden.name,
            fields: [
                {
                    arrayName: d.Schaden.fields.Positionen.name,
                    fields: [
                        d.Schaden.fields.Positionen.fields.Obergruppe,
                        d.Schaden.fields.Positionen.fields.Untergruppe,
                        d.Schaden.fields.Positionen.fields.Beschaedigungart,
                        d.Schaden.fields.Positionen.fields.Reparaturweg,
                        d.Schaden.fields.Positionen.fields.Bilder,
                        d.Schaden.fields.Positionen.fields.Intensitaet,
                        d.Schaden.fields.Positionen.fields.RelativerWert,
                        d.Schaden.fields.Positionen.fields.ExternalId,
                        d.Vorschaden.fields.Positionen.fields.Extern,
                        d.Vorschaden.fields.Positionen.fields.Bestaetigt,
                        !minderwert
                            ? d.Schaden.fields.Positionen.fields.Preis
                            : {
                                  groupName: 'minderwertGroup',
                                  fields: [
                                      d.Schaden.fields.Positionen.fields.Preis,
                                      d.Schaden.fields.Positionen.fields.Minderwert,
                                  ],
                              },
                    ],
                },
                {
                    arrayName: d.Schaden.fields.Beschreibungen.name,
                    fields: [
                        d.Schaden.fields.Beschreibungen.fields.Id,
                        d.Schaden.fields.Beschreibungen.fields.Kurztext,
                        d.Schaden.fields.Beschreibungen.fields.Langtext,
                        d.Schaden.fields.Beschreibungen.fields.Verfuegbarkeit,
                        d.Schaden.fields.Beschreibungen.fields.Produkte,
                        d.Schaden.fields.Beschreibungen.fields.Feature,
                        d.Schaden.fields.Beschreibungen.fields.Feld,
                        d.Schaden.fields.Beschreibungen.fields.Tags,
                        d.Schaden.fields.Beschreibungen.fields.Standard,
                        d.Schaden.fields.Beschreibungen.fields.ErstelltAm,
                        d.Schaden.fields.Beschreibungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createFeststellungen(d: any): Feature {
        return {
            name: d.Feststellungen.name,
            fields: [
                {
                    arrayName: d.Feststellungen.fields.Umfaenge.name,
                    fields: [
                        d.Feststellungen.fields.Umfaenge.fields.Obergruppe,
                        d.Feststellungen.fields.Umfaenge.fields.ObergruppeName,
                        {
                            arrayName: d.Feststellungen.fields.Umfaenge.fields.Beschreibung.name,
                            fields: [
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Id,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Kurztext,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Langtext,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Verfuegbarkeit,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Produkte,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Feature,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Feld,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Tags,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.Standard,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.ErstelltAm,
                                d.Feststellungen.fields.Umfaenge.fields.Beschreibung.fields.ErstelltVon,
                            ],
                        },
                    ],
                },
                {
                    arrayName: d.Feststellungen.fields.Positionen.name,
                    fields: [
                        d.Feststellungen.fields.Positionen.fields.Obergruppe,
                        d.Feststellungen.fields.Positionen.fields.Untergruppe,
                        {
                            arrayName: d.Feststellungen.fields.Positionen.fields.Zustand.name,
                            fields: [
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Id,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Kurztext,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Langtext,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Verfuegbarkeit,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Produkte,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Feature,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Feld,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Tags,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.Standard,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.ErstelltAm,
                                d.Feststellungen.fields.Positionen.fields.Zustand.fields.ErstelltVon,
                            ],
                        },
                        d.Feststellungen.fields.Positionen.fields.Beschaedigungart,
                        d.Feststellungen.fields.Positionen.fields.Bilder,
                        d.Feststellungen.fields.Positionen.fields.Preis,
                        d.Feststellungen.fields.Positionen.fields.ExternalId,
                        d.Vorschaden.fields.Positionen.fields.Extern,
                        d.Vorschaden.fields.Positionen.fields.Bestaetigt,
                    ],
                },
                {
                    arrayName: d.Feststellungen.fields.Beschreibungen.name,
                    fields: [
                        d.Feststellungen.fields.Beschreibungen.fields.Id,
                        d.Feststellungen.fields.Beschreibungen.fields.Kurztext,
                        d.Feststellungen.fields.Beschreibungen.fields.Langtext,
                        d.Feststellungen.fields.Beschreibungen.fields.Verfuegbarkeit,
                        d.Feststellungen.fields.Beschreibungen.fields.Produkte,
                        d.Feststellungen.fields.Beschreibungen.fields.Feature,
                        d.Feststellungen.fields.Beschreibungen.fields.Feld,
                        d.Feststellungen.fields.Beschreibungen.fields.Tags,
                        d.Feststellungen.fields.Beschreibungen.fields.Standard,
                        d.Feststellungen.fields.Beschreibungen.fields.ErstelltAm,
                        d.Feststellungen.fields.Beschreibungen.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createAusstattung(d: any): Feature {
        return {
            name: d.Ausstattung.name,
            fields: [
                {
                    arrayName: d.Ausstattung.fields.Gruppen.name,
                    fields: [
                        d.Ausstattung.fields.Gruppen.fields.Name,
                        d.Ausstattung.fields.Gruppen.fields.Sonderausstattung,
                        {
                            arrayName: d.Ausstattung.fields.Gruppen.fields.Teile.name,
                            fields: [
                                d.Ausstattung.fields.Gruppen.fields.Teile.fields.Name,
                                d.Ausstattung.fields.Gruppen.fields.Teile.fields.Vorhanden,
                                d.Ausstattung.fields.Gruppen.fields.Teile.fields.ExternalServiceId,
                            ],
                        },
                    ],
                },
                {
                    arrayName: d.Ausstattung.fields.Individualaufbauten.name,
                    fields: [
                        d.Ausstattung.fields.Individualaufbauten.fields.Id,
                        d.Ausstattung.fields.Individualaufbauten.fields.Kurztext,
                        d.Ausstattung.fields.Individualaufbauten.fields.Langtext,
                        d.Ausstattung.fields.Individualaufbauten.fields.Verfuegbarkeit,
                        d.Ausstattung.fields.Individualaufbauten.fields.Produkte,
                        d.Ausstattung.fields.Individualaufbauten.fields.Feature,
                        d.Ausstattung.fields.Individualaufbauten.fields.Feld,
                        d.Ausstattung.fields.Individualaufbauten.fields.Tags,
                        d.Ausstattung.fields.Individualaufbauten.fields.Standard,
                        d.Ausstattung.fields.Individualaufbauten.fields.ErstelltAm,
                        d.Ausstattung.fields.Individualaufbauten.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createUnterlagen(d: any): Feature {
        return {
            name: d.Unterlagen.name,
            fields: [
                d.Unterlagen.fields.SchluesselFahrzeug,
                d.Unterlagen.fields.SchluesselAHK,
                d.Unterlagen.fields.FernbedienungStandheizung,
                {
                    arrayName: d.Unterlagen.fields.Positionen.name,
                    fields: [
                        d.Unterlagen.fields.Positionen.fields.Bezeichnung,
                        d.Unterlagen.fields.Positionen.fields.Bilder,
                    ],
                },
            ],
        };
    }

    static createFehlteile(d: any): Feature {
        return {
            name: d.Fehlteile.name,
            fields: [
                {
                    arrayName: d.Fehlteile.fields.Positionen.name,
                    fields: [
                        d.Fehlteile.fields.Positionen.fields.ExternalId,
                        d.Fehlteile.fields.Positionen.fields.Bezeichnung,
                        d.Fehlteile.fields.Positionen.fields.Preis,
                    ],
                },
            ],
        };
    }

    static createWartung(d: any): Feature {
        return {
            name: d.Wartung.name,
            fields: [
                d.Wartung.fields.LetzterServiceNichtBekannt,
                {
                    groupName: 'letzterServiceGroup',
                    fields: [
                        d.Wartung.fields.LetzterServiceBei,
                        d.Wartung.fields.LetzterServiceFaelligEinheit,
                        d.Wartung.fields.LetzterServiceAm,
                    ],
                },
                d.Wartung.fields.NaechsterServiceFaelligNichtBekannt,
                {
                    groupName: 'naechsterServiceGroup',
                    fields: [
                        d.Wartung.fields.NaechsterServiceFaelligIn,
                        d.Wartung.fields.NaechsterServiceFaelligEinheit,
                        d.Wartung.fields.NaechsterServiceFaelligInZeit,
                        d.Wartung.fields.NaechsterServiceFaelligEinheitZeit,
                        d.Wartung.fields.NaechsterServiceFaelligAm,
                    ],
                },
                d.Wartung.fields.NaechsterServiceFaelligKosten,
                d.Wartung.fields.NaechsterServiceFaelligExternalId,
                {
                    arrayName: d.Wartung.fields.NaechsterServiceBeschreibung.name,
                    fields: [
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Id,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Kurztext,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Langtext,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Verfuegbarkeit,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Produkte,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Feature,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Feld,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Tags,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.Standard,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.ErstelltAm,
                        d.Wartung.fields.NaechsterServiceBeschreibung.fields.ErstelltVon,
                    ],
                },
                d.Wartung.fields.NaechsteHauptAbgasUntersuchungNichtBekannt,
                d.Wartung.fields.NaechsteHauptAbgasUntersuchung,
                d.Wartung.fields.HauptAbgasUntersuchungFaelligKosten,
                d.Wartung.fields.NaechsteHauptAbgasUntersuchungExternalId,
                {
                    groupName: 'zahnriemenwechselGroup',
                    fields: [
                        d.Wartung.fields.ZahnriemenwechselBei,
                        d.Wartung.fields.ZahnriemenwechselFaelligEinheit,
                        d.Wartung.fields.ZahnriemenwechselAm,
                    ],
                },
                d.Wartung.fields.ZahnriemenFaelligKosten,
                d.Wartung.fields.ZahnriemenFaelligExternalId,
                {
                    arrayName: d.Wartung.fields.Positionen.name,
                    fields: [
                        d.Wartung.fields.Positionen.fields.ExternalId,
                        d.Wartung.fields.Positionen.fields.Bezeichnung,
                        d.Wartung.fields.Positionen.fields.Kosten,
                    ],
                },
            ],
        };
    }

    static createAbschluss(d: any): Feature {
        return {
            name: d.Abschluss.name,
            fields: [
                d.Abschluss.fields.Adresse,
                {
                    arrayName: d.Abschluss.fields.Fazit.name,
                    fields: [
                        d.Abschluss.fields.Fazit.fields.Id,
                        d.Abschluss.fields.Fazit.fields.Kurztext,
                        d.Abschluss.fields.Fazit.fields.Langtext,
                        d.Abschluss.fields.Fazit.fields.Verfuegbarkeit,
                        d.Abschluss.fields.Fazit.fields.Produkte,
                        d.Abschluss.fields.Fazit.fields.Feature,
                        d.Abschluss.fields.Fazit.fields.Feld,
                        d.Abschluss.fields.Fazit.fields.Tags,
                        d.Abschluss.fields.Fazit.fields.Standard,
                        d.Abschluss.fields.Fazit.fields.ErstelltAm,
                        d.Abschluss.fields.Fazit.fields.ErstelltVon,
                    ],
                },
                {
                    arrayName: d.Abschluss.fields.AbschlussBemerkung.name,
                    fields: [
                        d.Abschluss.fields.AbschlussBemerkung.fields.Id,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Kurztext,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Langtext,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Verfuegbarkeit,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Produkte,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Feature,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Feld,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Tags,
                        d.Abschluss.fields.AbschlussBemerkung.fields.Standard,
                        d.Abschluss.fields.AbschlussBemerkung.fields.ErstelltAm,
                        d.Abschluss.fields.AbschlussBemerkung.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createVtiAbschluss(d: any): Feature {
        return {
            name: d.VtiAbschluss.name,
            fields: [d.VtiAbschluss.fields.Adresse],
        };
    }

    static createCgAbschluss(d: any): Feature {
        return {
            name: d.CgAbschluss.name,
            fields: [
                d.CgAbschluss.fields.Adresse,
                {
                    arrayName: d.CgAbschluss.fields.Fazit.name,
                    fields: [
                        d.CgAbschluss.fields.Fazit.fields.Id,
                        d.CgAbschluss.fields.Fazit.fields.Kurztext,
                        d.CgAbschluss.fields.Fazit.fields.Langtext,
                        d.CgAbschluss.fields.Fazit.fields.Verfuegbarkeit,
                        d.CgAbschluss.fields.Fazit.fields.Produkte,
                        d.CgAbschluss.fields.Fazit.fields.Feature,
                        d.CgAbschluss.fields.Fazit.fields.Feld,
                        d.CgAbschluss.fields.Fazit.fields.Tags,
                        d.CgAbschluss.fields.Fazit.fields.Standard,
                        d.CgAbschluss.fields.Fazit.fields.ErstelltAm,
                        d.CgAbschluss.fields.Fazit.fields.ErstelltVon,
                    ],
                },
                {
                    arrayName: d.CgAbschluss.fields.AbschlussBemerkung.name,
                    fields: [
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Id,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Kurztext,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Langtext,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Verfuegbarkeit,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Produkte,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Feature,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Feld,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Tags,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.Standard,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.ErstelltAm,
                        d.CgAbschluss.fields.AbschlussBemerkung.fields.ErstelltVon,
                    ],
                },
            ],
        };
    }

    static createAcAbschluss(d: any): Feature {
        return {
            name: d.AcAbschluss.name,
            fields: [d.AcAbschluss.fields.Adresse, d.AcAbschluss.fields.AcVersendet],
        };
    }

    static createRestwertprognose(d: any): Feature {
        return {
            name: d.Restwertprognose.name,
            fields: [
                d.Restwertprognose.fields.Bezugsdatum,
                d.Restwertprognose.fields.Vertragsnummer,
                d.Restwertprognose.fields.Vertragsbeginn,
                d.Restwertprognose.fields.Vertragsende,
                d.Restwertprognose.fields.VereinbarteKilometer,
                d.Restwertprognose.fields.TatsaechlicheKilometer,
                d.Restwertprognose.fields.DifferenzKilometer,
                d.Restwertprognose.fields.DatDossierId,
                d.Restwertprognose.fields.RestwertNetto,
                d.Restwertprognose.fields.RestwertBrutto,
                d.Restwertprognose.fields.Dokument,
            ],
        };
    }

    static createKalkulation(d: any): Feature {
        return {
            name: d.Kalkulation.name,
            fields: [
                d.Kalkulation.fields.Bezugsdatum,
                d.Kalkulation.fields.DossierId,
                d.Kalkulation.fields.ReparaturkostenNetto,
                d.Kalkulation.fields.ReparaturkostenBrutto,
                d.Kalkulation.fields.Dokument,
            ],
        };
    }

    static createCgFeststellung(d: any): Feature {
        return {
            name: d.CgFeststellung.name,
            fields: [
                d.CgFeststellung.fields.Versicherungsart,
                {
                    arrayName: d.CgFeststellung.fields.PositionenBauteileversichert.name,
                    fields: [
                        d.CgFeststellung.fields.PositionenBauteileversichert.fields.Bezeichnung,
                        d.CgFeststellung.fields.PositionenBauteileversichert.fields.Baugruppe,
                        d.CgFeststellung.fields.PositionenBauteileversichert.fields.Prueftext,
                        d.CgFeststellung.fields.PositionenBauteileversichert.fields.Bilder,
                    ],
                },
                {
                    arrayName: d.CgFeststellung.fields.PositionenFahrzeugversichert.name,
                    fields: [
                        d.CgFeststellung.fields.PositionenFahrzeugversichert.fields.Bezeichnung,
                        d.CgFeststellung.fields.PositionenFahrzeugversichert.fields.Baugruppe,
                        d.CgFeststellung.fields.PositionenFahrzeugversichert.fields.Prueftext,
                        d.CgFeststellung.fields.PositionenFahrzeugversichert.fields.Bilder,
                    ],
                },
                d.CgFeststellung.fields.Verschleissbedingt,
                d.CgFeststellung.fields.Diagnose,
                d.CgFeststellung.fields.InternerKommentar,
            ],
        };
    }
}
