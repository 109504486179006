import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { AccordionComponent } from '../accordion/accordion.component';

@Component({
    selector: 'app-panel-value',
    template: '{{value}}',
    styleUrls: ['./panel-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelValueComponent {
    private _value: string;

    get value(): string {
        return this._value;
    }

    @Input()
    set value(value: string) {
        if (this.accordion && this.next && this._value !== value) {
            this.accordion.next();
        }
        this._value = value;
    }

    @Input()
    next = true;

    constructor(
        @Inject(AccordionComponent)
        private readonly accordion: AccordionComponent,
    ) {}
}
