import { Injectable } from '@angular/core';
import { ViewFormControlFormatter } from '@shared/helper/form-controls/view-form-control';

@Injectable({
    providedIn: 'root',
})
export class DecimalSeperatorFormatterService implements ViewFormControlFormatter<number> {
    format(rawValue: number): string {
        if (rawValue === undefined || rawValue === null) {
            return undefined;
        }
        return rawValue.toLocaleString('de-DE');
    }

    parse(viewValue: string): number {
        if (viewValue === undefined || viewValue === null || viewValue === '') {
            return undefined;
        }

        const parsedValue = +viewValue.replace(',', '.');
        if (isNaN(parsedValue)) {
            return undefined;
        } else {
            return parsedValue;
        }
    }
}
