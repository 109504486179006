import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TrackBy } from '@shared/helper/track-by';

@Component({
    selector: 'app-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent {
    trackByKey = TrackBy.trackByKey;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    displayName: string;

    @Input()
    valuePrefix = '';

    @Input()
    disabled = false;

    @Output()
    change = new EventEmitter<boolean>();
}
