import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { ProduktArt, ProduktIcon } from '@data/domain/schema/enum';
import { environment } from '@env/environment';
import { GridTile } from '@shared/component';
import { Assert } from '@shared/helper/assert';
import { SharedModule } from '@shared/shared.module';
import { take } from 'rxjs';

const TILE_VTI_TOOLING = {
    icon: 'ruecknahmebewertung',
    link: `/produkt/erstellen/${ProduktArt.VtiTooling}`,
    id: 'id-vti-tooling',
    title: 'VTI Tooling',
};

const TILE_OLDTIMER = {
    icon: ProduktIcon.Oldtimer,
    link: `/produkt/erstellen/${ProduktArt.OldTimer}`,
    id: 'id-oldtimer',
    title: 'Oldtimer',
};

@Component({
    selector: 'app-produkt-erstellen',
    standalone: true,
    templateUrl: './produkt-erstellen.component.html',
    imports: [SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktErstellenComponent {
    displayTiles = signal<GridTile[]>([]);
    tiles: GridTile[] = [
        {
            icon: 'bewertung',
            link: `/produkt/erstellen/${ProduktArt.Bewertung}`,
            id: 'id-bewertung',
            title: 'Bewertung',
        },
        {
            icon: 'ruecknahmebewertung',
            link: `/produkt/erstellen/${ProduktArt.Ruecknahmebewertung}`,
            id: 'id-ruecknahmebewertung',
            title: 'Rücknahme\u200Bbewertung',
        },
        {
            icon: 'zustandsbericht',
            link: `/produkt/erstellen/${ProduktArt.Zustandsbericht}`,
            id: 'id-zustandsbericht',
            title: 'Zustandsbericht',
        },
        {
            icon: 'car-garantie',
            link: `/produkt/erstellen/${ProduktArt.CarGarantie}`,
            id: 'id-car-garantie',
            title: 'CarGarantie',
            disabled: true,
        },
        // {
        //   icon: 'ruecknahmebewertung',
        //   link: `/produkt/erstellen/${ProduktArt.NfzRuecknahmebewertung}`,
        //   id: 'id-nfz-ruecknahmebewertung',
        //   title: 'NFZ Rücknahme\u200Bbewertung'
        // },
        {
            icon: 'dynarex-on-demand',
            externalLink: 'https://gtue.onrex.de/auth/',
            id: 'id-dynarex-on-demand',
            title: 'GTÜ Dynarex on Demand',
        },
        {
            icon: 'gtue-modul',
            externalLink: `${environment.bvws.combiPlusOnline.url}`,
            id: 'id-gtue-modul',
            title: 'GTÜ-Modul',
        },
    ];

    constructor(private readonly authService: AuthService) {
        Assert.notNullOrUndefined(authService, 'authService');

        this.setupTiles();
    }

    private setupTiles(): void {
        const indexVtiTooling = 4;

        this.authService
            .getClaims()
            .pipe(take(1))
            .subscribe({
                next: (claims) => {
                    if (claims) {
                        if (claims.istVti) {
                            this.tiles.splice(indexVtiTooling, 0, TILE_VTI_TOOLING);
                        }
                        if (claims.istCarGarantie) {
                            const carGarantieTile = this.tiles.find((tile) => tile.id === 'id-car-garantie');
                            carGarantieTile.disabled = false;
                        }
                    } else {
                        console.error('Could not get user claims.');
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.setupOldtimerTile();
                },
                complete: () => {
                    this.setupOldtimerTile();
                },
            });
    }

    private setupOldtimerTile(): void {
        if (environment.name === 'DEV' || environment.name === 'NRO') {
            this.tiles.push(TILE_OLDTIMER);
        }
        this.displayTiles.set(this.tiles);
    }
}
