import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import {
    ProduktArt,
    VorschadenArt,
    VorschadenObergruppe,
    VorschadenReparatur,
    VorschadenReparaturMapNames,
    VorschadenUntergruppe,
} from '@data/domain/schema/enum';
import { Vorschaden, VorschadenInput, VorschadenPosition } from '@data/domain/schema/type';
import { ProduktVorschadenService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import {
    OBERGRUPPE_TO_UNTERGRUPPE_MAP,
    VORSCHADEN_GRUPPEN,
} from '@modules/produkt/config/produkt-vorschaden-gruppen.config';
import { ProduktDetailVorschadenFormViewFactory } from '@modules/produkt/factory/vorschaden/produkt-detail-vorschaden-form-view.factory';
import { ProduktDetailVorschadenPositionFormViewFactory } from '@modules/produkt/factory/vorschaden/produkt-detail-vorschaden-position-form-view.factory';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import {
    ColumnCount,
    MaxColumnEntries,
} from '@shared/component/form-controls/extendable-radio-list/extendable-radio-list.component';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ArrayValues, EnumValues, Values } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { ProduktDetailSchadenComponentBase } from '../base/produkt-detail-schaden-base.component';

const OBERGRUPPE_STRING_MAP = {
    [VorschadenObergruppe.Frontschaden]: VORSCHADEN_GRUPPEN.find((x) => x === 'Frontschaden'),
    [VorschadenObergruppe.SchwellerschadenLinks]: VORSCHADEN_GRUPPEN.find((x) => x === 'Schwellerschaden links'),
    [VorschadenObergruppe.SeitenschadenLinks]: VORSCHADEN_GRUPPEN.find((x) => x === 'Seitenschaden links'),
    [VorschadenObergruppe.Heckschaden]: VORSCHADEN_GRUPPEN.find((x) => x === 'Heckschaden'),
    [VorschadenObergruppe.SchwellerschadenRechts]: VORSCHADEN_GRUPPEN.find((x) => x === 'Schwellerschaden rechts'),
    [VorschadenObergruppe.SeitenschadenRechts]: VORSCHADEN_GRUPPEN.find((x) => x === 'Seitenschaden rechts'),
    [VorschadenObergruppe.SchadenAmUnterboden]: VORSCHADEN_GRUPPEN.find((x) => x === 'Schaden am Unterboden'),
    [VorschadenObergruppe.SchadenAmDach]: VORSCHADEN_GRUPPEN.find((x) => x === 'Schaden am Dach'),
};

export enum VorschadenDisplay {
    NONE = 0,
    Modell = 1,
    Manuell = 2,
}

interface VorschadenDialogData {
    form: ViewFormGroup;
    fields: FeatureFields;
    produktArt?: ProduktArt;
}

interface VorschadenUntergruppeDialogData {
    form: ViewFormGroup;
    values: Values;
}

@Component({
    selector: 'app-produkt-detail-vorschaden',
    templateUrl: './produkt-detail-vorschaden.component.html',
    styleUrls: ['./produkt-detail-vorschaden.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailVorschadenComponent
    extends ProduktDetailSchadenComponentBase<Vorschaden, VorschadenInput, VorschadenPosition, VorschadenDisplay>
    implements OnInit {
    protected featureName = PRODUKT_CONFIG_FEATURES.Vorschaden.name;
    protected positionenName = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.name;
    protected positionenFieldConfig = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.fields;
    protected costGroupName = 'wertminderungGroup';

    protected display$ = new BehaviorSubject(VorschadenDisplay.NONE);

    private positionenObergruppeName = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.fields.Obergruppe.name;

    vorschadenDisplay = VorschadenDisplay;
    maxColumnEntries = MaxColumnEntries;
    columnCountEnum = ColumnCount;
    obergruppenManuell = new EnumValues(VORSCHADEN_GRUPPEN);
    reparatur = new EnumValues(VorschadenReparatur);
    reparaturMap = new EnumValues(VorschadenReparaturMapNames);
    art = new EnumValues(VorschadenArt);

    activeObergruppen$: Observable<string[]>;

    modelFileConfigs: ModelFileConfig[];
    modelDisabled$ = new BehaviorSubject<boolean>(false);

    @ViewChild('untergruppe', { static: true })
    untergruppeTemplate: TemplateRef<any>;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktVorschadenService: ProduktVorschadenService,
        formViewFactory: ProduktDetailVorschadenFormViewFactory,
        currencyFormatter: CurrencyFormatterService,
        modelFileService: ModelFileService,
        viewportService: ViewportService,
        notizenService: ProduktDetailFeatureNotizenService,
        updateWerteService: UpdateWerteService,
        clipboard: Clipboard,
        public snackBarService: SnackBarService,
        public positionFormViewFactory: ProduktDetailVorschadenPositionFormViewFactory,
        public templateDialogService: TemplateDialogService,
        private readonly einstellungenService: EinstellungenService,
    ) {
        super(
            produktConfigResolveService,
            produktDetailResolveService,
            produktVorschadenService,
            positionFormViewFactory,
            templateDialogService,
            snackBarService,
            formViewFactory,
            currencyFormatter,
            modelFileService,
            viewportService,
            notizenService,
            updateWerteService,
            clipboard,
        );
        Assert.notNullOrUndefined(formViewFactory, 'vorschadenFormViewFactory');
        Assert.notNullOrUndefined(positionFormViewFactory, 'vorschadenPositionFormViewFactory');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
        Assert.notNullOrUndefined(modelFileService, 'modelFileService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        Assert.notNullOrUndefined(notizenService, 'notizenService');
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
        Assert.notNullOrUndefined(updateWerteService, 'updateWerteService');
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Vorschaden.name;
        this.setModelAndDisplay();
        this.init(name);
        super.ngOnInit();
    }

    onModelLoad(modelLoadStatus: ModelLoadResult): void {
        Assert.notNullOrUndefined(modelLoadStatus, 'modelLoadStatus');
        if (modelLoadStatus === ModelLoadResult.None) {
            this.modelDisabled$.next(true);
            this.onDisplayChange(VorschadenDisplay.Manuell);
            this.snackBarService.warning('modell.couldNotLoad');
        } else if (modelLoadStatus === ModelLoadResult.Fallback) {
            this.snackBarService.info('modell.fallback');
        }
    }

    protected createForm(): ViewFormGroup {
        const form = super.createForm();
        this.activeObergruppen$ = this.getActiveObergruppen$();
        return form;
    }

    private getActiveObergruppen$(): Observable<string[]> {
        return this.positionen.valueChanges.pipe(
            startWith({}),
            map(() =>
                [...(this.positionen.controls as ViewFormGroup[])].map(
                    (control) => control.getRawValue()[this.positionenObergruppeName],
                ),
            ),
        );
    }

    protected selectUntergruppe(ogruppe: number): Observable<VorschadenPosition> {
        const fields = this.positionenFields;
        let obergruppe = OBERGRUPPE_STRING_MAP[ogruppe];

        if (!obergruppe) {
            obergruppe = ogruppe;
        }

        const form = this.positionFormViewFactory.create({ obergruppe }, fields);
        const button = [this.templateDialogService.getCancelButtonSetting()];

        let untergruppen = OBERGRUPPE_TO_UNTERGRUPPE_MAP.get(ogruppe);
        if (!untergruppen) {
            untergruppen = [obergruppe as any];
        }
        const values = new ArrayValues(untergruppen as string[], VorschadenUntergruppe);

        const data: VorschadenUntergruppeDialogData = { form, values };
        const dialog = this.templateDialogService.open(obergruppe, button, this.untergruppeTemplate, data, true);

        const promise = new Promise<VorschadenPosition>((resolve, reject) => {
            let valueChangeSubscription = form.valueChanges.subscribe({
                next: (value) => {
                    valueChangeSubscription.unsubscribe();
                    valueChangeSubscription = null;
                    dialog.close();
                    resolve(value);
                },
                error: (error) => reject(new Error(error)),
            });
            dialog
                .afterClosed()
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        if (valueChangeSubscription) {
                            valueChangeSubscription.unsubscribe();
                        } else {
                            resolve(null);
                        }
                    },
                    error: (error) => reject(new Error(error)),
                });
        });
        return from(promise);
    }

    protected setModelAndDisplay() {
        this.einstellungenService
            .getBenutzer()
            .pipe(take(1))
            .subscribe((benutzerEinstellungen) => {
                if (benutzerEinstellungen?.deactivate3dModel) {
                    this.modelDisabled$.next(benutzerEinstellungen.deactivate3dModel);
                    this.display$.next(VorschadenDisplay.Manuell);
                } else {
                    this.modelDisabled$.next(false);
                    this.display$.next(VorschadenDisplay.Modell);
                }
            });
    }

    protected readonly MaxColumnEntries = MaxColumnEntries;
}
