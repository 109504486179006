<app-card width="100%">
    <app-produkt-detail-fahrzeug-form
        [manuellFahrzeugartDisabled]="manuellFahrzeugartDisabled"
        [produkt]="produkt"
        [manuellBauformDisabled]="manuellBauformDisabled"
        [form]="form"
        [name]="name"
        [fields]="fields"
        [vinDisabled]="isVtiTooling()"
        [laufleistungenVisible]="true"
        (abrechnungResolved)="onAbrechnungResolved($event)"
        (ausstattungResolved)="onAusstattungResolved($event)"
        (save)="saveProdukt($event)"
    />
</app-card>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />
