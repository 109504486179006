<div class="nav">
    <div class="header-wrapper" [ngClass]="isProdEnvironment() ? 'env-prod' : 'env-dev'">
        <div class="footer-wrapper" *ngIf="(viewport$ | async) === viewport.Desktop">
            <app-footer />
        </div>
        <div class="title-value" *ngIf="isActive && (viewport$ | async) !== viewport.Desktop && (produkt$ | async)">
            {{ auftragsnummer$ | async }}
        </div>
        <div class="quick-menu-wrapper">
            <app-button
                class="quick-menu-item"
                [title]="'footer.user' | translate"
                [matMenuTriggerFor]="menuUser"
                [isSmall]="true"
                (action)="onUserMenuClicked()"
            >
                <app-mat-icon icon="account_circle" />
            </app-button>
            <app-button
                class="quick-menu-item"
                [title]="'footer.support' | translate"
                [matMenuTriggerFor]="menuHelp"
                [isSmall]="true"
            >
                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-alert-help" />
            </app-button>
            <mat-menu #menuUser="matMenu">
                <app-user-menu [userClaims$]="userClaims$" />
            </mat-menu>
            <mat-menu #menuHelp="matMenu">
                <app-help-menu />
            </mat-menu>
            <div class="divider-right"></div>
            <div class="sync">
                <app-button [count]="syncCount$ | async" [isSmall]="true" (action)="onSyncClick()" countAlign="center">
                    <app-mat-icon [class.animation-cycle]="syncInProgress$ | async" icon="sync" size="2rem" />
                </app-button>
            </div>
        </div>
    </div>
    <app-progress-bar class="navigating" *ngIf="navigating$ | async" />
</div>
