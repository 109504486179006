import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    RestwertprognosDto,
    RestwertprognoseRequest,
    RestwertprognoseService,
} from '@data/api-gateway/service/restwertprognose.service';
import { FahrzeugLaufleistungEinheit } from '@data/domain/schema/enum';
import { Restwertprognose, RestwertprognoseInput } from '@data/domain/schema/type';
import { ProduktRestwertprognoseService } from '@data/domain/service/feature/produkt-restwertprognose.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { Feature, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailFahrzeugFormViewFactory } from '@modules/produkt/factory/fahrzeug/produkt-detail-fahrzeug-form-view.factory';
import { ProduktDetailRestwertprognoseFormViewFactory } from '@modules/produkt/factory/restwertprognose/produkt-detail-restwertprognose-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { DatFinanceLineService } from '@modules/produkt/service/dat-finance-line-service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { ThousandsSeperatorFormatterService } from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

declare let SphinxClass: any;

@Component({
    selector: 'app-produkt-detail-restwertprognose',
    templateUrl: './produkt-detail-restwertprognose.component.html',
    styleUrls: ['./produkt-detail-restwertprognose.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailRestwertprognoseComponent
    extends ProduktDetailFeatureInputComponent<Restwertprognose, RestwertprognoseInput>
    implements OnInit {
    trackByField = TrackBy.trackByField;

    name;

    fahrzeug: Feature;
    fahrzeugForm;
    fahrzeugFields;

    fahrzeugLaufleistungEinheit = new EnumValues(FahrzeugLaufleistungEinheit);

    sphinx;
    display$ = new BehaviorSubject(1);
    hasExternalServiceIdentifier = false;
    loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktRestwertprognoseService: ProduktRestwertprognoseService,
        private clipboard: Clipboard,
        private readonly thousandsSeperatorFormatterService: ThousandsSeperatorFormatterService,
        private readonly snackBarService: SnackBarService,
        private readonly produktService: ProduktDetailResolveService,
        private readonly datFinanceLineService: DatFinanceLineService,
        private readonly restwertprognoseService: RestwertprognoseService,
        private readonly restwertprognoseFormViewFactory: ProduktDetailRestwertprognoseFormViewFactory,
        private readonly fahrzeugFormViewFactory: ProduktDetailFahrzeugFormViewFactory,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktRestwertprognoseService);
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Restwertprognose.name;
        this.init(name);
        this.sphinx = SphinxClass.getInstance();
        this.fahrzeug = this.getFeatureByName(PRODUKT_CONFIG_FEATURES.Fahrzeug.name);
        this.fahrzeugFields = this.fahrzeug.fields;
        this.fahrzeugForm = this.fahrzeugFormViewFactory.create(this.produkt.fahrzeug, this.fahrzeugFields);
        this.hasExternalServiceIdentifier = this.hasExternalServiceIdentified();
        this.updateTatsaechlicheKilometer();
        this.updateDifferenzKilometer();
    }

    protected createForm(): ViewFormGroup {
        const form = this.restwertprognoseFormViewFactory.create(this.produkt.restwertprognose, this.fields);
        return form;
    }

    initIFrame(): void {
        this.datFinanceLineService
            .getToken()
            .pipe(first())
            .subscribe((token) => {
                if (token) {
                    if (!this.produkt.restwertprognose.datDossierId) {
                        this.loading$.next(true);
                        this.createDossier().subscribe(
                            (dossierId) => {
                                this.produkt.restwertprognose.datDossierId = dossierId;
                                this.form.patchValue({ datDossierId: dossierId });

                                this.restwertprognoseService
                                    .get(dossierId, this.produkt.id)
                                    .pipe(first())
                                    .subscribe((restwertprognoseGetResponse) => {
                                        this.saveRestwertprognoseGetResponse(restwertprognoseGetResponse);
                                        this.setUpSphinx(token, dossierId);
                                        this.loading$.next(false);
                                        this.sphinx.sendDossierRequest();
                                    });
                            },
                            (error) => {
                                this.loading$.next(false);
                                console.error('ERROR: this.restwertprognoseService.post: ', error);
                            },
                        );
                    } else {
                        this.setUpSphinx(token, this.produkt.restwertprognose.datDossierId);
                        this.sphinx.sendDossierRequest();
                    }
                } else {
                    this.snackBarService.error('Fehler bei der Anmeldung bei DAT. Token konnte nicht erstellt werden.');
                }
            });
    }

    private setUpSphinx(token: any, dossierId: string): void {
        console.info(`produktId: ${this.produkt.id}`);
        const produktId = this.produkt.id;
        const that = this;
        const thatSphinx = this.sphinx;
        this.sphinx.setProductUrl('https://www.datgroup.com/FinanceLine/').setIframeName('iframeSilverDAT');
        this.sphinx.hostUrl = window.location.href;
        this.sphinx.credentials = {
            token,
        };
        this.sphinx.params = {
            datCountryIndicator: 'DE',
            locale: 'de_DE',
            action: 'changeDossier',
            dossierid: dossierId,
            type: 'evaluation',
            vxs: '',
            page: 'model selection',
            workflow: 'true',
            save: 'true',
        };

        this.sphinx.onLoginFailure = function (response) {
            alert(`DAT login failed!\nReason: ${response.message}`);
        };
        this.sphinx.onFinished = function () {
            that.restwertprognoseService
                .get(dossierId, produktId)
                .pipe(first())
                .subscribe((restwertprognoseGetResponse) => {
                    that.saveRestwertprognoseGetResponse(restwertprognoseGetResponse);
                    that.display$.next(1);
                });
            thatSphinx.exportDossier();
        };
        this.sphinx.afterExportDossier = function () {
            thatSphinx.logout();
        };
    }

    private createDossier(): Observable<string> {
        const request = this.createRestwertprognoseRequest();
        return this.restwertprognoseService.post(request).pipe(first());
    }

    private createRestwertprognoseRequest(): RestwertprognoseRequest {
        return {
            produktId: this.produkt.id,
            dossierId: '',
            dossierBezeichnung: this.produkt.auftrag.nummer,
            vertragsnummer: 'Vertragsnummer Test',
            vertragsbeginn: '2021-01-20T08:00:00',
            vertragsende: '2022-01-20T08:00:00',
            vereinbarteKilometer: 10000,
            tatsaechlicheKilometer: 15000,
            differenzKilometer: 5000,
            kennzeichen: this.produkt.fahrzeug.kennzeichen,
            identnummer: this.produkt.fahrzeug.identnummer,
            laufleistung: this.produkt.fahrzeug.laufleistung,
            erstzulassung: this.produkt.fahrzeug.erstzulassung,
            specialEquipmentIds: this.getSonderausstattungTeilenummer(),
            identifier: this.produkt.fahrzeug.fahrzeugExternalServiceReference.identifier,
            container: this.produkt.fahrzeug.fahrzeugExternalServiceReference.container,
            constructionTime: this.produkt.fahrzeug.fahrzeugExternalServiceReference.constructionTime,
        };
    }

    private saveRestwertprognoseGetResponse(restwertprognosDto: RestwertprognosDto): void {
        this.form.patchValue({ bezugsdatum: new Date().toISOString() });
        this.form.patchValue({ datDossierId: restwertprognosDto.dossierId });
        this.form.patchValue({ dokument: restwertprognosDto.dokument });
        this.form.patchValue({ restwertNetto: restwertprognosDto.restwertprognoseNetto });
        this.form.patchValue({ restwertBrutto: restwertprognosDto.restwertprognoseBrutto });
        this.produkt.restwertprognose.bezugsdatum = new Date(restwertprognosDto.bezugsdatum).toISOString();
        this.produkt.restwertprognose.datDossierId = restwertprognosDto.dossierId;
        this.produkt.restwertprognose.dokument = restwertprognosDto.dokument;
        this.produkt.restwertprognose.restwertNetto = restwertprognosDto.restwertprognoseNetto;
        this.produkt.restwertprognose.restwertBrutto = restwertprognosDto.restwertprognoseBrutto;
        this.save();
    }

    private getSonderausstattungTeilenummer(): string[] {
        const teileNummer = [];
        const sonderausstattungGruppen = this.produkt.ausstattung.gruppen.filter(
            (group) => group.sonderausstattung === true,
        );
        sonderausstattungGruppen.forEach((gruppe) =>
            gruppe.teile
                .filter((teile) => teile.vorhanden === true)
                .forEach((teil) => teileNummer.push(teil.externalServiceId)),
        );
        return teileNummer;
    }

    copy(elementId: any): void {
        const inputValue = (document.getElementById(elementId) as HTMLInputElement).value;
        this.clipboard.copy(inputValue);
        this.snackBarService.success(`Wert kopiert: ${inputValue}`);
    }

    onDisplayChange(display: number): void {
        Assert.notNullOrUndefined(display, 'display');
        this.display$.next(display);
        if (display === 2) {
            this.initIFrame();
        }
    }

    private hasExternalServiceIdentified(): boolean {
        const fahrzeugExternalServiceReference = this.produkt.fahrzeug?.fahrzeugExternalServiceReference;
        if (!fahrzeugExternalServiceReference?.identifier) {
            return false;
        }
        const { constructionTime, container, identifier } = fahrzeugExternalServiceReference;
        return !!constructionTime && !!container && !!identifier;
    }

    private updateTatsaechlicheKilometer(): void {
        this.form.patchValue({
            tatsaechlicheKilometer: this.thousandsSeperatorFormatterService
                .format(this.produkt.fahrzeug.laufleistung)
                .toString(),
        });
    }

    private updateDifferenzKilometer(): void {
        this.form.patchValue({
            differenzKilometer: (
                this.produkt.restwertprognose.vereinbarteKilometer - this.produkt.fahrzeug.laufleistung
            ).toString(),
        });
    }
}
