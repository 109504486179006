<div matTooltip="{{ tooltip }}">
    <button
        [disabled]="disabled"
        [matBadgeHidden]="count <= 0"
        [matBadge]="count"
        [style.height]="size"
        [style.width]="size"
        [style.line-height]="size"
        (click)="disabled ? null : action.emit($event)"
        mat-mini-fab
        color="{{ color }}"
    >
        <ng-content />
    </button>
</div>
