<ng-container *ngIf="statusChanged$ | async as status; else loading">
    <app-stepper [linear]="true">
        <!-- schließen -->
        <app-step [editable]="!(rechnungsArtChanged | async)" [completed]="status == 5">
            <app-step-label>
                <ng-container *ngIf="status <= 1; else geschlossenLabel">
                    {{ name + '.workflow.schliessen' | translate }}
                </ng-container>
                <ng-template #geschlossenLabel>
                    <ng-container>
                        {{ name + '.workflow.geschlossen' | translate }}
                    </ng-container>
                </ng-template>
            </app-step-label>
            <ng-container *ngIf="!(loading$ | async); else loading">
                <ng-container *ngIf="status <= 1; else geschlossen">
                    <div>
                        {{ name + '.workflow.schliessenLabel' | translate }}
                    </div>
                    <div>
                        <app-button
                            class="button-abschluss-steps"
                            [type]="ButtonType.PRIMARY"
                            [name]="name + '.workflow.schliessen'"
                            (action)="onProduktCloseClick()"
                        >
                            {{ name + '.workflow.schliessen' | translate }}
                        </app-button>
                    </div>
                </ng-container>
                <ng-template #geschlossen>
                    <div>
                        {{ name + '.workflow.geschlossenLabel' | translate }}
                    </div>
                    <div>
                        <app-button
                            class="button-abschluss-steps"
                            [type]="ButtonType.SECONDARY"
                            [name]="name + '.workflow.geschlossenErneut'"
                            (action)="onProduktOpenClick()"
                        >
                            {{ name + '.workflow.geschlossenErneut' | translate }}
                        </app-button>
                    </div>
                </ng-template>
            </ng-container>
        </app-step>

        <!-- beendet -->
        <app-step>
            <app-step-label>
                {{ name + '.workflow.beendetLabel' | translate }}
            </app-step-label>
            <ng-container *ngIf="!(loading$ | async); else loading">
                <div>{{ name + '.workflow.beendet' | translate }}</div>
                <div class="buttons">
                    <app-button
                        class="btn-icon"
                        [name]="name + '.workflow.reset.beendet'"
                        (action)="onAbschlussResetClick()"
                    >
                        <ng-container>
                            <app-mat-icon icon="lock_open" color="primary" />
                        </ng-container>
                    </app-button>
                    <div class="spacer"></div>
                    <app-button
                        class="btn-icon"
                        *ngIf="!(downloadUrl$ | async); else open"
                        [name]="name + '.workflow.download'"
                        (action)="onDownloadClick()"
                    >
                        <ng-container>
                            <app-mat-icon icon="picture_as_pdf" color="primary" />
                        </ng-container>
                    </app-button>
                    <ng-template #open>
                        <app-button
                            class="btn-icon"
                            [name]="name + '.workflow.download.open'"
                            (action)="onOpenDownloadClick()"
                        >
                            <ng-container>
                                <app-mat-icon icon="picture_as_pdf" color="primary" />
                            </ng-container>
                        </app-button>
                    </ng-template>
                </div>
            </ng-container>
        </app-step>
    </app-stepper>
</ng-container>

<ng-template #loading>
    <app-progress-spinner />
</ng-template>

<ng-template #vtiAbschlussResetDialog>
    <mat-label>
        {{ name + '.workflow.reset.confirm' | translate }}
    </mat-label>
</ng-template>
