<app-scene class="scene" [class.animating]="animating$ | async">
    <app-gltf [modelFileConfigs]="modelFileConfigs" (sceneLoad)="onSceneLoad($event)" (modelLoad)="onModelLoad($event)">
        <ng-container *ngIf="disabled$ | async as disabled">
            <app-gltf-indicator
                *ngFor="let position of positionen.keys; trackBy: trackByIndex"
                [name]="names[position]"
                [checkVisible]="true"
            >
                <app-mini-fab [disabled]="disabled[position]" (action)="onMessungClick(position)" size="35px">
                    <app-mat-icon icon="control_camera" />
                </app-mini-fab>
            </app-gltf-indicator>
        </ng-container>
    </app-gltf>
    <app-object-view-control [target]="scene" [vertical]="1" (animatingChange)="animating$.next($event)" />
</app-scene>
