<div class="button-row-container">
    <div class="toggle">
        <app-toggle-group [value]="display$ | async" (valueChange)="onDisplayChange($event)">
            <app-toggle [value]="1">
                {{ name + '.daten' | translate }}
            </app-toggle>
            <app-toggle [value]="2" [disabled]="!hasExternalServiceIdentifier">
                {{ name + '.dat' | translate }}
            </app-toggle>
        </app-toggle-group>
    </div>
</div>

<div class="main-content">
    <ng-container [ngSwitch]="display$ | async">
        <ng-container *ngSwitchCase="1">
            <app-card>
                <h4>Fahrzeugdaten</h4>
                <ng-container *ngFor="let field of fahrzeugFields; trackBy: trackByField">
                    <ng-container [ngSwitch]="field.name">
                        <!-- Kennzeichen -->
                        <app-fluid-cell *ngSwitchCase="'kennzeichen'">
                            <ng-container *ngTemplateOutlet="textFahrzeug; context: { field: field }" />
                        </app-fluid-cell>
                        <!-- Identnummer -->
                        <app-fluid-cell *ngSwitchCase="'identnummer'">
                            <ng-container *ngTemplateOutlet="textFahrzeug; context: { field: field }" />
                        </app-fluid-cell>
                        <!-- Erstzulassung für Required Fields -->
                        <app-fluid-cell *ngSwitchCase="'erstzulassung'">
                            <ng-container *ngTemplateOutlet="textFahrzeug; context: { field: field }" />
                        </app-fluid-cell>
                        <!-- Laufleistung -->
                        <app-fluid-cell *ngSwitchCase="'laufleistung'">
                            <ng-container
                                *ngTemplateOutlet="distanceKm; context: { field: field, unit: 'laufleistungEinheit' }"
                            />
                        </app-fluid-cell>
                        <!-- DatECode -->
                        <app-fluid-cell *ngSwitchCase="'datECode'">
                            <ng-container *ngTemplateOutlet="textFahrzeug; context: { field: field }" />
                        </app-fluid-cell>
                        <!-- FahrzeugExternalServiceReference -->
                        <ng-container *ngSwitchCase="'fahrzeugExternalServiceReference'">
                            <ng-container *ngIf="fahrzeugForm.get(field.name) as control">
                                <p
                                    class="form-error"
                                    *ngIf="(control.touchedChanges | async) && !(control.validChanges | async)"
                                >
                                    {{ 'fahrzeug.fahrzeugExternalServiceReference.invalid' | translate }}
                                </p>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </app-card>
            <app-card>
                <h4>Restwertprognose</h4>
                <ng-container *ngFor="let field of fields; trackBy: trackByField">
                    <ng-container [ngSwitch]="field.name">
                        <ng-container *ngSwitchCase="'bezugsdatum'">
                            <ng-container *ngTemplateOutlet="dateDAT; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'vertragsnummer'">
                            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'vertragsbeginn'">
                            <ng-container *ngTemplateOutlet="dateDAT; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'vertragsende'">
                            <ng-container *ngTemplateOutlet="dateDAT; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'vereinbarteKilometer'">
                            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'tatsaechlicheKilometer'">
                            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'differenzKilometer'">
                            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'datDossierId'">
                            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'restwertNetto'">
                            <ng-container *ngTemplateOutlet="textCopy; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'restwertBrutto'">
                            <ng-container *ngTemplateOutlet="textCopy; context: { field: field }" />
                        </ng-container>

                        <ng-container *ngSwitchCase="'dokument'">
                            <ng-container *ngTemplateOutlet="text; context: { field: field }" />
                        </ng-container>
                    </ng-container>
                </ng-container>
            </app-card>
        </ng-container>

        <app-card *ngSwitchCase="2">
            <iframe id="iframeSilverDAT" name="iframeSilverDAT"></iframe>
            <app-progress-spinner
                class="loading-spinner"
                [class.loading-finished]="loading$ | async"
                [diameter]="150"
            />
        </app-card>
    </ng-container>
</div>

<ng-template #text let-field="field">
    <app-input
        [disabled]="true"
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="text"
    />
</ng-template>

<ng-template #textCopy let-field="field">
    <div class="text-copy-container">
        <app-input
            #userinput
            [disabled]="true"
            [form]="form"
            [name]="field.name"
            [displayName]="name + '.' + field.name"
            type="text"
            inputmode="text"
        />
        <app-button class="copy-button" (action)="copy(userinput.input.nativeElement.id)">
            <app-mat-icon icon="file_copy" />
        </app-button>
    </div>
</ng-template>

<ng-template #textFahrzeug let-field="field">
    <app-input
        [disabled]="true"
        [form]="fahrzeugForm"
        [name]="field.name"
        [displayName]="'fahrzeug' + '.' + field.name"
        type="text"
        inputmode="text"
    />
</ng-template>

<ng-template #distanceKm let-field="field" let-unit="unit">
    <app-form-group cols="6">
        <app-form-field colspan="4">
            <app-input
                [disabled]="true"
                [form]="fahrzeugForm"
                [name]="field.name"
                [displayName]="'fahrzeug' + '.' + field.name"
                type="text"
                inputmode="numeric"
            />
        </app-form-field>
        <app-form-field colspan="2">
            <app-select
                [disabled]="true"
                [form]="fahrzeugForm"
                [name]="unit"
                [displayName]="'fahrzeug' + '.' + unit"
                [values]="fahrzeugLaufleistungEinheit"
                [valuePrefix]="name + '.'"
                [hasEmptyField]="false"
            />
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #dateDAT let-field="field">
    <app-date
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [readonly]="true"
        [hasDatepicker]="false"
        [disabled]="true"
    />
</ng-template>
