import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-signout',
    template: `
        <h2 class="center">{{ 'auth.logout' | translate }}</h2>
    `,
    styleUrls: ['./signout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignoutComponent implements OnInit, OnDestroy {
    constructor(
        private readonly authService: AuthService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(authService, 'authService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }

    private signoutSubscription: Subscription;

    ngOnInit(): void {
        this.signoutSubscription = this.authService.completeSignout().subscribe({
            error: () => this.snackBarService.error('auth.completeSignoutError'),
        });
    }

    ngOnDestroy(): void {
        this.signoutSubscription.unsubscribe();
    }
}
