import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AC_PROFILTIEFE_VALUES } from '@data/domain/schema/const';
import { FahrzeugArt, ProduktArt, RadPosition, RaederNotlaufeinrichtung } from '@data/domain/schema/enum';
import { Notiz, RadSatz, Raeder, RaederInput } from '@data/domain/schema/type';
import { ProduktRaederService } from '@data/domain/service/feature';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailRaederFormViewFactory } from '@modules/produkt/factory/raeder/produkt-detail-raeder-form-view.factory';
import { ProduktDetailRaederRadSatzFormViewFactory } from '@modules/produkt/factory/raeder/produkt-detail-raeder-radsatz-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ArrayStringKeysNumberValues, EnumValues } from '@shared/helper/values';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable, take } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-raeder',
    templateUrl: './produkt-detail-raeder.component.html',
    styleUrls: ['./produkt-detail-raeder.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailRaederComponent
    extends ProduktDetailFeatureInputComponent<Raeder, RaederInput>
    implements OnInit, AfterViewInit {
    feature = PRODUKT_CONFIG_FEATURES.Raeder.name;
    fieldBemerkungen = PRODUKT_CONFIG_FEATURES.Raeder.fields.Bemerkungen.name;

    trackByField = TrackBy.trackByField;
    trackByIndex = TrackBy.trackByIndex;

    notizen$: Observable<Notiz[]>;

    notlaufeinrichtung = new EnumValues(RaederNotlaufeinrichtung);
    acProfiltiefeSelectValues = new ArrayStringKeysNumberValues(AC_PROFILTIEFE_VALUES);

    protected readonly ProduktArt = ProduktArt;

    @ViewChild('bemerkungen')
    bemerkungenElement: TextbausteineComponent;

    @ViewChild('confirmDialog')
    confirmDialogTemplate: TemplateRef<any>;

    get radSaetze(): ViewFormArray {
        return this.form?.get(PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.name) as ViewFormArray;
    }

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktRaederService: ProduktRaederService,
        private readonly formViewFactory: ProduktDetailRaederFormViewFactory,
        private readonly textbausteineService: TextbausteineService,
        private readonly radSatzFormViewFactory: ProduktDetailRaederRadSatzFormViewFactory,
        private readonly templateDialogService: TemplateDialogService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktRaederService);
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
        Assert.notNullOrUndefined(radSatzFormViewFactory, 'radSatzFormViewFactory');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(notizenService, 'notizenService');
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Raeder.name;
        this.notizen$ = this.notizenService.init(this.produkt, name);
        this.init(name);

        if (!this.isRadSatzVorhanden()) {
            this.addSatz();
        }
    }
    ngAfterViewInit(): void {
        if (this.form?.get(this.fieldBemerkungen) && this.produkt?.raeder?.bemerkungen === null) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldBemerkungen,
                this.produkt.art,
                this.form,
                this.bemerkungenElement,
            );
        }
    }

    onAddSatzClick(): void {
        this.addSatz();
    }

    onDuplicateSatzClick(): void {
        this.duplicateSatz();
    }

    onRemoveSatzClick(): void {
        const title = `${this.name}.confirm.dialog.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];
        this.templateDialogService
            .openTemplate(title, buttons, this.confirmDialogTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                    this.removeSatz();
                    this.form.markAllAsTouched();
                }
            });
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    protected getFeature(): Raeder {
        return this.form?.getRawValue();
    }

    protected createForm(): ViewFormGroup {
        return this.formViewFactory.create(this.produkt?.raeder, this.fields, this.produkt?.art);
    }

    private addSatz(): void {
        if (!this.radSaetze) {
            console.error('addSatz(), radSaetze is null or undefined');
            return;
        }
        let radSatz: RadSatz;

        if (this.produkt?.fahrzeug?.fahrzeugart === FahrzeugArt.Kraftrad) {
            radSatz = this.createZweirad();
        } else {
            radSatz = this.createVierrad();
        }

        const radSaetzeFields = (
            this.fields.find(
                (x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.name,
            ) as FeatureFieldArray
        ).fields;
        const form = this.radSatzFormViewFactory.create(radSatz, radSaetzeFields, this.produkt?.art);
        this.radSaetze.push(form);
    }

    private createVierrad(): RadSatz {
        return {
            rads: [
                { radPosition: RadPosition.VL },
                { radPosition: RadPosition.VR },
                { radPosition: RadPosition.HL },
                { radPosition: RadPosition.HR },
            ],
        };
    }

    private createZweirad(): RadSatz {
        return {
            rads: [{ radPosition: RadPosition.VA }, { radPosition: RadPosition.HA }],
        };
    }

    private isRadSatzVorhanden(): boolean {
        return this.radSaetze?.length > 0;
    }

    private duplicateSatz(): void {
        if (!this.radSaetze) {
            console.error('duplicateSatz(), radSaetze is null or undefined');
        }
        const radSaetzeFields = (
            this.fields.find(
                (x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.name,
            ) as FeatureFieldArray
        ).fields;
        const radSatz = (this.radSaetze.controls[0] as ViewFormGroup).getRawValue() as RadSatz;
        const form = this.radSatzFormViewFactory.create(radSatz, radSaetzeFields);
        this.radSaetze.push(form);
    }

    private removeSatz(): void {
        if (this.radSaetze) {
            this.radSaetze.removeAt(this.radSaetze.length - 1);
        }
    }
}
