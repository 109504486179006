import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { DateFormatService, DEFAULT_FORMAT, MONTH_YEAR_FORMAT } from './date.format.service';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
    constructor(private readonly dateTimeService: DateFormatService) {
        super('de-DE');
    }

    format(date: moment.Moment, _: string): string {
        return date.locale(this.dateTimeService.locale).format(this.dateTimeService.format);
    }

    parse(value: string): moment.Moment | null {
        let inputDate = value.replace(/\./g, '');
        if (inputDate.length < 4) {
            return this.invalid();
        }

        if (this.isYearTwoDigit(inputDate)) {
            const twoDigitYear = inputDate.slice(-2);
            inputDate = inputDate.slice(0, -2) + this.padYear(twoDigitYear);
        }

        let result: moment.Moment;
        switch (inputDate.length) {
            case 6: {
                const format = 'MMYYYY';
                result = moment(inputDate, format, true);
                break;
            }
            case 8: {
                const format = 'DDMMYYYY';
                result = moment(inputDate, format, true);
                break;
            }
            case 10: {
                result = moment(inputDate, 'DD.MM.YYYY', true);
                break;
            }
            default: {
                return this.invalid();
            }
        }

        return result.isValid ? result : this.invalid();
    }

    private padYear(value: string): string {
        if (Number(value) < 50) {
            return `20${value}`;
        }
        return `19${value}`;
    }

    private isYearTwoDigit(value: string): boolean {
        if (
            (this.dateTimeService.format === DEFAULT_FORMAT && value.length === 6) ||
            (this.dateTimeService.format === MONTH_YEAR_FORMAT && value.length === 4)
        ) {
            return true;
        }
        return false;
    }
}
