import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveAuftrag, SaveAuftragData } from '../../graphql/mutations';
import { Auftrag, AuftragInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktAuftragService extends ProduktFeatureService<Auftrag, AuftragInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Auftrag): AuftragInput {
        const input: AuftragInput = {
            ...feature,
            id: produktId,
            hinweise: (feature.hinweise || []).map((hinweis, hinweisIndex) => ({
                ...hinweis,
                id: `${produktId}:${hinweisIndex}`,
            })),
            auftragsbeschreibung: (feature.auftragsbeschreibung || []).map(
                (auftragsbeschreibung, auftragsbeschreibungIndex) => ({
                    ...auftragsbeschreibung,
                }),
            ),
            bemerkungen: (feature.bemerkungen || []).map((bemerkung, bemerkungIndex) => ({
                ...bemerkung,
            })),
            besichtigungsbedingungen: (feature.besichtigungsbedingungen || []).map(
                (besichtigungsbedingung, besichtigungsbedingungIndex) => ({
                    ...besichtigungsbedingung,
                }),
            ),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveAuftrag;
    }

    protected getSaveVariables(input: AuftragInput): any {
        return {
            auftrag: input,
        };
    }

    protected getSaveOptimisticResponse(input: AuftragInput): any {
        return {
            saveAuftrag: {
                ...input,
                __typename: 'Auftrag',
                hinweise: (input.hinweise || []).map((hinweis) => ({
                    ...hinweis,
                    __typename: 'Auftragsbeschreibung',
                })),
                auftragsbeschreibung: (input.auftragsbeschreibung || []).map((auftragsbeschreibung) => ({
                    ...auftragsbeschreibung,
                    __typename: 'Textbaustein',
                })),
                bemerkungen: (input.bemerkungen || []).map((bemerkung) => ({
                    ...bemerkung,
                    __typename: 'Textbaustein',
                })),
                besichtigungsbedingungen: (input.besichtigungsbedingungen || []).map((besichtigungsbedingung) => ({
                    ...besichtigungsbedingung,
                    __typename: 'Textbaustein',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveAuftragData>): Auftrag {
        return response.data.saveAuftrag;
    }

    protected update(produkt: Produkt, feature: Auftrag): void {
        produkt.auftrag = {
            ...produkt?.auftrag,
            ...feature,
        };
    }
}
