import { Injectable } from '@angular/core';
import { ProduktDetailKalkulationCanActivate } from '@modules/produkt/guard/kalkulation/produkt-detail-kalkulation.can-activate';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailSchadenKalkulationCanActivate extends ProduktDetailKalkulationCanActivate {
    protected navigateToBaseRoute(produktId: string): void {
        this.router.navigate(['produkt', 'detail', produktId, 'schaden']);
    }
}
