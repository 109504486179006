<div class="sidepanel">
    <mat-tab-group
        class="tab-sidebar tab-sidebar-multiline"
        [disablePagination]="true"
        [selectedIndex]="0"
        (selectedIndexChange)="onChange($event)"
        disableRipple="true"
    >
        <mat-tab
            class="tab-mutliline"
            id="produkt-element-{{ produkt.auftrag.id }}}"
            *ngFor="let produkt of produkte | slice: selectedIndex : selectedIndex + 9; let i = index"
            [ngClass]="i === selectedIndex ? 'active' : ''"
        >
            <ng-template mat-tab-label>
                <div class="header">
                    <mat-icon class="icon-car" />
                    <div
                        class="tab-text"
                        *ngIf="produkt.fahrzeug.kennzeichen && produkt.fahrzeug.kennzeichen !== ''; else empty"
                    >
                        {{ produkt.fahrzeug.kennzeichen }}
                    </div>
                    <ng-template #empty>
                        <div class="tab-text">-</div>
                    </ng-template>
                </div>
                <div class="text-multiline">
                    <div>
                        <span class="text-label">{{ 'auftrag.nummer' | translate }}</span>
                        <span>{{ produkt.auftrag.nummer }}</span>
                    </div>
                    <div>
                        <span class="text-label">{{ 'fahrzeug.identnummer' | translate }}</span>
                        <span>{{ produkt.fahrzeug.identnummer }}</span>
                    </div>
                    <div>
                        <span class="text-label">{{ 'auftrag.erstellungsTag' | translate }}</span>
                        <span>{{ produkt.erstelltAm | date }}</span>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
