import { HttpErrorResponse } from '@angular/common/http';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FileService } from '@app/service/file.service';
import {
    AbschlussDownloadResponse,
    AbschlussDownloadService,
    AbschlussGetResponse,
    AbschlussRechnungsArt,
    AbschlussRechnungsService,
    AbschlussService,
    AbschlussVersandArt,
    AbschlussVersandService,
    AbschlussWorkflowStatus,
    AbschlussWorkflowStep,
} from '@data/api-gateway';
import { AcAbschlussVersendenService } from '@data/api-gateway/service/alphacontroller/ac-abschluss-versenden.service';
import { AcProdukt, AcProduktStatus } from '@data/api-gateway/service/alphacontroller/ac-vorgang.service';
import { ProduktRechnungsArt, ProduktStatus, ProduktVersandArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailAbschlussHelperService } from '@modules/produkt/service/produkt-detail-abschluss-helper.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject, Observable, PartialObserver, Subscription, iif, of, throwError } from 'rxjs';
import {
    concatMap,
    delay,
    distinctUntilChanged,
    finalize,
    first,
    mergeMap,
    retryWhen,
    take,
    tap,
} from 'rxjs/operators';

const STATUS_INTERVAL = 1000;
const STATUS_INTERVAL_FACTOR = [3, 2, 1, 2, 3, 5, 5, 10, 10, 15, 30];
const STATUS_RESPONSE_DELAY = 1000;
const FIELD_AC_VERSENDET = PRODUKT_CONFIG_FEATURES.AcAbschluss.fields.AcVersendet.name;
const MESSAGE_ERROR_BILDER = 'abschluss.error.check.bilder';
const MESSAGE_ERROR_PDF = 'abschluss.error.check.pdf';

enum AcAbschlussStep {
    Offen = 0,
    Beendet = 1,
    Versendet = 2,
}

@Component({
    selector: 'app-produkt-detail-ac-abschluss-workflow',
    templateUrl: './produkt-detail-ac-abschluss-workflow.component.html',
    styleUrls: ['./produkt-detail-ac-abschluss-workflow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAcAbschlussWorkflowComponent implements OnInit, OnDestroy, AfterViewChecked {
    private subscriptions: Subscription[] = [];

    trackByField = TrackBy.trackByField;

    @Input()
    name: string;

    @Input()
    produkt: AcProdukt;

    @Input()
    form: ViewFormGroup;

    @ViewChild('stepperComponent', { static: false })
    stepperComponent: StepperComponent;

    @ViewChild('acAbschlussResetDialog', { static: true })
    acAbschlussResetTemplate: TemplateRef<any>;

    @ViewChild('acAbschlussVersendenDialog', { static: true })
    acAbschlussVersendenTemplate: TemplateRef<any>;

    @ViewChild('dialogDataSynchronization', { static: true })
    dialogDataSynchronizationTemplate: TemplateRef<any>;

    @Output()
    statusChangedEvent = new EventEmitter<ProduktStatus>();

    statusEnum = ProduktStatus;

    statusChanged$ = new BehaviorSubject<ProduktStatus>(1);
    acVersendetChanged$ = new BehaviorSubject<boolean>(null);

    rechnungsArt = new EnumValues(ProduktRechnungsArt);
    rechnungsArtChanged = new BehaviorSubject<ProduktRechnungsArt>(undefined);

    versandArt = new EnumValues(ProduktVersandArt);
    versandArtChanged = new BehaviorSubject<ProduktVersandArt>(undefined);

    loading$ = new BehaviorSubject(false);
    downloadUrl$ = new BehaviorSubject<string>(undefined);

    currentSyncCount: number;

    protected readonly ButtonType = ButtonType;

    constructor(
        private readonly abschlussService: AbschlussService,
        private readonly acAbschlussVersendenService: AcAbschlussVersendenService,
        private readonly abschlussRechnungsService: AbschlussRechnungsService,
        private readonly abschlussVersandService: AbschlussVersandService,
        private readonly abschlussDownloadService: AbschlussDownloadService,
        private readonly snackbarService: SnackBarService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly produktService: ProduktService,
        private readonly produktDetailAbschlussHelperService: ProduktDetailAbschlussHelperService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly fileService: FileService,
    ) {
        Assert.notNullOrUndefined(abschlussService, 'abschlussService');
        Assert.notNullOrUndefined(abschlussRechnungsService, 'abschlussRechnungsService');
        Assert.notNullOrUndefined(abschlussVersandService, 'abschlussVersandService');
        Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
        Assert.notNullOrUndefined(snackbarService, 'snackbarService');
        Assert.notNullOrUndefined(changeDetector, 'changeDetector');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(produktDetailAbschlussHelperService, 'produktDetailAbschlussHelperService');
        Assert.notNullOrUndefined(fileService, 'fileService');
    }

    ngOnInit(): void {
        this.loading$.next(true);
        if (this.produkt?.status) {
            this.statusChanged$.next(this.produkt.status);
        }
        this.subscriptions.push(this.checkStatus().subscribe());
        this.subscriptions.push(this.createSyncCountSubscription());
        this.acVersendetChanged$.next(this.form.get(FIELD_AC_VERSENDET)?.value);
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
        this.subscriptions.push(
            this.statusChanged$
                .pipe(distinctUntilChanged())
                .subscribe((produktStatus) => this.selectIndex(produktStatus, this.acVersendetChanged$.value)),
        );
        this.subscriptions.push(
            this.acVersendetChanged$
                .pipe(distinctUntilChanged())
                .subscribe((acVersendet) => this.selectIndex(this.statusChanged$.value, acVersendet)),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    onProduktCloseClick(): void {
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.close.failed`);
        this.loading$.next(true);
        this.subscriptions.push(this.checkStatus(this.abschlussService.post(this.produkt?.id)).subscribe(observer));
    }

    onProduktOpenClick(): void {
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.reopen.failed`);
        this.loading$.next(true);
        this.subscriptions.push(
            this.checkStatus(
                this.abschlussRechnungsService.post(this.produkt?.id, AbschlussRechnungsArt.Aborted),
            ).subscribe(observer),
        );
    }

    onVersendenClick(): void {
        this.changeDetector.detectChanges();
        this.openAbschlussVersendenConfirmDialog();
    }

    onDownloadClick(): void {
        const observer = this.getActionObserver<AbschlussDownloadResponse>(
            (response) => this.onDownloadResponse(response),
            `${this.name}.workflow.download.failed`,
        );
        this.loading$.next(true);
        this.subscriptions.push(
            this.abschlussDownloadService
                .get(this.produkt?.id)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(observer),
        );
    }

    onOpenDownloadClick(): void {
        const { value } = this.downloadUrl$;
        window.open(value, '_blank');
    }

    onAbschlussResetClick(): void {
        this.openAbschlussResetConfirmDialog();
    }

    private postDefaultRechnungsart() {
        const art = AbschlussRechnungsArt.Without;
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.rechnung.failed`);
        this.subscriptions.push(
            this.checkStatus(this.abschlussRechnungsService.post(this.produkt?.id, art)).subscribe(observer),
        );
    }

    private postDefaultVersandArt() {
        const art = AbschlussVersandArt.NoDelivery;
        const observer = this.getCheckStatusObserver(`${this.name}.workflow.versand.failed`);
        this.subscriptions.push(
            this.checkStatus(this.abschlussVersandService.post(this.produkt?.id, art)).subscribe(observer),
        );
    }

    private openAbschlussResetConfirmDialog() {
        const title = `${this.name}.workflow.reset.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        this.subscriptions.push(
            this.templateDialogService
                .openTemplate(title, buttons, this.acAbschlussResetTemplate)
                .pipe(take(1))
                .subscribe((result) => {
                    if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                        this.resetAbschluss();
                    }
                }),
        );
    }

    private openAbschlussVersendenConfirmDialog() {
        const title = `${this.name}.workflow.versenden.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate(title, buttons, this.acAbschlussVersendenTemplate)
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                    this.versenden();
                }
            });
    }

    private resetAbschluss() {
        this.loading$.next(true);
        const observer = this.getActionObserver(() => window.location.reload(), `${this.name}.workflow.reset.failed`);
        this.subscriptions.push(this.abschlussService.delete(this.produkt?.id).subscribe(observer));

        if (this.produkt?.acMetaInformation) {
            this.produkt.acMetaInformation.acVorgangStatus = AcProduktStatus.InBearbeitung;
        } else {
            console.warn('Unable to set acVorgangStatus - acMetaInformation is undefined.');
        }
    }

    private nextStep(count: number = 1): void {
        setTimeout(() => this.onNextStep(count), 1);
    }

    private updateProdukt(): Observable<Produkt> {
        return this.produktService
            .getById(this.produkt?.id, false)
            .pipe(tap((produkt) => this.onUpdateProdukt(produkt)));
    }

    private getActionObserver<T>(action: (value: T) => void, error: string): PartialObserver<T> {
        return {
            next: (value) => action(value),
            error: (ex) => {
                console.warn('An unexpected error occured while checking the status', ex);
                this.snackbarService.error(error);
            },
        };
    }

    private getCheckStatusObserver(error: string): PartialObserver<boolean> {
        return {
            next: (success: boolean) => {
                if (!success) {
                    this.snackbarService.error(error);
                }
            },
            error: (ex) => {
                console.warn('An unexpected error occured while checking the status', ex);
                this.handleCheckErrors(ex);
                this.snackbarService.error(error);
            },
        };
    }

    private checkStatus(start?: Observable<any>): Observable<boolean | Produkt> {
        if (this.produkt?.status === ProduktStatus.Beendet) {
            if (this.produkt?.rechnungsArt) {
                this.rechnungsArtChanged.next(this.produkt.rechnungsArt);
            }
            if (this.produkt?.versandArt) {
                this.versandArtChanged.next(this.produkt.versandArt);
            }
            if (this.produkt?.acAbschluss?.acVersendet) {
                this.acVersendetChanged$.next(this.produkt?.acAbschluss?.acVersendet);
            }
            this.nextStep(6);
            this.loading$.next(false);
            return of(true);
        }

        this.loading$.next(true);
        return (start ? start : of(null)).pipe(
            mergeMap(() =>
                this.waitUntilStep([
                    AbschlussWorkflowStep.Abgebrochen,
                    AbschlussWorkflowStep.InvoiceTypeDecision,
                    AbschlussWorkflowStep.ProductDeliveryTypeDecision,
                    AbschlussWorkflowStep.WaitForInvoice,
                    AbschlussWorkflowStep.StartBilling,
                    AbschlussWorkflowStep.FinishProduct,
                ]),
            ),
            mergeMap((reached) => iif(() => reached, this.updateProdukt(), of(null))),
            tap(() => this.nextStep(6)),
        );
    }

    private waitUntilStep(requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
        return this.abschlussService.get(this.produkt?.id).pipe(
            delay(STATUS_RESPONSE_DELAY),
            mergeMap((response) => this.onStatusResponse(response, requiredSteps)),
            retryWhen((errors) => errors.pipe(concatMap((error, retry) => this.onStatusError(error, retry)))),
        );
    }

    private onNextStep(count: number): void {
        if (this.stepperComponent) {
            for (let i = 0; i < count; ++i) {
                this.stepperComponent.next();
            }
        }
    }

    private onUpdateProdukt(produkt: Produkt): void {
        this.statusChanged$.next(produkt.status);
        this.statusChangedEvent.emit(produkt.status);
        this.rechnungsArtChanged.next(produkt.rechnungsArt);
        this.versandArtChanged.next(produkt.versandArt);
    }

    private onDownloadResponse(response: AbschlussDownloadResponse): void {
        if (response?.url?.length) {
            window.open(response.url, '_blank');
            this.downloadUrl$.next(response.url);
        }
    }

    private onStatusError(error: any, retry: number): Observable<void> {
        const retryDelay = (STATUS_INTERVAL_FACTOR[retry] || 30) * STATUS_INTERVAL;
        return of(error).pipe(delay(retryDelay));
    }

    private onStatusResponse(
        response: AbschlussGetResponse,
        requiredSteps: AbschlussWorkflowStep[],
    ): Observable<boolean> {
        if (!response) {
            return throwError(() => new Error('could not retrieve status.'));
        }

        const { status } = response;
        if (status === AbschlussWorkflowStatus.None || status === AbschlussWorkflowStatus.Aborted) {
            this.loading$.next(false);
            return of(true);
        }
        if (!response?.details?.step) {
            return throwError(() => new Error('could not retrieve details.'));
        }

        const { details } = response;
        if (status !== AbschlussWorkflowStatus.Running && status !== AbschlussWorkflowStatus.Succeeded) {
            this.loading$.next(false);
            return of(false);
        }

        const { step } = details;
        if (!requiredSteps.includes(step)) {
            return throwError(() => new Error('step not reached yet.'));
        }

        switch (step) {
            case AbschlussWorkflowStep.InvoiceTypeDecision:
                this.loading$.next(true);
                this.postDefaultRechnungsart();
                break;
            case AbschlussWorkflowStep.ProductDeliveryTypeDecision:
                this.loading$.next(true);
                this.postDefaultVersandArt();
                break;
            case AbschlussWorkflowStep.FinishProduct:
                this.loading$.next(false);
                this.statusChanged$.next(this.statusEnum.Beendet);
                break;
            case AbschlussWorkflowStep.StartBilling:
                this.loading$.next(false);
                this.statusChanged$.next(this.statusEnum.Beendet);
                break;
        }

        return of(true);
    }

    private versenden(): void {
        this.loading$.next(true);

        this.acAbschlussVersendenService
            .post(this.produkt?.id)
            .pipe(first())
            .subscribe({
                next: (response) => {
                    this.handleAcVersendenResponse(response);
                },
                error: (err) => {
                    this.snackbarService.error('acAbschluss.workflow.versenden.failed');
                    console.error('ERROR: versenden() - error ', err);
                },
                complete: () => this.loading$.next(false),
            });
    }

    private handleAcVersendenResponse(response): void {
        if (response?.statusCode !== 200) {
            this.snackbarService.error('acAbschluss.workflow.versenden.failed');
            console.error('ERROR: versenden() - response ', response);
            return;
        }

        this.snackbarService.success('acAbschluss.workflow.versenden.success');
        console.info('SUCCESS: versenden() - response: ', response);
        this.acVersendetChanged$.next(true);
        if (this.produkt?.acAbschluss) {
            this.produkt.acAbschluss.acVersendet = true;
        }
    }

    private selectIndex(produktStatus: ProduktStatus, acVersendet: boolean): void {
        this.changeDetector.detectChanges();
        if (!this.stepperComponent) {
            return;
        }

        if (acVersendet === true) {
            this.stepperComponent.setIndex(AcAbschlussStep.Versendet);
            return;
        }

        switch (produktStatus) {
            case ProduktStatus.Offen:
                this.stepperComponent.setIndex(AcAbschlussStep.Offen);
                break;
            case ProduktStatus.Beendet:
                this.stepperComponent.setIndex(AcAbschlussStep.Beendet);
                break;
            default:
                this.stepperComponent.setIndex(AcAbschlussStep.Offen);
        }
    }

    private createSyncCountSubscription(): Subscription {
        return this.fileService
            .syncCount()
            .pipe(tap((syncCount) => (this.currentSyncCount = syncCount)))
            .subscribe();
    }

    private handleCheckErrors(response: HttpErrorResponse) {
        if (response?.error) {
            switch (response?.error?.message) {
                case MESSAGE_ERROR_BILDER:
                case MESSAGE_ERROR_PDF: {
                    this.openSynchronizationDialog();
                    this.loading$.next(false);
                }
            }
        }
    }

    private openSynchronizationDialog() {
        const title = 'abschluss.workflow.synchronization.dialog.title';
        const buttons = [this.templateDialogService.getCancelButtonSetting()];
        if (this.currentSyncCount > 0) {
            buttons.push(this.templateDialogService.getSynchronizeButtonSetting());
        }

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogDataSynchronizationTemplate, null, true)
            .pipe(first())
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSynchronizeButtonSetting().title) {
                    this.produktDetailAbschlussHelperService.onSyncClicked(this.loading$);
                }
            });
    }
}
