import { Injectable } from '@angular/core';
import { FahrzeugServiceResponse } from '@data/api-gateway';
import { Assert } from '@shared/helper/assert';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
    ProduktDetailFahrzeugDialogComponent,
    ProduktDetailFahrzeugDialogData,
} from '../component/produkt-detail-fahrzeug-dialog/produkt-detail-fahrzeug-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFahrzeugDialogService {
    constructor(private readonly templateDialog: TemplateDialogService) {
        Assert.notNullOrUndefined(templateDialog, 'templateDialog');
    }

    open(title: string, response: FahrzeugServiceResponse): Observable<FahrzeugServiceResponse> {
        Assert.notNullOrEmpty(title, 'title');
        Assert.notNullOrUndefined(response, 'response');

        const data: ProduktDetailFahrzeugDialogData = {
            response,
        };
        const buttons = [this.templateDialog.getCancelButtonSetting()];
        return this.templateDialog.openTemplate(title, buttons, ProduktDetailFahrzeugDialogComponent, data, true).pipe(
            mergeMap((result) => {
                if (result.name === 'fahrzeug.error') {
                    return throwError(() => new Error('fahrzeug.error'));
                }
                return of(
                    result.name === this.templateDialog.getSaveButtonSetting().title ? null : result.data.response,
                );
            }),
        );
    }
}
