import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Adresse, Adressen } from '@data/domain/schema/type';
import { PRODUKT_ADRESSEN_ARTEN, ProduktAdresseArt } from '@modules/produkt/config/produkt-adressen-arten.config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, CalculatedViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '../../config/produkt-config';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailAdressenAdresseFormViewFactory } from './produkt-detail-adressen-adresse-form-view.factory';

function requiredIfValidator(predicate: (parent: UntypedFormGroup) => boolean): ValidatorFn {
    return (formControl) => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate(formControl.parent as UntypedFormGroup)) {
            return Validators.required(formControl);
        }
        return null;
    };
}

function artenNotContainsArt(arten: string[], art: ProduktAdresseArt): boolean {
    const stringArt = PRODUKT_ADRESSEN_ARTEN[art].toLowerCase();
    return (arten || []).map((x: string) => x.toLowerCase()).indexOf(stringArt) === -1;
}

function requiredIfAdressenNotContainsArt(art: ProduktAdresseArt): ValidatorFn {
    return requiredIfValidator((form) => {
        const adressen: Adresse[] = (
            form.get(PRODUKT_CONFIG_FEATURES.Adressen.name) as AbstractViewFormControl
        ).getRawValue();
        return adressen.every((x) => artenNotContainsArt(x.arten, art));
    });
}

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailAdressenFormViewFactory extends FormViewModelBaseFactory<Adressen> {
    constructor(private readonly adresseFormViewFactory: ProduktDetailAdressenAdresseFormViewFactory) {
        super();
        Assert.notNullOrUndefined(adresseFormViewFactory, 'adresseFormViewFactory');
    }

    protected createField(_model: Adressen, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Adressen.fields;
        if (name === fields.Auftraggeber.name) {
            return new CalculatedViewFormControl({
                calculateValue: () => undefined,
                continuous: false,
                validators: [requiredIfAdressenNotContainsArt(ProduktAdresseArt.Auftraggeber)],
            });
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Adressen, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Adressen.fields;
        if (arrayName === fields.Adressen.name) {
            const adressen = model.adressen || [];
            return new ViewFormArray(
                adressen.map((adresse) => this.adresseFormViewFactory.create(adresse, arrayFields)),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
