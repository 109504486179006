import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CombiPlusOnlineService, CombiPlusVorgangsArt } from '@data/api-gateway/service/bvws-combi-plus-online.service';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GridTile } from './grid-tile';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit {
    trackByLink = TrackBy.trackByLink;

    readonly rowHeight = '14rem';
    readonly gutterSize = '0rem';

    cols$: Observable<number>;
    loading$ = new BehaviorSubject(false);

    @Input()
    tiles: GridTile[] = [];

    constructor(
        private readonly combiPlusOnlineService: CombiPlusOnlineService,
        private readonly snackBarService: SnackBarService,
        private readonly viewportService: ViewportService,
    ) {
        Assert.notNullOrUndefined(viewportService, 'viewportService');
    }

    ngOnInit(): void {
        this.cols$ = this.viewportService.observe().pipe(map((viewport) => this.mapCols(viewport)));
    }

    private mapCols(viewport: Viewport): number {
        switch (viewport) {
            case Viewport.Desktop:
                return 4;
            case Viewport.Tablet:
                return 4;
            case Viewport.Mobile:
                return 2;
        }
    }

    onHaftpflichtschadenClick(): void {
        this.putCpoVorgang(CombiPlusVorgangsArt.Haftpflichtschaden);
    }

    onKaskoSchadenClick(): void {
        this.putCpoVorgang(CombiPlusVorgangsArt.Kaskoschaden);
    }

    onProduktClick(): void {
        this.loading$.next(true);
    }

    private putCpoVorgang(art: CombiPlusVorgangsArt): void {
        this.loading$.next(true);
        this.combiPlusOnlineService
            .putCase(art)
            .pipe(take(1))
            .subscribe({
                next: (url) => {
                    if (url) {
                        window.open(url, '_blank');
                    }
                },
                error: (error) => {
                    error.status === 404
                        ? this.snackBarService.info('Vorgang konnte nicht angelegt werden. 404')
                        : this.snackBarService.error('Vorgang konnte nicht angelegt werden.');
                },
            });
        this.loading$.next(false);
    }
}
