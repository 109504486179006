import { CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { GtueMaterialModule } from '@gtue/material';
import { TranslateModule } from '@ngx-translate/core';
import { MenuButtonComponent } from '@shared/component/button-indicator/menu-button/menu-button.component';
import { SlideToggleComponent } from '@shared/component/button-indicator/slide-toggle/slide-toggle.component';
import { BadgeComponent } from '@shared/component/information/badge/badge.component';
import { ExpansionPanelCustomComponent } from '@shared/component/layout/expansion/expansion-panel-custom/expansion-panel-custom.component';
import { ExpansionPanelHeaderCustomComponent } from '@shared/component/layout/expansion/expansion-panel-header-custom/expansion-panel-header-custom.component';
import { ExpansionPanelSingleComponent } from '@shared/component/layout/expansion/expansion-panel-single/expansion-panel-single.component';
import { OnlyNumberDirective } from '@shared/directive/form-controls/only.number.directive';
import { ButtonComponent } from './component/button-indicator/button/button.component';
import { ChipComponent, ChipListComponent } from './component/button-indicator/chip-list';
import { FabComponent } from './component/button-indicator/fab/fab.component';
import { IconButtonComponent } from './component/button-indicator/icon-button/icon-button.component';
import { IconComponent } from './component/button-indicator/icon/icon.component';
import { LayeredIconComponent } from './component/button-indicator/layered-icon/layered-icon.component';
import { MatIconComponent } from './component/button-indicator/mat-icon/mat-icon.component';
import { MiniFabComponent } from './component/button-indicator/mini-fab/mini-fab.component';
import { ProgressBarComponent } from './component/button-indicator/progress-bar/progress-bar.component';
import { ProgressSpinnerComponent } from './component/button-indicator/progress-spinner/progress-spinner.component';
import { ToggleGroupComponent } from './component/button-indicator/toggle-group/toggle-group.component';
import { ToggleComponent } from './component/button-indicator/toggle/toggle.component';
import { CameraControlsComponent } from './component/camera/camera-controls/camera-controls.component';
import { CameraViewComponent } from './component/camera/camera-view/camera-view.component';
import {
    TableColumnComponent,
    TableComponent,
    TableFormColumnComponent,
    TableFormComponent,
} from './component/data-table';
import {
    AutocompleteComponent,
    CheckboxComponent,
    DateComponent,
    InputComponent,
    RadioComponent,
    SelectComponent,
    SliderComponent,
    TextareaComponent,
} from './component/form-controls';
import { ButtonGroupComponent } from './component/form-controls/button-group/button-group.component';
import { ExtendableListComponent } from './component/form-controls/extendable-list/extendable-list.component';
import { ExtendableRadioListComponent } from './component/form-controls/extendable-radio-list/extendable-radio-list.component';
import { InlineImageComponent } from './component/form-controls/inline-image/inline-image.component';
import { InputSearchComponent } from './component/form-controls/input-search/input-search.component';
import { TextbausteineListComponent } from './component/form-controls/textbausteine-list/textbausteine-list.component';
import { TextbausteineComponent } from './component/form-controls/textbausteine/textbausteine.component';
import { AudioGalleryComponent } from './component/layout/audio-gallery/audio-gallery.component';
import { AudioComponent } from './component/layout/audio/audio.component';
import { CardComponent } from './component/layout/card/card.component';
import { ChatComponent } from './component/layout/chat/chat.component';
import { DividerComponent } from './component/layout/divider/divider.component';
import {
    AccordionComponent,
    ExpansionHeaderComponent,
    ExpansionPanelComponent,
    PanelTitleComponent,
    PanelValueComponent,
} from './component/layout/expansion';
import { ActionRowComponent } from './component/layout/expansion/action-row/action-row.component';
import { FileGalleryComponent } from './component/layout/file-gallery/file-gallery.component';
import { FileComponent } from './component/layout/file/file.component';
import { FluidCellComponent } from './component/layout/fluid-cell/fluid-cell.component';
import { FluidContainerComponent } from './component/layout/fluid-container/fluid-container.component';
import { FormFieldComponent } from './component/layout/form-field/form-field.component';
import { FormGroupComponent } from './component/layout/form-group/form-group.component';
import { GridComponent } from './component/layout/grid/grid.component';
import { ImageComponent } from './component/layout/image/image.component';
import {
    ActionListComponent,
    CardListComponent,
    CardListItemComponent,
    DropListComponent,
    ListOptionComponent,
    SelectionListComponent,
} from './component/layout/list';
import { ProduktSidepanelComponent } from './component/layout/produkt-sidepanel/produkt-sidepanel.component';
import { SidePanelComponent } from './component/layout/side-panel/side-panel.component';
import { StepperComponent } from './component/layout/stepper';
import { StepLabelComponent } from './component/layout/stepper/step-label/step-label.component';
import { StepComponent } from './component/layout/stepper/step/step.component';
import { TabComponent, TabGroupComponent, TabNavComponent } from './component/layout/tabs';
import { BottomSheetComponent } from './component/popup-modal/bottom-sheet/bottom-sheet.component';
import { CaptureDialogComponent } from './component/popup-modal/capture-dialog/capture-dialog.component';
import { DeleteDialogComponent } from './component/popup-modal/delete-dialog/delete-dialog.component';
import { FileDialogComponent } from './component/popup-modal/file-dialog/file-dialog.component';
import { PrivacyDialogComponent } from './component/popup-modal/privacy-dialog/privacy-dialog.component';
import { TemplateDialogComponent } from './component/popup-modal/template-dialog/template-dialog.component';
import { TextbausteineDialogEditComponent } from './component/popup-modal/textbausteine-dialog-edit/textbausteine-dialog-edit.component';
import { TextbausteineDialogComponent } from './component/popup-modal/textbausteine-dialog/textbausteine-dialog.component';
import { UploadDialogComponent } from './component/popup-modal/upload-dialog/upload-dialog.component';
import { PrintButtonComponent } from './component/print-button/print-button/print-button.component';
import { SpinnerOverlayComponent } from './component/spinner-overlay/spinner-overlay.component';
import { GltfIndicatorComponent } from './component/three/gltf-indicator/gltf-indicator.component';
import { GltfComponent } from './component/three/gltf/gltf.component';
import { ObjectViewControlComponent } from './component/three/object-view-control/object-view-control.component';
import { SceneComponent } from './component/three/scene/scene.component';
import { HintDirective } from './directive/form-controls/hint.directive';
import { SuffixDirective } from './directive/form-controls/suffix.directive';
import { MaterialModule } from './module/material.module';

@NgModule({
    declarations: [
        MatIconComponent,
        ProgressBarComponent,
        LayeredIconComponent,
        InputComponent,
        InputSearchComponent,
        CardComponent,
        RadioComponent,
        TextareaComponent,
        SelectComponent,
        CheckboxComponent,
        DateComponent,
        FormGroupComponent,
        FormFieldComponent,
        SuffixDirective,
        GridComponent,
        SceneComponent,
        GltfComponent,
        ObjectViewControlComponent,
        ProgressSpinnerComponent,
        ToggleGroupComponent,
        ToggleComponent,
        GltfIndicatorComponent,
        FabComponent,
        UploadDialogComponent,
        CaptureDialogComponent,
        FileComponent,
        ImageComponent,
        FileDialogComponent,
        BottomSheetComponent,
        TabGroupComponent,
        TabComponent,
        TabNavComponent,
        ButtonComponent,
        MiniFabComponent,
        SliderComponent,
        DividerComponent,
        FileGalleryComponent,
        ActionListComponent,
        SelectionListComponent,
        ListOptionComponent,
        TemplateDialogComponent,
        TableFormComponent,
        TableFormColumnComponent,
        AutocompleteComponent,
        ExpansionPanelComponent,
        ExpansionHeaderComponent,
        AccordionComponent,
        PanelTitleComponent,
        ChipListComponent,
        ChipComponent,
        IconComponent,
        ActionRowComponent,
        StepperComponent,
        StepComponent,
        StepLabelComponent,
        HintDirective,
        ExtendableListComponent,
        AudioGalleryComponent,
        AudioComponent,
        ChatComponent,
        ExtendableRadioListComponent,
        PanelValueComponent,
        CameraViewComponent,
        CameraControlsComponent,
        TableComponent,
        TableColumnComponent,
        PrivacyDialogComponent,
        InlineImageComponent,
        FluidContainerComponent,
        FluidCellComponent,
        ButtonGroupComponent,
        SidePanelComponent,
        IconButtonComponent,
        TextbausteineComponent,
        TextbausteineDialogEditComponent,
        ProduktSidepanelComponent,
        TextbausteineDialogComponent,
        TextbausteineListComponent,
        DeleteDialogComponent,
        PrintButtonComponent,
        OnlyNumberDirective,
        SlideToggleComponent,
        ExpansionPanelSingleComponent,
        DropListComponent,
        CardListComponent,
        CardListItemComponent,
        BadgeComponent,
        MenuButtonComponent,
        SpinnerOverlayComponent,
        ExpansionPanelCustomComponent,
        ExpansionPanelHeaderCustomComponent,
    ],
    imports: [
        // default
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        // 3rd Party
        TranslateModule,

        // modules
        MaterialModule,
        GtueMaterialModule,
        MatSlideToggleModule,
        CdkDropList,
        CdkDragHandle,
    ],
    exports: [
        // default
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        // 3rd Party
        TranslateModule,

        // components
        GridComponent,
        // // popup-modal
        BottomSheetComponent,
        // // button-indicator
        MatIconComponent,
        LayeredIconComponent,
        ProgressBarComponent,
        ProgressSpinnerComponent,
        ToggleComponent,
        ToggleGroupComponent,
        FabComponent,
        MiniFabComponent,
        ButtonComponent,
        ChipListComponent,
        ChipComponent,
        IconComponent,
        // // three
        SceneComponent,
        GltfComponent,
        GltfIndicatorComponent,
        ObjectViewControlComponent,
        // // layout
        ActionListComponent,
        FormGroupComponent,
        FormFieldComponent,
        CardComponent,
        TabNavComponent,
        FileComponent,
        ImageComponent,
        TabComponent,
        TabGroupComponent,
        DividerComponent,
        FileGalleryComponent,
        ListOptionComponent,
        SelectionListComponent,
        AudioGalleryComponent,
        AudioComponent,
        ChatComponent,
        FluidContainerComponent,
        FluidCellComponent,
        // // form-controls
        InputComponent,
        InputSearchComponent,
        RadioComponent,
        TextareaComponent,
        SelectComponent,
        CheckboxComponent,
        DateComponent,
        SuffixDirective,
        SliderComponent,
        AutocompleteComponent,
        HintDirective,
        ExtendableListComponent,
        ExtendableRadioListComponent,
        InlineImageComponent,
        // // data-table
        TableFormComponent,
        TableFormColumnComponent,
        TableComponent,
        TableColumnComponent,
        // // expansion
        AccordionComponent,
        ExpansionPanelComponent,
        ExpansionHeaderComponent,
        PanelTitleComponent,
        PanelValueComponent,
        ActionRowComponent,
        // // stepper
        StepperComponent,
        StepComponent,
        StepLabelComponent,
        ButtonGroupComponent,
        SidePanelComponent,
        TextbausteineComponent,
        ProduktSidepanelComponent,
        TextbausteineListComponent,
        DeleteDialogComponent,
        PrintButtonComponent,
        SlideToggleComponent,
        ExpansionPanelSingleComponent,
        DropListComponent,
        CardListComponent,
        CardListItemComponent,
        BadgeComponent,
        IconButtonComponent,
        MenuButtonComponent,
        SpinnerOverlayComponent,
        ExpansionPanelCustomComponent,
        ExpansionPanelHeaderCustomComponent,
        TextbausteineDialogComponent,
        TextbausteineDialogEditComponent,
    ],
})
export class SharedModule {}
