import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { Assert } from '@shared/helper/assert';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { TrackBy } from '@shared/helper/track-by';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent extends FormControlBase implements OnInit {
    trackByKey = TrackBy.trackByKey;

    seperators = [ENTER, COMMA];
    viewControl = new UntypedFormControl();
    optionsFiltered$: Observable<string[]>;

    @Input()
    options: string[] = [];

    @ViewChild(MatInput, { static: true, read: ElementRef })
    input: ElementRef<HTMLElement>;

    @ViewChild('trigger', { static: true })
    trigger: MatAutocompleteTrigger;

    constructor(formControlFocusService: FormControlFocusService) {
        super(formControlFocusService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.optionsFiltered$ = this.viewControl.valueChanges.pipe(
            startWith(null as string),
            map((term) => this.filterOptions(term)),
        );
    }

    onFieldClick(): void {
        this.openPanel();
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent, input: HTMLInputElement): void {
        Assert.notNullOrUndefined(event, 'event');
        Assert.notNullOrUndefined(input, 'input');
        const value = (event.option.value || '').trim();
        this.addOption(value, input);
    }

    onOptionAdd(event: MatChipInputEvent, input: HTMLInputElement): void {
        Assert.notNullOrUndefined(event, 'event');
        Assert.notNullOrUndefined(input, 'input');
        const value = (event.value || '').trim();
        this.addOption(value, input);
    }

    onOptionRemove(index: number): void {
        Assert.notNullOrUndefined(index, 'index');
        this.removeOption(index);
    }

    blur(): void {
        this.input.nativeElement.blur();
    }

    focus(): void {
        this.input.nativeElement.focus();
        this.openPanel();
    }

    private addOption(value: string, input: HTMLInputElement): void {
        value = ViewFormControlFormatters.firstLetterToUppercase.format(value);
        this.control.setValue([...this.control.value, value]);
        input.value = '';
        this.viewControl.setValue(null);
        this.openPanel();
    }

    private openPanel(): void {
        this.trigger.openPanel();
    }

    private removeOption(index: number): void {
        this.control.value.splice(index, 1);
        this.control.setValue(this.control.value);
        this.viewControl.setValue(null);
    }

    private filterOptions(term: string): string[] {
        const availableOptions = this.options.filter((option) => this.control.value.indexOf(option) === -1);

        term = (term || '').toLowerCase();
        if (term.length > 0) {
            return availableOptions.filter((option) => option.toLowerCase().indexOf(term) !== -1);
        }
        return availableOptions;
    }
}
