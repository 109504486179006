import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { File } from '@app/class/file';
import { FileData } from '@app/class/file-data';
import { FileSize } from '@app/enum/file-size.enum';
import { FileService } from '@app/service/file.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';
import { FileDialogService } from '@shared/service/file-dialog.service';
import { ImageEditorService } from '@shared/service/image-editor.service';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

interface FileInput {
    id: string;
    thumb: boolean;
}

interface FileResult extends FileInput {
    file?: File;
    image?: boolean;
}

@Component({
    selector: 'app-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent implements OnInit, OnDestroy {
    private fileInput: FileInput = {
        id: undefined,
        thumb: true,
    };
    private fileInputChange = new Subject<FileInput>();
    private fileInputChangeSubscription: Subscription;

    result$ = new BehaviorSubject<FileResult>(undefined);

    @Input()
    buttonLabel: string;

    @Input()
    set fileId(id: string) {
        this.fileInput.id = id;
        this.fileInputChange.next(this.fileInput);
    }

    @Input()
    set thumb(thumb: boolean) {
        this.fileInput.thumb = thumb;
        this.fileInputChange.next(this.fileInput);
    }

    @Output()
    save = new EventEmitter<FileData<ArrayBuffer>>();

    @Output()
    buttonClick = new EventEmitter<string>();

    constructor(
        private readonly fileService: FileService,
        private readonly fileDialog: FileDialogService,
        private readonly imageEditorService: ImageEditorService,
    ) {
        Assert.notNullOrUndefined(fileService, 'file');
        Assert.notNullOrUndefined(fileDialog, 'fileDialog');
        Assert.notNullOrUndefined(imageEditorService, 'imageEditorService');
    }

    ngOnInit(): void {
        this.fileInputChangeSubscription = this.fileInputChange
            .pipe(
                tap(() => this.clear()),
                debounceTime(100),
            )
            .subscribe((input) => this.loadFile(input));
        this.loadFile(this.fileInput);
    }

    ngOnDestroy(): void {
        this.fileInputChangeSubscription?.unsubscribe();
    }

    onFileClick(fileResult: FileResult): void {
        if (!fileResult.file && !fileResult.image) {
            return;
        }

        if (!fileResult.thumb) {
            return;
        }

        if (this.buttonLabel) {
            this.fileDialog
                .displayFile(fileResult.file.name || '', fileResult.id, [
                    { title: this.buttonLabel, type: ButtonType.PRIMARY },
                ])
                .pipe(take(1))
                .subscribe((result) => {
                    if (result?.data?.fileId) {
                        this.buttonClick.emit(result.data.fileId);
                    }
                });
        } else {
            this.fileDialog.displayFile(fileResult.file.name || '', fileResult.id);
        }
    }

    onReloadClick(): void {
        this.fileInputChange.next(this.fileInput);
    }

    edit(): void {
        const { image, file } = this.result$.value;
        if (image && file) {
            this.fileService
                .get(file.id, FileSize.Fullscreen)
                .pipe(
                    mergeMap((data) => this.imageEditorService.edit(data)),
                    take(1),
                )
                .subscribe((result) => {
                    this.save.next(result);
                });
        } else {
            alert('todo');
        }
    }

    private loadFile(input: FileInput): void {
        const size = input.thumb ? FileSize.Thumbnail : FileSize.Fullscreen;
        this.fileService
            .get(input.id, size)
            .pipe(
                takeUntil(this.fileInputChange),
                catchError(() => of(null)),
            )
            .subscribe((file) =>
                this.result$.next({
                    ...input,
                    file,
                    image: file?.type?.startsWith('image/'),
                }),
            );
    }

    private clear(): void {
        this.result$.next(undefined);
    }
}
