import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveKalkulation, SaveKalkulationData } from '../../graphql/mutations';
import { Kalkulation, KalkulationInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktKalkulationService extends ProduktFeatureService<Kalkulation, KalkulationInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Kalkulation): KalkulationInput {
        const input: KalkulationInput = {
            id: produktId,
            ...feature,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveKalkulation;
    }

    protected getSaveVariables(input: KalkulationInput): any {
        return {
            kalkulation: input,
        };
    }

    protected getSaveOptimisticResponse(input: KalkulationInput): any {
        return {
            saveKalkulation: {
                ...input,
                __typename: 'Kalkulation',
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveKalkulationData>): Kalkulation {
        return response.data.saveKalkulation;
    }

    protected update(produkt: Produkt, feature: Kalkulation): void {
        produkt.kalkulation.id = feature.id;
        produkt.kalkulation.bezugsdatum = feature.bezugsdatum;
        produkt.kalkulation.dossierId = feature.dossierId;
        produkt.kalkulation.reparaturkostenNetto = feature.reparaturkostenNetto;
        produkt.kalkulation.reparaturkostenBrutto = feature.reparaturkostenBrutto;
        produkt.kalkulation.dokument = feature.dokument;
    }
}
