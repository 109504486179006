import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BenutzerEinstellungen } from '@data/api-gateway/service/einstellungen.service';
import { EinstellungenBenutzerFormFactory } from '@modules/einstellungen/factory';
import { EinstellungenViewModelResolveService } from '@modules/einstellungen/service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';

@Component({
    selector: 'app-einstellungen-benutzer-form',
    templateUrl: './einstellungen-benutzer-form.component.html',
    styleUrls: ['./einstellungen-benutzer-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinstellungenBenutzerFormComponent implements OnInit {
    readonly name = 'einstellungen';
    form: UntypedFormGroup;
    maxFileUploadSizeInKb = 500;

    protected readonly ButtonType = ButtonType;

    @Input()
    saving: boolean;

    @Output()
    save = new EventEmitter<BenutzerEinstellungen>();

    constructor(
        private readonly resolve: EinstellungenViewModelResolveService,
        private readonly factory: EinstellungenBenutzerFormFactory,
    ) {
        Assert.notNullOrUndefined(resolve, 'resolve');
        Assert.notNullOrUndefined(factory, 'factory');
    }

    ngOnInit(): void {
        const { benutzer } = this.resolve.get();
        this.form = this.factory.create(benutzer);
    }

    onSave(): void {
        this.save.next(this.form.value);
    }
}
