import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { ProduktArt } from '@data/domain/schema/enum';
import { SchadenPositionBase } from '@data/domain/schema/type';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import {
    ColumnCount,
    MaxColumnEntries,
} from '@shared/component/form-controls/extendable-radio-list/extendable-radio-list.component';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { EnumValues, ObjectValues } from '@shared/helper/values';
import { TemplateButtonDisableEvent } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable, Subscription } from 'rxjs';

@Directive()
export abstract class ProduktDetailSchadenPositionComponentBase implements OnInit, OnDestroy {
    @Input()
    produktArt: ProduktArt;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    @Input()
    fieldConfig: any;

    @Output()
    disableTemplateButton = new EventEmitter<TemplateButtonDisableEvent>();

    feature: string;

    // Template variables
    viewport = Viewport;
    columnCountEnum = ColumnCount;
    maxColumnEntries = MaxColumnEntries;

    // Observables
    viewport$: Observable<Viewport>;

    // Subscriptions
    subscriptions: Subscription[] = [];

    trackByField = TrackBy.trackByField;

    requiredFields: string[];

    protected abstract obergruppe: ObjectValues | EnumValues;
    protected abstract untergruppe: ObjectValues | EnumValues;

    constructor(
        private readonly fileFieldService: ProduktDetailFileFieldService,
        private readonly viewportService: ViewportService,
    ) {
        Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
    }

    ngOnInit(): void {
        this.viewport$ = this.viewportService.observe();
        this.handlePositionBestaetigung(this.form?.getRawValue());
        this.subscriptions.push(
            this.form?.valueChanges.subscribe((value) => {
                this.handlePositionBestaetigung(value);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(files, 'files');
        this.fileFieldService.add(field, files);
    }

    onFileDelete(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.remove(field, fileId);
    }

    onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        this.fileFieldService.update(field, event);
    }

    onFileReset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.reset(field, fileId);
    }

    isFileResetable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.fileFieldService.isUpdateable(fileId);
    }

    getEmptyFieldsForValidation(): string[] {
        if (this.requiredFields?.length === 0 || this.produktArt !== ProduktArt.AlphaController) {
            return [];
        }

        const ret: string[] = [];

        this.requiredFields.forEach((field) => {
            const value = this.form.get(field)?.value;
            if (value === null || value === undefined) {
                ret.push(field);
            }
        });

        return ret;
    }

    private handlePositionBestaetigung(value: SchadenPositionBase): void {
        const bestaetigtControl = this.form.get(this.fieldConfig?.Bestaetigt?.name);
        if (this.requiredFields?.length === 0 || !value || !bestaetigtControl) {
            return;
        }

        if (value.extern || this.produktArt === ProduktArt.AlphaController) {
            for (const field of this.requiredFields) {
                if (!value[field]) {
                    this.form.get(this.fieldConfig.Bestaetigt.name)?.setValue(false, { emitEvent: false });
                    this.disableTemplateButton.emit({ index: 1, disable: true });
                    return;
                }
            }
        }

        this.form.get(this.fieldConfig.Bestaetigt.name)?.setValue(true, { emitEvent: false });
        this.disableTemplateButton.emit({ index: 1, disable: false });
    }
}
