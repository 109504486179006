import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';

@Component({
    selector: 'app-print-button',
    templateUrl: './print-button.component.html',
    styleUrls: ['./print-button.component.scss'],
})
export class PrintButtonComponent implements OnInit {
    @Input()
    form: ViewFormGroup;

    @Input()
    name: string;

    @Input()
    disabled: boolean;

    control: AbstractControl;

    value: boolean;

    ngOnInit() {
        this.control = this.form.get(this.name);
        this.value = this.control.value;
    }

    onClicked() {
        this.form.setControlValue(this.name, !this.form.get(this.name).value);
        this.value = this.form.get(this.name).value;
    }
}
