import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { environment } from '@env/environment';
import { ProduktDetailErrorDialogComponent } from '@modules/produkt/component/produkt-detail-error-dialog/produkt-detail-error-dialog.component';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { concatMap, first, of } from 'rxjs';
import { QuotaExceededService } from './quota-exceeded.service';
import { SpinnerOverlayService } from './spinner-overlay.service';

const STACK_TRACE_LIMIT = 20;
const SNACKBAR_ERROR_DURATION = 8000;
const ERROR_LIST = {
    QUOTA_EXCEEDED: { name: 'AbortError', message: 'QuotaExceededError ' },
};

export interface AppLoggerPostRequest {
    url?: string;
    name?: string;
    message?: string;
    stack?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
    private readonly restApiUrl: string;

    constructor(
        private readonly snackbarService: SnackBarService,
        private readonly einstellungenService: EinstellungenService,
        private readonly quotaExceededService: QuotaExceededService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly spinnerOverlayService: SpinnerOverlayService,
        private readonly httpClient: HttpClient,
    ) {
        super();

        Error.stackTraceLimit = STACK_TRACE_LIMIT;
        this.restApiUrl = `${environment.aws.apiGateway.url}/applogger`;
    }

    handleError(error: Error): void {
        if (!error) {
            return;
        }
        try {
            this.handleErrorByUserSetting(error);
            this.handleSpecificError(error);
            super.handleError(error);
        } catch (err) {
            console.error('handleError() - Exception: ', err);
        }
    }

    private handleErrorByUserSetting(error) {
        this.einstellungenService
            .getBenutzer()
            .pipe(first())
            .subscribe({
                next: (benutzer) => {
                    if (benutzer?.errorHandlingSnackbar) {
                        this.showErrorOnSnackbar(error);
                    }
                    if (benutzer?.errorHandlingCloudSink) {
                        this.postErrorToCloudSink(error);
                    }
                },
                error: (err) => {
                    console.error('Unable to get benutzereinstellungen: ', err);
                },
            });
    }

    private postErrorToCloudSink(error: Error): void {
        if (!error) {
            return;
        }
        const request: AppLoggerPostRequest = {
            url: window?.location?.href,
            name: error.name,
            message: error.message,
            stack: error.stack,
        };
        this.httpClient
            .post<AppLoggerPostRequest>(this.restApiUrl, request)
            .pipe(first())
            .subscribe({
                next: (result) => {
                    if (result) {
                        console.info('Error info successfully uploaded to cloud sink.');
                    } else {
                        console.error('Upload error info to cloud sink failed: ', result);
                    }
                },
                error: (err) => {
                    console.error('Upload error info to cloud sink - error: ', err);
                },
            });
    }

    private showErrorOnSnackbar(error): void {
        if (!error) {
            return;
        }
        this.snackbarService.error(error, null, null, SNACKBAR_ERROR_DURATION, false);

        const logEntry = `${new Date().toLocaleString('de-DE')}: - ${error}`;
        console.error(logEntry);
    }

    private handleSpecificError(error: Error): void {
        if (!error) {
            return;
        }
        if (error.name === ERROR_LIST.QUOTA_EXCEEDED.name && error.message === ERROR_LIST.QUOTA_EXCEEDED.message) {
            this.quotaExceededError();
        }
    }

    private quotaExceededError(): void {
        const title = 'errorhandling.quotaExceeded.title';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];
        const data = {
            textKey: 'errorhandling.quotaExceeded',
        };
        this.templateDialogService
            .openTemplate(title, buttons, ProduktDetailErrorDialogComponent, data)
            .pipe(
                first(),
                concatMap((result) => {
                    if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                        this.spinnerOverlayService.show();
                        return this.quotaExceededService.handle();
                    } else {
                        return of(null);
                    }
                }),
            )
            .subscribe({
                next: (result) => {
                    if (result === null || result === undefined) {
                        return;
                    }
                    if (result) {
                        this.snackbarService.success('indexeddbService.clear.success');
                        window?.location?.reload();
                    } else {
                        this.snackbarService.error('indexeddbService.clear.error');
                    }
                    this.spinnerOverlayService.hide();
                },
                error: (error) => {
                    console.error('Error on clearing indexeddb: ', error);
                    this.snackbarService.error('indexeddbService.clear.error');
                    this.spinnerOverlayService.hide();
                },
            });
    }
}
