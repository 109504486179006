import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Values } from '@shared/helper/values';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent {
    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    displayName: string;

    @Input()
    showTicks = true;

    @Input()
    discrete = false;

    @Input()
    min: number;

    @Input()
    max: number;

    @Input()
    values: Values;

    @Input()
    valuePrefix = '';

    @Input()
    step = 1;

    @Input()
    tickInterval = 1;

    @Input()
    thumbLabel = true;

    @Input()
    rangeLabel = true;
}
