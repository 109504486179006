import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ProduktArt } from '@data/domain/schema/enum';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { take } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-feststellungen-umfang',
    templateUrl: './produkt-detail-feststellungen-umfang.component.html',
    styleUrls: ['./produkt-detail-feststellungen-umfang.component.scss'],
})
export class ProduktDetailFeststellungenUmfangComponent {
    @Input()
    umfaenge: ViewFormArray;

    @Output()
    addClicked = new EventEmitter<string>();

    @Output()
    deleteClicked = new EventEmitter<number>();

    @ViewChild('dialogDelete', { static: true })
    dialogDeleteTemplate: TemplateRef<any>;

    produktArt = ProduktArt;

    constructor(private readonly templateDialogService: TemplateDialogService) {}

    onDelete(index: number): void {
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getDeleteButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate('Umfang löschen', buttons, this.dialogDeleteTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getDeleteButtonSetting().title) {
                    this.deleteClicked.emit(index);
                }
            });
    }
}
