import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileData } from '@app/class/file-data';
import { FileValidationService, FileValidationTypes } from '@app/service/file-validation.service';
import { Assert } from '@shared/helper/assert';
import { CaptureDialogService } from '@shared/service/capture-dialog.service';
import { UploadDialogService } from '@shared/service/upload-dialog.service';
import { filter, map, take } from 'rxjs/operators';

export enum FileGalleryAddType {
    Capture = 1,
    File = 2,
}

export interface FileGalleryUpdateEvent {
    id: string;
    data: FileData<ArrayBuffer>;
}

export type isResetableFn = (fileId: string) => boolean;

@Component({
    selector: 'app-file-gallery',
    templateUrl: './file-gallery.component.html',
    styleUrls: ['./file-gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileGalleryComponent {
    private readonly updated: {
        [key: string]: number;
    } = {};

    @Input()
    fileIds: string[];

    @Input()
    title: string;

    @Input()
    dialogTitle: string;

    @Input()
    accept = '.png, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .bmp, .dib';

    @Input()
    fileValidationType = FileValidationTypes.Image;

    @Input()
    resetable: isResetableFn;

    @Input()
    showCamera = true;

    @Input()
    showFolder = true;

    @Input()
    buttonLabel;

    @Input()
    disabled = false;

    @Input()
    multiple = true;

    @Output()
    add = new EventEmitter<FileData<ArrayBuffer>[]>();

    @Output()
    delete = new EventEmitter<string>();

    @Output()
    reset = new EventEmitter<string>();

    @Output()
    update = new EventEmitter<FileGalleryUpdateEvent>();

    @Output()
    buttonClick = new EventEmitter<string>();

    constructor(
        private readonly uploadService: UploadDialogService,
        private readonly captureService: CaptureDialogService,
        private readonly fileValidationService: FileValidationService,
    ) {}

    onAddClick(type: FileGalleryAddType): void {
        Assert.notNullOrUndefined(type, 'type');
        const files$ =
            type === FileGalleryAddType.Capture
                ? this.captureService.captureImage(this.dialogTitle)
                : this.uploadService.uploadFiles(this.dialogTitle, this.accept, this.multiple);
        files$
            .pipe(
                filter((files) => !!files && files.length > 0),
                map(
                    (files) =>
                        this.fileValidationService.validateFileTypeAndExtension(files, this.fileValidationType)
                            .validFiles,
                ),
                take(1),
            )
            .subscribe((files) => {
                this.add.emit(files);
            });
    }

    onFileSaved(id: string, data: FileData<ArrayBuffer>): void {
        Assert.notNullOrUndefined(id, 'id');
        Assert.notNullOrUndefined(data, 'data');
        this.updated[id] = Date.now();
        this.update.emit({ data, id });
    }

    trackByKey(_index: number, key: string): string {
        if (!this.updated[key]) {
            this.updated[key] = Date.now();
        }
        return `${key}_${this.updated[key]}`;
    }
}
