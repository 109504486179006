import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveNfzRaeder, SaveNfzRaederData } from '../../graphql/mutations';
import { NfzRaeder, NfzRaederInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktNfzRaederService extends ProduktFeatureService<NfzRaeder, NfzRaederInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: NfzRaeder): NfzRaederInput {
        const input: NfzRaederInput = {
            id: produktId,
            radSaetze: (feature.radSaetze || []).map((radSatz, radSatzIndex) => ({
                ...radSatz,
                id: `${produktId}:${radSatzIndex}`,
                achsen: (radSatz.achsen || []).map((rad, radIndex) => ({
                    ...rad,
                    id: `${produktId}:${radSatzIndex}:${radIndex}`,
                    seiten: (rad.seiten || []).map((seite, seiteIndex) => ({
                        ...seite,
                        id: `${produktId}:${radSatzIndex}:${radIndex}:${seiteIndex}`,
                        achsenRaeder: (seite.achsenRaeder || []).map((achsenRad, achsenRadIndex) => ({
                            ...achsenRad,
                            id: `${produktId}:${radSatzIndex}:${radIndex}:${seiteIndex}:${achsenRadIndex}`,
                        })),
                    })),
                })),
            })),
            notlaufeinrichtung: feature.notlaufeinrichtung,
            notlaufeinrichtungProfiltiefe: feature.notlaufeinrichtungProfiltiefe,
            bemerkungen: feature.bemerkungen,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveNfzRaeder;
    }

    protected getSaveVariables(input: NfzRaederInput): any {
        return {
            nfzRaeder: input,
        };
    }

    protected getSaveOptimisticResponse(input: NfzRaederInput): SaveNfzRaederData {
        const data: SaveNfzRaederData = {
            saveNfzRaeder: {
                ...input,
                __typename: 'NfzRaeder',
                bemerkungen: (input.bemerkungen || []).map((bemerkung) => ({
                    ...bemerkung,
                    __typename: 'Textbaustein',
                })),
                radSaetze: (input.radSaetze || []).map((radSatz) => ({
                    ...radSatz,
                    __typename: 'NfzRadsatz',
                    achsen: (radSatz.achsen || []).map((rad) => ({
                        ...rad,
                        __typename: 'Achse',
                        seiten: (rad.seiten || []).map((seite) => ({
                            ...seite,
                            __typename: 'AchsenSeite',
                            achsenRaeder: (rad.seiten || []).map((achsenRad) => ({
                                ...achsenRad,
                                __typename: 'AchsenRad',
                            })),
                        })),
                    })),
                })),
            },
        };
        return data;
    }

    protected getSaveResponse(response: GraphQLResponse<SaveNfzRaederData>): NfzRaeder {
        return response.data.saveNfzRaeder;
    }

    protected update(produkt: Produkt, feature: NfzRaeder): void {
        produkt.nfzRaeder = feature;
    }
}
