import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSetting, ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';

export interface FileDialogSettings {
    title: string;
    fileId: string;
    buttons?: ButtonSetting[];
}

@Component({
    selector: 'app-file-dialog',
    templateUrl: './file-dialog.component.html',
    styleUrls: ['./file-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDialogComponent {
    Buttontype = ButtonType;

    constructor(
        readonly dialogRef: MatDialogRef<FileDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        readonly settings: FileDialogSettings,
    ) {
        Assert.notNullOrUndefined(settings, 'settings');
    }

    onAction(button: string): void {
        this.dialogRef.close({
            name: button,
            data: this.settings,
        });
    }
}
