<ng-container [formGroup]="form">
    <div
        class="gtue-information input-component-wrapper"
        [ngClass]="
            (control.touchedChanges | async) &&
            !(control.validChanges | async) &&
            (control.hasError('required') || control.hasError('pattern'))
                ? 'error'
                : ''
        "
    >
        <h4 class="mat-caption text-field-label">{{ displayName | translate }}</h4>
        <mat-form-field
            [class.read_only]="readonly"
            [class.mat-form-field-disabled]="disabled"
            appearance="fill"
            floatLabel="never"
        >
            <app-vin
                *ngIf="type === 'vin'"
                [placeholder]="
                    (displayName | translate) +
                    (!(control.validChanges | async) && control.hasError('required') ? '*' : '')
                "
                [id]="name"
                [formControlName]="name"
            />
            <input
                *ngIf="type !== 'vin'"
                [type]="type"
                [readonly]="disabled || readonly"
                [class.input-disabled]="disabled"
                [placeholder]="
                    placeholder
                        ? placeholder
                        : (displayName | translate) +
                          (!(control.validChanges | async) && control.hasError('required') ? '*' : '')
                "
                [formControlName]="name"
                [name]="name"
                [id]="id ? id : name"
                [attr.inputmode]="inputmode"
                [autocomplete]="inputmode === 'text' ? '' : 'off'"
                matInput
            />

            <span [class.readonly]="readonly" matSuffix style="display: flex">
                <app-mini-fab
                    [color]="'basic'"
                    [class.icon-disabled]="isSearchDisabled()"
                    [disabled]="isSearchDisabled()"
                    (action)="onSearch()"
                >
                    <app-mat-icon icon="search" />
                </app-mini-fab>
                <app-mini-fab
                    [color]="'basic'"
                    [class.icon-disabled]="isResetDisabled()"
                    [disabled]="isResetDisabled()"
                    (action)="onReset()"
                >
                    <app-mat-icon icon="delete" />
                </app-mini-fab>
            </span>
        </mat-form-field>
        <mat-error>
            {{
                control.hasError('pattern')
                    ? ('control.pattern' | translate: { name: (displayName | translate) })
                    : ('control.required' | translate: { name: (displayName | translate) })
            }}
        </mat-error>
    </div>
</ng-container>
