<h3>{{ name + '.beurteilung' | translate }}</h3>
<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'verschleissbedingt'">
            <p>{{ name + '.verschleissbedingt.text' | translate }}</p>
            <ng-container [formGroup]="form">
                <mat-radio-group [formControlName]="field.name">
                    <mat-radio-button [value]="true" (click)="removeValidator()">
                        {{ name + '.verschleissbedingt.ja' | translate }}
                    </mat-radio-button>
                    <mat-radio-button [value]="false" (click)="setValidator()">
                        {{ name + '.verschleissbedingt.nein' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'diagnose'">
            <app-textarea
                class="diagnose"
                #diagnose
                [form]="form"
                [name]="field.name"
                [displayName]="name + '.' + field.name"
            />
            <p class="small-font">
                <u>{{ name + '.hinweis' | translate }}</u>
                <br />
                {{ name + '.hinweis.text1' | translate }}
                <br />
                {{ name + '.hinweis.text2' | translate }}
            </p>
        </ng-container>
        <ng-container *ngSwitchCase="'internerKommentar'">
            <app-divider />
            <h3>{{ name + '.internerKommentar.header' | translate }}</h3>
            <app-textarea
                class="interner-kommentar"
                [form]="form"
                [name]="field.name"
                [displayName]="name + '.' + field.name"
            />
            <p class="small-font">
                <u>{{ name + '.hinweis' | translate }}</u>
                <br />
                {{ name + '.hinweis.text3' | translate }}
            </p>
        </ng-container>
    </ng-container>
</ng-container>
