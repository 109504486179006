import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CgAbschluss } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailCgAbschlussFormViewFactory extends FormViewModelBaseFactory<CgAbschluss> {
    constructor(private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory) {
        super();
        Assert.notNullOrUndefined(produktDetailTextbausteinFormViewFactory, 'produktDetailTextbausteinFormViewFactory');
    }

    protected createField(model: CgAbschluss, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.CgAbschluss.fields;
        if (name === fields.Adresse.name) {
            return new ViewFormControl('', {
                validators: Validators.required,
            });
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: CgAbschluss, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.CgAbschluss.fields;

        switch (arrayName) {
            case fields.Fazit.name: {
                const fazit = model.fazit || [];
                return new ViewFormArray(
                    fazit.map((value) => this.produktDetailTextbausteinFormViewFactory.create(value, arrayFields)),
                );
            }
            case fields.AbschlussBemerkung.name: {
                const abschlussBemerkung = model.abschlussBemerkung || [];
                return new ViewFormArray(
                    abschlussBemerkung.map((value) =>
                        this.produktDetailTextbausteinFormViewFactory.create(value, arrayFields),
                    ),
                );
            }
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
