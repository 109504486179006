import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { deleteLackmessungNotiz, saveLackmessungNotiz } from '@data/domain/graphql/mutations';
import { Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '../../produkt.service';
import { ProduktFeatureNotizenService } from './produkt-feature-notizen.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktLackmessungNotizenService extends ProduktFeatureNotizenService {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    getNotizen(produkt: Produkt): Notiz[] {
        if (!produkt.lackmessung.notizen) {
            produkt.lackmessung.notizen = [];
        }
        return produkt.lackmessung.notizen;
    }

    protected getSaveNotizMutation(): any {
        return saveLackmessungNotiz;
    }

    protected getSaveNotizMutationName(): string {
        return 'saveLackmessungNotiz';
    }

    protected getDeleteNotizMutation(): any {
        return deleteLackmessungNotiz;
    }

    protected getDeleteNotizMutationName(): any {
        return 'deleteLackmessungNotiz';
    }
}
