import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EinstellungenViewModelResolveService } from '../service/einstellungen-view-model-resolve.service';
import { EinstellungenViewModel } from '../view/einstellungen-view.model';

@Injectable({
    providedIn: 'root',
})
export class EinstellungenViewModelResolve {
    constructor(
        private readonly modelResolve: EinstellungenViewModelResolveService,
        private readonly router: Router,
        private readonly location: Location,
        private readonly snackbar: SnackBarService,
    ) {
        Assert.notNullOrUndefined(modelResolve, 'viewModelResolve');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(location, 'location');
        Assert.notNullOrUndefined(snackbar, 'snackbar');
    }

    resolve(): Observable<EinstellungenViewModel> {
        return this.modelResolve.resolve().pipe(
            catchError((error) => {
                this.snackbar.warning('einstellungen.model.resolveError');
                this.router.navigateByUrl(this.location.path());
                console.error(error);
                return EMPTY;
            }),
        );
    }
}
