import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { Assert } from '@shared/helper/assert';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveProduktart } from '../../graphql/mutations';

@Injectable({
    providedIn: 'root',
})
export class ProduktartService {
    constructor(private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    }

    save(produktId: string, art: number): Observable<number> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrUndefined(art, 'art');

        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate({
            mutation: saveProduktart,
            variables: {
                id: produktId,
                art,
            },
            optimisticResponse: {
                saveProduktart: art,
            },
        });
        return from(mutatePromise).pipe(map((response) => response.data.saveProduktart));
    }
}
