<ng-container [formGroup]="form">
    <h4>{{ displayName | translate }}</h4>
    <ng-container *ngIf="(control.valueChanges | async) || control.value as values">
        <mat-list>
            <mat-list-item *ngFor="let value of values; let index = index">
                <span>{{ property ? value[property] : value }}</span>
                <app-icon-button class="button delete-button" (action)="onRemoveClick(index)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-icon-button>
                <app-icon-button
                    class="button add-button"
                    *ngIf="hasImages"
                    [count]="value.bilder?.length ? value.bilder?.length : ''"
                    (action)="onAddImagesClick(index)"
                >
                    <app-mat-icon icon="add_photo_alternate" />
                </app-icon-button>
            </mat-list-item>
        </mat-list>
    </ng-container>
    <mat-form-field>
        <input [attr.inputmode]="inputmode" (keyup)="onFilterKeyUp()" matInput />
        <ng-container matSuffix>
            <app-mini-fab
                class="add"
                [color]="'basic'"
                [disabled]="input.nativeElement.value.length === 0"
                (click)="onAddClick()"
                type="button"
                mat-mini-fab
            >
                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
            </app-mini-fab>
        </ng-container>
    </mat-form-field>
    <mat-action-list>
        <button *ngFor="let value of filteredValues$ | async" (click)="onItemClick(value)" mat-list-item>
            {{ value }}
        </button>
    </mat-action-list>
</ng-container>
