import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse } from '@data/domain/graphql/mutations';
import { Notiz, NotizInput, Produkt } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProduktService } from '../../produkt.service';

export abstract class ProduktFeatureNotizenService {
    constructor(
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
        private readonly produktService: ProduktService,
    ) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    saveNotiz(produktId: string, notizId: string, notiz: Notiz): Observable<boolean> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(notizId, 'notizId');
        Assert.notNullOrUndefined(notiz, 'notiz');

        const input: NotizInput = {
            id: notiz.id,
            createdAt: notiz.createdAt,
            datei: (notiz.datei || '').length > 0 ? notiz.datei : null,
            sprachnachricht: (notiz.sprachnachricht || '').length > 0 ? notiz.sprachnachricht : null,
            textnachricht: (notiz.textnachricht || '').length > 0 ? notiz.textnachricht : null,
        };
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate({
            mutation: this.getSaveNotizMutation(),
            variables: {
                id: produktId,
                notizId,
                notiz: input,
            },
            optimisticResponse: {
                [this.getSaveNotizMutationName()]: true,
            },
            update: (store) =>
                this.produktService.updateGetByIdCache(store, produktId, (produkt) => {
                    this.updateNotiz(produkt, notiz);
                    return produkt;
                }),
        });
        return from(mutatePromise).pipe(
            map((response: GraphQLResponse<any>) => response.data[this.getSaveNotizMutationName()]),
        );
    }

    deleteNotiz(produktId: string, notizId: string): Observable<boolean> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(notizId, 'notizId');

        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate({
            mutation: this.getDeleteNotizMutation(),
            variables: {
                id: produktId,
                notizId,
            },
            optimisticResponse: {
                [this.getDeleteNotizMutationName()]: true,
            },
            update: (store) =>
                this.produktService.updateGetByIdCache(store, produktId, (produkt) => {
                    this.removeNotiz(produkt, notizId);
                    return produkt;
                }),
        });
        return from(mutatePromise).pipe(
            map((response: GraphQLResponse<any>) => response.data[this.getDeleteNotizMutationName()]),
        );
    }

    abstract getNotizen(produkt: Produkt): Notiz[];

    protected abstract getSaveNotizMutation(): any;
    protected abstract getSaveNotizMutationName(): string;

    protected abstract getDeleteNotizMutation(): any;
    protected abstract getDeleteNotizMutationName(): string;

    private updateNotiz(produkt: Produkt, notiz: Notiz): void {
        const newNotiz: Notiz = {
            __typename: 'Notiz',
            id: notiz.id,
            createdAt: notiz.createdAt,
            datei: notiz.datei || null,
            sprachnachricht: notiz.sprachnachricht || null,
            textnachricht: notiz.textnachricht || null,
        };

        const notizen = this.getNotizen(produkt);
        const notizIndex = notizen.findIndex((x) => x.id === notiz.id);
        if (notizIndex === -1) {
            notizen.push(newNotiz);
        } else {
            notizen[notizIndex] = newNotiz;
        }
    }

    private removeNotiz(produkt: Produkt, notizId: string): void {
        const notizen = this.getNotizen(produkt);
        const notizIndex = notizen.findIndex((x) => x.id === notizId);
        if (notizIndex !== -1) {
            notizen.splice(notizIndex, 1);
        }
    }
}
