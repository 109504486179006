import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, AuthUserClaims } from '@app/service/auth.service';
import { DatFirstPage, DatKalkulationService } from '@data/api-gateway/service/dat-kalkulation.service';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { KalkulationDownloadService } from '@data/api-gateway/service/kalkulation-download.service';
import { ProduktStatus, SchadenIntensitaet, SchadenObergruppe, SchadenUntergruppe } from '@data/domain/schema/enum';
import { Notiz, Produkt, Schaden, SchadenInput, SchadenPosition } from '@data/domain/schema/type';
import { ProduktSchadenService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { OBERGRUPPE_TO_UNTERGRUPPE_MAP } from '@modules/produkt/config/produkt-schaden-gruppen.config';
import { ProduktDetailKalkulationFormViewFactory } from '@modules/produkt/factory/kalkulation/produkt-detail-kalkulation-form-view.factory';
import { ProduktDetailSchadenFormViewFactory } from '@modules/produkt/factory/schaden/produkt-detail-schaden-form-view.factory';
import { ProduktDetailSchadenPositionFormViewFactory } from '@modules/produkt/factory/schaden/produkt-detail-schaden-position-form-view.factory';
import { DatCalculateProService } from '@modules/produkt/service/dat-calculate-pro-service';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ColumnCount } from '@shared/component/form-controls/extendable-radio-list/extendable-radio-list.component';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ArrayValues, EnumValues, ObjectValues, Values } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription, from, of } from 'rxjs';
import { first, mergeMap, take } from 'rxjs/operators';
import { ProduktDetailSchadenComponentBase } from '../base/produkt-detail-schaden-base.component';

export enum SchadenGruppenDisplay {
    None = -1,
    Umfang = 0,
    Exterieur = 1,
    Interieur = 2,
    Technik = 3,
    Manuell = 4,
    Text = 5,
    Daten = 6,
    Kalkulation = 7,
}

interface ProduktDetailSchadenUntergruppeDialogData {
    form: ViewFormGroup;
    values: Values;
    produkt: Produkt;
}

declare let SphinxClassCP: any;

@Component({
    selector: 'app-produkt-detail-schaden',
    templateUrl: './produkt-detail-schaden.component.html',
    styleUrls: ['./produkt-detail-schaden.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailSchadenComponent
    extends ProduktDetailSchadenComponentBase<Schaden, SchadenInput, SchadenPosition, SchadenGruppenDisplay>
    implements OnInit, OnDestroy {
    protected featureName = PRODUKT_CONFIG_FEATURES.Schaden.name;
    protected positionenName = PRODUKT_CONFIG_FEATURES.Schaden.fields.Positionen.name;
    protected positionenFieldConfig = PRODUKT_CONFIG_FEATURES.Schaden.fields.Positionen.fields;
    protected costGroupName = 'minderwertGroup';

    protected display$ = new BehaviorSubject(SchadenGruppenDisplay.None);

    claims$: Observable<AuthUserClaims>;

    schadenDisplay = SchadenGruppenDisplay;

    notizen$: Observable<Notiz[]>;
    viewport$: Observable<Viewport>;
    viewport = Viewport;
    statusEnum = ProduktStatus;

    kalkulation: UntypedFormGroup;
    kalkulationFields;
    sphinx;
    private callbackUrlKalkulationSuccess = '';
    private callbackUrlKalkulationError = '';

    loading$ = new BehaviorSubject<boolean>(false);
    loadingGetKalkulationData$ = new BehaviorSubject<boolean>(false);
    hasFahrzeugExternalServiceReference = false;

    obergruppe = new ObjectValues(SchadenObergruppe);
    intensitaet = new EnumValues(SchadenIntensitaet);

    rows$: Observable<AbstractViewFormControl[]>;
    sums$: Observable<string>;

    modelFileConfigs: ModelFileConfig[];
    modelDisabled$ = new BehaviorSubject<boolean>(false);

    private subscriptions: Subscription[] = [];

    @ViewChild('untergruppe', { static: true })
    untergruppeTemplate: TemplateRef<any>;

    @ViewChild('dialogKalkulationConfirm', { static: true })
    dialogKalkulationConfirmTemplate: TemplateRef<any>;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktSchadenService: ProduktSchadenService,
        formViewFactory: ProduktDetailSchadenFormViewFactory,
        currencyFormatter: CurrencyFormatterService,
        modelFileService: ModelFileService,
        viewportService: ViewportService,
        notizenService: ProduktDetailFeatureNotizenService,
        updateWerteService: UpdateWerteService,
        clipboard: Clipboard,
        public snackBarService: SnackBarService,
        public positionFormViewFactory: ProduktDetailSchadenPositionFormViewFactory,
        public templateDialogService: TemplateDialogService,
        readonly datKalkulationService: DatKalkulationService,
        private readonly einstellungenService: EinstellungenService,
        private readonly auth: AuthService,
        private readonly datCalculateProService: DatCalculateProService,
        private readonly kalkulationDownloadService: KalkulationDownloadService,
        private readonly produktDetailKalkulationFormViewFactory: ProduktDetailKalkulationFormViewFactory,
    ) {
        super(
            produktConfigResolveService,
            produktDetailResolveService,
            produktSchadenService,
            positionFormViewFactory,
            templateDialogService,
            snackBarService,
            formViewFactory,
            currencyFormatter,
            modelFileService,
            viewportService,
            notizenService,
            updateWerteService,
            clipboard,
        );
        Assert.notNullOrUndefined(clipboard, 'clipboard');
        Assert.notNullOrUndefined(auth, 'auth');
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(datCalculateProService, 'datCalculateProService');
        Assert.notNullOrUndefined(datKalkulationService, 'datKalkulationService');
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
        Assert.notNullOrUndefined(modelFileService, 'modelFileService');
        Assert.notNullOrUndefined(produktDetailKalkulationFormViewFactory, 'produktDetailKalkulationFormViewFactory');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(notizenService, 'notizenService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        Assert.notNullOrUndefined(notizenService, 'notizenService');
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
        Assert.notNullOrUndefined(updateWerteService, 'updateWerteService');
    }

    ngOnInit(): void {
        this.claims$ = this.auth.getClaims();
        this.intensitaet = new EnumValues(SchadenIntensitaet);
        const nameKalkulation = PRODUKT_CONFIG_FEATURES.Kalkulation.name;
        this.hasFahrzeugExternalServiceReference =
            !!this.produkt.fahrzeug?.fahrzeugExternalServiceReference?.identifier;
        this.setModelAndDisplay();
        this.initKalkulation(nameKalkulation);
        this.subscriptions.push(
            this.datKalkulationService.getKalkulationStatusSubscription(
                this.produkt,
                this.kalkulation,
                this.loadingGetKalkulationData$,
            ),
        );
        this.sphinx = SphinxClassCP.getInstance();
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.datKalkulationService.removeKalkulationStorageListener(this.produkt.id);
        super.ngOnDestroy();
    }

    showKalkulation(): Observable<boolean> {
        return this.viewport$.pipe(
            take(1),
            mergeMap((viewport) => {
                if (viewport !== Viewport.Mobile) {
                    return this.datKalkulationService.getIsKalkulationActive();
                }
                return of(false);
            }),
        );
    }

    onOpenKalkulation(): void {
        if (this.produkt.kalkulation?.dossierId) {
            this.display$.next(SchadenGruppenDisplay.Daten);
            this.datKalkulationService.addKalkulationStorageListener(this.produkt.id);
            const datPageConfig = {
                firstPage: DatFirstPage.MODEL,
                currentPage: DatFirstPage.EQUIPMENT_PAGE,
            };
            this.datKalkulationService.initIframe(
                document,
                this.sphinx,
                datPageConfig,
                this.loading$,
                this.produkt,
                this.kalkulation,
                true,
            );
        } else {
            this.openConfirmKalkulationDialog();
        }
    }

    onModelLoad(result: ModelLoadResult): void {
        Assert.notNullOrUndefined(result, 'modelLoadStatus');
        if (result === ModelLoadResult.None) {
            this.modelDisabled$.next(true);
            this.onDisplayChange(SchadenGruppenDisplay.Manuell);
            this.snackBarService.warning('modell.couldNotLoad');
        } else if (result === ModelLoadResult.Fallback) {
            this.snackBarService.info('modell.fallback');
        }
    }

    downloadKalkulation(): void {
        this.auth
            .getClaims()
            .pipe(first())
            .subscribe((claims) => {
                if (claims && claims['custom:buero_id']) {
                    this.kalkulationDownloadService
                        .download(claims['custom:buero_id'], this.produkt.id)
                        .pipe(first())
                        .subscribe((url) => {
                            if (url) {
                                window.open(url);
                            } else {
                                this.snackBarService.error('schaden.kalkulation.download.error');
                            }
                        });
                }
            });
    }

    displayModel(): boolean {
        if (
            this.display$.value === SchadenGruppenDisplay.Exterieur ||
            this.display$.value === SchadenGruppenDisplay.Interieur ||
            this.display$.value === SchadenGruppenDisplay.Technik
        ) {
            return true;
        }
        return false;
    }

    protected selectUntergruppe(obergruppe: SchadenObergruppe): Observable<SchadenPosition> {
        const fields = this.positionenFields;
        const form = this.positionFormViewFactory.create({ obergruppe }, fields);
        const button = [this.templateDialogService.getCancelButtonSetting()];

        let untergruppen = OBERGRUPPE_TO_UNTERGRUPPE_MAP.get(obergruppe);
        if (!untergruppen) {
            untergruppen = [obergruppe as any];
        }
        const values = new ArrayValues(untergruppen as string[], SchadenUntergruppe);
        const produkt = this.produkt;

        const data: ProduktDetailSchadenUntergruppeDialogData = { form, values, produkt };
        const dialog = this.templateDialogService.open(obergruppe, button, this.untergruppeTemplate, data, true);

        const promise = new Promise<SchadenPosition>((resolve, reject) => {
            let valueChangeSubscription = form.valueChanges.subscribe({
                next: (value) => {
                    valueChangeSubscription.unsubscribe();
                    valueChangeSubscription = null;
                    dialog.close();
                    resolve(value);
                },
                error: (error) => reject(new Error(error)),
            });
            dialog
                .afterClosed()
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        if (valueChangeSubscription) {
                            valueChangeSubscription.unsubscribe();
                        } else {
                            resolve(null);
                        }
                    },
                    error: (error) => reject(new Error(error)),
                });
        });
        return from(promise);
    }

    private initKalkulation(name: string) {
        this.kalkulationFields = this.getFeatureByName(name).fields;
        this.kalkulation = this.produktDetailKalkulationFormViewFactory.create(
            this.produkt.kalkulation,
            this.kalkulationFields,
        );
    }

    private openConfirmKalkulationDialog(): void {
        const title = `${this.name}.kalkulation.dialog.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getConfirmButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogKalkulationConfirmTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
                    this.display$.next(SchadenGruppenDisplay.Daten);
                    this.datKalkulationService.addKalkulationStorageListener(this.produkt.id);
                    const datPageConfig = {
                        firstPage: DatFirstPage.MODEL,
                        currentPage: DatFirstPage.EQUIPMENT_PAGE,
                    };
                    this.datKalkulationService.initIframe(
                        document,
                        this.sphinx,
                        datPageConfig,
                        this.loading$,
                        this.produkt,
                        this.kalkulation,
                        true,
                    );
                }
            });
    }

    protected setModelAndDisplay() {
        this.einstellungenService
            .getBenutzer()
            .pipe(take(1))
            .subscribe((benutzerEinstellungen) => {
                if (benutzerEinstellungen?.deactivate3dModel) {
                    this.modelDisabled$.next(benutzerEinstellungen.deactivate3dModel);
                    this.display$.next(SchadenGruppenDisplay.Manuell);
                } else {
                    this.modelDisabled$.next(false);
                    this.display$.next(SchadenGruppenDisplay.Exterieur);
                }
            });
    }

    protected readonly ColumnCount = ColumnCount;
}
