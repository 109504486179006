import { Injectable } from '@angular/core';
import { Unterlagen } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailUnterlagenPositionFormViewFactory } from './produkt-detail-unterlagen-position-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailUnterlagenFormViewFactory extends FormViewModelBaseFactory<Unterlagen> {
    constructor(private readonly positionFormViewFactory: ProduktDetailUnterlagenPositionFormViewFactory) {
        super();
    }

    protected createField(model: Unterlagen, name: string): AbstractViewFormControl {
        switch (name) {
            case PRODUKT_CONFIG_FEATURES.Unterlagen.fields.SchluesselFahrzeug.name:
                return new ViewFormControl(model.schluesselFahrzeug);
            case PRODUKT_CONFIG_FEATURES.Unterlagen.fields.SchluesselAHK.name:
                return new ViewFormControl(model.schluesselAHK);
            case PRODUKT_CONFIG_FEATURES.Unterlagen.fields.FernbedienungStandheizung.name:
                return new ViewFormControl(model.fernbedienungStandheizung);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Unterlagen, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Unterlagen.fields;
        if (arrayName === fields.Positionen.name) {
            const positionen = model.positionen || [];
            return new ViewFormArray(
                positionen.map((position) => this.positionFormViewFactory.create(position, arrayFields)),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
