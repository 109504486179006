<app-card *ngIf="validations.length > 0; else valid">
    <div class="form-error" *ngFor="let validation of validations">
        <h3>{{ validation.name | translate }}</h3>
        <ul>
            <li *ngFor="let field of validation.incorrectFields">
                {{ name + '.field' | translate: { name: validation.name + '.' + field | translate } }}
            </li>
        </ul>
    </div>
</app-card>
<ng-template #valid>
    <app-card>
        <app-produkt-detail-vti-abschluss-workflow
            [name]="name"
            [produkt]="produkt"
            (statusChangedEvent)="onStatusChanged($event)"
        />
    </app-card>
</ng-template>
