import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AbschlussDownloadService } from '@data/api-gateway';
import { AcProdukt } from '@data/api-gateway/service/alphacontroller/ac-vorgang.service';
import { BilderDownloadService } from '@data/api-gateway/service/bilder-download.service';
import { AcProduktUbersichtFilter, ProduktArt, ProduktArtNachbewertung, ProduktStatus } from '@data/domain/schema/enum';
import { Adresse, Produkt } from '@data/domain/schema/type';
import { ProduktDetailNachbewertungDialogComponent } from '@modules/produkt/component/produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { AcProduktUebersichtResolveService } from '@modules/produkt/service/ac-produkt-uebersicht-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableComponent } from '@shared/component/data-table';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues, ObjectValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject } from 'rxjs';
import { first, take } from 'rxjs/operators';

@Component({
    selector: 'app-produkt-uebersicht-ac',
    templateUrl: './produkt-uebersicht-ac.component.html',
    styleUrls: ['./produkt-uebersicht-ac.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktUebersichtAcComponent implements OnInit {
    trackById = TrackBy.trackByIndex;

    produkte: Produkt[];
    name = 'produktUebersicht';
    produktStatus = new EnumValues(ProduktStatus);
    loading$ = new BehaviorSubject(true);
    searchTerm = '';
    form: ViewFormGroup;
    produktArt = ProduktArt;
    produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);

    vorgaenge: AcProdukt[] = [];
    vorgaengeFiltered = new BehaviorSubject<AcProdukt[]>([]);
    slideToggleFilterOffenName = 'slide-filter-offen';
    filterName = 'filter';
    filterStatusName = 'status';

    status = ProduktStatus;
    dataSource: any;
    pageSizeOptions: number[] = [20, 40, 80];
    pageSize = 20;
    currentPage = 0;
    totalSize = 0;
    lowValue = 0;
    highValue = 20;

    ButtonType = ButtonType;
    selections: string[] = [];

    @ViewChild('table')
    table: TableComponent;

    @ViewChild('nachbewertungArtSelection')
    selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

    @ViewChild('dialogConfirmDuplicate', { static: true })
    dialogConfirmDuplicateTemplate: TemplateRef<any>;

    @ViewChild('paginator', { static: false })
    paginator: MatPaginator;

    constructor(
        private readonly acProduktUebersichtResolveService: AcProduktUebersichtResolveService,
        private readonly router: Router,
        private readonly bilderDownloadService: BilderDownloadService,
        private readonly datePipe: DatePipe,
        private readonly abschlussDownloadService: AbschlussDownloadService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(acProduktUebersichtResolveService, 'acProduktUebersichtResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(bilderDownloadService, 'bilderDownloadService');
        Assert.notNullOrUndefined(bilderDownloadService, 'bilderDownloadService');
        Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }

    ngOnInit(): void {
        this.selections = this.getSelections();
        this.vorgaenge = this.acProduktUebersichtResolveService.get();
        this.filterVorgaenge();

        this.form = new ViewFormGroup({
            searchTerm: new ViewFormControl(this.searchTerm, {
                formatter: ViewFormControlFormatters.toUppercase,
            }),
            filter: new ViewFormControl(''),
            status: new ViewFormControl(AcProduktUbersichtFilter.Alle),
        });
        this.form.addControl(this.slideToggleFilterOffenName, new ViewFormControl(false));
    }

    onFilterStatus(matButtonToggleChange: MatButtonToggleChange): void {
        this.vorgaengeFiltered.next(
            this.filter(this.searchVorgaenge(this.form.get('filter')?.value), matButtonToggleChange.value),
        );
        this.totalSize = this.vorgaengeFiltered?.value?.length;
        this.updateCardList();
    }

    onFilter(filterTerm: string): void {
        this.vorgaengeFiltered.next(this.filter(this.searchVorgaenge(filterTerm), this.form.get('status')?.value));
        this.totalSize = this.vorgaengeFiltered?.value?.length;
        this.updateCardList();
    }

    onOpenProdukt(produkt: Produkt): void {
        Assert.notNullOrUndefined(produkt, 'produkt');
        this.router.navigate(['produkt', 'detail', produkt?.id]);
    }

    onBilderDownload(event: MouseEvent, produktId: string, disabled: boolean): void {
        Assert.notNullOrUndefined(produktId, 'produktId');
        event.stopPropagation();

        if (disabled) {
            return;
        }

        this.loading$.next(true);
        this.bilderDownloadService
            .get(produktId)
            .pipe(take(1))
            .subscribe({
                next: (url) => {
                    if (url) {
                        window.open(url);
                        this.loading$.next(false);
                    }
                },
                error: (error) => {
                    this.loading$.next(false);
                    error.status === 404
                        ? this.snackBarService.info('produkt.uebersicht.downloadKeineBilder')
                        : this.snackBarService.error('produkt.uebersicht.downloadBilderError');
                },
            });
    }

    onClickProduktSearch(searchText: string): void {
        this.searchAcProdukt(searchText);
        this.updateCardList();
    }

    onResetIdentAndSearch(): void {
        this.form.patchValue({ searchTerm: '' });
        this.acProduktUebersichtResolveService
            .resolve()
            .pipe(first())
            .subscribe({
                next: (data) => {
                    this.vorgaenge = data;
                    this.filterVorgaenge();
                },
                error: (err) => {
                    console.error('error: ', err);
                },
            });
        this.updateCardList();
    }

    selectIndex(stepper: StepperComponent, status: number): number {
        const index = status - 1;
        if (stepper) {
            stepper.setIndex(index);
        }
        return index;
    }

    onDownloadClick(produkt: Produkt): void {
        this.abschlussDownloadService
            .get(produkt?.id)
            .pipe(take(1))
            .subscribe((response) => {
                if (response?.url) {
                    this.onDownloadResponse(response.url);
                }
            });
    }

    alphaNumericOnly(e: KeyboardEvent): boolean {
        return String(e.key).match(/[^a-zA-Z0-9]/g) === null;
    }

    handlePage(event: any) {
        this.currentPage = event?.pageIndex;
        this.pageSize = event?.pageSize;
        this.lowValue = this.currentPage * this.pageSize;
        this.highValue = this.lowValue + this.pageSize;
    }

    private updateCardList(): void {
        this.lowValue = this.currentPage * this.pageSize;
        this.highValue = this.lowValue + this.pageSize;
        if (this.paginator) {
            this.paginator.firstPage();
        }
    }

    private filterVorgaenge(): void {
        this.loading$.next(true);
        this.vorgaengeFiltered.next(this.filter(this.vorgaenge, AcProduktUbersichtFilter.Alle));
        this.totalSize = this.vorgaengeFiltered?.value?.length;
        this.dataSource = this.vorgaengeFiltered?.value;
        this.loading$.next(false);
    }

    private filter(vorgaenge: AcProdukt[], filter: string): AcProdukt[] {
        let result = vorgaenge;
        result = result.sort(function (a, b) {
            if (!a?.acMetaInformation?.xmlErstelldatum) {
                return 1;
            }
            if (!b?.acMetaInformation?.xmlErstelldatum) {
                return -1;
            }
            // @ts-ignore
            return new Date(b?.acMetaInformation?.xmlErstelldatum) - new Date(a?.acMetaInformation?.xmlErstelldatum);
        });

        switch (filter) {
            case AcProduktUbersichtFilter.Offen:
                result = result.filter((vorgang) => vorgang?.status === this.status.Offen);
                break;
            case AcProduktUbersichtFilter.Bearbeitet:
                result = result.filter(
                    (vorgang) => vorgang?.status === this.status.Beendet && !vorgang.acAbschluss?.acVersendet,
                );
                break;
            case AcProduktUbersichtFilter.Fertig:
                result = result.filter(
                    (vorgang) => vorgang?.status === this.status.Beendet && vorgang.acAbschluss?.acVersendet,
                );
                break;
            default:
                console.warn('filter is null or undefined');
        }
        return result;
    }

    private searchVorgaenge(searchTerm: string = ''): AcProdukt[] {
        let foundVorgaenge = this.vorgaenge;
        foundVorgaenge = foundVorgaenge?.filter((vorgang) => {
            if (
                this.includes(vorgang?.fahrzeug?.identnummer, searchTerm) ||
                this.includes(vorgang?.fahrzeug?.kennzeichen, searchTerm) ||
                this.includes(vorgang?.fahrzeug?.modell, searchTerm) ||
                this.includes(vorgang?.fahrzeug?.hersteller, searchTerm) ||
                this.includesInAdressen(vorgang?.adressen?.adressen, searchTerm) ||
                this.includes(this.datePipe.transform(vorgang?.acMetaInformation?.xmlErstelldatum), searchTerm)
            ) {
                return true;
            }
            return false;
        });
        return foundVorgaenge;
    }

    private searchAcProdukt(searchTerm: string = ''): void {
        this.acProduktUebersichtResolveService
            .resolve(searchTerm)
            .pipe(first())
            .subscribe({
                next: (result) => {
                    if (result.length < 1) {
                        this.snackBarService.info('produkt.uebersicht.IdentSucheNothingFound');
                        return;
                    }
                    this.vorgaenge = result;
                    this.vorgaengeFiltered.next(this.filter(result, this.form.get(this.filterStatusName).value));
                    this.totalSize = this.vorgaengeFiltered?.value?.length;
                    this.dataSource = this.vorgaengeFiltered?.value;
                    // this.table.refresh(this.produkte);
                },
                error: (error) => {
                    this.snackBarService.info('produkt.uebersicht.Suche', error);
                },
            });
    }

    private includes(value: string, searchTerm: string): boolean {
        if (!value) {
            return false;
        }
        return value.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
    }

    private includesInAdressen(adressen: Adresse[], searchTerm: string): boolean {
        let found = false;
        if (!adressen) {
            return false;
        }
        adressen.forEach((adresse) => {
            if (!adresse?.arten?.includes('Auftraggeber')) {
                return false;
            }
            if (
                this.includes(adresse?.firma, searchTerm) ||
                this.includes(adresse?.name, searchTerm) ||
                this.includes(adresse?.vorname, searchTerm)
            ) {
                found = true;
            } else {
                found = false;
            }
        });
        return found;
    }

    private onDownloadResponse(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    private getSelections(): string[] {
        const enumValues = new ObjectValues(AcProduktUbersichtFilter);
        const result = [];
        enumValues.keys.forEach((key) => {
            if (key === 'None' || key === '0') {
                return;
            }
            result.push(enumValues.values[key]);
        });
        return result;
    }
}
