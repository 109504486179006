import { ChangeDetectionStrategy, Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { File } from '@app/class/file';
import { getOrientation } from '@app/function/image';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnInit {
    @Input()
    file: File;

    @Input()
    src: SafeUrl;

    @Input()
    orientation: number;

    constructor(private readonly domSanitizer: DomSanitizer) {}

    ngOnInit(): void {
        if (this.file) {
            const objectUrl = URL.createObjectURL(new Blob([this.file.data]));
            this.src = this.domSanitizer.bypassSecurityTrustUrl(objectUrl);
            this.orientation = getOrientation(this.file.data);
        }
    }

    onImgLoad(container: HTMLElement): void {
        const objectUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.src);
        URL.revokeObjectURL(objectUrl);

        if (this.orientation > 4 && this.orientation < 9) {
            const { clientWidth, clientHeight } = container;
            if (clientWidth > clientHeight) {
                const padding = Math.round((clientWidth - clientHeight) * 0.5);
                container.style.padding = `${padding}px 0`;
            }
        }
    }
}
