import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProduktStatusService } from '@app/service/produkt-status.service';
import { ProduktStatus } from '@data/domain/schema/enum';
import { ProduktCgAbschlussService } from '@data/domain/service/feature/produkt-cg-abschluss.service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { Feature, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailCgAbschlussFormViewFactory } from '@modules/produkt/factory/cg-abschluss/produkt-detail-cg-abschluss-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureValidService } from '@modules/produkt/service/produkt-detail-feature-valid.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { BehaviorSubject, Subscription } from 'rxjs';

interface FeatureValidation {
    name: string;
    incorrectFields: string[];
}

@Component({
    selector: 'app-produkt-detail-cg-abschluss',
    templateUrl: './produkt-detail-cg-abschluss.component.html',
    styleUrls: ['./produkt-detail-cg-abschluss.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailCgAbschlussComponent
    extends ProduktDetailFeatureComponent
    implements OnInit, OnDestroy, AfterViewInit {
    trackByField = TrackBy.trackByField;
    feature = PRODUKT_CONFIG_FEATURES.CgAbschluss.name;
    fieldFazit = PRODUKT_CONFIG_FEATURES.CgAbschluss.fields.Fazit.name;
    fieldAbschlussBemerkung = PRODUKT_CONFIG_FEATURES.CgAbschluss.fields.AbschlussBemerkung.name;

    form: ViewFormGroup;

    adressen: Feature;

    validations: FeatureValidation[] = [];

    statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

    @ViewChild('fazit')
    fazitElement: TextbausteineComponent;

    @ViewChild('abschlussBemerkung')
    abschlussBemerkungElement: TextbausteineComponent;

    private subscriptions: Subscription[] = [];

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        private readonly featureValidService: ProduktDetailFeatureValidService,
        private readonly produktDetailCgAbschlussFormViewFactory: ProduktDetailCgAbschlussFormViewFactory,
        private readonly produktStatusService: ProduktStatusService,
        private readonly produktCgAbschlussService: ProduktCgAbschlussService,
        private readonly textbausteineService: TextbausteineService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService);
        Assert.notNullOrUndefined(featureValidService, 'featureValidService');
        Assert.notNullOrUndefined(produktDetailCgAbschlussFormViewFactory, 'produktDetailCgAbschlussFormViewFactory');
        Assert.notNullOrUndefined(produktStatusService, 'produktStatusService');
        Assert.notNullOrUndefined(produktCgAbschlussService, 'produktCgAbschlussService');
        Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
    }

    ngOnInit(): void {
        this.init(PRODUKT_CONFIG_FEATURES.CgAbschluss.name);
        this.adressen = this.getFeatureByName(PRODUKT_CONFIG_FEATURES.Adressen.name);
        this.form = this.produktDetailCgAbschlussFormViewFactory.create(this.produkt.cgAbschluss, this.fields);

        if (this.form.get(this.fieldFazit)) {
            this.subscriptions.push(
                this.form.get(this.fieldFazit).valueChanges.subscribe((value) => {
                    this.produkt.cgAbschluss.fazit = value;
                    this.produktCgAbschlussService.save(this.produkt.id, this.produkt.cgAbschluss);
                }),
            );
        }
        if (this.form.get(this.fieldAbschlussBemerkung)) {
            this.subscriptions.push(
                this.form.get(this.fieldAbschlussBemerkung).valueChanges.subscribe((value) => {
                    this.produkt.cgAbschluss.abschlussBemerkung = value;
                    this.produktCgAbschlussService.save(this.produkt.id, this.produkt.cgAbschluss);
                }),
            );
        }

        this.getFeatures().forEach(({ name, fields }) => {
            const incorrectFields = this.featureValidService.getFeatureIncorrectFields(this.produkt, name, fields);
            if (incorrectFields.length > 0) {
                this.validations.push({ name, incorrectFields });
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.form.get(this.fieldFazit) && this.validations?.length <= 0) {
            try {
                if (this.produkt?.abschluss?.fazit === null) {
                    this.textbausteineService.prefillWithStandardTextbausteine(
                        this.feature,
                        this.fieldFazit,
                        this.produkt.art,
                        this.form,
                        this.fazitElement,
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (this.form.get(this.fieldAbschlussBemerkung) && this.validations.length <= 0) {
            try {
                if (this.produkt?.abschluss?.abschlussBemerkung === null) {
                    this.textbausteineService.prefillWithStandardTextbausteine(
                        this.feature,
                        this.fieldAbschlussBemerkung,
                        this.produkt.art,
                        this.form,
                        this.abschlussBemerkungElement,
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    onStatusChanged($event: ProduktStatus): void {
        this.statusChanged$.next($event);
        this.produktStatusService.update($event);
    }
}
