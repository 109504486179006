import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { S3EntwurfFileService } from '@app/service/s3-entwurf-file.service';
import { EntwurfService } from '@data/api-gateway';
import { ProduktArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import {
    Feature,
    FeatureFieldArray,
    FeatureFields,
    PRODUKT_CONFIG_FEATURES,
} from '@modules/produkt/config/produkt-config';
import { ProduktDetailAbschlussEntwurfFormViewFactory } from '@modules/produkt/factory/abschluss/produkt-detail-abschluss-entwurf-form-view.factory';
import { ProduktDetailAdressenFormViewFactory } from '@modules/produkt/factory/adressen/produkt-detail-adressen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailAbschlussHelperService } from '@modules/produkt/service/produkt-detail-abschluss-helper.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ObjectValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import * as moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { PdfProduktArtFileNameSuffix } from '../../../../data/domain/schema/enum';

@Component({
    selector: 'app-produkt-detail-abschluss-entwurf',
    templateUrl: './produkt-detail-abschluss-entwurf.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAbschlussEntwurfComponent implements OnInit {
    trackByField = TrackBy.trackByField;

    adressenAdressenForms: AbstractControl[];
    adressenFields: FeatureFields;

    entwurf: ViewFormGroup;
    entwurfAdressen: ObjectValues;
    entwurfRequestActiveChange = new BehaviorSubject(false);
    entwurfUrl$ = new BehaviorSubject<string>(undefined);

    adresseSelected$ = new BehaviorSubject(false);
    //public loading$ = new BehaviorSubject(false);

    protected readonly ButtonType = ButtonType;

    @Input()
    name: string = PRODUKT_CONFIG_FEATURES.Abschluss.name;

    @Input()
    produkt: Produkt;

    @Input()
    fields: FeatureFields;

    @Input()
    adressen: Feature;

    @ViewChild('dialogDataSynchronization', { static: true })
    dialogDataSynchronizationTemplate: TemplateRef<any>;

    constructor(
        private readonly entwurfService: EntwurfService,
        private readonly s3EntwurfFileService: S3EntwurfFileService,
        private readonly snackBarService: SnackBarService,
        private readonly entwurfFormViewFactory: ProduktDetailAbschlussEntwurfFormViewFactory,
        private readonly templateDialogService: TemplateDialogService,
        private readonly produktDetailAbschlussHelperService: ProduktDetailAbschlussHelperService,
        private readonly adressenFormViewFactory: ProduktDetailAdressenFormViewFactory,
    ) {
        Assert.notNullOrUndefined(entwurfService, 'entwurfService');
        Assert.notNullOrUndefined(s3EntwurfFileService, 's3EntwurfFileService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(entwurfFormViewFactory, 'entwurfFormViewFactory');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(produktDetailAbschlussHelperService, 'produktDetailAbschlussHelperService');
        Assert.notNullOrUndefined(adressenFormViewFactory, 'adressenFormViewFactory');
    }

    ngOnInit(): void {
        this.adressenFields = (
            this.adressen.fields.find(
                (x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.name,
            ) as FeatureFieldArray
        ).fields;
        const adressenForm = this.adressenFormViewFactory.create(this.produkt.adressen, this.adressen.fields);
        this.adressenAdressenForms = (
            adressenForm.get(PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.name) as ViewFormArray
        ).controls;

        this.entwurf = this.entwurfFormViewFactory.create(this.fields);
        const entwurfAdressen = this.adressenAdressenForms
            .filter((form) => form.valid)
            .map((form) => form.value.nameGroup);
        this.entwurfAdressen = new ObjectValues(entwurfAdressen);
    }

    onEntwurf(): void {
        if (!this.entwurf.valid) {
            this.entwurf.updateValueAndValidity();
            this.entwurf.get('adresse').markAsTouched();
            return;
        }

        const form = this.entwurf.getRawValue();
        const produktId = this.produkt?.id;
        const adresseId = this.produkt?.adressen?.adressen[form.adresse].id;
        const downloadFilename = this.getDownloadFilename();

        if (!produktId || !adresseId) {
            console.error('onEntwurf - produktId or adresseId is null');
            return;
        }

        this.entwurfRequestActiveChange.next(true);
        this.entwurfService
            .get(produktId, adresseId)
            .pipe(
                first(),
                mergeMap((response) => {
                    if (!response.error) {
                        return this.s3EntwurfFileService.getSignedUrl(response.path, downloadFilename);
                    }
                    return of(null);
                }),
            )
            .subscribe({
                next: (url) => {
                    if (url && window.open(url, '_blank') === null) {
                        this.entwurfUrl$.next(url);
                    }
                    this.entwurfRequestActiveChange.next(false);
                },
                error: (response) => {
                    if (response?.error?.error === 'checkBilderExists') {
                        this.openSynchronizationDialog();
                        return;
                    }
                    console.error(`Entwurf erstellen failed: ${response?.error?.error}`);
                    this.snackBarService.error(`${this.name}.entwurfService.error`);
                    this.entwurfRequestActiveChange.next(false);
                },
            });
    }

    onEntwurfOpen(): void {
        const url = this.entwurfUrl$.value;
        if (url && url.length > 0) {
            window.open(url, '_blank');
        }
    }

    onAdressenChange($event: MatSelect) {
        if ($event.value === undefined) {
            this.adresseSelected$.next(false);
        } else {
            this.adresseSelected$.next(true);
        }
    }

    private getDownloadFilename(): string {
        const fileDate = moment(Date.now()).format('YYYYMMDD-HHmm');
        const produktArt = this.produkt?.art;

        let produktArtSuffix = PdfProduktArtFileNameSuffix.Default;
        if (produktArt === ProduktArt.Bewertung) {
            produktArtSuffix = PdfProduktArtFileNameSuffix.Bewertung;
        } else if (produktArt === ProduktArt.Ruecknahmebewertung) {
            produktArtSuffix = PdfProduktArtFileNameSuffix.Ruecknahmebewertung;
        }

        const kennzeichen = this.produkt?.fahrzeug?.kennzeichen ? `_${this.produkt?.fahrzeug?.kennzeichen}` : '';
        return `${fileDate}_entwurf${produktArtSuffix}${kennzeichen}.pdf`;
    }

    private openSynchronizationDialog() {
        const title = `${this.name}.workflow.synchronization.dialog.title`;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSynchronizeButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogDataSynchronizationTemplate, null, true)
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSynchronizeButtonSetting().title) {
                    this.produktDetailAbschlussHelperService.onSyncClicked(this.entwurfRequestActiveChange);
                    return;
                }
                this.entwurfRequestActiveChange.next(false);
            });
    }
}
