import { Injectable } from '@angular/core';
import { Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktFeatureNotizenService } from '@data/domain/service/feature/notizen';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProduktDetailFeatureNotizenServiceProvider } from '../provider/produkt-detail-feature-notizen-service.provider';

@Injectable()
export class ProduktDetailFeatureNotizenService {
    private produktId: string;
    private service: ProduktFeatureNotizenService;
    private notizen$: BehaviorSubject<Notiz[]>;

    constructor(private readonly serviceProvider: ProduktDetailFeatureNotizenServiceProvider) {}

    init(produkt: Produkt, featureName: string): Observable<Notiz[]> {
        this.produktId = produkt.id;
        this.service = this.serviceProvider.provide(featureName);
        this.notizen$ = new BehaviorSubject<Notiz[]>(this.service.getNotizen(produkt));
        return this.notizen$;
    }

    save(notizen: Notiz[]): Observable<boolean> {
        const currentNotizen = this.notizen$.getValue();

        const toSave = notizen.filter((x) => !currentNotizen.find((y) => y.id === x.id));
        const toRemove: Notiz[] = currentNotizen.filter((x) => !notizen.find((y) => y.id === x.id));

        const saveOrDeletes = toSave
            .map((x) => this.service.saveNotiz(this.produktId, x.id, x))
            .concat(toRemove.map((x) => this.service.deleteNotiz(this.produktId, x.id)));

        if (saveOrDeletes.length > 0) {
            return forkJoin(saveOrDeletes).pipe(
                map((results) => results.every((result) => !!result)),
                tap((success) => {
                    if (success) {
                        this.notizen$.next(notizen);
                    }
                }),
            );
        }
        return of(true);
    }
}
