<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- RadSaetze -->
        <ng-container *ngSwitchCase="'radSaetze'">
            <app-card
                class="satz"
                *ngFor="let radsatz of form.get(field.arrayName).controls; trackBy: trackByIndex; let i = index"
                spacing="8px"
            >
                <h3>{{ 'raeder.radsatz.' + i | translate }}</h3>
                <app-produkt-detail-raeder-radsatz
                    [form]="radsatz"
                    [name]="name"
                    [fields]="field.fields"
                    [produktArt]="this.produkt.art"
                />

                <app-mini-fab class="remove" (click)="onRemoveSatzClick()">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-mini-fab>
            </app-card>
            <app-card *ngIf="form.get(field.arrayName).controls.length !== 2" spacing="8px">
                <div align="center">
                    <app-button
                        *ngIf="form.get(field.arrayName).controls.length >= 1"
                        (action)="onDuplicateSatzClick()"
                    >
                        <app-mat-icon class="new-style" [newStyle]="true" icon="icon-duplicate-report" />
                        {{ 'raeder.duplicatesatz' | translate }}
                    </app-button>
                    <app-button (action)="onAddSatzClick()">
                        <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
                        {{ 'raeder.addsatz.' + form.get(field.arrayName).controls.length | translate }}
                    </app-button>
                </div>
            </app-card>
        </ng-container>
    </ng-container>
</ng-container>

<app-card>
    <h3>{{ 'raeder.sonstiges' | translate }}</h3>
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name || field.arrayName">
            <!-- Notlaufeinrichtung -->
            <ng-container *ngSwitchCase="'notlaufeinrichtung'">
                <app-select
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [values]="notlaufeinrichtung"
                    [valuePrefix]="name + '.'"
                />
            </ng-container>
            <!-- Notlaufeinrichtung Profiltiefe -->
            <ng-container *ngSwitchCase="'notlaufeinrichtungProfiltiefe'">
                <ng-container *ngIf="this.produkt.art === ProduktArt.AlphaController">
                    <ng-template *ngTemplateOutlet="acProfilTiefe; context: { field: field }" />
                </ng-container>
                <ng-container *ngIf="this.produkt.art !== ProduktArt.AlphaController">
                    <ng-template *ngTemplateOutlet="mm; context: { field: field }" />
                </ng-container>
            </ng-container>
            <!-- Bemerkungen -->
            <ng-container *ngSwitchCase="'bemerkungen'">
                <app-textbausteine
                    #bemerkungen
                    [form]="form"
                    [name]="'bemerkungen'"
                    [displayName]="'Bemerkungen'"
                    [feature]="'raeder'"
                    [produktArt]="this.produkt.art"
                />
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!-- Templates -->
<ng-template #mm let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;mm</ng-container>
    </app-input>
</ng-template>
<ng-template #acProfilTiefe let-field="field">
    <app-select
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [values]="acProfiltiefeSelectValues"
        [useKeyAsSelectValue]="false"
        [useKeyAsDisplayValue]="true"
        [valuePostfix]="' mm'"
    >
        <ng-container appSuffix>&nbsp;mm</ng-container>
    </app-select>
</ng-template>

<ng-template #confirmDialog>
    <mat-label>
        {{ name + '.confirm.dialog.text' | translate }}
    </mat-label>
</ng-template>
