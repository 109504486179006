import { Injectable } from '@angular/core';
import { FileData } from '@app/class/file-data';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';

export interface IFileValidationResult {
    validFiles: FileData<ArrayBuffer>[];
    invalidFiles: FileData<ArrayBuffer>[];
}

export enum FileValidationTypes {
    Image,
    PDF,
}

@Injectable({
    providedIn: 'root',
})
export class FileValidationService {
    private acceptedFileExtensionsForImage = ['jpeg', 'jpg', 'jpe', 'jif', 'jfif', 'jfi', 'png', 'bmp', 'dib'];

    private acceptedFileExtensionsForPdf = ['pdf'];

    private acceptedFileTypesForImage = ['image/jpeg', 'image/png', 'image/bmp', 'image/x-bmp', 'image/x-ms-bmp'];

    private acceptedFileTypesForPdf = ['application/pdf'];

    constructor(private readonly snackBarService: SnackBarService) {
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    }

    validateFileTypeAndExtension(
        files: FileData<ArrayBuffer>[],
        fileValidationType: FileValidationTypes,
    ): IFileValidationResult {
        Assert.notNullOrUndefined(fileValidationType, 'fileValidationType');

        if (!files) {
            return {
                validFiles: [],
                invalidFiles: [],
            };
        }

        let acceptedFileExtensions: string[] = [];
        let acceptedFileTypes: string[] = [];

        const validFiles: FileData<ArrayBuffer>[] = [];
        const invalidFiles: FileData<ArrayBuffer>[] = [];

        switch (fileValidationType) {
            case FileValidationTypes.Image:
                acceptedFileExtensions = this.acceptedFileExtensionsForImage;
                acceptedFileTypes = this.acceptedFileTypesForImage;
                break;
            case FileValidationTypes.PDF:
                acceptedFileExtensions = this.acceptedFileExtensionsForPdf;
                acceptedFileTypes = this.acceptedFileTypesForPdf;
                break;
            default:
                console.error('Unknown fileValidationType: ', fileValidationType);
                return {
                    validFiles: [],
                    invalidFiles: [],
                };
        }

        files.forEach((file) => {
            const fileMimeType = this.getMimeTypeFromArrayBuffer(file.data);
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!acceptedFileExtensions.includes(fileExtension) || !acceptedFileTypes.includes(fileMimeType)) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        });

        if (validFiles.length === 0 && invalidFiles.length === 1) {
            this.snackBarService.error('snackbar.filevalidation.typeOrExtensionInvalid.singular');
        } else if (validFiles.length === 0 && invalidFiles.length > 1) {
            this.snackBarService.error('snackbar.filevalidation.typeOrExtensionInvalid.all');
        } else if (validFiles.length > 0 && invalidFiles.length > 0) {
            this.snackBarService.warning('snackbar.filevalidation.typeOrExtensionInvalid.plural');
        }

        return {
            validFiles,
            invalidFiles,
        };
    }

    getMimeTypeFromArrayBuffer(arrayBuffer): string {
        Assert.notNullOrUndefined(arrayBuffer, 'arrayBuffer');

        const uint8arr = new Uint8Array(arrayBuffer);
        const len = 4;

        if (uint8arr.length >= len) {
            const signatureArr = new Array(len);
            for (let i = 0; i < len; i++) {
                signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16);
            }
            const signature = signatureArr.join('').toUpperCase();

            if (signature.startsWith('424D')) {
                return 'image/bmp';
            }
            if (signature.startsWith('FFD8')) {
                return 'image/jpeg';
            }

            switch (signature) {
                case '89504E47':
                    return 'image/png';
                case '25504446':
                    return 'application/pdf';
                default:
                    return 'unknown';
            }
        }
        return 'unknown';
    }
}
