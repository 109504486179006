import { AusstattungGruppe } from '@data/domain/schema/type';

export const PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL: AusstattungGruppe[] = [
    {
        name: 'Exterieur',
        teile: [
            { name: 'Halogenscheinwerfer' },
            { name: 'Stahlfelgen' },
            { name: 'Leichtmetallfelgen' },
            { name: 'Facelift' },
            { name: 'Sportpaket' },
            { name: 'Sportfahrwerk' },
            { name: 'Anhängerkupplung' },
            { name: 'Dachreling' },
        ],
    },
    {
        name: 'Interieur',
        teile: [
            { name: 'Isofix' },
            { name: 'Schiebedach' },
            { name: 'Panoramadach' },
            { name: 'Panorama-Glasschiebedach' },
            { name: 'Sportsitze' },
            { name: 'Skisack' },
            { name: 'Fernseher hinten' },
            { name: 'Warndreieck' },
            { name: 'Verbandskasten' },
            { name: 'Bordwerkzeug' },
        ],
    },
    {
        name: 'Sicherheitssysteme',
        teile: [
            { name: 'ABS' },
            { name: 'ASR' },
            { name: 'ESP' },
            { name: 'Airbagsystem' },
            { name: 'Tagfahrlicht' },
            { name: 'Kurvenlicht' },
            { name: 'Nebelscheinwerfer' },
            { name: 'El. Wegfahrsperre' },
            { name: 'Alarmanlage' },
            { name: 'Freisprecheinrichtung' },
            { name: 'Aktiver Insassenschutz' },
        ],
    },
    {
        name: 'Assistenzsysteme',
        teile: [
            { name: 'Dynamischer Lichtassistent' },
            { name: 'Tempomat/ Geschwindigkeitsregelanlage' },
            { name: 'Spurhalteassistent Radar Sensorik' },
            { name: 'Spurhalteassistent Kamerasystem' },
            { name: 'ACC Distronic' },
            { name: 'Einparkhilfe Ultraschallgeber hinten' },
            { name: 'Einparkhilfe Ultraschallgeber vorne/ hinten' },
            { name: 'Einparkhilfe Ultraschallgeber vorne' },
            { name: 'Einparkhilfe Selbstlenkende Systeme' },
            { name: 'Einparkhilfe Kamera' },
        ],
    },
    {
        name: 'Komfortsysteme',
        teile: [
            { name: 'Klimaanlage' },
            { name: 'Klimaautomatik' },
            { name: 'Xenonscheinwerfer' },
            { name: 'Laserscheinwerfer' },
            { name: 'LED-Scheinwerfer' },
            { name: 'El. Fensterheber vorne' },
            { name: 'El. Fensterheber hinten' },
            { name: 'El. Seitenspiegel' },
            { name: 'El. Seitenspiegel einklappbar' },
            { name: 'El. Sitze' },
            { name: 'Servolenkung' },
            { name: 'El. Lenkung' },
            { name: 'Lichtsensor' },
            { name: 'Head-up-Display' },
            { name: 'Standheizung' },
            { name: 'Zentralverrieglung' },
            { name: 'Multimediasystem' },
            { name: 'Tuner/ Radio' },
            { name: 'USB-Portaudio' },
            { name: 'MP3-Schnittstelle' },
            { name: 'Bluetooth-Schnittstelle' },
            { name: 'Navigationssystem klein' },
            { name: 'Navigationssystem groß' },
            { name: 'Bordcomputer' },
            { name: 'Multifunktionslenkrad' },
            { name: 'Sitzheizung' },
            { name: 'Sitzlüftung' },
            { name: 'Lordosenstütze' },
            { name: 'TV-Anbindung' },
            { name: 'Beheizbare Frontscheibe' },
            { name: 'El. Heckklappe' },
            { name: 'Keyless Go' },
        ],
    },
    {
        name: 'Sonderumbauten',
        teile: [
            { name: 'Taxiausstattung' },
            { name: 'Fahrschulausstattung' },
            { name: 'Behindertenumbau' },
            { name: 'Sonderfahrzeuge Feuerwehr' },
            { name: 'Sonderfahrzeuge Polizei' },
            { name: 'Sonderfahrzeug Aufbauart individuell' },
        ],
    },
    {
        name: 'Sonderzubehör',
        teile: [
            { name: 'Radträger' },
            { name: 'Gepäckbox Radträger' },
            { name: 'Gepäckbox Dach' },
            { name: 'Kühlbox' },
            { name: 'Sonstiges' },
            { name: 'Warnweste' },
            { name: 'Zweiter Radsatz' },
        ],
    },
    {
        name: 'Erfasste Ausstattung',
        teile: [],
    },
];
