import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileData } from '@app/class/file-data';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface FahrzeugscheinVinScore {
    text: string;
    score: number;
}

export enum FahrzeugscheinResponseCode {
    NotRecognized = 1,
    SingleRecognized = 2,
    MultipleRecognized = 3,
}

export class FahrzeugscheinResponse {
    error?: string;
    code?: FahrzeugscheinResponseCode;
    vins?: FahrzeugscheinVinScore[];
}

@Injectable({
    providedIn: 'root',
})
export class FahrzeugscheinService {
    private readonly restApiUrl: string;

    constructor(private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/fahrzeugschein`;
    }

    post(image: FileData<ArrayBuffer>): Observable<FahrzeugscheinResponse> {
        const file = new File([image.data], image.name, {
            type: image.type,
        });
        return this.httpClient.post<FahrzeugscheinResponse>(this.restApiUrl, file);
    }
}
