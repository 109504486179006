import { AssertError } from './assert-error';

export class Assert {
    /**
     * @throws {AssertError} Argument argValue must be undefined
     */
    static undefined(argValue: any, argName: string, message?: string) {
        if (argValue !== undefined) {
            throw new AssertError(argName, message || `"${argName}" was not undefined.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must not be undefined
     */
    static notUndefined(argValue: any, argName: string, message?: string) {
        if (argValue === undefined) {
            throw new AssertError(argName, message || `"${argName}" was undefined.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must be null
     */
    static null(argValue: any, argName: string, message?: string) {
        if (argValue !== null) {
            throw new AssertError(argName, message || `"${argName}" was not null.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must not be null
     */
    static notNull(argValue: any, argName: string, message?: string) {
        if (argValue === null) {
            throw new AssertError(argName, message || `"${argName}" was null.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must be true
     */
    static true(argValue: boolean, argName: string, message?: string) {
        if (argValue !== true) {
            throw new AssertError(argName, message || `"${argName}" was not true.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must be false
     */
    static false(argValue: boolean, argName: string, message?: string) {
        if (argValue !== false) {
            throw new AssertError(argName, message || `"${argName}" was not false.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must be empty
     */
    static empty(argValue: string, argName: string, message?: string) {
        if (argValue !== '') {
            throw new AssertError(argName, message || `"${argName}" was not ''.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must not be empty
     */
    static notEmpty(argValue: string, argName: string, message?: string) {
        if (argValue === '') {
            throw new AssertError(argName, message || `"${argName}" was ''.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must be null or empty
     */
    static nullOrEmpty(argValue: string, argName: string, meesage?: string) {
        if (argValue !== null && argValue !== '') {
            throw new AssertError(argName, meesage || `"${argName}" was not null or ''.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must not be null or empty
     */
    static notNullOrEmpty(argValue: string, argName: string, message?: string) {
        if (argValue === null || argValue === '') {
            throw new AssertError(argName, message || `"${argName}" was null or ''.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must be null or undefined
     */
    static nullOrUndefined(argValue: any, argName: string, meesage?: string) {
        if (argValue !== null && argValue !== undefined) {
            throw new AssertError(argName, meesage || `"${argName}" was not null or undefined.`);
        }
    }

    /**
     * @throws {AssertError} Argument argValue must not be null or undefined
     */
    static notNullOrUndefined(argValue: any, argName: string, message?: string) {
        if (argValue === null || argValue === undefined) {
            throw new AssertError(argName, message || `"${argName}" was null or undefined.`);
        }
    }
}
