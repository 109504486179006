import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TableColumnComponent } from '../table-column/table-column.component';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
    displayedColumns$ = new BehaviorSubject<string[]>([]);
    columns$ = new BehaviorSubject<TableColumnComponent[]>([]);
    dataSource = new MatTableDataSource<any>();

    @Input()
    filter$ = new BehaviorSubject<string>('');

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ContentChildren(TableColumnComponent)
    columns: QueryList<TableColumnComponent>;

    @Input()
    data: any[];

    @Input()
    pageSizeOptions: number[];

    @Input()
    active: string;

    @Input()
    direction: 'asc' | 'desc';

    @Input()
    filterPredicate: (date: any, filter: string) => boolean;

    @Output()
    action = new EventEmitter<any>();

    @Input()
    customFilterVisible = true;

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.dataSource.data = this.data;
        this.dataSource.sortingDataAccessor = (item, property) =>
            property.split('.').reduce((value, key) => (value ? value[key] : undefined), item);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = this.filter$.value;
        this.dataSource.filterPredicate = this.filterPredicate;
        this.subscriptions.push(this.filter$.subscribe((value) => (this.dataSource.filter = value)));
    }

    ngAfterViewInit(): void {
        const columns = this.columns.toArray();
        setTimeout(() => {
            this.displayedColumns$.next(columns.map((x) => x.name));
            this.columns$.next(columns);
            this.dataSource.paginator = this.paginator;
            this.sort.sort({ id: this.active, start: this.direction, disableClear: false });
        }, 1);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    onRowClick(entry: any): void {
        this.action.next(entry);
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    refresh(tabledata: any[]): void {
        this.dataSource.data = tabledata;
    }
}
