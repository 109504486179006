import { UntypedFormArray } from '@angular/forms';
import { ViewFormGroup } from './view-form-group';

export class ViewFormArray extends UntypedFormArray {
    getRawValue(): any[] {
        const groupValue = [];
        for (let i = 0; i < this.controls.length; ++i) {
            const control = this.controls[i] as ViewFormGroup;

            let value = control.getRawValue();
            if (value === undefined || value === '') {
                value = null;
            }
            groupValue.push(value);
        }
        return groupValue;
    }
}
