import { Component } from '@angular/core';

@Component({
    selector: 'app-error',
    template: `
        <h2 class="center">{{ 'auth.error' | translate }}</h2>
    `,
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {}
