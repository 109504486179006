<mat-form-field class="input-form-field">
    <input #filter matInput />
    <ng-container matSuffix>
        <button [disabled]="filter.value.length === 0" (click)="addClicked.emit(filter.value)" mat-button>
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
        </button>
    </ng-container>
</mat-form-field>

<app-accordion [multi]="false">
    <ng-container *ngFor="let umfang of umfaenge.controls; let index = index">
        <app-expansion-panel>
            <app-expansion-header>
                <app-panel-title class="title">
                    <strong>{{ umfang.value.obergruppe }}</strong>
                </app-panel-title>
            </app-expansion-header>
            <app-textbausteine
                [form]="umfang"
                [name]="'beschreibung'"
                [displayName]="'Textbausteine'"
                [feature]="umfang.value.obergruppeName"
                [produktArt]="this.produktArt.Zustandsbericht"
            />
            <div class="button-container">
                <button (click)="onDelete(index)" mat-button>
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </button>
            </div>
        </app-expansion-panel>
    </ng-container>
</app-accordion>

<!-- Delete dialog -->
<ng-template #dialogDelete>
    <app-delete-dialog />
</ng-template>
