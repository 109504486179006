import { guid } from '@app/function/guid';
import { FeatureField, FeatureFieldArray, FeatureFieldGroup } from '@modules/produkt/config/produkt-config';

export namespace TrackBy {
    export function trackByField(_index: number, field: FeatureField | FeatureFieldGroup | FeatureFieldArray): string {
        // name
        return (
            (field as FeatureField).name ||
            (field as FeatureFieldGroup).groupName ||
            (field as FeatureFieldArray).arrayName
        );
    }

    export function trackByIndex(index: number): number {
        return index;
    }

    export function trackById(
        _index: number,
        obj: {
            id: string;
        },
    ): string {
        return obj.id;
    }

    export function trackByInstance(
        _index: number,
        obj: {
            __uuid: string;
        },
    ): string {
        if (obj.__uuid === undefined) {
            obj.__uuid = guid();
        }
        return obj.__uuid;
    }
}
