<mat-card class="card-list-item">
    <div class="header">
        <div class="header-text">
            <ng-content select="[title]" />
            <ng-content select="[subtitle]" />
        </div>
        <div class="space"></div>
        <div class="header-status">
            <ng-content select="[date]" />
            <ng-content select="[status]" />
        </div>
    </div>
    <ng-content />
</mat-card>
