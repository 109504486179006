import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum ButtonType {
    PRIMARY,
    SECONDARY,
    TERTIARY,
}

export interface ButtonSetting {
    title: string;
    type: ButtonType;
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    @Input()
    name: string;

    @Input()
    tooltip: string;

    @Input()
    type = ButtonType.TERTIARY;

    @Input()
    color = 'basic';

    @Input()
    disabled = false;

    @Input()
    loading = false;

    @Input()
    count = 0;

    @Input()
    countAlign: 'center' | 'top' = 'top';

    @Input()
    isSmall = false;

    @Output()
    action = new EventEmitter<MouseEvent>();

    Buttontype = ButtonType;
}
