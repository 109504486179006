import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveCgFeststellung, SaveCgFeststellungData } from '../../graphql/mutations';
import { CgFeststellung, CgFeststellungInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktCgFeststellungService extends ProduktFeatureService<CgFeststellung, CgFeststellungInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: CgFeststellung): CgFeststellungInput {
        const input: CgFeststellungInput = {
            ...feature,
            id: produktId,
            positionenBauteileversichert: (feature.positionenBauteileversichert || []).map((bauteil, index) => ({
                ...bauteil,
                id: `${produktId}:0:${index}`,
            })),
            positionenFahrzeugversichert: (feature.positionenFahrzeugversichert || []).map((bauteil, index) => ({
                ...bauteil,
                id: `${produktId}:1:${index}`,
            })),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveCgFeststellung;
    }

    protected getSaveVariables(input: CgFeststellungInput): any {
        return {
            cgFeststellung: input,
        };
    }

    protected getSaveOptimisticResponse(input: CgFeststellungInput): any {
        return {
            saveCgFeststellung: {
                ...input,
                __typename: 'CgFeststellung',
                positionenBauteileversichert: (input.positionenBauteileversichert || []).map((bauteil) => ({
                    ...bauteil,
                    __typename: 'Bauteil',
                })),
                positionenFahrzeugversichert: (input.positionenFahrzeugversichert || []).map((bauteil) => ({
                    ...bauteil,
                    __typename: 'Bauteil',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveCgFeststellungData>): CgFeststellung {
        return response.data.saveCgFeststellung;
    }

    protected update(produkt: Produkt, feature: CgFeststellung): void {
        produkt.cgFeststellung.id = feature.id;
        produkt.cgFeststellung.versicherungsart = feature.versicherungsart;
        produkt.cgFeststellung.positionenBauteileversichert = feature.positionenBauteileversichert;
        produkt.cgFeststellung.positionenFahrzeugversichert = feature.positionenFahrzeugversichert;
        produkt.cgFeststellung.verschleissbedingt = feature.verschleissbedingt;
        produkt.cgFeststellung.diagnose = feature.diagnose;
        produkt.cgFeststellung.internerKommentar = feature.internerKommentar;
    }
}
