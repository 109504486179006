import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-list-option',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOptionComponent {
    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    id: string;

    @Input()
    displayName: string;

    get selected(): boolean {
        return this.form.get(this.name).value;
    }

    set selected(selected: boolean) {
        this.form.get(this.name).setValue(selected);
    }
}
