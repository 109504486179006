<ng-container *ngIf="!(loading$ | async); else spinner">
    <ng-container *ngIf="parentProdukt; else noParentData">
        <ng-container *ngIf="(viewport$ | async) === viewport.Mobile; else noAccordion">
            <ng-container *ngTemplateOutlet="accordion" />
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #accordion>
    <app-accordion class="parent-accordion">
        <app-expansion-panel>
            <app-expansion-header>
                <app-panel-title>
                    <h4>{{ 'parent.title' | translate }}</h4>
                </app-panel-title>
            </app-expansion-header>
            <ng-container *ngTemplateOutlet="content" />
        </app-expansion-panel>
    </app-accordion>
</ng-template>

<ng-template #noAccordion>
    <h4>{{ 'parent.title' | translate }}</h4>
    <ng-container *ngTemplateOutlet="content" />
</ng-template>

<ng-template #content>
    <div class="text-content" *ngIf="parentProdukt.erstelltAm">
        <h4 class="mat-caption text-field-label">{{ 'parent.erstelldatum' | translate }}</h4>
        <mat-label>{{ parentProdukt.erstelltAm | date }}</mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.art">
        <h4 class="mat-caption text-field-label">{{ 'parent.art' | translate }}</h4>
        <mat-label>
            {{ 'toolbar.' + (produktArtNachbewertungValues.values[parentProdukt.art] | lowercase) | translate }}
        </mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.auftrag.nummer">
        <h4 class="mat-caption text-field-label">{{ 'auftrag.nummer' | translate }}</h4>
        <mat-label>{{ parentProdukt.auftrag.nummer }}</mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.auftrag.vorgangsnummer">
        <h4 class="mat-caption text-field-label">{{ 'auftrag.vorgangsnummer' | translate }}</h4>
        <mat-label>{{ parentProdukt.auftrag.vorgangsnummer }}</mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.fahrzeug.identnummer">
        <h4 class="mat-caption text-field-label">{{ 'fahrzeug.identnummer' | translate }}</h4>
        <mat-label>{{ parentProdukt.fahrzeug.identnummer }}</mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.fahrzeug.erstzulassung">
        <h4 class="mat-caption text-field-label">{{ 'fahrzeug.erstzulassung' | translate }}</h4>
        <mat-label>{{ parentProdukt.fahrzeug.erstzulassung | date }}</mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.fahrzeug.hersteller">
        <h4 class="mat-caption text-field-label">{{ 'fahrzeug.hersteller' | translate }}</h4>
        <mat-label>{{ parentProdukt.fahrzeug.hersteller }}</mat-label>
    </div>
    <div class="text-content" *ngIf="parentProdukt.fahrzeug.modell">
        <h4 class="mat-caption text-field-label">{{ 'fahrzeug.modell' | translate }}</h4>
        <mat-label>{{ parentProdukt.fahrzeug.modell }}</mat-label>
    </div>
    <div *ngIf="showOpenParentButton">
        <app-button (click)="onOpenParentProdukt()">
            {{ 'parent.open' | translate }}
        </app-button>
    </div>
</ng-template>

<ng-template #spinner>
    <app-progress-spinner />
</ng-template>

<ng-template #noParentData>
    <mat-label>{{ 'parent.error' | translate }}</mat-label>
</ng-template>
