<h4 *ngIf="title">{{ title | translate }}</h4>
<div class="add-wrapper" *ngIf="add.observers.length > 0">
    <div class="add">
        <app-button *ngIf="showCamera" [disabled]="disabled" (action)="onAddClick(1)">
            <app-mat-icon icon="photo_camera" />
        </app-button>
        <app-button *ngIf="showFolder" [disabled]="disabled" (action)="onAddClick(2)">
            <app-mat-icon icon="folder" />
        </app-button>
    </div>
</div>
<div class="file-gallery-wrapper">
    <div class="actions-wrapper" *ngFor="let fileId of fileIds; trackBy: trackByKey.bind(this)">
        <app-file
            #file
            [fileId]="fileId"
            [buttonLabel]="buttonLabel"
            (save)="onFileSaved(fileId, $event)"
            (buttonClick)="buttonClick.emit($event)"
        />
        <div class="actions">
            <app-mini-fab
                *ngIf="reset.observers.length > 0 && resetable(fileId)"
                (click)="reset.emit(fileId)"
                color="basic"
            >
                <app-mat-icon icon="undo" size="1.3rem" />
            </app-mini-fab>
            <app-mini-fab *ngIf="update.observers.length > 0" (click)="file.edit()" color="basic">
                <app-mat-icon icon="edit" size="1.3rem" />
            </app-mini-fab>
            <app-mini-fab *ngIf="delete.observers.length > 0" (click)="delete.emit(fileId)" color="basic">
                <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
            </app-mini-fab>
        </div>
    </div>
</div>
