import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { FileValidationTypes } from '@app/service/file-validation.service';
import { FileService } from '@app/service/file.service';
import { ProduktArt, ProduktStatus } from '@data/domain/schema/enum';
import { Anhang, Produkt } from '@data/domain/schema/type';
import { ProduktAnhaengeService } from '@data/domain/service/feature/produkt-anhaenge.service';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { UploadDialogService } from '@shared/service/upload-dialog.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-produkt-detail-abschluss-anhaenge',
    templateUrl: './produkt-detail-abschluss-anhaenge.component.html',
    styleUrls: ['./produkt-detail-abschluss-anhaenge.component.scss'],
})
export class ProduktDetailAbschlussAnhaengeComponent implements OnInit, OnDestroy {
    trackById = TrackBy.trackById;

    @Input()
    name: string = PRODUKT_CONFIG_FEATURES.Abschluss.name;

    @Input()
    produkt: Produkt;

    @Input()
    statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

    produktArt = ProduktArt;

    form: ViewFormGroup;

    acceptPDF = '.pdf';

    multiple = false;

    control: UntypedFormControl;

    anhaenge$: BehaviorSubject<Anhang[]>;

    disabled$ = new BehaviorSubject<boolean>(false);

    fileValidationType = FileValidationTypes.PDF;

    private subscriptions: Subscription[] = [];

    private readonly maxBytes = 2097152; // 2mb

    constructor(
        private readonly uploadService: UploadDialogService,
        private readonly snackBarService: SnackBarService,
        private readonly produktAnhaengeService: ProduktAnhaengeService,
        private readonly fileService: FileService,
        private readonly fileFieldService: ProduktDetailFileFieldService,
    ) {
        Assert.notNullOrUndefined(uploadService, 'uploadService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(produktAnhaengeService, 'produktAnhaengeService');
        Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
        Assert.notNullOrUndefined(fileService, 'fileService');
    }

    ngOnInit(): void {
        this.control = new ViewFormControl('');
        this.anhaenge$ = new BehaviorSubject<Anhang[]>(this.produkt.anhaenge.anhaenge);
        this.subscriptions.push(this.getStatusChangedSubscription());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(files, 'files');

        if (files.length > 0) {
            if (this.exceedMaxFileSize(files)) {
                this.snackBarService.error('abschluss.error.maxFileSize');
                return;
            }

            this.fileFieldService.add(field, files);

            this.subscriptions.push(this.getSaveAnhangSubscription(field, files));
        }
    }

    onFileDelete(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.remove(field, fileId);
    }

    onDelete(index: number, anhang: Anhang): void {
        if (index !== -1) {
            this.produktAnhaengeService
                .deleteAnhang(this.produkt.id, this.produkt, anhang.id)
                .pipe(first())
                .subscribe((respone) => this.anhaenge$.next(this.produkt.anhaenge.anhaenge));
            this.fileFieldService.remove(this.control, anhang.id);
            this.fileService.delete(anhang.quelle);
        }
    }

    onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        this.fileFieldService.update(field, event);
    }

    onFileReset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.reset(field, fileId);
    }

    isFileResetable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.fileFieldService.isUpdateable(fileId);
    }

    private exceedMaxFileSize(files: FileData<ArrayBuffer>[]): boolean {
        let result = false;
        files.forEach((file) => {
            if (file.size > this.maxBytes) {
                result = true;
                return false;
            }
        });
        return result;
    }

    private getSaveAnhangSubscription(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): Subscription {
        return field.valueChanges.pipe(first()).subscribe((newValue) => {
            const anhang: Anhang = {
                id: Date.now().toString(),
                quelle: newValue[newValue.length - 1],
                bezeichnung: files[0].name,
            };

            this.produktAnhaengeService
                .saveAnhang(this.produkt.id, anhang.id, anhang)
                .pipe(first())
                .subscribe((x) => {
                    if (x) {
                        this.produkt.anhaenge.anhaenge.push(anhang);
                        this.anhaenge$.next(this.produkt.anhaenge.anhaenge);
                    }
                });
        });
    }

    private getStatusChangedSubscription(): Subscription {
        return this.statusChanged$.subscribe((status) =>
            status !== ProduktStatus.Offen ? this.disabled$.next(true) : this.disabled$.next(false),
        );
    }
}
