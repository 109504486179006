import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { guid } from '@app/function/guid';
import {
    CgBaugruppeBauteileversichert,
    CgBaugruppeFahrzeugversichert,
    CgVersicherungsart,
} from '@data/domain/schema/enum';
import { Bauteil, Produkt } from '@data/domain/schema/type';
import { BAUGRUPPE_TO_BAUTEIL_MAP } from '@modules/produkt/config/produkt-cg-baugruppen.config';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailCgFeststellungBauteilFormViewFactory } from '@modules/produkt/factory/cg-feststellung/produkt-detail-cg-feststellung-bauteil-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import {
    ColumnCount,
    MaxColumnEntries,
} from '@shared/component/form-controls/extendable-radio-list/extendable-radio-list.component';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ObjectValues, Values } from '@shared/helper/values';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription, filter, from, map, mergeMap, of, take } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-produkt-detail-cg-feststellung-baugruppen',
    templateUrl: './produkt-detail-cg-feststellung-baugruppen.component.html',
    styleUrls: ['./produkt-detail-cg-feststellung-baugruppen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailCgFeststellungBaugruppenComponent implements OnInit {
    @Input()
    produkt: Produkt;

    @Input()
    featureFields: FeatureFields;

    @Input()
    positionen$: BehaviorSubject<ViewFormArray>;

    @Input()
    baugruppenEnum: any;

    @Input()
    selectedView: CgVersicherungsart;

    @Input()
    name: string;

    cgFestellungVersicherungsartEnum = CgVersicherungsart;

    baugruppenListe: Values;

    disableSaveBauteil$ = new BehaviorSubject(false);
    disableSaveBauteilSubscription: Subscription;

    @ViewChild('bauteilSelect', { static: true })
    bauteilSelectTemplate: TemplateRef<any>;

    @ViewChild('bauteilDetail', { static: true })
    bauteilDetailTemplate: TemplateRef<any>;

    protected maxColumnEntries = MaxColumnEntries;
    protected columnCountEnum = ColumnCount;
    protected viewport = Viewport;
    protected viewport$: Observable<Viewport>;
    protected trackByField = TrackBy.trackByField;

    private bauteileFields: FeatureFields;

    constructor(
        private readonly bauteilFormFactory: ProduktDetailCgFeststellungBauteilFormViewFactory,
        private readonly templateDialogService: TemplateDialogService,
        private readonly viewportService: ViewportService,
        private readonly fileFieldService: ProduktDetailFileFieldService,
    ) {}

    ngOnInit() {
        let positionenName;
        switch (this.selectedView) {
            case CgVersicherungsart.Bauteileversichert:
                this.baugruppenEnum = CgBaugruppeBauteileversichert;
                positionenName = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields.PositionenBauteileversichert.name;
                break;
            case CgVersicherungsart.Fahrzeugversichert:
                this.baugruppenEnum = CgBaugruppeFahrzeugversichert;
                positionenName = PRODUKT_CONFIG_FEATURES.CgFeststellung.fields.PositionenFahrzeugversichert.name;
                break;
            default:
                throw new Error('Invalid selected view.');
        }

        this.bauteileFields = (
            this.featureFields.find((x: FeatureFieldArray) => x.arrayName === positionenName) as FeatureFieldArray
        ).fields;

        this.baugruppenListe = new ObjectValues(this.baugruppenEnum);
        this.viewport$ = this.viewportService.observe();
    }

    onBaugruppeClick(baugruppe: string): void {
        if (this.selectedView === CgVersicherungsart.Bauteileversichert) {
            this.createPredefinedBauteil(baugruppe);
            return;
        }
        this.createCustomBauteil(this.baugruppenEnum[baugruppe]);
    }

    onBaugruppeAdd(baugruppe: string): void {
        this.createCustomBauteil(baugruppe);
    }

    private createPredefinedBauteil(baugruppe: number | string): void {
        this.openBauteilSelectDialog(baugruppe)
            .pipe(
                filter((value) => !!value),
                mergeMap((value) => this.openBauteilDetailDialog(value)),
                take(1),
            )
            .subscribe((bauteilData) => {
                this.addPosition(bauteilData);
            });
    }

    private createCustomBauteil(baugruppe: number | string): void {
        this.openBauteilDetailDialog({
            id: guid(),
            bezeichnung: '',
            baugruppe: baugruppe as string,
        })
            .pipe(take(1))
            .subscribe((bauteilData) => {
                this.addPosition(bauteilData);
            });
    }

    openBauteilSelectDialog(baugruppe: number | string): Observable<Bauteil> {
        const form = this.bauteilFormFactory.create(
            {
                id: guid(),
                baugruppe: this.baugruppenEnum[baugruppe],
            },
            this.bauteileFields,
        );

        const button = [this.templateDialogService.getCancelButtonSetting()];

        const bauteile = BAUGRUPPE_TO_BAUTEIL_MAP.get(this.baugruppenEnum[baugruppe]);
        if (!bauteile) {
            return of(null);
        }

        const values = new ObjectValues(bauteile);
        const data: any = { form, values, produkt: this.produkt };

        const dialog = this.templateDialogService.open(
            this.baugruppenEnum[baugruppe],
            button,
            this.bauteilSelectTemplate,
            data,
            true,
        );

        const promise = new Promise<Bauteil>((resolve, reject) => {
            let valueChangeSubscription = form.valueChanges.subscribe({
                next: (value) => {
                    valueChangeSubscription.unsubscribe();
                    valueChangeSubscription = null;
                    dialog.close();
                    resolve(value);
                },
                error: (error) => reject(new Error(error)),
            });
            dialog
                .afterClosed()
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        if (valueChangeSubscription) {
                            valueChangeSubscription.unsubscribe();
                        } else {
                            resolve(null);
                        }
                    },
                    error: (error) => reject(new Error(error)),
                });
        });

        return from(promise);
    }

    openBauteilDetailDialog(bauteil: Bauteil): Observable<ViewFormGroup> {
        if (!this.bauteilDetailTemplate) {
            console.error(
                'ProduktDetailCgFeststellungBaugruppenComponent - openBauteilDetailDialog(), no bauteilDetailTemplate found',
            );
            return of(null);
        }

        const bauteilName = bauteil.bezeichnung || '';
        const baugruppeName = bauteil.baugruppe || '';
        const title =
            bauteilName === baugruppeName
                ? bauteilName
                : baugruppeName + (bauteilName === '' ? '' : ` / ${bauteilName}`);

        const fields = this.bauteileFields;
        const form = this.bauteilFormFactory.create(bauteil, fields);
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data = { form, fields };

        const dialogRef = this.templateDialogService.open(title, buttons, this.bauteilDetailTemplate, data, true);
        this.templateDialogService.setCurrentDialog(dialogRef);
        dialogRef
            .afterOpened()
            .pipe(take(1))
            .subscribe((value) => {
                this.templateDialogService.disableButton(1);
                this.disableSaveBauteilSubscription = form.valueChanges.subscribe((value2) => {
                    if (value2.bilder.length > 0 && value2.prueftext && value2.bezeichnung) {
                        this.templateDialogService.enableButton(1);
                    } else {
                        this.templateDialogService.disableButton(1);
                    }
                });
            });
        return dialogRef.afterClosed().pipe(
            take(1),
            filter((result) => result?.name === this.templateDialogService.getSaveButtonSetting().title),
            tap((value) => this.disableSaveBauteilSubscription.unsubscribe()),
            map((result) => result.data.form),
        );
    }

    private addPosition(bauteilData: ViewFormGroup): void {
        const newValue = this.positionen$.value;
        newValue.push(bauteilData);
        this.sortPositionen(newValue);
    }

    sortPositionen(newPositionen?: ViewFormArray): void {
        const newValue = newPositionen || this.positionen$.value;
        newValue.controls.sort((control1, control2) => {
            const compareBaugruppe = control1.get('baugruppe').value.localeCompare(control2.get('baugruppe').value);
            if (compareBaugruppe === 0) {
                return control1.get('bezeichnung').value.localeCompare(control2.get('bezeichnung').value);
            }
            return compareBaugruppe;
        });
        this.positionen$.next(newValue);
    }

    onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(files, 'files');
        this.fileFieldService.add(field, files);
    }

    onFileDelete(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.remove(field, fileId);
    }

    onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        this.fileFieldService.update(field, event);
    }

    onFileReset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        this.fileFieldService.reset(field, fileId);
    }

    isFileResetable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.fileFieldService.isUpdateable(fileId);
    }
}
