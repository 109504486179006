<ng-container>
    <ng-container *ngIf="showCancelAbschluss$ | async">
        <div class="button-cancel-container">
            <span>{{ name + '.workflow.cancel.text' | translate }}</span>
            <app-button
                class="button-abschluss-steps"
                [type]="ButtonType.SECONDARY"
                [name]="name + '.workflow.cancel'"
                (action)="this.onAbschlussCancelClick()"
            >
                {{ name + '.workflow.cancel' | translate }}
            </app-button>
        </div>
    </ng-container>

    <ng-container *ngIf="statusChanged$ | async as status; else loading">
        <app-stepper [linear]="true">
            <!-- schließen -->
            <app-step [editable]="!(rechnungsArtChanged | async)" [completed]="status > 1">
                <app-step-label>
                    <ng-container *ngIf="status <= 1; else geschlossenLabel">
                        {{ name + '.workflow.schliessen' | translate }}
                    </ng-container>
                    <ng-template #geschlossenLabel>
                        <ng-container *ngIf="rechnungsArtChanged | async; else erneutLabel">
                            {{ name + '.workflow.geschlossen' | translate }}
                        </ng-container>
                        <ng-template #erneutLabel>
                            {{ name + '.workflow.geschlossenErneut' | translate }}
                        </ng-template>
                    </ng-template>
                </app-step-label>
                <ng-container *ngIf="!(loading$ | async); else loading">
                    <ng-container *ngIf="status <= 1; else geschlossen">
                        <div>
                            {{ name + '.workflow.schliessenLabel' | translate }}
                        </div>
                        <div>
                            <app-button
                                class="button-abschluss-steps"
                                [type]="ButtonType.PRIMARY"
                                [name]="name + '.workflow.schliessen'"
                                (action)="onProduktCloseClick()"
                            >
                                {{ name + '.workflow.schliessen' | translate }}
                            </app-button>
                        </div>
                    </ng-container>
                    <ng-template #geschlossen>
                        <div>
                            {{ name + '.workflow.geschlossenLabel' | translate }}
                        </div>
                        <div>
                            <app-button
                                class="button-abschluss-steps"
                                [type]="ButtonType.SECONDARY"
                                [name]="name + '.workflow.geschlossenErneut'"
                                (action)="onProduktOpenClick()"
                            >
                                {{ name + '.workflow.geschlossenErneut' | translate }}
                            </app-button>
                        </div>
                    </ng-template>
                </ng-container>
            </app-step>

            <!-- rechnungsart -->
            <app-step [completed]="rechnungsArtChanged | async">
                <app-step-label>
                    <ng-container *ngIf="rechnungsArtChanged | async as value; else rechnungsArtSelect">
                        {{
                            name + '.workflow.rechnungsArtAusgewaehlt'
                                | translate: { value: name + '.' + rechnungsArt.values[value] | lowercase | translate }
                        }}
                    </ng-container>
                    <ng-template #rechnungsArtSelect>
                        {{ name + '.workflow.rechnungsArtAuswaehlen' | translate }}
                    </ng-template>
                </app-step-label>
                <ng-container *ngIf="!(loading$ | async); else loading">
                    <app-action-list
                        [values]="rechnungsArt"
                        [disabled]="rechnungsArtDisabled"
                        [valuePrefix]="name + '.'"
                        (action)="onRechungsArtSelect($event)"
                    />
                </ng-container>
            </app-step>

            <!-- rechnung -->
            <app-step [completed]="status > 3">
                <app-step-label>
                    {{ name + '.workflow.rechnung' | translate }}
                </app-step-label>
                <ng-container *ngIf="(rechnungsArtChanged | async) === 1; else manuell">
                    <ng-container *ngIf="!(loading$ | async); else loading" />
                    {{ name + '.workflow.rechnung.bvws' | translate }}
                </ng-container>
                <ng-template #manuell>
                    {{ name + '.workflow.rechnung.manuell' | translate }}
                    <div>TODO</div>
                </ng-template>
                <div>
                    <app-button
                        class="button-abschluss-steps"
                        [type]="ButtonType.SECONDARY"
                        [name]="name + '.workflow.reset.rechnung'"
                        (action)="onAbschlussResetClick()"
                    >
                        {{ name + '.workflow.reset.rechnung' | translate }}
                    </app-button>
                </div>
            </app-step>

            <!-- versand art -->
            <app-step [completed]="versandArtChanged | async">
                <app-step-label>
                    <ng-container *ngIf="versandArtChanged | async as value; else versandArtSelect">
                        {{
                            name + '.workflow.versandArtAusgewaehlt'
                                | translate: { value: name + '.' + versandArt.values[value] | lowercase | translate }
                        }}
                    </ng-container>
                    <ng-template #versandArtSelect>
                        {{ name + '.workflow.versandArtAuswaehlen' | translate }}
                    </ng-template>
                </app-step-label>

                <ng-container *ngIf="!(loading$ | async); else loading">
                    <app-action-list
                        [values]="versandArt"
                        [valuePrefix]="name + '.'"
                        [disabled]="versandArtDisabled"
                        (action)="onVersandArtSelect($event)"
                    />
                </ng-container>
            </app-step>

            <!-- beendet -->
            <app-step>
                <app-step-label>
                    {{ name + '.workflow.beendetLabel' | translate }}
                </app-step-label>
                <ng-container *ngIf="!(loading$ | async); else loading">
                    <div>{{ name + '.workflow.beendet' | translate }}</div>
                    <div class="buttons">
                        <app-button
                            class="btn-icon"
                            [name]="name + '.workflow.reset.beendet'"
                            (action)="onAbschlussResetClick()"
                        >
                            <ng-container>
                                <app-mat-icon icon="lock_open" color="primary" />
                            </ng-container>
                        </app-button>
                        <div class="spacer"></div>
                        <app-button
                            class="btn-icon"
                            *ngIf="!(downloadUrl$ | async); else open"
                            [name]="name + '.workflow.download'"
                            (action)="onDownloadClick()"
                        >
                            <ng-container>
                                <app-mat-icon icon="picture_as_pdf" color="primary" />
                            </ng-container>
                        </app-button>
                        <ng-template #open>
                            <app-button
                                class="btn-icon"
                                [name]="name + '.workflow.download.open'"
                                (action)="onOpenDownloadClick()"
                            >
                                <ng-container>
                                    <app-mat-icon icon="picture_as_pdf" color="primary" />
                                </ng-container>
                            </app-button>
                        </ng-template>
                    </div>
                </ng-container>
            </app-step>
        </app-stepper>
    </ng-container>
</ng-container>

<ng-template #loading>
    <app-progress-spinner />
</ng-template>

<ng-template #abschlussResetDialog>
    <mat-label *ngIf="canAbschlussReset()">
        {{ name + '.workflow.reset.confirm' | translate }}
    </mat-label>
    <mat-label *ngIf="!canAbschlussReset()">
        {{ name + '.workflow.reset.reject' | translate }}
        <br />
        <br />
        {{ name + '.workflow.reset.alternative' | translate }}
        <button class="btn-create-duplicate" (click)="onClickDuplicate($event, produkt)" mat-button>
            {{ name + '.workflow.reset.nachbewertung' | translate }}
        </button>
    </mat-label>
</ng-template>

<!-- Confirm dialog -->
<ng-template #dialogConfirmDuplicate let-data="data">
    <mat-label class="dialog-text">{{ 'nachbewertung.text' | translate }}</mat-label>
    <app-produkt-detail-nachbewertung-dialog #nachbewertungArtSelection [defaultSelection]="data.produkt.art" />
</ng-template>

<ng-template #dialogDataSynchronization>
    <mat-label class="dialog-text">{{ name + '.workflow.synchronization.dialog.text' | translate }}</mat-label>
    <mat-label class="dialog-text" *ngIf="currentSyncCount === 0">
        {{ name + '.workflow.synchronization.otherdevice.dialog.text' | translate }}
    </mat-label>
</ng-template>
