import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { TextareaComponent } from '@shared/component/form-controls';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';

@Component({
    selector: 'app-produkt-detail-cg-feststellung-beurteilung',
    templateUrl: './produkt-detail-cg-feststellung-beurteilung.component.html',
    styleUrls: ['./produkt-detail-cg-feststellung-beurteilung.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailCgFeststellungBeurteilungComponent {
    protected trackByField = TrackBy.trackByField;

    @Input()
    form?: ViewFormGroup;

    @Input()
    fields?: FeatureFields;

    @Input()
    name?: string;

    @ViewChild('diagnose') diagnose?: TextareaComponent;

    removeValidator(): void {
        const diagnose = this.form?.get('diagnose');
        diagnose?.setValidators([]);
        diagnose?.updateValueAndValidity();
        diagnose?.markAllAsTouched();
        diagnose?.markAsDirty();
    }

    setValidator(): void {
        const diagnose = this.form?.get('diagnose');
        diagnose?.setValidators([Validators.required]);
        this.diagnose?.focus();
        diagnose?.updateValueAndValidity();
        diagnose?.markAllAsTouched();
        diagnose?.markAsDirty();
    }
}
