import { Injectable } from '@angular/core';
import { WerteRoundValue } from '@data/domain/schema/enum';
import { Werte } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { ProduktDetailWerteManuelleWerteFormViewFactory } from '@modules/produkt/factory/werte/produkt-detail-werte-manuelleWerte-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailWerteFormViewFactory extends FormViewModelBaseFactory<Werte> {
    constructor(
        private readonly produktDetailWerteManuelleWerteFormViewFactory: ProduktDetailWerteManuelleWerteFormViewFactory,
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly currencyFormatterService: CurrencyFormatterService,
    ) {
        super();
        Assert.notNullOrUndefined(currencyFormatterService, 'currencyFormatterService');
    }

    protected createField(model: Werte, name: string): AbstractViewFormControl {
        switch (name) {
            case PRODUKT_CONFIG_FEATURES.Werte.fields.Bezugsdatum.name:
                return new ViewFormControl(model.bezugsdatum);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.RoundValue.name:
                return new ViewFormControl(model.roundValue || WerteRoundValue.Netto);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.Haendlereinkaufswert.name:
                return new ViewFormControl(model.haendlereinkaufswert, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertDrucken.name:
                return new ViewFormControl(!!model.haendlereinkaufswertDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertNetto.name:
                return new ViewFormControl(model.haendlereinkaufswertNetto, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertNettoDrucken.name:
                return new ViewFormControl(!!model.haendlereinkaufswertNettoDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.Haendlerverkaufswert.name:
                return new ViewFormControl(model.haendlerverkaufswert, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertDrucken.name:
                return new ViewFormControl(!!model.haendlerverkaufswertDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertNetto.name:
                return new ViewFormControl(model.haendlerverkaufswertNetto, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertBesteuerung.name:
                return new ViewFormControl(model.haendlerverkaufswertBesteuerung);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertNettoDrucken.name:
                return new ViewFormControl(!!model.haendlerverkaufswertNettoDrucken);

            case PRODUKT_CONFIG_FEATURES.Werte.fields.DatHvkNettoDifferenz.name:
                return new ViewFormControl(model.datHvkNettoDifferenz);

            case PRODUKT_CONFIG_FEATURES.Werte.fields.DatHvkNettoRegel.name:
                return new ViewFormControl(model.datHvkNettoRegel);

            case PRODUKT_CONFIG_FEATURES.Werte.fields.Aufwendungen.name:
                return new ViewFormControl(model.aufwendungen, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.AufwendungenDrucken.name:
                return new ViewFormControl(
                    model.aufwendungenDrucken === undefined || model.aufwendungenDrucken === null
                        ? true
                        : model.aufwendungenDrucken,
                );
            case PRODUKT_CONFIG_FEATURES.Werte.fields.AufwendungenDetailsDrucken.name:
                return new ViewFormControl(
                    model.aufwendungenDetailsDrucken === undefined || model.aufwendungenDetailsDrucken === null
                        ? false
                        : model.aufwendungenDetailsDrucken,
                );
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertAbzuege.name:
                return new ViewFormControl(model.haendlereinkaufswertAbzuege, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertAbzuegeDrucken.name:
                return new ViewFormControl(!!model.haendlereinkaufswertAbzuegeDrucken);

            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertAbzuegeNetto.name:
                return new ViewFormControl(model.haendlereinkaufswertAbzuegeNetto, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlereinkaufswertAbzuegeNettoDrucken.name:
                return new ViewFormControl(!!model.haendlereinkaufswertAbzuegeNettoDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertAbzuege.name:
                return new ViewFormControl(model.haendlerverkaufswertAbzuege, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertAbzuegeDrucken.name:
                return new ViewFormControl(!!model.haendlerverkaufswertAbzuegeDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertAbzuegeNetto.name:
                return new ViewFormControl(model.haendlerverkaufswertAbzuegeNetto, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.HaendlerverkaufswertAbzuegeNettoDrucken.name:
                return new ViewFormControl(!!model.haendlerverkaufswertAbzuegeNettoDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.ManuelleWerteDrucken.name:
                return new ViewFormControl(!!model.manuelleWerteDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.WertAmMarkt.name:
                return new ViewFormControl(model.wertAmMarkt, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.WertAmMarktBemerkung.name:
                return new ViewFormControl(model.wertAmMarktBemerkung);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.RestwertAusUnfallschaden.name:
                return new ViewFormControl(model.restwertAusUnfallschaden, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.RestwertAusUnfallschadenNetto.name:
                return new ViewFormControl(model.restwertAusUnfallschadenNetto, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.Neuwert.name:
                return new ViewFormControl(model.neuwert, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.NeuwertDrucken.name:
                return new ViewFormControl(!!model.neuwertDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.RelativerWert.name:
                return new ViewFormControl(model.relativerWert, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.RelativerWertDrucken.name:
                return new ViewFormControl(!!model.relativerWertDrucken);
            case PRODUKT_CONFIG_FEATURES.Werte.fields.WerterhoehendesZubehoer.name:
                return new ViewFormControl(model.werterhoehendesZubehoer, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.WerterhoehenderWartungszustand.name:
                return new ViewFormControl(model.werterhoehenderWartungszustand, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.WerterhoehenderReparaturzustand.name:
                return new ViewFormControl(model.werterhoehenderReparaturzustand, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.ZweiterRadsatzAnteilig.name:
                return new ViewFormControl(model.zweiterRadsatzAnteilig, {
                    formatter: this.currencyFormatterService,
                });
            case PRODUKT_CONFIG_FEATURES.Werte.fields.ExternalServicesReferenceId.name:
                return new ViewFormControl(model.externalServicesReferenceId);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Werte, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Werte.fields;
        switch (arrayName) {
            case fields.ManuelleWerte.name:
                const manuelleWerte = model.manuelleWerte || [];
                return new ViewFormArray(
                    manuelleWerte.map((werte) =>
                        this.produktDetailWerteManuelleWerteFormViewFactory.create(werte, arrayFields),
                    ),
                );
            case fields.WertAmMarktBemerkungen.name:
                const wertAmMarktBemerkungen = model.wertAmMarktBemerkungen || [];
                return new ViewFormArray(
                    wertAmMarktBemerkungen.map((wertAmMarktBemerkung) =>
                        this.produktDetailTextbausteinFormViewFactory.create(wertAmMarktBemerkung, arrayFields),
                    ),
                );
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
