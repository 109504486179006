import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingSpinnerGlobalService {
    private loading$ = new BehaviorSubject<boolean>(false);

    get(): BehaviorSubject<boolean> {
        return this.loading$;
    }

    set(loading: boolean): BehaviorSubject<boolean> {
        this.loading$.next(loading);
        return this.loading$;
    }
}
