import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import * as AWS from 'aws-sdk';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class CognitoService {
    constructor(private readonly authService: AuthService) {
        Assert.notNullOrUndefined(authService, 'authService');
    }

    secureContext(): Observable<any> {
        return this.getCredentials().pipe(
            mergeMap((credentials) => {
                if (credentials.needsRefresh()) {
                    const promise = credentials.refreshPromise();
                    return from(promise).pipe(map(() => credentials.data));
                }
                return of(credentials.data);
            }),
        );
    }

    private getCredentials(): Observable<AWS.CognitoIdentityCredentials> {
        const credentials = AWS.config.credentials as AWS.CognitoIdentityCredentials;
        if (credentials) {
            return of(credentials);
        } else {
            return this.createCredentials();
        }
    }

    private createCredentials(): Observable<AWS.CognitoIdentityCredentials> {
        return this.authService.getUser().pipe(
            map(
                (user) =>
                    new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: `${environment.aws.region}:${environment.aws.cognito.identityPoolId}`,
                        Logins: {
                            [`cognito-idp.${environment.aws.region}.amazonaws.com/${environment.aws.region}_${environment.aws.cognito.userPoolId}`]:
                                user.id_token,
                        },
                    }),
            ),
            tap((credentials) => {
                AWS.config.update({
                    region: environment.aws.region,
                    credentials,
                });
            }),
        );
    }
}
