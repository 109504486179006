<mat-expansion-panel #panel [expanded]="expanded" hideToggle togglePosition="before">
    <mat-expansion-panel-header
        [class.column-2]="tableColumn === 2"
        [class.column-3]="tableColumn === 3"
        [class.column-4]="tableColumn === 4"
    >
        <ng-content select="app-expansion-panel-header-custom'" />
    </mat-expansion-panel-header>
    <!--body of expansion panel-->
    <ng-content />
</mat-expansion-panel>
