import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface CombiPlusOnlineDto {
    kennzeichen?: string;
    vorgangId?: number;
    vorgang?: string;
    art?: CombiPlusVorgangsArt;
    schadennummer?: string;
    schadendatum?: string;
    vorgangsdatum?: string;
    anspruchssteller?: string;
    versicherungsnehmer?: string;
    status?: CombiPlusOnlineStatus;
    url?: string;
}
export enum CombiPlusOnlineStatus {
    None = 0,
    Offen = 1,
    Geschlossen = 2,
}

// CaseTypeName
export enum CombiPlusVorgangsArt {
    None = 0,
    Haftpflichtschaden = 301,
    Kaskoschaden = 302,
}

@Injectable({
    providedIn: 'root',
})
export class CombiPlusOnlineService {
    private readonly restApiUrl: string;

    constructor(private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = 'https://api.dev.bewertung-stage.gtue.de/bvws/cpo';
    }

    getVorgaenge(): Observable<CombiPlusOnlineDto[]> {
        return this.httpClient.get<CombiPlusOnlineDto[]>(this.restApiUrl);
    }

    putCase(vorgangsart: number): Observable<string> {
        return this.httpClient.put<string>(this.restApiUrl, vorgangsart);
    }
}
