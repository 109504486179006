import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
    ProduktDetailFahrzeugscheinDialogComponent,
    ProduktDetailFahrzeugscheinDialogData,
} from '../component/produkt-detail-fahrzeugschein-dialog/produkt-detail-fahrzeugschein-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFahrzeugscheinDialogService {
    constructor(private readonly templateDialog: TemplateDialogService) {
        Assert.notNullOrUndefined(templateDialog, 'templateDialog');
    }

    open(title: string): Observable<string> {
        Assert.notNullOrEmpty(title, 'title');

        const data: ProduktDetailFahrzeugscheinDialogData = {};
        const buttons = [this.templateDialog.getRetryButtonSetting(), this.templateDialog.getCancelButtonSetting()];
        return this.templateDialog
            .openTemplate(title, buttons, ProduktDetailFahrzeugscheinDialogComponent, data, true)
            .pipe(
                first(),
                map((result) =>
                    result.name === this.templateDialog.getCancelButtonSetting().title ? null : result.data.vin,
                ),
            );
    }
}
