<app-expansion-panel-single>
    <app-expansion-header>
        <div class="filter-container" [class.mobile]="(viewport$ | async) === viewport.Mobile">
            <h4 class="filter-header-text">{{ 'produktUebersicht.filter' | translate }}</h4>
            <app-slide-toggle class="filter" [form]="form" [name]="'status'" displayName="nur offene" />
        </div>
    </app-expansion-header>

    <div class="filter-container" [class.mobile]="(viewport$ | async) === viewport.Mobile">
        <app-select
            class="filter-large"
            [form]="form"
            [name]="'art'"
            [displayName]="'produktUebersicht.art'"
            [values]="produktArtEnumValues"
            [valuePrefix]="name + '.'"
            [hasEmptyField]="true"
        />
        <app-input
            class="filter-large"
            [form]="form"
            [name]="'searchText'"
            [displayName]="'produktUebersicht.searchText'"
            type="text"
        />
        <app-slide-toggle
            class="filter-toggle"
            [form]="form"
            [name]="'dateToggle'"
            displayName="produktUebersicht.dateToggle"
        />
        <app-date
            class="filter"
            [form]="form"
            [name]="'von'"
            [displayName]="'produktUebersicht.von'"
            [min]="minDate"
            [max]="maxDate"
        />
        <app-date
            class="filter"
            [form]="form"
            [name]="'bis'"
            [displayName]="'produktUebersicht.bis'"
            [min]="minDate"
            [max]="maxDate"
        />
        <app-button class="search-button" [type]="ButtonType.PRIMARY" (action)="onClickProduktSearch()">
            <div [class.invisible]="loading$ | async">{{ 'produktUebersicht.suche' | translate }}</div>
            <app-progress-spinner class="spinner" *ngIf="loading$ | async" [diameter]="25" />
        </app-button>
        <app-button class="button" [type]="ButtonType.SECONDARY" (action)="onCsvDownload($event)">
            <div [class.invisible]="loadingCsv$ | async">{{ 'produktuebersicht.csv' | translate }}</div>
            <app-progress-spinner class="spinner" *ngIf="loadingCsv$ | async" [diameter]="25" />
        </app-button>
        <app-button class="reset-button" [type]="ButtonType.TERTIARY" (action)="onResetFilter()">
            {{ 'produktuebersicht.reset' | translate }}
        </app-button>
    </div>
</app-expansion-panel-single>

<app-table
    #table
    [data]="produkte"
    [filter$]="filter$"
    [filterPredicate]="filterPredicate"
    [pageSizeOptions]="[25, 50, 100]"
    [customFilterVisible]="false"
    (action)="onAction($event)"
    active="erstelltAm"
    direction="desc"
>
    <app-table-column [displayName]="name + '.art'" [sort]="true" name="art">
        <ng-template let-element="element">
            <app-mat-icon *ngIf="element.art === produktArt.Bewertung" [svgIcon]="'bewertung'" color="primary" />
            <app-mat-icon
                *ngIf="element.art === produktArt.Ruecknahmebewertung"
                [svgIcon]="'ruecknahmebewertung'"
                color="primary"
            />
            <app-mat-icon
                *ngIf="element.art === produktArt.Zustandsbericht"
                [svgIcon]="'zustandsbericht'"
                color="primary"
            />
            <h4 *ngIf="element.art === produktArt.VtiTooling">VTI</h4>
            <app-mat-icon *ngIf="element.art === produktArt.CarGarantie" [svgIcon]="'car-garantie'" color="primary" />
            <app-mat-icon
                *ngIf="element.art === produktArt.AlphaController"
                [svgIcon]="'alphacontroller'"
                color="primary"
            />
            <app-mat-icon
                *ngIf="element.art === produktArt.OldTimer"
                [svgIcon]="produktIcon.Oldtimer"
                color="primary"
            />
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.nummer'" [sort]="true" name="auftrag.nummer">
        <ng-template let-element="element">{{ element.auftrag?.nummer }}</ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.identnummer'" [sort]="true" name="fahrzeug.identnummer">
        <ng-template let-element="element">
            {{ element.fahrzeug?.identnummer }}
            <br />
            {{ element.fahrzeug?.kennzeichen }}
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.erstelltAm'" [sort]="true" name="erstelltAm" start="desc">
        <ng-template let-element="element">{{ element.erstelltAm | date }}</ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.status'" [sort]="true" name="status">
        <ng-template let-element="element">
            {{ produktStatus.values[element.status] }}
            <br />
            {{ element.abgeschlossenAm ? '(' + (element.abgeschlossenAm | date) + ')' : '' }}
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.download'" [sort]="false" name="download">
        <ng-template let-element="element">
            <div class="button-container">
                <app-button
                    [matBadge]="element.anzahlBilder"
                    [matBadgeHidden]="element.anzahlBilder <= 0"
                    [class.disabled]="element.anzahlBilder <= 0"
                    (action)="onBilderDownload($event, element.id, element.anzahlBilder <= 0)"
                >
                    <ng-container>
                        <app-mat-icon icon="photo_gallery" color="primary" />
                    </ng-container>
                </app-button>
                <app-button
                    [class.disabled]="element.status !== produktStatusEnum.Beendet"
                    (action)="onDownloadClick($event, element.id, element.status !== produktStatusEnum.Beendet)"
                >
                    <ng-container>
                        <app-mat-icon icon="picture_as_pdf" color="primary" />
                    </ng-container>
                </app-button>
            </div>
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.duplicate'" [sort]="false" name="duplicate">
        <ng-template let-element="element">
            <app-button *ngIf="nachbewertungEnabled(element.art)" (action)="onClickDuplicate($event, element)">
                <ng-container>
                    <app-mat-icon icon="control_point_duplicate" color="primary" />
                </ng-container>
            </app-button>
        </ng-template>
    </app-table-column>
</app-table>

<!-- Confirm dialog -->
<ng-template #dialogConfirmDuplicate let-data="data">
    <mat-label class="dialog-text">{{ 'nachbewertung.text' | translate }}</mat-label>
    <app-produkt-detail-nachbewertung-dialog #nachbewertungArtSelection [defaultSelection]="data.produkt.art" />
</ng-template>
