<div matTooltip="{{ tooltip }}">
    <ng-container [ngSwitch]="type">
        <button
            *ngSwitchCase="Buttontype.PRIMARY"
            [id]="name"
            [class.loading]="loading"
            [class.count-center]="countAlign === 'center'"
            [disabled]="disabled || loading"
            [class.small]="isSmall"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count"
            [style.margin]="0"
            (click)="!disabled && this.action.emit($event)"
            mat-flat-button
            disableRipple
            color="primary"
        >
            <ng-container [ngTemplateOutlet]="content" />
        </button>

        <button
            *ngSwitchCase="Buttontype.SECONDARY"
            [id]="name"
            [class.loading]="loading"
            [class.count-center]="countAlign === 'center'"
            [disabled]="disabled || loading"
            [class.small]="isSmall"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count"
            [style.margin]="0"
            (click)="!disabled && this.action.emit($event)"
            mat-stroked-button
            disableRipple
            color="secondary"
        >
            <ng-container [ngTemplateOutlet]="content" />
        </button>

        <button
            *ngSwitchCase="Buttontype.TERTIARY"
            [id]="name"
            [class.loading]="loading"
            [class.count-center]="countAlign === 'center'"
            [disabled]="disabled || loading"
            [class.small]="isSmall"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count"
            [style.margin]="0"
            (click)="!disabled && this.action.emit($event)"
            mat-button
            disableRipple
            color="{{ color }}"
        >
            <ng-container [ngTemplateOutlet]="content" />
        </button>
    </ng-container>
</div>

<ng-template #content>
    <ng-content />
</ng-template>
