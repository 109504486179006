import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Assert } from '@shared/helper/assert';
import { forkJoin, from, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

const SNACK_BAR_DURATION_DEFAULT = 5 * 1000;

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(
        private readonly matSnackBar: MatSnackBar,
        private readonly translate: TranslateService,
    ) {
        Assert.notNullOrUndefined(matSnackBar, 'matSnackBar');
        Assert.notNullOrUndefined(translate, 'translate');
    }

    error(
        message: string,
        messageObject?: Object,
        action?: string,
        duration: number = SNACK_BAR_DURATION_DEFAULT,
        translate: boolean = true,
    ): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        if (translate) {
            return this.showTranslated(message, messageObject, action, duration, 'error', translate);
        }
        return this.show(message, action, duration, 'error');
    }

    info(
        message: string,
        messageObject?: Object,
        action?: string,
        duration: number = SNACK_BAR_DURATION_DEFAULT,
    ): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.showTranslated(message, messageObject, action, duration, 'info', true);
    }

    warning(
        message: string,
        messageObject?: Object,
        action?: string,
        duration: number = SNACK_BAR_DURATION_DEFAULT,
    ): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.showTranslated(message, messageObject, action, duration, 'warning', true);
    }

    success(
        message: string,
        messageObject?: Object,
        action?: string,
        duration: number = SNACK_BAR_DURATION_DEFAULT,
    ): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.showTranslated(message, messageObject, action, duration, 'success', true);
    }

    private showTranslated(
        message: string,
        messageObject: Object,
        action: string,
        duration: number,
        panelClass: string,
        translate: boolean,
    ): Observable<void> {
        const showPromise = new Promise<void>((resolve, reject) => {
            forkJoin([this.translate.get(message, messageObject), action ? this.translate.get(action) : of(undefined)])
                .pipe(
                    mergeMap((translations) => {
                        const ref = this.matSnackBar.open(translations[0], translations[1], {
                            duration,
                            verticalPosition: 'bottom',
                            panelClass: `snack-bar-service-${panelClass}`,
                        });
                        return ref.onAction();
                    }),
                )
                .pipe(take(1))
                .subscribe({ next: () => resolve(), error: (err) => reject(err) });
        });
        return from(showPromise);
    }

    private show(message: string, action: string, duration: number, panelClass: string): Observable<void> {
        const showPromise = new Promise<void>((resolve, reject) => {
            const ref = this.matSnackBar.open(message, action, {
                duration,
                verticalPosition: 'bottom',
                panelClass: `snack-bar-service-${panelClass}`,
            });
            return ref
                .onAction()
                .pipe(take(1))
                .subscribe({ next: () => resolve(), error: (err) => reject(err) });
        });
        return from(showPromise);
    }
}
