<div class="help-menu-wrapper" *ngIf="context$ | async as context">
    <app-menu-button (action)="goToUrl('https://www.gtue.de/partnerbereich/page/aktueller-updatestand/102508')">
        <span>{{ 'footer.whatsNew' | translate }}</span>
    </app-menu-button>
    <ng-container *ngIf="'footer.support.subject' | translate: context as subject">
        <ng-container *ngIf="'footer.support.body' | translate: context as body">
            <app-menu-button (action)="sendMailTo('bewertung-support@gtue.de', subject, body)">
                <span>{{ 'footer.support' | translate }}</span>
            </app-menu-button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="'footer.feedback.subject' | translate: context as subject">
        <ng-container *ngIf="'footer.feedback.body' | translate: context as body">
            <app-menu-button (action)="sendMailTo('bewertung-feedback@gtue.de', subject, body)">
                <span>{{ 'footer.feedback' | translate }}</span>
            </app-menu-button>
        </ng-container>
    </ng-container>
    <app-menu-button (action)="onPolicyClick()">
        <span>{{ 'footer.privacy' | translate }}</span>
    </app-menu-button>
    <app-divider />
    <div [disabled]="true" mat-menu-item>{{ 'footer.version' | translate }}: {{ version }} {{ environment }}</div>
</div>
