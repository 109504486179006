import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';

@Component({
    selector: 'app-produkt-detail-fehlteile-position',
    templateUrl: './produkt-detail-fehlteile-position.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailFehlteilePositionComponent {
    trackByField = TrackBy.trackByField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;
}
