import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { File } from '@app/class/file';
import { FileData } from '@app/class/file-data';
import { FileValidationService, FileValidationTypes } from '@app/service/file-validation.service';
import { FileService } from '@app/service/file.service';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { first } from 'rxjs/operators';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-inline-image',
    templateUrl: './inline-image.component.html',
    styleUrls: ['./inline-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineImageComponent extends FormControlBase implements OnInit {
    @Input()
    accept = '.png, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .bmp, .dib';

    @Input()
    size: number;

    @Input()
    isEinstellungen = false;

    img: File;

    constructor(
        formControlFocusService: FormControlFocusService,
        private fileService: FileService,
        private readonly snackBarService: SnackBarService,
        private readonly fileValidationService: FileValidationService,
    ) {
        super(formControlFocusService);
    }

    onSelect(input: HTMLInputElement): void {
        input.click();
    }

    onSelected(input: HTMLInputElement): void {
        if (input.files.length > 0) {
            const file = input.files[0];

            if (!!this.size && file.size > this.size) {
                alert(`max file size of ${Math.round(this.size / 1024)}kB exceeded.`);
                return;
            }

            const fileReader = new FileReader();

            fileReader.onload = (e: any) => {
                const buffer = e.target.result as ArrayBuffer;
                const bufferFile: FileData<ArrayBuffer> = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    data: buffer,
                };
                const validationResult = this.fileValidationService.validateFileTypeAndExtension(
                    [bufferFile],
                    FileValidationTypes.Image,
                );

                if (validationResult && validationResult.validFiles.length > 0) {
                    fileReader.onload = () => {
                        const img = e.target.result as string;

                        this.control.setValue(img);
                        this.control.markAsTouched();
                        this.control.updateValueAndValidity();
                    };

                    fileReader.readAsDataURL(file);
                }
            };

            fileReader.readAsArrayBuffer(input.files[0]);
        }
    }

    onClear(): void {
        this.control.setValue(undefined);
        this.control.markAsTouched();
        this.control.updateValueAndValidity();
    }

    blur(): void {
        // This is intentional
    }

    focus(): void {
        // This is intentional
    }

    ngOnInit(): void {
        if (this.isEinstellungen) {
            const value = this.control.value;

            if (typeof value === 'string') {
                if (!value.includes('data:image')) {
                    // download from s3 bucket
                    this.fileService
                        .getEinstellungenImage(value)
                        .pipe(first())
                        .subscribe({
                            next: (result) => (this.img = result),
                            error: (error) => console.error(error),
                        });
                }
            }
        }
    }
}
