import { ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { FormControlBase } from '../form-control-base.component';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent extends FormControlBase {
    @ViewChild(MatInput, { static: true, read: ElementRef })
    input: ElementRef<HTMLElement>;

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (!(event.key === 'Enter' || event.keyCode === 13)) {
            return;
        }

        if (event.shiftKey) {
            this.prev();
        } else {
            this.next();
        }
    }

    constructor(formControlFocusService: FormControlFocusService) {
        super(formControlFocusService);
    }

    blur(): void {
        this.input.nativeElement.blur();
    }

    focus(): void {
        // setTimeout require to skip enter event
        setTimeout(() => {
            this.input.nativeElement.focus();
        }, 1);
    }
}
