import { Injectable } from '@angular/core';
import { guid } from '@app/function/guid';
import { VorschadenPosition } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailVorschadenPositionFormViewFactory extends FormViewModelBaseFactory<VorschadenPosition> {
    constructor(private readonly currencyFormatter: CurrencyFormatterService) {
        super();
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    }

    protected createField(model: VorschadenPosition, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.fields;
        switch (name) {
            case fields.Obergruppe.name:
                return new ViewFormControl(model.obergruppe);
            case fields.Untergruppe.name:
                return new ViewFormControl(model.untergruppe, {
                    updateOn: 'change',
                });
            case fields.Art.name:
                return new ViewFormControl(model.art, {
                    updateOn: 'change',
                });
            case fields.Reparatur.name:
                return new ViewFormControl(model.reparatur, {
                    updateOn: 'change',
                });
            case fields.Bilder.name:
                return new ViewFormControl(model.bilder || []);
            case fields.Schadendatum.name:
                return new ViewFormControl(model.schadendatum);
            case fields.Schadenhoehe.name:
                return new ViewFormControl(model.schadenhoehe, {
                    formatter: this.currencyFormatter,
                });
            case fields.Wertminderung.name:
                return new ViewFormControl(model.wertminderung, {
                    formatter: this.currencyFormatter,
                });
            case fields.ExternalId.name:
                return new ViewFormControl(model.externalId || guid());
            case fields.Extern.name:
                return new ViewFormControl(model.extern);
            case fields.Bestaetigt.name:
                return new ViewFormControl(model.bestaetigt);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
