import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AcProdukt, AlphaControllerVorgangService } from '@data/api-gateway/service/alphacontroller/ac-vorgang.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AcProduktUebersichtResolveService } from '../service/ac-produkt-uebersicht-resolve.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktUebersichtAcResolve implements Resolve<AcProdukt[]> {
    constructor(
        private readonly alphaControllerVorgangService: AlphaControllerVorgangService,
        private readonly acProduktUebersichtResolveService: AcProduktUebersichtResolveService,
        private readonly router: Router,
        private readonly location: Location,
        private readonly snackbar: SnackBarService,
    ) {
        Assert.notNullOrUndefined(alphaControllerVorgangService, 'alphaControllerVorgangService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(location, 'location');
        Assert.notNullOrUndefined(snackbar, 'snackbar');
    }

    resolve(): Observable<AcProdukt[]> {
        return this.acProduktUebersichtResolveService.resolve().pipe(
            catchError((error) => {
                this.snackbar.warning('produkt.uebersicht.resolveError');
                this.router.navigateByUrl(this.location.path());
                console.error(error);
                return EMPTY;
            }),
        );
    }
}
