<button
    class="icon-button"
    [disabled]="disabled"
    [matBadge]="count"
    (click)="action.emit($event)"
    type="button"
    mat-icon-button
>
    <ng-content />
</button>
