import { Injectable } from '@angular/core';
import { ProduktDetailKalkulationErrorCanActivate } from '@modules/produkt/guard/kalkulation/error/produkt-detail-kalkulation-error.can-activate';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailSchadenKalkulationErrorCanActivate extends ProduktDetailKalkulationErrorCanActivate {
    protected navigateToBaseRoute(produktId: string): void {
        this.router.navigate(['produkt', 'detail', produktId, 'schaden']);
    }
}
