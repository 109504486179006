import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
    @Input()
    count: string;

    @Input()
    disabled: boolean;

    @Output()
    action = new EventEmitter<MouseEvent>();
}
