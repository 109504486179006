import { Injectable } from '@angular/core';
import { guid } from '@app/function/guid';
import { FehlteilePosition } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFehlteilePositionFormViewFactory extends FormViewModelBaseFactory<FehlteilePosition> {
    constructor(private readonly currencyFormatter: CurrencyFormatterService) {
        super();
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    }

    protected createField(model: FehlteilePosition, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Fehlteile.fields.Positionen.fields;
        switch (name) {
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung);
            case fields.Preis.name:
                return new ViewFormControl(model.preis, {
                    formatter: this.currencyFormatter,
                });
            case fields.ExternalId.name:
                return new ViewFormControl(model.externalId || guid());
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
