import { Injectable } from '@angular/core';
import { Ausstattung } from '@data/domain/schema/type';
import { PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL } from '@modules/produkt/config/produkt-ausstattung-gruppen.config';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailAusstattungGruppeFormViewFactory } from './produkt-detail-ausstattung-gruppe-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailAusstattungFormViewFactory extends FormViewModelBaseFactory<Ausstattung> {
    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly produktDetailAusstattungGruppeFormViewFactory: ProduktDetailAusstattungGruppeFormViewFactory,
    ) {
        super();
        Assert.notNullOrUndefined(
            produktDetailAusstattungGruppeFormViewFactory,
            'produktDetailAusstattungGruppeFormViewFactory',
        );
    }

    protected createField(_: Ausstattung, name: string): AbstractViewFormControl {
        throw new Error(`Could not create field for name: '${name}'.`);
    }

    protected createArray(model: Ausstattung, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Ausstattung.fields;
        switch (arrayName) {
            case fields.Gruppen.name:
                const gruppen = model.gruppen || PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL;
                return new ViewFormArray(
                    gruppen.map((gruppe) =>
                        this.produktDetailAusstattungGruppeFormViewFactory.create(gruppe, arrayFields),
                    ),
                );
            case fields.Individualaufbauten.name:
                const individualaufbauten = model.individualaufbauten || [];
                return new ViewFormArray(
                    individualaufbauten.map((individualaufbaute) =>
                        this.produktDetailTextbausteinFormViewFactory.create(individualaufbaute, arrayFields),
                    ),
                );
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
