<ng-container *ngIf="!saving; else spinner">
    <div class="settings-container">
        <h4>{{ name + '.druckprodukt.title' | translate }}</h4>
        <div class="setting">
            <label>{{ name + '.' + 'stempel' | translate }}</label>
            <app-inline-image
                [form]="form"
                [size]="1024 * maxFileUploadSizeInKb"
                [displayName]="name + '.stempel'"
                name="stempel"
                isEinstellungen="true"
            />
        </div>
        <div class="setting">
            <label>{{ name + '.' + 'unterschrift' | translate }}</label>
            <app-inline-image
                [form]="form"
                [size]="1024 * maxFileUploadSizeInKb"
                [displayName]="name + '.unterschrift'"
                name="unterschrift"
                isEinstellungen="true"
            />
        </div>
        <div class="setting">
            <ng-container [formGroup]="form">
                <gtuema-max-character-textfield
                    class="drucktext"
                    [labelText]="name + '.sachverstaendigerText' | translate"
                    [formControlName]="'sachverstaendigerText'"
                    [inputHeight]="'10vh'"
                    name="sachverstaendigerText"
                    maxLength="500"
                />
            </ng-container>
        </div>

        <app-slide-toggle
            class="setting"
            [form]="form"
            [displayName]="name + '.watermarkEnable'"
            name="watermarkEnable"
        />
    </div>

    <div class="settings-container">
        <h4>{{ name + '.fileSync.title' | translate }}</h4>
        <app-slide-toggle [form]="form" [displayName]="name + '.directFileSync'" name="directFileSync" />
        <!-- <app-slide-toggle [form]="form" name="wifiOnlyFileSync" [displayName]="name + '.wifiOnlyFileSync'"></app-slide-toggle> -->
        <h4>{{ name + '.deactivate3dModel.title' | translate }}</h4>
        <app-slide-toggle [form]="form" [displayName]="name + '.deactivate3dModel.slider'" name="deactivate3dModel" />
    </div>

    <div class="settings-container">
        <h4>{{ name + '.customTexts.title' | translate }}</h4>
        <app-input
            [readonly]="false"
            [form]="form"
            [displayName]="name + '.customTextBewertung'"
            name="customTextBewertung"
            type="text"
            inputmode="text"
        />
        <app-textarea
            [readonly]="false"
            [form]="form"
            [displayName]="name + '.customDescriptionBewertung'"
            name="customDescriptionBewertung"
        />
        <app-input
            [readonly]="false"
            [form]="form"
            [displayName]="name + '.customTextRuecknahmebewertung'"
            name="customTextRuecknahmebewertung"
            type="text"
            inputmode="text"
        />
        <app-textarea
            [readonly]="false"
            [form]="form"
            [displayName]="name + '.customDescriptionRuecknahmebewertung'"
            name="customDescriptionRuecknahmebewertung"
        />
        <app-input
            [readonly]="false"
            [form]="form"
            [displayName]="name + '.customTextZustandsbericht'"
            name="customTextZustandsbericht"
            type="text"
            inputmode="text"
        />
        <app-textarea
            [readonly]="false"
            [form]="form"
            [displayName]="name + '.customDescriptionZustandsbericht'"
            name="customDescriptionZustandsbericht"
        />
    </div>

    <div class="settings-container">
        <h4>{{ name + '.dat.benutzer.title' | translate }}</h4>
        <app-input
            id="datCustomerNumberBenutzer"
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datCustomerNumber'"
            name="datCustomerNumber"
            type="text"
            inputmode="text"
        />
        <app-input
            id="datCustomerLoginBenutzer"
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datCustomerLogin'"
            name="datCustomerLogin"
            type="text"
            inputmode="text"
        />
        <app-input
            id="datInterfacePartnerNumberBenutzer"
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datInterfacePartnerNumber'"
            name="datInterfacePartnerNumber"
            type="text"
            inputmode="text"
        />
        <app-input
            id="datProductVariantBenutzer"
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datProductVariant'"
            name="datProductVariant"
            type="text"
            inputmode="text"
        />
        <app-input
            id="datInterfacePartnerSignatureBenutzer"
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datInterfacePartnerSignature'"
            name="datInterfacePartnerSignature"
            type="text"
            inputmode="text"
        />
        <app-input
            id="datCustomerPassword"
            [readonly]="true"
            [form]="form"
            [displayName]="name + '.datCustomerPassword'"
            name="datCustomerPassword"
            type="password"
            inputmode="text"
        />
    </div>
</ng-container>

<ng-template #spinner>
    <app-progress-spinner />
</ng-template>
<div align="end">
    <app-button [type]="ButtonType.PRIMARY" [disabled]="!form.valid || saving" (action)="form.valid && onSave()">
        {{ name + '.save' | translate }}
    </app-button>
</div>
