import { Injectable } from '@angular/core';
import { RadLagerort } from '@data/domain/schema/enum';
import { NfzRadsatz } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailNfzRaederRadsatzAchseFormViewFactory } from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-achse-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailNfzRaederRadsatzFormViewFactory extends FormViewModelBaseFactory<NfzRadsatz> {
    constructor(
        private readonly nfzRaederRadsatzAchseFormViewFactory: ProduktDetailNfzRaederRadsatzAchseFormViewFactory,
    ) {
        super();
        Assert.notNullOrUndefined(nfzRaederRadsatzAchseFormViewFactory, 'nfzRaederRadsatzAchseSeiteFormViewFactor');
    }

    protected createField(model: NfzRadsatz, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields;
        if (name === fields.Lagerort.name) {
            return new ViewFormControl(model.lagerort || RadLagerort.Montiert);
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: NfzRadsatz, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields;
        if (arrayName === fields.Achsen.name) {
            const achsen = model.achsen || [];
            return new ViewFormArray(
                achsen.map((achse) => this.nfzRaederRadsatzAchseFormViewFactory.create(achse, arrayFields)),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
