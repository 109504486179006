import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { VorschadenArt, VorschadenReparatur, VorschadenUntergruppe } from '@data/domain/schema/enum';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { VORSCHADEN_GRUPPEN } from '@modules/produkt/config/produkt-vorschaden-gruppen.config';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { Assert } from '@shared/helper/assert';
import { EnumValues, ObjectValues } from '@shared/helper/values';
import { ViewportService } from '@shared/service/viewport.service';
import { ProduktDetailSchadenPositionComponentBase } from '../base/produkt-detail-schaden-position-component-base';

@Component({
    selector: 'app-produkt-detail-vorschaden-position',
    templateUrl: './produkt-detail-vorschaden-position.component.html',
    styleUrls: ['./produkt-detail-vorschaden-position.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailVorschadenPositionComponent
    extends ProduktDetailSchadenPositionComponentBase
    implements OnInit {
    now = new Date();

    beschaedigungart = new ObjectValues(VorschadenArt);
    beschaedigungarten = ['Nachlackierung', 'Unfall', 'Hagelschaden', 'Sonstige'];

    vorschadenReparatur = new ObjectValues(VorschadenReparatur);
    vorschadenReparaturen = ['SachUndFachgerechtRepariert', 'Teilrepariert', 'UnsachgemaessRepariert', 'Sonstige'];

    protected obergruppe = new EnumValues(VORSCHADEN_GRUPPEN);
    protected untergruppe = new ObjectValues(VorschadenUntergruppe);

    constructor(fileFieldService: ProduktDetailFileFieldService, viewportService: ViewportService) {
        Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');

        super(fileFieldService, viewportService);
    }

    ngOnInit(): void {
        this.fieldConfig = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.fields;
        this.requiredFields = [
            this.fieldConfig.Obergruppe.name,
            this.fieldConfig.Untergruppe.name,
            this.fieldConfig.Reparatur.name,
        ];
        super.ngOnInit();
    }
}
