import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveRestwertprognose, SaveRestwertprognoseData } from '../../graphql/mutations';
import { Produkt, Restwertprognose, RestwertprognoseInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktRestwertprognoseService extends ProduktFeatureService<Restwertprognose, RestwertprognoseInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Restwertprognose): RestwertprognoseInput {
        const input: RestwertprognoseInput = {
            ...feature,
            id: produktId,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveRestwertprognose;
    }

    protected getSaveVariables(input: RestwertprognoseInput): any {
        return {
            restwertprognose: input,
        };
    }

    protected getSaveOptimisticResponse(input: RestwertprognoseInput): any {
        return {
            saveRestwertprognose: {
                ...input,
                __typename: 'Restwertprognose',
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveRestwertprognoseData>): Restwertprognose {
        return response.data.saveRestwertprognose;
    }

    protected update(produkt: Produkt, feature: Restwertprognose): void {
        produkt.restwertprognose = {
            ...produkt.restwertprognose,
            ...feature,
        };
    }
}
