import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProduktDetailFeststellungenKalkulationErrorCanActivate } from '@modules/produkt/guard/feststellungen/error/produkt-detail-feststellungen-kalkulation-error.can-activate';
import { ProduktDetailFeststellungenKalkulationCanActivate } from '@modules/produkt/guard/feststellungen/produkt-detail-feststellungen-kalkulation.can-activate';
import { ProduktDetailKalkulationErrorCanActivate } from '@modules/produkt/guard/kalkulation/error/produkt-detail-kalkulation-error.can-activate';
import { ProduktDetailKalkulationCanActivate } from '@modules/produkt/guard/kalkulation/produkt-detail-kalkulation.can-activate';
import { ProduktAuftragCanActivate } from '@modules/produkt/guard/produkt-auftrag.can-activate';
import { ProduktDetailSchadenKalkulationErrorCanActivate } from '@modules/produkt/guard/schaden/error/produkt-detail-schaden-kalkulation-error.can-activate';
import { ProduktDetailSchadenKalkulationCanActivate } from '@modules/produkt/guard/schaden/produkt-detail-schaden-kalkulation.can-activate';
import { ProduktDetailAcAbschlussComponent } from '@modules/produkt/page/produkt-detail-ac-abschluss/produkt-detail-ac-abschluss.component';
import { ProduktDetailCgAbschlussComponent } from '@modules/produkt/page/produkt-detail-cg-abschluss/produkt-detail-cg-abschluss.component';
import { ProduktDetailCgFeststellungComponent } from '@modules/produkt/page/produkt-detail-cg-feststellung/produkt-detail-cg-feststellung.component';
import { ProduktDetailFeststellungenComponent } from '@modules/produkt/page/produkt-detail-feststellungen/produkt-detail-feststellungen.component';
import { ProduktDetailKalkulationComponent } from '@modules/produkt/page/produkt-detail-kalkulation/produkt-detail-kalkulation.component';
import { ProduktDetailNfzRaederComponent } from '@modules/produkt/page/produkt-detail-nfz-raeder/produkt-detail-nfz-raeder.component';
import { ProduktDetailVtiAbschlussComponent } from '@modules/produkt/page/produkt-detail-vti-abschluss/produkt-detail-vti-abschluss.component';
import { ProduktDetailVtiAuftragComponent } from '@modules/produkt/page/produkt-detail-vti-auftrag/produkt-detail-vti-auftrag.component';
import { ProduktUebersichtGtueEmComponent } from '@modules/produkt/page/produkt-uebersicht-gtue-em/produkt-uebersicht-gtue-em.component';
import { ProduktUebersichtGtueModulComponent } from '@modules/produkt/page/produkt-uebersicht-gtue-modul/produkt-uebersicht-gtue-modul.component';
import { ProduktUebersichtAcResolve } from '@modules/produkt/resolve/produkt-uebersicht-ac.resolve';
import { ProduktUebersichtCpoResolve } from '@modules/produkt/resolve/produkt-uebersicht-cpo.resolve';
import {
    PRODUKT_FESTSTELLUNGEN_REQUIRED,
    PRODUKT_KALKULATION_REQUIRED,
    PRODUKT_LACKMESSUNG_REQUIRED,
    PRODUKT_RAEDER_REQUIRED,
    PRODUKT_RESTWERTPROGNOSE_REQUIRED,
    PRODUKT_SCHADEN_REQUIRED,
    PRODUKT_UEBERSICHT_REQUIRED,
    PRODUKT_VORSCHADEN_REQUIRED,
    PRODUKT_WERTE_REQUIRED,
} from './config/produkt-feature-required-config';
import { ProduktDetailFeatureCanDeactivate } from './guard/produkt-detail-feature.can-deactivate';
import { ProduktDetailAbschlussComponent } from './page/produkt-detail-abschluss/produkt-detail-abschluss.component';
import { ProduktDetailAdressenComponent } from './page/produkt-detail-adressen/produkt-detail-adressen.component';
import { ProduktDetailAuftragComponent } from './page/produkt-detail-auftrag/produkt-detail-auftrag.component';
import { ProduktDetailAusstattungComponent } from './page/produkt-detail-ausstattung/produkt-detail-ausstattung.component';
import { ProduktDetailFahrzeugComponent } from './page/produkt-detail-fahrzeug/produkt-detail-fahrzeug.component';
import { ProduktDetailFehlteileComponent } from './page/produkt-detail-fehlteile/produkt-detail-fehlteile.component';
import { ProduktDetailLackmessungComponent } from './page/produkt-detail-lackmessung/produkt-detail-lackmessung.component';
import { ProduktDetailRaederComponent } from './page/produkt-detail-raeder/produkt-detail-raeder.component';
import { ProduktDetailRestwertprognoseComponent } from './page/produkt-detail-restwertprognose/produkt-detail-restwertprognose.component';
import { ProduktDetailSchadenComponent } from './page/produkt-detail-schaden/produkt-detail-schaden.component';
import { ProduktDetailUebersichtComponent } from './page/produkt-detail-uebersicht/produkt-detail-uebersicht.component';
import { ProduktDetailUnterlagenComponent } from './page/produkt-detail-unterlagen/produkt-detail-unterlagen.component';
import { ProduktDetailVorschadenComponent } from './page/produkt-detail-vorschaden/produkt-detail-vorschaden.component';
import { ProduktDetailVtiWerteComponent } from './page/produkt-detail-vti-werte/produkt-detail-vti-werte.component';
import { ProduktDetailWartungComponent } from './page/produkt-detail-wartung/produkt-detail-wartung.component';
import { ProduktDetailWerteComponent } from './page/produkt-detail-werte/produkt-detail-werte.component';
import { ProduktDetailComponent } from './page/produkt-detail/produkt-detail.component';
import { ProduktErstellenComponent } from './page/produkt-erstellen/produkt-erstellen.component';
import { ProduktUebersichtAcComponent } from './page/produkt-uebersicht-ac/produkt-uebersicht-ac.component';
import { ProduktUebersichtComponent } from './page/produkt-uebersicht/produkt-uebersicht.component';
import { ProduktConfigResolve } from './resolve/produkt-config.resolve';
import { ProduktDetailResolve } from './resolve/produkt-detail.resolve';
import { ProduktErstellenResolve } from './resolve/produkt-erstellen.resolve';
import { ProduktUebersichtResolve } from './resolve/produkt-uebersicht.resolve';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'erstellen',
        component: ProduktErstellenComponent,
        data: {
            backUrl: '/',
        },
        children: [
            {
                path: ':art',
                children: [],
                resolve: {
                    produkt: ProduktErstellenResolve,
                },
            },
        ],
    },
    {
        path: 'uebersicht',
        component: ProduktUebersichtComponent,
        data: {
            backUrl: '/',
        },
        children: [
            {
                path: 'gtue-em',
                component: ProduktUebersichtGtueEmComponent,
                resolve: {
                    produkte: ProduktUebersichtResolve,
                },
            },
            {
                path: 'gtue-modul',
                component: ProduktUebersichtGtueModulComponent,
                resolve: {
                    cpoVorgaenge: ProduktUebersichtCpoResolve,
                },
            },
        ],
    },
    {
        path: 'uebersicht-ac',
        component: ProduktUebersichtAcComponent,
        resolve: {
            vorgaenge: ProduktUebersichtAcResolve,
        },
        data: {
            backUrl: '/',
        },
    },
    {
        path: 'detail/:id',
        component: ProduktDetailComponent,
        resolve: {
            config: ProduktConfigResolve,
            produkt: ProduktDetailResolve,
        },
        data: {
            backUrl: '/produkt/uebersicht/gtue-em',
        },
        children: [
            {
                path: 'auftrag',
                component: ProduktDetailAuftragComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canActivate: [ProduktAuftragCanActivate],
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'vtiAuftrag',
                component: ProduktDetailVtiAuftragComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'fahrzeug',
                component: ProduktDetailFahrzeugComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'uebersicht',
                component: ProduktDetailUebersichtComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_UEBERSICHT_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'raeder',
                component: ProduktDetailRaederComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_RAEDER_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'nfzRaeder',
                component: ProduktDetailNfzRaederComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'vorschaden',
                component: ProduktDetailVorschadenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_VORSCHADEN_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'lackmessung',
                component: ProduktDetailLackmessungComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_LACKMESSUNG_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'schaden',
                component: ProduktDetailSchadenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_SCHADEN_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'schaden/kalkulation/:dossierId',
                component: ProduktDetailSchadenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_SCHADEN_REQUIRED,
                },
                canActivate: [ProduktDetailSchadenKalkulationCanActivate],
            },
            {
                path: 'schaden/kalkulation/error',
                component: ProduktDetailSchadenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_SCHADEN_REQUIRED,
                },
                canActivate: [ProduktDetailSchadenKalkulationErrorCanActivate],
            },
            {
                path: 'cgFeststellung',
                component: ProduktDetailCgFeststellungComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                // data: {
                //   required: PRODUKT_FESTSTELLUNGEN_REQUIRED
                // },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'feststellungen',
                component: ProduktDetailFeststellungenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_FESTSTELLUNGEN_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'feststellungen/kalkulation/:dossierId',
                component: ProduktDetailFeststellungenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_FESTSTELLUNGEN_REQUIRED,
                },
                canActivate: [ProduktDetailFeststellungenKalkulationCanActivate],
            },
            {
                path: 'feststellungen/kalkulation/error',
                component: ProduktDetailFeststellungenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_FESTSTELLUNGEN_REQUIRED,
                },
                canActivate: [ProduktDetailFeststellungenKalkulationErrorCanActivate],
            },
            {
                path: 'ausstattung',
                component: ProduktDetailAusstattungComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'unterlagen',
                component: ProduktDetailUnterlagenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'wartung',
                component: ProduktDetailWartungComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'werte',
                component: ProduktDetailWerteComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_WERTE_REQUIRED,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'fehlteile',
                component: ProduktDetailFehlteileComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'adressen',
                component: ProduktDetailAdressenComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                canDeactivate: [ProduktDetailFeatureCanDeactivate],
            },
            {
                path: 'vtiWerte',
                component: ProduktDetailVtiWerteComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_WERTE_REQUIRED,
                },
                // canDeactivate: [ProduktDetailFeatureCanDeactivate]
            },
            {
                path: 'restwertprognose',
                component: ProduktDetailRestwertprognoseComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_RESTWERTPROGNOSE_REQUIRED,
                },
                // canDeactivate: [ProduktDetailFeatureCanDeactivate]
            },
            {
                path: 'kalkulation',
                component: ProduktDetailKalkulationComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                // canDeactivate: [ProduktDetailFeatureCanDeactivate]
            },
            {
                path: 'kalkulation/kalkulation/:dossierId',
                component: ProduktDetailKalkulationComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_KALKULATION_REQUIRED,
                },
                canActivate: [ProduktDetailKalkulationCanActivate],
            },
            {
                path: 'kalkulation/kalkulation/error',
                component: ProduktDetailKalkulationComponent,
                resolve: {
                    produkt: ProduktDetailResolve,
                },
                data: {
                    required: PRODUKT_KALKULATION_REQUIRED,
                },
                canActivate: [ProduktDetailKalkulationErrorCanActivate],
            },
            {
                path: 'vtiAbschluss',
                component: ProduktDetailVtiAbschlussComponent,
                data: {
                    ignoreCache: true,
                },
                resolve: {
                    produkt: ProduktDetailResolve,
                },
            },
            {
                path: 'cgAbschluss',
                component: ProduktDetailCgAbschlussComponent,
                data: {
                    ignoreCache: true,
                },
                resolve: {
                    produkt: ProduktDetailResolve,
                },
            },
            {
                path: 'acAbschluss',
                component: ProduktDetailAcAbschlussComponent,
                data: {
                    ignoreCache: true,
                },
                resolve: {
                    produkt: ProduktDetailResolve,
                },
            },
            {
                path: 'abschluss',
                component: ProduktDetailAbschlussComponent,
                data: {
                    ignoreCache: true,
                },
                resolve: {
                    produkt: ProduktDetailResolve,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProduktRoutingModule {}
