import { Injectable } from '@angular/core';
import { VtiWerte } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailVtiWerteFormViewFactory extends FormViewModelBaseFactory<VtiWerte> {
    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly currencyFormatterService: CurrencyFormatterService,
    ) {
        super();
        Assert.notNullOrUndefined(currencyFormatterService, 'currencyFormatterService');
    }

    protected createField(model: VtiWerte, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.VtiWerte.fields;
        switch (name) {
            case fields.Bezugsdatum.name:
                return new ViewFormControl(model.bezugsdatum);
            case fields.Haendlereinkaufswert.name:
                return new ViewFormControl(model.haendlereinkaufswert, {
                    formatter: this.currencyFormatterService,
                });
            case fields.HaendlereinkaufswertDrucken.name:
                return new ViewFormControl(!!model.haendlereinkaufswertDrucken);
            case fields.HaendlereinkaufswertNetto.name:
                return new ViewFormControl(model.haendlereinkaufswertNetto, {
                    formatter: this.currencyFormatterService,
                });
            case fields.HaendlereinkaufswertNettoDrucken.name:
                return new ViewFormControl(!!model.haendlereinkaufswertNettoDrucken);
            case fields.Haendlerverkaufswert.name:
                return new ViewFormControl(model.haendlerverkaufswert, {
                    formatter: this.currencyFormatterService,
                });
            case fields.HaendlerverkaufswertDrucken.name:
                return new ViewFormControl(!!model.haendlerverkaufswertDrucken);
            case fields.HaendlerverkaufswertNetto.name:
                return new ViewFormControl(model.haendlerverkaufswertNetto, {
                    formatter: this.currencyFormatterService,
                });
            case fields.HaendlerverkaufswertBesteuerung.name:
                return new ViewFormControl(model.haendlerverkaufswertBesteuerung);
            case fields.HaendlerverkaufswertNettoDrucken.name:
                return new ViewFormControl(!!model.haendlerverkaufswertNettoDrucken);
            case fields.WertAmMarkt.name:
                return new ViewFormControl(model.wertAmMarkt, {
                    formatter: this.currencyFormatterService,
                });
            case fields.Neuwert.name:
                return new ViewFormControl(model.neuwert, {
                    formatter: this.currencyFormatterService,
                });
            case fields.NeuwertDrucken.name:
                return new ViewFormControl(!!model.neuwertDrucken);
            case fields.RelativerWert.name:
                return new ViewFormControl(model.relativerWert, {
                    formatter: this.currencyFormatterService,
                });
            case fields.RelativerWertDrucken.name:
                return new ViewFormControl(!!model.relativerWertDrucken);
            case fields.ExternalServicesReferenceId.name:
                return new ViewFormControl(model.externalServicesReferenceId);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: VtiWerte, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.VtiWerte.fields;
        if (arrayName === fields.WertAmMarktBemerkungen.name) {
            const wertAmMarktBemerkungen = model.wertAmMarktBemerkungen || [];
            return new ViewFormArray(
                wertAmMarktBemerkungen.map((wertAmMarktBemerkung) =>
                    this.produktDetailTextbausteinFormViewFactory.create(wertAmMarktBemerkung, arrayFields),
                ),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
