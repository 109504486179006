<ng-container [formGroup]="form">
    <div
        class="date-component-wrapper gtue-information"
        [ngClass]="
            (control.touchedChanges | async) && !(control.validChanges | async) && control.hasError('required')
                ? 'error'
                : ''
        "
        [class.mat-form-field-appearance-legacy]="readonly"
        [class.mat-form-field-disabled]="readonly"
    >
        <h4 class="mat-caption text-field-label">{{ (displayName | translate) + (required ? ' *' : '') }}</h4>
        <mat-form-field appearance="fill" floatLabel="never">
            <input
                [id]="name"
                [formControlName]="name"
                [name]="name"
                [matDatepicker]="datepicker"
                [class.disabled]="disabled"
                [readonly]="readonly"
                [max]="max"
                [min]="min"
                [value]="value"
                (focusin)="focusInputField.emit($event)"
                (dateChange)="dateChange.emit($event.value)"
                matInput
                onFocus="this.select()"
                autocomplete="off"
            />
            <mat-datepicker-toggle *ngIf="hasDatepicker" [for]="datepicker" matSuffix />
            <mat-datepicker
                #datepicker
                (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, datepicker)"
                startView="{{ startView }}"
            />
            <span matSuffix>
                <ng-content select="[appSuffix]" />
            </span>
        </mat-form-field>
        <mat-error>
            {{ 'control.required' | translate: { name: (displayName | translate) } }}
        </mat-error>
    </div>
</ng-container>
