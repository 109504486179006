import { Injectable } from '@angular/core';
import { Vorschaden } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailVorschadenPositionFormViewFactory } from './produkt-detail-vorschaden-position-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailVorschadenFormViewFactory extends FormViewModelBaseFactory<Vorschaden> {
    constructor(private readonly vorschadenPositionFormViewFactory: ProduktDetailVorschadenPositionFormViewFactory) {
        super();
        Assert.notNullOrUndefined(vorschadenPositionFormViewFactory, 'vorschadenPositionFormViewFactory');
    }

    protected createField(_: Vorschaden, name: string): AbstractViewFormControl {
        throw new Error(`Could not create field for name: '${name}'.`);
    }

    protected createArray(model: Vorschaden, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Vorschaden.fields;
        if (arrayName === fields.Positionen.name) {
            const positionen = model.positionen || [];
            return new ViewFormArray(
                positionen.map((position) => this.vorschadenPositionFormViewFactory.create(position, arrayFields)),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
