import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(private readonly authService: AuthService) {
        Assert.notNullOrUndefined(authService, 'authService');
    }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isLoggedIn().pipe(
            map((result) => {
                if (result) {
                    return true;
                }

                this.authService.startSignin();
                return false;
            }),
        );
    }
}
