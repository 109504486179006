import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ProduktDetailFeatureDialogComponent,
    ProduktDetailFeatureDialogData,
} from '../component/produkt-detail-feature-dialog/produkt-detail-feature-dialog.component';
import { ProduktFeatureFieldsMap } from '../config/produkt-config';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailFeatureDialogService {
    constructor(private readonly templateDialog: TemplateDialogService) {
        Assert.notNullOrUndefined(templateDialog, 'templateDialog');
    }

    open(title: string, required: ProduktFeatureFieldsMap): Observable<boolean> {
        Assert.notNullOrEmpty(title, 'title');
        Assert.notNullOrUndefined(required, 'required');

        const data: ProduktDetailFeatureDialogData = {
            required,
        };
        const buttons = [this.templateDialog.getCancelButtonSetting(), this.templateDialog.getSaveButtonSetting()];
        return this.templateDialog
            .openTemplate(title, buttons, ProduktDetailFeatureDialogComponent, data, true)
            .pipe(map((result) => result.name === this.templateDialog.getSaveButtonSetting().title));
    }
}
