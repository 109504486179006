<app-card spacing="8px">
    <h3>{{ name + '.dateien' | translate }}</h3>
    <app-file-gallery
        [fileIds]="dateien$ | async"
        [dialogTitle]="name + '.addfile'"
        [showCamera]="true"
        [showFolder]="true"
        (add)="onFileAdd($event)"
        (delete)="onFileDelete($event)"
    />
</app-card>
<app-card spacing="8px">
    <h3>{{ name + '.sprachnachrichten' | translate }}</h3>
    <app-audio-gallery
        [files]="sprachnachrichten$ | async"
        (add)="onAudioAdd($event)"
        (delete)="onAudioDelete($event)"
        (error)="onAudioError()"
    />
</app-card>
<app-card spacing="8px">
    <h3>{{ name + '.textnachrichten' | translate }}</h3>
    <app-chat [messages]="textnachrichten$ | async" (add)="onChatAdd($event)" (delete)="onChatDelete($event)" />
</app-card>
