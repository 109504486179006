<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name || field.arrayName">
        <!-- Dicke -->
        <ng-container *ngSwitchCase="'dicke'">
            <app-input
                [form]="form"
                [name]="field.name"
                [displayName]="name + '.' + field.name"
                type="number"
                inputmode="numeric"
            >
                <ng-container appSuffix>&nbsp;µm</ng-container>
            </app-input>
        </ng-container>

        <!-- Ergebnis -->
        <ng-container *ngSwitchCase="'ergebnis'">
            <app-extendable-list
                [form]="form"
                [name]="field.name"
                [displayName]="name + '.' + field.name"
                [values]="[]"
                [property]="null"
                [inputmode]="'decimal'"
                (add)="onErgebnisAdd($event)"
                (remove)="onErgebnisRemove($event)"
                property="bezeichnung"
            />
        </ng-container>

        <!-- Beschreibung -->
        <ng-container *ngSwitchCase="'beschreibung'">
            <app-textarea [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" />
        </ng-container>

        <!-- Bemerkungen -->
        <ng-container *ngSwitchCase="'bemerkungen'">
            <app-textbausteine
                #bemerkungen
                [form]="form"
                [name]="field.arrayName"
                [displayName]="'Bemerkungen'"
                [feature]="'Lackmessung'"
                [produktArt]="this.produkt.art"
            />
        </ng-container>

        <!-- Bilder -->
        <ng-container *ngSwitchCase="'bilder'">
            <label>{{ name + '.bilder' | translate }}</label>
            <ng-container *ngIf="form.get('bilder') as control">
                <ng-container *ngIf="(control.valueChanges | async) || control.value as files">
                    <app-file-gallery
                        [fileIds]="files"
                        [dialogTitle]="name + '.addfile'"
                        [showCamera]="true"
                        [showFolder]="true"
                        [resetable]="isFileResetable.bind(this)"
                        (add)="onFileAdd(control, $event)"
                        (delete)="onFileDelete(control, $event)"
                        (update)="onFileUpdate(control, $event)"
                        (reset)="onFileReset(control, $event)"
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
