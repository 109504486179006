import { ChangeDetectionStrategy, Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { File } from '@app/class/file';
import { FileSize } from '@app/enum/file-size.enum';
import { FileService } from '@app/service/file.service';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, take } from 'rxjs';

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioComponent {
    private _fileId: string;

    name$ = new BehaviorSubject<string>(null);
    audioSrc$ = new BehaviorSubject<SafeUrl>(null);
    loaded$ = new BehaviorSubject<boolean>(false);

    @Input()
    set fileId(fileId: string) {
        this._fileId = fileId;
        setTimeout(() => {
            this.loadFile();
        }, 1);
    }

    constructor(
        private readonly fileService: FileService,
        private readonly domSanitizer: DomSanitizer,
    ) {
        Assert.notNullOrUndefined(fileService, 'fileService');
        Assert.notNullOrUndefined(domSanitizer, 'domSanitizer');
    }

    onReloadClick(): void {
        this.loadFile();
    }

    onAudioLoad(audioSrc: string): void {
        const objectUrl = this.domSanitizer.sanitize(SecurityContext.URL, audioSrc);
        URL.revokeObjectURL(objectUrl);
    }

    private loadFile(): void {
        this.fileService
            .get(this._fileId, FileSize.None)
            .pipe(take(1))
            .subscribe({
                next: (file) => this.onFileLoaded(file),
                error: () => this.onFileLoaded(null),
            });
    }

    private onFileLoaded(file: File): void {
        this.loaded$.next(!!file);
        if (file) {
            this.name$.next(file.name);
            const objectUrl = URL.createObjectURL(new Blob([file.data]));
            this.audioSrc$.next(this.domSanitizer.bypassSecurityTrustUrl(objectUrl));
        }
    }
}
