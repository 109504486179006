import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TrackBy } from '@shared/helper/track-by';
import { Values } from '@shared/helper/values';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent {
    trackByKey = TrackBy.trackByKey;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    displayName: string;

    @Input()
    values: Values;

    @Input()
    valuePrefix = '';

    @Input()
    disabled: any = {};

    @Input()
    inline = true;
}
