<div class="uebersicht-header" *ngIf="!(loading$ | async)">
    <div class="search-field">
        <app-input
            #input
            [form]="form"
            [readonly]="false"
            [displayName]="'Suche'"
            [placeholder]="name + '.search' | translate"
            (keypress)="alphaNumericOnly($event)"
            (ngModelChange)="searchTerm = searchTerm.toUpperCase()"
            (keydown.enter)="onClickProduktSearch(form.get('searchTerm').value)"
            name="searchTerm"
            minLength="3"
            maxlength="17"
            type="text"
            inputmode="text"
        >
            <div class="icons" appSuffix>
                <app-mini-fab
                    [color]="'basic'"
                    [disabled]="form.get('searchTerm').value.length < 3"
                    (action)="onClickProduktSearch(form.get('searchTerm').value)"
                >
                    <app-mat-icon icon="search" />
                </app-mini-fab>
                <app-mini-fab
                    [color]="'basic'"
                    [disabled]="form.get('searchTerm').value.length === 0"
                    (action)="onResetIdentAndSearch()"
                >
                    <app-mat-icon icon="delete" />
                </app-mini-fab>
            </div>
        </app-input>
    </div>

    <div class="filter-container">
        <app-input
            #input
            [form]="form"
            [readonly]="false"
            [displayName]="name + '.filter' | translate"
            [placeholder]="name + '.filter' | translate"
            (keydown)="onFilter(form.get(filterName).value)"
            name="{{ filterName }}"
            type="text"
            inputmode="text"
        />
    </div>

    <app-button-group
        class="button-group"
        [form]="form"
        [name]="'status'"
        [displayName]="name + '.status' | translate"
        [selections]="selections"
        [default]="'alle'"
        (change)="onFilterStatus($event)"
    />
</div>

<ng-container *ngIf="!(loading$ | async); else loading">
    <ng-container *ngIf="(vorgaengeFiltered | async).length > 0; else emptyList">
        <app-card-list>
            <app-card-list-item
                *ngFor="let vorgang of vorgaengeFiltered | async | slice: lowValue : highValue; trackBy: trackById"
            >
                <h4 title>{{ vorgang.fahrzeug.identnummer }}</h4>
                <h4 subtitle>{{ vorgang.fahrzeug.kennzeichen }}</h4>
                <div class="date" date>{{ vorgang.acMetaInformation.xmlErstelldatum | date: 'short' }}</div>

                <app-bagde
                    *ngIf="vorgang.status === status.Offen"
                    [icon]="'icon-pencil'"
                    [color]="'orange'"
                    [label]="name + '.status.open' | translate"
                    status
                />
                <app-bagde
                    *ngIf="vorgang.status !== status.Offen && !vorgang.acAbschluss?.acVersendet"
                    [icon]="'icon-pencil'"
                    [color]="'grey'"
                    [label]="name + '.status.edit' | translate"
                    status
                />
                <app-bagde
                    *ngIf="vorgang.status !== status.Offen && vorgang.acAbschluss?.acVersendet"
                    [icon]="'icon-check'"
                    [color]="'green'"
                    [label]="name + '.status.done' | translate"
                    status
                />
                <mat-card-content class="card-content">
                    <span *ngIf="vorgang.fahrzeug.hersteller">
                        <strong>{{ vorgang.fahrzeug.hersteller }}</strong>
                        {{ vorgang.fahrzeug.modell }}
                    </span>
                    <br *ngIf="vorgang.fahrzeug.hersteller" />
                    <span *ngIf="vorgang.fahrzeug.erstzulassung || vorgang.fahrzeug.laufleistung">
                        <ng-container *ngIf="vorgang.fahrzeug.erstzulassung">
                            {{ vorgang.fahrzeug.erstzulassung | date }} |
                        </ng-container>
                        <strong *ngIf="vorgang.fahrzeug.laufleistung">{{ vorgang.fahrzeug.laufleistung }} km</strong>
                    </span>
                </mat-card-content>

                <mat-card-actions>
                    <ng-container *ngIf="vorgang.status === status.Offen">
                        <app-button [type]="ButtonType.SECONDARY" (action)="this.onOpenProdukt(vorgang)">
                            {{ name + '.button.edit' | translate }}
                        </app-button>
                    </ng-container>

                    <ng-container *ngIf="vorgang.status !== status.Offen">
                        <app-button [type]="ButtonType.SECONDARY" (action)="this.onOpenProdukt(vorgang)">
                            {{ name + '.button.open' | translate }}
                        </app-button>
                        <app-button [type]="ButtonType.SECONDARY" (action)="onDownloadClick(vorgang)">
                            {{ name + '.button.pdf' | translate }}
                        </app-button>
                    </ng-container>
                </mat-card-actions>
            </app-card-list-item>
        </app-card-list>
        <mat-paginator
            class="paginator"
            #paginator
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="true"
            [length]="totalSize"
            [pageIndex]="currentPage"
            (page)="handlePage($event)"
        />
    </ng-container>
</ng-container>
<!-- Confirm dialog -->
<ng-template #dialogConfirmDuplicate let-data="data">
    <mat-label class="dialog-text">{{ name + '.duplicate.text' | translate }}</mat-label>
    <app-produkt-detail-nachbewertung-dialog #nachbewertungArtSelection [defaultSelection]="data.produkt.art" />
</ng-template>

<ng-template #loading>
    <app-progress-spinner class="spinner" [diameter]="150" />
</ng-template>

<ng-template #emptyList>
    <h3 class="empty-list">Keine Vorgänge unter den eingestellten Filterkriterien gefunden.</h3>
</ng-template>
