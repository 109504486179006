import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-divider',
    templateUrl: './divider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent {
    @Input()
    spacing = '0px';

    @Input()
    vertical = false;

    @HostBinding('style.display')
    get display(): string {
        return !this.vertical ? 'block' : 'inline-block';
    }
}
