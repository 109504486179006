<h3>{{ name + '.entwurf' | translate }}</h3>
<ng-container *ngIf="entwurfUrl$ | async as url; else entwurfErstellen">
    <p>{{ name + '.entwurfErstellt' | translate }}</p>
    <div align="end">
        <app-button (action)="onEntwurfOpen()">
            {{ name + '.oeffnen' | translate }}
        </app-button>
    </div>
</ng-container>
<ng-template #entwurfErstellen>
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <div *ngIf="field.name === 'adresse'">
            <ng-container [ngSwitch]="field.name">
                <app-select
                    [form]="entwurf"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [disabled]="(entwurfRequestActiveChange | async) === true"
                    [values]="entwurfAdressen"
                    (selectionChange)="onAdressenChange($event)"
                />
            </ng-container>
        </div>
    </ng-container>
    <div align="end">
        <app-button
            [disabled]="(entwurfRequestActiveChange | async) || !(adresseSelected$ | async)"
            [type]="ButtonType.SECONDARY"
            (action)="onEntwurf()"
        >
            <ng-container *ngIf="(entwurfRequestActiveChange | async) === true">
                <app-progress-spinner [diameter]="25" />
            </ng-container>
            <ng-container *ngIf="(entwurfRequestActiveChange | async) === false">
                {{ name + '.erstellen' | translate }}
            </ng-container>
        </app-button>
    </div>
</ng-template>

<ng-template #dialogDataSynchronization>
    <mat-label class="dialog-text">{{ name + '.workflow.synchronization.dialog.text' | translate }}</mat-label>
</ng-template>
