<ng-container [formGroup]="form">
    <div class="nachbewertung-dialog-wrapper">
        <h4 class="mat-caption text-field-label">{{ 'nachbewertung.produktart' | translate }}</h4>
        <mat-form-field [class.disabled]="disabled" floatLabel="never">
            <mat-select
                #matSelect
                [id]="'produktArt'"
                [placeholder]="'nachbewertung.produktart' | translate"
                disableRipple
                disableOptionCentering
                formControlName="produktArtControl"
                panelClass="mat-dropdown"
            >
                <mat-option *ngFor="let key of produktArtNachbewertungValues.keys" [value]="key">
                    {{ 'toolbar.' + produktArtNachbewertungValues.values[key].toLowerCase() | translate }}
                </mat-option>
            </mat-select>
            <mat-icon class="dropdown-icon" [ngClass]="matSelect.panelOpen ? 'icon-chevron-up' : 'icon-chevron-down'" />
        </mat-form-field>
    </div>
</ng-container>
