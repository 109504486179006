import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ProduktArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-produkt-detail-parent-form',
    templateUrl: './produkt-detail-parent-form.component.html',
    styleUrls: ['./produkt-detail-parent-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailParentFormComponent implements OnInit {
    @Input()
    idParent;

    loading$ = new BehaviorSubject<boolean>(false);
    viewport$: Observable<Viewport>;
    viewport = Viewport;
    parentProdukt: Produkt = null;
    produktArtNachbewertungValues = new EnumValues(ProduktArt);

    showOpenParentButton = false;

    constructor(
        private readonly produktService: ProduktService,
        private readonly viewportService: ViewportService,
    ) {
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(viewportService, 'viewPortService');
    }

    ngOnInit(): void {
        this.viewport$ = this.viewportService.observe();
        this.getParentProdukt();
    }

    onOpenParentProdukt() {
        if (!this.parentProdukt.id) {
            console.error('Cannot open parent produkt: parentProdukt.id is undefined.');
        }
        window.open(`/produkt/detail/${this.parentProdukt.id}`);
    }

    private getParentProdukt() {
        if (!this.idParent) {
            console.error('Cannot get parent produkt: idParent is undefined.');
            return;
        }
        if (this.idParent) {
            this.loading$.next(false);
            this.produktService
                .getById(this.idParent, false)
                .pipe(take(1))
                .subscribe(
                    (parent) => {
                        this.loading$.next(false);
                        this.parentProdukt = parent;
                    },
                    (err) => {
                        console.error(`Unable to get parent produkt: ${err}`);
                    },
                );
        }
    }
}
