<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'bezeichnung'">
            <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" />
        </ng-container>
        <ng-container *ngSwitchCase="'wert'">
            <app-input
                [form]="form"
                [name]="field.name"
                [displayName]="name + '.' + field.name"
                [onlyNumber]="true"
                type="text"
                inputmode="numeric"
            >
                <ng-container appSuffix>&nbsp;€</ng-container>
            </app-input>
        </ng-container>
    </ng-container>
</ng-container>
