import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';

@Directive()
export abstract class FormControlBase implements OnInit, OnDestroy {
    @Input()
    form: UntypedFormGroup;

    @Input()
    id: string;

    @Input()
    name: string;

    @Input()
    displayName: string;

    get control(): AbstractViewFormControl {
        return this.form.get(this.name) as AbstractViewFormControl;
    }

    constructor(private readonly formControlFocusService: FormControlFocusService) {
        Assert.notNullOrUndefined(formControlFocusService, 'formControlFocusService');
    }

    ngOnInit(): void {
        this.formControlFocusService.register(this);
    }

    ngOnDestroy(): void {
        this.formControlFocusService.unregister(this);
    }

    next(step = 1): void {
        this.formControlFocusService.next(this, step);
    }

    prev(): void {
        this.formControlFocusService.prev(this);
    }

    abstract blur(): void;
    abstract focus(): void;
}
