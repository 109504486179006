import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-toggle',
    template: '<ng-template #content>' + '<ng-content></ng-content>' + '</ng-template>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
    disabledChange = new BehaviorSubject<boolean>(false);

    @Input()
    value: number;

    @Input()
    set disabled(disabled: boolean) {
        this.disabledChange.next(disabled);
    }

    @ViewChild('content', { static: true })
    template: TemplateRef<any>;
}
