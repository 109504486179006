<app-icon-button class="close" tabindex="-1" mat-dialog-close>
    <mat-icon>close</mat-icon>
</app-icon-button>
<h1 class="title" *ngIf="settings.title" mat-dialog-title>
    {{ settings.title | translate }}
</h1>
<div class="content-wrapper" mat-dialog-content>
    <app-file [fileId]="settings.fileId" [thumb]="false" />
</div>
<div class="actions" *ngIf="settings.buttons" mat-dialog-actions>
    <app-button *ngFor="let button of settings.buttons" [type]="button.type" (action)="onAction(button.title)">
        {{ button.title | translate }}
    </app-button>
</div>
