import { guid } from '@app/function/guid';

export namespace TrackBy {
    export function trackByIndex(index: number): number {
        return index;
    }

    export function trackByKey(_index: number, key: string | number): string | number {
        return key;
    }

    export function trackByValue(
        _index: number,
        obj: {
            value: any;
        },
    ): any {
        return obj.value;
    }

    export function trackByName(
        _index: number,
        obj: {
            name: string;
        },
    ): any {
        return obj.name;
    }

    export function trackByLink(
        _index: number,
        obj: {
            link: string;
        },
    ): any {
        return obj.link;
    }

    export function trackByPath(
        _index: number,
        obj: {
            path: string;
        },
    ): any {
        return obj.path;
    }

    export function trackByInstance(
        _index: number,
        obj: {
            __uuid: string;
        },
    ): string {
        if (obj.__uuid === undefined) {
            obj.__uuid = guid();
        }
        return obj.__uuid;
    }
}
