import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { ExternalDataServiceResponseBase } from '../schema/external-data-service';

export interface WerteDto {
    bezugsdatum?: Date;
    roundValue?: number;
    haendlereinkaufswert?: number;
    haendlereinkaufswertNetto?: number;
    haendlerverkaufswert?: number;
    haendlerverkaufswertRegelbesteuertNetto?: number;
    haendlerverkaufswertNetto?: number;
    aufwendungen?: number;
    haendlereinkaufswertAbzuege?: number;
    haendlereinkaufswertAbzuegeNetto?: number;
    haendlerverkaufswertAbzuege?: number;
    haendlerverkaufswertAbzuegeNetto?: number;
    wertAmMarkt?: number;
    wertAmMarktBemerkung?: string;
    basiswert?: number;
    neuwert?: number;
    relativerWert?: number;
    werterhoehendesZubehoer?: number;
    werterhoehenderWartungszustand?: number;
    reparaturzustand?: number;
    zweiterRadsatzAnteilig?: number;
    externalServicesReferenceId?: string;
}

export interface WerteRequest {
    erstzulassung: string;
    laufleistung: number;
    specialEquipmentIds: string[];
    identifier: string;
    container: string;
    constructionTime: number;
    referenceId: string;
}

export interface WerteServiceResponse extends ExternalDataServiceResponseBase {
    werte?: WerteDto;
    message?: string;
}

@Injectable({
    providedIn: 'root',
})
export class WerteService {
    private readonly restApiUrl: string;

    constructor(private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/dat/werte`;
    }

    put(request: WerteRequest): Observable<WerteServiceResponse> {
        Assert.notNullOrUndefined(request, 'request');
        return this.httpClient.put<WerteServiceResponse>(this.restApiUrl, request);
    }

    post(request: WerteRequest): Observable<WerteServiceResponse> {
        Assert.notNullOrUndefined(request, 'request');
        return this.httpClient.post<WerteServiceResponse>(this.restApiUrl, request);
    }
}
