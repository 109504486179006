<ng-container *ngFor="let field of fields; trackBy: trackByField">
    <ng-container [ngSwitch]="field.name">
        <!-- Preis -->
        <ng-container *ngSwitchCase="'preis'">
            <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
        </ng-container>
    </ng-container>
</ng-container>

<!-- Templates -->
<ng-template #currency let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
</ng-template>
