import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { Values } from '@shared/helper/values';
import { BehaviorSubject, forkJoin, of } from 'rxjs';

@Component({
    selector: 'app-action-list',
    templateUrl: './action-list.component.html',
    styleUrls: ['./action-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListComponent implements OnInit {
    private keys: any[] = [];
    private _values: Values;

    trackByKey = TrackBy.trackByKey;

    keys$ = new BehaviorSubject<number[]>([]);
    keyValues: { [key: string]: any } = {};

    @Input()
    set values(values: Values) {
        Assert.notNullOrUndefined(values, 'values');
        if (this._values) {
            this._values = values;
            this.initKeys();
        } else {
            this._values = values;
        }
    }

    @Input()
    searchable = false;

    @Input()
    valuePrefix = '';

    @Input()
    disabled: any = {};

    @Output()
    action = new EventEmitter<number>();

    @Output()
    submit = new EventEmitter<string>();

    constructor(private readonly translationService: TranslateService) {
        Assert.notNullOrUndefined(translationService, 'translationService');
    }

    ngOnInit(): void {
        this.initKeys();
    }

    onItemClick(key: number): void {
        Assert.notNullOrUndefined(key, 'key');
        this.action.emit(key);
    }

    onFilterKeyUp(value?: string): void {
        this.filter(value);
    }

    onSubmit(input: HTMLInputElement): void {
        Assert.notNullOrUndefined(input, 'input');
        if (input.value.length <= 0) {
            return;
        }
        this.submit.emit(input.value);
        input.value = '';
        this.filter(input.value);
    }

    private filter(value: string): void {
        const filterValue = (value || '').toLocaleLowerCase();
        this.keys$.next(
            this.keys.filter((key) => {
                const keyValue = this.keyValues[key].toLocaleLowerCase();
                return keyValue.indexOf(filterValue) !== -1;
            }),
        );
    }

    private initKeys(): void {
        this.keys = this._values.keys;
        const values = this._values.values;

        const keyValues$ = this.keys.map((key) => {
            if (this.valuePrefix) {
                return this.translationService.get(this.valuePrefix + values[key].toLowerCase());
            }
            return of(values[key]);
        });

        forkJoin(keyValues$).subscribe((keyValues) => {
            this.keyValues = {};
            this.keys.forEach((key, index) => {
                this.keyValues[key] = keyValues[index];
                this.keyValues[values[key]] = key;
            });
            this.keys$.next(this.keys);
        });
    }
}
