<mat-tab-group
    [selectedIndex]="selectedIndex"
    (selectedIndexChange)="onSelectedIndexChange($event)"
    (swipeLeft)="onLeft()"
    (swipeRight)="onRight()"
    mat-align-tabs="center"
>
    <mat-tab *ngFor="let tab of tabs; trackBy: trackByInstance">
        <ng-template mat-tab-label>
            {{ tab.label }}
        </ng-template>
        <ng-container *ngTemplateOutlet="tab.template" />
    </mat-tab>
</mat-tab-group>
