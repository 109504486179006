import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProduktStatus } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, retryWhen } from 'rxjs/operators';
import { ProduktDetailFeatureDialogService } from '../service/produkt-detail-feature-dialog.service';
import { ProduktDetailFeatureValidService } from '../service/produkt-detail-feature-valid.service';
import { ProduktDetailResolveService } from '../service/produkt-detail-resolve.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailResolve {
    constructor(
        private readonly produktDetailResolveService: ProduktDetailResolveService,
        private readonly produktDetailFeatureValidService: ProduktDetailFeatureValidService,
        private readonly produktDetailFeatureDialogService: ProduktDetailFeatureDialogService,
        private readonly router: Router,
        private readonly location: Location,
    ) {
        Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
        Assert.notNullOrUndefined(produktDetailFeatureValidService, 'produktDetailFeatureValidService');
        Assert.notNullOrUndefined(produktDetailFeatureDialogService, 'produktDetailFeatureDialogService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(location, 'location');
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Produkt> {
        Assert.notNullOrUndefined(route, 'route');

        const id = route.paramMap.get('id') ?? route.parent.paramMap.get('id');
        const { required, ignoreCache } = route.data;

        return of(null).pipe(
            mergeMap(() =>
                this.produktDetailResolveService.resolve(id, !!ignoreCache).pipe(
                    map((produkt) => {
                        if (!produkt) {
                            throw new Error('empty');
                        }
                        if (produkt.status === ProduktStatus.Offen) {
                            if (required && !this.produktDetailFeatureValidService.isValid(produkt, required)) {
                                throw new Error('invalid');
                            }
                        }
                        return produkt;
                    }),
                ),
            ),
            retryWhen((errors) =>
                errors.pipe(
                    mergeMap(({ message }) => {
                        if (!required || message === 'empty') {
                            return of(false);
                        }
                        return this.produktDetailFeatureDialogService.open('feature.dialog.title', required);
                    }),
                    map((result) => {
                        if (!result) {
                            throw new Error('cancel');
                        }
                    }),
                ),
            ),
            catchError((_) => {
                this.router.navigateByUrl(this.location.path());
                return EMPTY;
            }),
        );
    }
}
