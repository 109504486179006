<ng-container *ngIf="visible$ | async">
    <app-tab-nav
        [class.display-none]="!(visible$ | async)"
        [links]="links"
        [disabled]="this.datRequestActive$ | async"
        [tabPanel]="tabPanel"
    >
        <ng-template #icon let-link>
            <app-layered-icon [icon]="link.icon" />
        </ng-template>
    </app-tab-nav>
</ng-container>
