import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface BueroEinstellungen {
    logo?: string;
    logoTop?: string;
    fusszeile?: string;
    datCustomerNumber?: string;
    datCustomerLogin?: string;
    datInterfacePartnerNumber?: string;
    datInterfacePartnerSignature?: string;
    datProductVariant?: string;
    datCustomerPassword?: string;
    watermarkEnable?: boolean;
    stempel?: string;
    unterschrift?: string;
    sachverstaendigerText?: string;
    benutzerEinstellungen?: BenutzerEinstellungen[];
}

export interface BenutzerEinstellungen extends BueroEinstellungen {
    benutzerId?: string;
    directFileSync?: boolean;
    wifiOnlyFileSync?: boolean;
    deactivate3dModel?: boolean;
    customTextBewertung?: string;
    customTextRuecknahmebewertung?: string;
    customTextZustandsbericht?: string;
    customDescriptionBewertung?: string;
    customDescriptionRuecknahmebewertung?: string;
    customDescriptionZustandsbericht?: string;
    errorHandlingSnackbar?: boolean;
    errorHandlingCloudSink?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class EinstellungenService {
    private readonly restApiUrlBuero: string;
    private readonly restApiUrlBenutzer: string;

    private benutzerEinstellungen$: BehaviorSubject<BenutzerEinstellungen> = new BehaviorSubject<BenutzerEinstellungen>(
        undefined,
    );

    constructor(private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrlBuero = `${environment.aws.apiGateway.url}/einstellungen`;
        this.restApiUrlBenutzer = `${environment.aws.apiGateway.url}/einstellungen/benutzer`;
    }

    getBuero(): Observable<BueroEinstellungen> {
        return this.httpClient.get<BueroEinstellungen>(this.restApiUrlBuero);
    }

    putBuero(bueroRequest: BueroEinstellungen): Observable<BueroEinstellungen> {
        Assert.notNullOrUndefined(bueroRequest, 'bueroRequest');
        return this.httpClient.put<BueroEinstellungen>(this.restApiUrlBuero, bueroRequest);
    }

    getBenutzer(): Observable<BenutzerEinstellungen> {
        if (this.benutzerEinstellungen$.value) {
            return of(this.benutzerEinstellungen$.value);
        } else {
            return this.httpClient
                .get<BenutzerEinstellungen>(this.restApiUrlBenutzer)
                .pipe(tap((benutzereinstellungen) => this.benutzerEinstellungen$.next(benutzereinstellungen)));
        }
    }

    putBenutzer(benutzerRequest: BenutzerEinstellungen): Observable<BenutzerEinstellungen> {
        Assert.notNullOrUndefined(benutzerRequest, 'benutzerRequest');
        return this.httpClient.put<BenutzerEinstellungen>(this.restApiUrlBenutzer, benutzerRequest).pipe(
            tap((result) => {
                if (result) {
                    this.benutzerEinstellungen$.next(benutzerRequest);
                }
            }),
        );
    }
}
